import React from 'react';
import { WrapperButton, WrapperContainer, WrapperText } from './NextStepsButton.styles';
import { TextBlock, TypeOfText } from '@/components/text/TextBlock';
import { useAppSelector } from '@app/hooks';
import { Button } from '../DefaultButton/Button';

const NextStepsButton = () => {
  let templateConfig = useAppSelector((state) => state.TemplateConfig);

  const handleClick = () => {
    window.location.href = templateConfig?.pages?.completion?.nextSteps?.button?.redirectTo || '';
  };

  const infoNextSteps = templateConfig?.pages?.completion?.nextSteps;
  return (
    <WrapperContainer>
      <TextBlock text={infoNextSteps?.title?.text || 'Next Steps...'} isHidden={infoNextSteps?.title?.isHidden || false} type={TypeOfText.H2} />
      <WrapperText>
        <TextBlock text={infoNextSteps?.verbiage?.text || ''} direction="start" type={TypeOfText.Text} isHidden={infoNextSteps?.verbiage?.isHidden || false} />
      </WrapperText>
      {!infoNextSteps?.isButtonPortal ? (
        <WrapperButton>
          <Button label={infoNextSteps?.button?.text || ''} variant="primary" onClick={handleClick} />
        </WrapperButton>
      ) : (
        <WrapperText>
          <TextBlock
            text={infoNextSteps?.contact?.text || '<p>Question? Contact us at</p>'}
            direction={'start'}
            type={TypeOfText.Text}
            isHidden={infoNextSteps?.contact?.isHidden || false}
          />
        </WrapperText>
      )}
      <WrapperText>
        <TextBlock text={infoNextSteps?.description?.text || ''} direction="center" type={TypeOfText.Text} isHidden={infoNextSteps?.description?.isHidden || false} />
      </WrapperText>
    </WrapperContainer>
  );
};

export default NextStepsButton;
