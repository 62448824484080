import { FooterWrapper } from './FooterNewVmo.styles';
import SafeHTML from '@/utils/safeHTML';
import { useAppSelector } from '@app/hooks';
import { pages } from '@/defaultVerbiages';

export const FooterNewVmo = () => {
  let templateConfig = useAppSelector((state) => state.TemplateConfig);

  return (
    <FooterWrapper>
      {(templateConfig?.pages?.vmo?.vmoV2?.footer || pages?.vmo?.vmoV2?.footer).map((item, index) => (
        <SafeHTML key={index} tag="p" html={`(${index + 1}) ${item}`} />
      ))}
    </FooterWrapper>
  );
};
