import styled, { css } from 'styled-components';

export interface WrapperProps {
  $titleIsCustomColor?: boolean;
  $titleCustomColor?: string;
  width: string;
}

const AlignText = css`
  display: flex;
  align-items: center;
`;

const fontStyles = css`
  font-style: normal;
  line-height: normal;
`;

const ResponsiveFontStyle = css`
  font-size: 0.938rem;
  font-weight: 400;
`;

export const WrapperContainer = styled.div`
  flex-direction: column;
  width: 100%;
  ${AlignText};
  background: ${(props) => props.theme.colors.bgWhite};
  border-radius: 0.688rem;
  padding: 1.563rem;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: 100%;
  }
`;
export const Verbiage = styled.div`
  padding: 1.25rem 0 0 0;
  :first-child {
    font-family: Poppins;
    ${ResponsiveFontStyle};
    ${fontStyles};
    @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      font-size: 0.75rem;
      line-height: 1.125rem;
      text-align: left;
    }
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    text-align: center;
  }
`;

export const Value = styled.p`
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 100%;
    justify-content: end;
    color: ${({ theme }) => theme.colors.textDarkGrey};
  }
`;

export const Separator = styled.div`
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    height: 0.063rem;
    background-color: #c2c9d1;
    width: 100%;
    margin: 1.25rem 0 0.938rem 0;
  }
`;

export const TitleContainer = styled.div<WrapperProps>`
  justify-content: center;
  width: ${({ width }) => width};
  padding-bottom: 0.938rem;
  h1 {
    color: ${({ theme }) => theme.colors.bgSecondary};
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: 100%;
    padding-bottom: 0rem;
  }
`;

export const LoanDetails = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.9rem;
  div {
    display: flex;
  }
  p {
    ${AlignText};
    width: 15.625rem;
    color: #273141;
    font-size: 1.063rem;
    font-weight: 500;
    ${fontStyles};
    @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      ${ResponsiveFontStyle};
      width: 7.625rem;
    }
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    :first-child {
      color: ${(props) => props.color || '#6c7584'};
      width: 100%;
      ${ResponsiveFontStyle};
    }
  }
`;
