import React from 'react';
import { ButtonStyles, ButtonContainer } from './Button.styles';
import { PlusIcon } from '@/pages/EmploymentInformation/EmploymentInformation.styles';
export interface ButtonProps {
  label: string | React.ReactNode;
  variant: 'primary' | 'secondary' | 'tertiary';
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  isDisabled?: boolean;
  buttonSize?: 's' | 'm' | 'l';
  hasIcon?: boolean;
  isHidden?: boolean;
}

export const Button: React.FC<ButtonProps> = ({ label, variant, buttonSize = 's', onClick, isDisabled = false, hasIcon = false, isHidden = false }) => {
  return (
    <ButtonContainer $variant={variant} $buttonSize={buttonSize} $isHidden={isHidden}>
      <ButtonStyles $variant={variant} onClick={onClick} disabled={isDisabled} $buttonSize={buttonSize}>
        {hasIcon && <PlusIcon />}
        {label}
      </ButtonStyles>
    </ButtonContainer>
  );
};
