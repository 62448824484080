import { TemplateConfigState } from '@/reducers/TemplateConfig.types';
export const typeOfAmount = (value: string, programType: string, isICE: boolean, program, templateConfig: TemplateConfigState) => {
  let infoAmount;
  switch (value) {
    case 'amountLabel':
      infoAmount = programType === 'helData' || isICE ? templateConfig?.pages?.vmo?.loanAmount?.title : templateConfig?.pages?.vmo?.lineAmount?.title;
      break;
    case 'minValue':
      infoAmount = isICE ? program.minLoanAmount : program.minLineAmount;
      break;
    case 'maxValue':
      infoAmount = isICE ? program.maxLoanAmount : program.maxLineAmount;
      break;
    case 'amountValue':
      infoAmount = isICE ? program.loanAmount : program.lineAmount;
      break;
    case 'minAmountLabel':
      infoAmount = programType === 'helData' || isICE ? templateConfig?.pages?.vmo?.minLoanAmount : templateConfig?.pages?.vmo?.minLineAmount;
      break;
    case 'maxAmountLabel':
      infoAmount = programType === 'helData' || isICE ? templateConfig?.pages?.vmo?.maxLoanAmount : templateConfig?.pages?.vmo?.maxLineAmount;
      break;
    default:
      infoAmount = value;
  }
  return infoAmount;
};
