import React, { useState, useEffect, useCallback } from 'react';
import { InputDataPicker, DataPickerContainer, Label, DateInputIcon, IconContainer, InputContainer } from '../DatePicker/DataPicker.styles';
import { ErrorMessage } from '../TextInput/TextInput.styled';
import { formatInput, monthsBetweenDateAndToday } from '@/utils/handleDate';
import { createValidationSchema } from '@/utils/handleDate';

export interface DateEmploymentPickerProps {
  propertyName: 'startDate' | 'endDate' | 'startDateCoBorrower' | 'endDateCoBorrower';
  value?: string;
  placeholder?: string;
  label?: string;
  onChange?: (option: string, propertyType: string | undefined, index?: number) => void;
  propertyType?: string | undefined;
  isSoftPull?: boolean;
  typeOfIcon?: string;
  iconColor?: string;
  size?: string;
  isOccupancyDate?: boolean;
  labelContainer?: boolean;
  hasAsterisk?: boolean;
  selectedOption?: string;
  setEmploymentStartDate?: React.Dispatch<React.SetStateAction<number>>;
  setEmploymentEndDate?: React.Dispatch<React.SetStateAction<number>>;
  isHidden: boolean;
  isDisabled?: boolean;
  index?: number;
  hasLessThanTwoYears?: boolean;
}

export const DateEmploymentPicker: React.FC<DateEmploymentPickerProps> = ({
  propertyType,
  propertyName,
  value = '',
  placeholder = '',
  onChange,
  typeOfIcon,
  iconColor = '#5140E9',
  size = '18rem',
  labelContainer = false,
  label = '',
  hasAsterisk = false,
  selectedOption = 'false',
  setEmploymentStartDate,
  setEmploymentEndDate,
  isHidden,
  isDisabled = false,
  index,
  hasLessThanTwoYears = false,
}) => {
  const [formattedValue, setFormattedValue] = useState(value || '');
  const [validationDateMessage, setValidationDateMessage] = useState('');
  const [invalidDate, setInvalidDate] = useState(false);
  const [maxLength, setMaxLength] = useState<number | undefined>(undefined);
  const isRequired = true;
  const formSchema = createValidationSchema(propertyName, isRequired);
  const isEmploymentStartDate = propertyName === 'startDate';
  const hasEmployedEndDate = propertyName === 'endDate';
  const isEmploymentCoBorrowerStartDate = propertyName === 'startDateCoBorrower';
  const hasEmployedCoBorrowerEndDate = propertyName === 'endDateCoBorrower';
  const isSoftPull = !isEmploymentStartDate && !hasEmployedEndDate && !isEmploymentCoBorrowerStartDate && !hasEmployedCoBorrowerEndDate;
  const [startEmploymentDate, setStartEmploymentDate] = useState<number>(0);
  const [totalEmployment, setTotalEmployment] = useState<number>(0);
  const [startEmploymentCoBorrowerDate, setStartEmploymentCoBorrowerDate] = useState<number>(0);
  const [totalCoBorrowerEmployment, setTotalCoBorrowerEmployment] = useState<number>(0);

  useEffect(() => {
    isEmploymentStartDate || hasEmployedEndDate ? dateToEmployment() : dateToEmploymentCoBorrower();
    if (selectedOption === 'true') {
      if (setEmploymentEndDate) setEmploymentEndDate(0);
    }
  }, [selectedOption, startEmploymentDate, totalEmployment, totalCoBorrowerEmployment, startEmploymentCoBorrowerDate]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMaxLength(10);
    const formatted = formatInput(event.target.value, propertyName);
    setFormattedValue(formatted);
    if (isEmploymentStartDate) dateToEmployment();
    if (onChange) onChange(event.target.value, propertyType, index);
  };
  const dateToEmploymentCoBorrower = useCallback(() => {
    let monthsDateAndToday = monthsBetweenDateAndToday(formattedValue, propertyName);
    let endDate = 0;
    if (propertyType === 'startDateCoBorrower') {
      let startDateEmployed = startEmploymentCoBorrowerDate && !Number.isNaN(startEmploymentCoBorrowerDate) ? startEmploymentCoBorrowerDate : monthsDateAndToday;

      startDateEmployed = monthsDateAndToday;
      if (setEmploymentStartDate) setEmploymentStartDate(startDateEmployed);
      setStartEmploymentCoBorrowerDate(startDateEmployed);
    }
    if (propertyType === 'endDateCoBorrower') {
      const endDateEmployed = monthsDateAndToday;
      endDate = endDateEmployed;
    }
    const hasMinTimeAtWork = startEmploymentCoBorrowerDate >= endDate && startEmploymentCoBorrowerDate >= 48;
    setTotalCoBorrowerEmployment(startEmploymentCoBorrowerDate - endDate);
    if (setEmploymentEndDate) setEmploymentEndDate(endDate);
    return hasMinTimeAtWork;
  }, [formattedValue, propertyName, propertyType, startEmploymentCoBorrowerDate, setTotalEmployment, setEmploymentStartDate, setEmploymentEndDate]);

  const dateToEmployment = useCallback(() => {
    let monthsDateAndToday = monthsBetweenDateAndToday(formattedValue, propertyName);
    let endDate = 0;
    if (propertyType === 'startDate') {
      let startDateEmployed = startEmploymentDate && !Number.isNaN(startEmploymentDate) ? startEmploymentDate : monthsDateAndToday;

      startDateEmployed = monthsDateAndToday;
      if (setEmploymentStartDate) setEmploymentStartDate(startDateEmployed);
      setStartEmploymentDate(startDateEmployed);
    }
    if (propertyType === 'endDate') {
      const endDateEmployed = monthsDateAndToday;
      endDate = endDateEmployed;
    }
    const hasMinTimeAtWork = startEmploymentDate >= endDate && startEmploymentDate >= 24;
    setTotalEmployment(startEmploymentDate - endDate);
    if (setEmploymentEndDate) setEmploymentEndDate(endDate);
    return hasMinTimeAtWork;
  }, [formattedValue, propertyName, propertyType, startEmploymentDate, setTotalEmployment, setEmploymentStartDate, setEmploymentEndDate]);

  const onBlur = async () => {
    try {
      await formSchema.validate({ [propertyName]: formattedValue });
      const hasMinTimeAtWork = isEmploymentStartDate || hasEmployedEndDate ? dateToEmployment() : dateToEmploymentCoBorrower();
      if (formattedValue.length < 10 || formattedValue.length > 11) {
        setValidationDateMessage('Date must be in MM/DD/YYYY format');
        setInvalidDate(true);
      } else if (!hasMinTimeAtWork && !hasEmployedEndDate && !hasEmployedCoBorrowerEndDate && !hasLessThanTwoYears) {
        setValidationDateMessage(`We need a minimum of 2 years employment information.`);
        setInvalidDate(true);
      } else {
        setValidationDateMessage('');
        setInvalidDate(false);
      }
    } catch (error) {
      console.error(error);
      setValidationDateMessage('Date is required');
      setInvalidDate(true);
    }
  };

  useEffect(() => {
    if (isDisabled) {
      setInvalidDate(false);
      setFormattedValue('');
    }
  }, [isDisabled]);

  return (
    <DataPickerContainer size={size} $isHidden={isHidden} $isDisabled={isDisabled}>
      {labelContainer && (
        <Label>
          {label}
          {hasAsterisk && <p>*</p>}
        </Label>
      )}
      <InputContainer $isError={invalidDate}>
        {typeOfIcon && (
          <IconContainer>
            <DateInputIcon $iconColor={iconColor} />
          </IconContainer>
        )}
        <InputDataPicker
          placeholder={placeholder}
          onFocus={() => setInvalidDate(false)}
          onBlur={onBlur}
          onChange={handleChange}
          value={formattedValue}
          $isSoftPull={isSoftPull}
          size={size}
          maxLength={maxLength}
          disabled={isDisabled}
        />
      </InputContainer>

      {invalidDate && <ErrorMessage>{validationDateMessage}</ErrorMessage>}
    </DataPickerContainer>
  );
};

export default DateEmploymentPicker;
