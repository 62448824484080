import React, { useState, useEffect } from 'react';
import { TextInput } from '../input/TextInput/TextInput';
import { Reprice, LimitAmount, InputContainer, Spinner } from './RefreshableCurrencyInput.styles';
import { Button } from '../Button/DefaultButton/Button';
import { useAppSelector, useAppDispatch } from '@app/hooks';
import { formatNumber, convertToNumber } from '@/utils/formatValues';
import { handleDrawAmount } from '@/utils/handleDrawAmount';
import { executeAction } from '@/utils/handleEndpoints';
import _ from 'lodash';

interface RefreshableCurrencyInputPros {
  limitAmount: string;
  typeOfAmount?: string;
  variant?: 'primary' | 'secondary' | 'tertiary';
  auxMinValue?: number;
  auxMaxValue?: number;
  auxValue?: number;
  setIsReprice: React.Dispatch<React.SetStateAction<boolean>>;
}

const RefreshableCurrencyInput: React.FC<RefreshableCurrencyInputPros> = ({
  setIsReprice,
  limitAmount,
  typeOfAmount,
  variant = 'primary',
  auxMinValue = 50000,
  auxMaxValue = 100000,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>('');
  const dispatch = useAppDispatch();
  let templateConfig = useAppSelector((state) => state.TemplateConfig);
  let loanApplication = useAppSelector((state) => state.LoanApplication);
  const isICE = templateConfig.isICE ?? false;
  const typeOfProgram = loanApplication.programType === 'HEL' ? 'helData' : 'helocData';
  const isLineAmount =
    typeOfAmount === 'LINE AMOUNT' || typeOfAmount === 'LOAN AMOUNT'
      ? isICE
        ? loanApplication?.loanAmount.toString()
        : loanApplication?.lineAmount.toString()
      : loanApplication?.drawAmount.toString();
  useEffect(() => {
    if (typeOfAmount === 'INITIAL DRAW' && loanApplication?.drawAmount !== 0) {
      const drawValue = loanApplication?.drawAmount;
      const formatDraw = formatNumber(drawValue);
      setInputValue(formatDraw);
    } else {
      const lineValue = loanApplication?.lineAmount === 0 ? loanApplication?.selectedProgram?.lineAmount : loanApplication?.lineAmount;
      const formatLine = formatNumber(Number(lineValue));
      setInputValue(formatLine);
    }
  }, [typeOfAmount, loanApplication]);

  const handleInputChange = (value: string) => {
    setInputValue(value);
  };

  const HandleRepriceRates = async () => {
    setLoading(true);
    if (setIsReprice) setIsReprice(false);
    const cleanedString = inputValue.replace(/[$,]/g, '');
    const numberValue = parseFloat(cleanedString);
    let auxLoanApplication = _.cloneDeep(loanApplication);
    const isReprice = true;
    if (typeOfAmount === 'LINE AMOUNT' || typeOfAmount === 'LOAN AMOUNT') {
      auxLoanApplication.lineAmount = Math.trunc(numberValue / 100) * 100;
      if (isICE) auxLoanApplication.loanAmount = numberValue;
    } else {
      auxLoanApplication.drawAmount = Math.trunc(numberValue / 100) * 100;
      auxLoanApplication.minDrawAmount = auxLoanApplication.lineAmount;
    }
    const drawAmountValues = handleDrawAmount(numberValue, auxLoanApplication);
    dispatch({
      type: 'LoanApplication/updateValue',
      payload: { ...drawAmountValues },
    });
    try {
      const response = await executeAction(isICE, auxLoanApplication, dispatch, templateConfig, isReprice);
      setLoading(false);

      if (response && 'data' in response) {
        const responseData = isICE ? response?.data?.helRates[0] : response?.data[typeOfProgram]?.rates[0];
        const updateProgram = auxLoanApplication.selectedProgram;
        const estimatedMonthly = Math.round(parseFloat(responseData.estimatedMonthlyPayment));
        updateProgram.estimatedMonthlyPayment = estimatedMonthly.toString();
        if (isICE) {
          updateProgram.loanAmount = responseData.loanAmount.toString();
        } else {
          updateProgram.lineAmount = responseData.lineAmount;
        }
        updateProgram.apr = responseData.apr;
        setLoading(false);
        if (setIsReprice) setIsReprice(true);
        dispatch({
          type: 'LoanApplication/updateValue',
          payload: { ...auxLoanApplication },
        });
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };
  const isOnRange = () => {
    return convertToNumber(inputValue) < Number(auxMinValue) || convertToNumber(inputValue) > Number(auxMaxValue);
  };

  useEffect(() => {
    setInputValue(isLineAmount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loanApplication]);

  return (
    <>
      <Reprice>
        <InputContainer>
          <TextInput placeholder={'$0'} propertyType={'remaining'} onChange={handleInputChange} value={formatNumber(Number(inputValue))} />
          <Button label={!loading ? 'Reprice' : <Spinner />} variant={variant} onClick={HandleRepriceRates} isDisabled={loading || isOnRange()}></Button>
        </InputContainer>
        <LimitAmount>{limitAmount}</LimitAmount>
      </Reprice>
    </>
  );
};

export default RefreshableCurrencyInput;
