export const handleShowValue = (value: { title: string }): string => {
  const mapping: Record<string, string> = {
    'LINE AMOUNT': 'lineAmount',
    'ESTIMATED APR': 'apr',
    'EST MONTHLY PMT': 'estimatedMonthlyPayment',
    'ORIGINATION FEE': 'loanOriginationFee',
    'MINIMUM DRAW': 'minDrawAmount',
    'MAXIMUM DRAW': 'maxDrawAmount',
  };
  return mapping[value.title] || '';
};
