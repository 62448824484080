import styled, { css } from 'styled-components';

interface DebtConsolidationProps {
  $isSelected?: boolean;
  $isHidden?: boolean;
  $isCustomColor?: boolean;
  $barCustomBackground?: string;
  $disabledProgramBackground?: string;
  $selectedProgramBackground?: string;
  $activeTextBottomColor?: string;
  $disabledTextBottomColor?: string;
  $titleIsCustomColor?: boolean;
  $titleCustomColor?: string;
  $subtitleIsCustomColor?: boolean;
  $subtitleCustomColor?: string;
}

const TitleSubtitleText = css`
  text-align: center;
  font-family: Poppins;
  font-style: normal;
  line-height: normal;
  justify-content: center;
  font-size: 1.375rem;
`;

export const HeaderContainer = styled.div<DebtConsolidationProps>`
  display: ${({ $isHidden }) => ($isHidden ? 'none' : 'flex')};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.563rem 0;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding-bottom: 0;
  }
  :first-child {
    ${TitleSubtitleText};
    font-weight: 700;
    color: ${({ theme, $titleIsCustomColor, $titleCustomColor }) => ($titleIsCustomColor ? $titleCustomColor : theme.colors.bgSecondary)};
    @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      font-size: 0.733rem;
      padding-bottom: 0;
    }
  }
`;

export const SubtitleContainer = styled.div<DebtConsolidationProps>`
  display: ${({ $isHidden }) => ($isHidden ? 'none' : 'flex')};
  justify-content: center;
  align-items: center;
  :first-child {
    ${TitleSubtitleText};
    font-weight: 500;
    color: ${({ theme, $subtitleIsCustomColor, $subtitleCustomColor }) => ($subtitleIsCustomColor ? $subtitleCustomColor : theme.colors.bgSecondary)};
    @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      font-size: 0.628rem;
      font-weight: 400;
    }
  }
`;

export const AmountContainer = styled.div<DebtConsolidationProps>`
  display: ${({ $isHidden }) => ($isHidden ? 'none' : 'flex')};
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
  gap: 1.5rem;
  width: 95%;
  flex-wrap: wrap;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    gap: 2rem;
    width: 100%;
  }
`;

export const AmountVerbiageContainer = styled.div<DebtConsolidationProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  gap: 1.5rem;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    flex-direction: column-reverse;
    gap: 1rem;
    width: 100%;
  }
`;

export const TableHelpContainer = styled.div<DebtConsolidationProps>`
  border-radius: 1.125rem;
  background: ${(props) => props.theme.colors.bgWhite};
  box-shadow: 0rem 0.063rem 0.625rem 0rem rgba(0, 0, 0, 0.13);
  padding: 1rem 6.438rem;
  max-width: 58.625rem;
  margin-bottom: 1.5rem;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding: 1rem 0.523rem;
    margin: 1.5rem 0;
  }
  :first-child {
    font-family: Poppins;
    font-size: 0.688rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.125rem;
    text-align: center;
    @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      font-family: Poppins;
      font-size: 0.523rem;
      line-height: 1rem;
    }
  }
`;
