import styled from 'styled-components';
import PlusIcons from '../../assets/images/plus-icon.svg?react';

interface EmploymentInfoWrapper {
  $isMobile?: boolean;
}
export const EmploymentWrapper = styled.section<EmploymentInfoWrapper>`
  display: flex;
  flex-direction: column;
  section {
    margin-bottom: 0rem;
    padding-bottom: 1.5rem;
  }
  nav {
    margin-bottom: 0rem;
    justify-content: end;
    height: max-content;
    @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
      padding: 0 0.5rem;
      justify-content: start;
    }
    button {
      height: max-content;
      padding: 0.5rem 0;
      margin: 0;
      background: transparent;
      border: none;
      font-size: 0.938rem;
      font-weight: 400;
      font-family: 'Poppins';
      color: ${({ theme }) => theme.colors.textPrimaryLd};
      @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
        font-size: 0.75rem;
        justify-content: flex-start;
      }
    }
  }
`;

export const Separator = styled.div<EmploymentInfoWrapper>`
  height: 0.063rem;
  background-color: ${(props) => props.theme.colors.textLightGrey};
  width: 100%;
`;

export const CurrentMonthlyWrapper = styled.section<EmploymentInfoWrapper>`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 3.063rem;
  margin: 0 1.5rem;
  padding: 1.563rem 0.813rem;
  border-radius: 0 0 0.688rem 0.688rem;
  background-color: ${(props) => props.theme.colors.textDarkGrey};
  gap: 1.313rem;
  p {
    color: ${(props) => props.theme.colors.bgWhite};
    font-family: 'Nunito';
    font-size: 1.063rem;
    font-weight: 700;
    @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
      font-size: 0.75rem;
    }
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-direction: column;
    height: 100%;
    div {
      width: 100%;
      justify-content: center;
    }
  }
`;

export const PlusIcon = styled(PlusIcons)`
  width: 1.063rem;
  height: 1.25rem;
  margin-right: 0.5rem;
  path {
    fill: ${({ theme }) => theme.colors.textPrimaryLd};
  }
`;
