import React, { useEffect } from 'react';
import { Wrapper, WrapperText } from './MapOut.styles';
import { useAppSelector } from '@app/hooks';
import { GoogleMap, Marker } from '@react-google-maps/api';
import GeolocationService from '../../../geolocation-service';
import { TextBlock } from '@components/text/TextBlock';

export const MapOut = () => {
  const loanApplication = useAppSelector((state) => state.LoanApplication);
  const templateConfig = useAppSelector((state) => state.TemplateConfig);
  const borrowerProperty = {
    propertyStreetAddress: loanApplication?.borrowerProperty?.propertyStreetAddress ?? '',
    propertyCity: loanApplication?.borrowerProperty?.propertyCity ?? '',
    propertyState: loanApplication?.borrowerProperty?.propertyState ?? '',
    propertyLatitude: loanApplication?.borrowerProperty?.propertyLatitude ?? '',
    propertyLongitude: loanApplication?.borrowerProperty?.propertyLongitude ?? '',
    propertyZip: loanApplication?.borrowerProperty?.propertyZip ?? '',
  };
  const { propertyStreetAddress, propertyCity, propertyState, propertyLatitude, propertyLongitude, propertyZip } = borrowerProperty;
  const addressString = `${propertyStreetAddress}, ${propertyCity}, ${propertyState} ${propertyZip}`;
  const center = { lat: parseFloat(propertyLatitude.toString()), lng: parseFloat(propertyLongitude.toString()) };
  const geolocationCaller = new GeolocationService();

  useEffect(() => {
    if (!propertyLatitude || !propertyLongitude) {
      const callGeolocationService = async () => {
        try {
          await geolocationCaller.getGeolocationData(addressString, { propertyUnit: '' }, { propertyCounty: '' }, { propertyCity: propertyCity }, { propertyIdPlace: '' });
        } catch (error) {
          console.log(error);
        }
      };
      callGeolocationService();
    }
  }, [propertyCity, propertyLatitude, propertyLongitude, addressString]);

  return (
    <Wrapper $isHidden={templateConfig?.pages?.LetsGetStartedAddress?.map?.isHidden ?? false}>
      {propertyLatitude && propertyLongitude ? (
        <GoogleMap
          center={center}
          zoom={20}
          mapContainerStyle={{ width: '100%', height: '100%', borderRadius: '0.688rem 0.688rem 0.688rem 0.688rem' }}
          options={{
            draggable: false,
            zoomControl: false,
            streetViewControl: false,
            mapTypeControl: false,
            fullscreenControl: false,
            rotateControl: false,
            mapTypeId: 'satellite',
          }}
        >
          <Marker position={center} visible={true} />
        </GoogleMap>
      ) : (
        <WrapperText className="property-not-found">
          <TextBlock text="No property to display." />
        </WrapperText>
      )}
    </Wrapper>
  );
};
