import { LoanApplicationState } from '@/reducers/LoanApplication.types';

interface Program {
  apr: number;
  programType: string;
  programName: string;
  maxAmountApproved: number;
  description: string;
  term: string;
  lineAmount: string;
  estimatedMonthlyPayment: string;
  estimatedApr: string;
  minLineAmount: string;
  maxLineAmount: string;
  minDrawAmount: string;
  customVerbiage: string;
  originationFee: string;
  loanAmount?: string;
  minLoanAmount?: string;
  maxLoanAmount?: string;
}
export const handleProgramSelection = (program: Program, auxLoanApplication: LoanApplicationState): LoanApplicationState => {
  const { programName, programType, estimatedMonthlyPayment, apr, minLineAmount, maxLineAmount, lineAmount, minDrawAmount, loanAmount, minLoanAmount, maxLoanAmount } = program;
  return {
    ...auxLoanApplication,
    selectedProgram: program,
    selectedProgramName: programName,
    programName,
    programType,
    apr,
    lineAmount: Number(lineAmount),
    drawAmount: Number(minDrawAmount),
    estimatedMonthlyPayment: Number(estimatedMonthlyPayment),
    maxDrawAmount: Number(maxLineAmount),
    minDrawAmount: Number(minLineAmount),
    maxLineAmount: Number(maxLineAmount),
    minLineAmount: Number(minLineAmount),
    ...(loanAmount != null && { loanAmount: Number(loanAmount) }),
    ...(minLoanAmount != null && { minLoanAmount: Number(minLoanAmount) }),
    ...(maxLoanAmount != null && { maxLoanAmount: Number(maxLoanAmount) }),
    initialLineAmount: Number(lineAmount),
  };
};
