import styled, { css } from 'styled-components';
import SquareActiveSvg from '../../../assets/images/square-active.svg?react';
import SquareCheckSvg from '../../../assets/images/square-check.svg?react';

interface TableProgramProps {
  $isBarCustomColor?: boolean;
  $barCustomColor?: string;
  $isIconCustomColor?: boolean;
  $iconCustomColor?: string;
  $barTextCustomColor?: string;
  $isHidden?: boolean;
}

const fontStyle = css`
  font-family: Poppins;
  font-size: 0.813rem;
  line-height: 1.188rem;
  font-style: normal;
  text-align: center;
`;
export const TableContainer = styled.table<TableProgramProps>`
  display: ${({ $isHidden }) => $isHidden && 'none'};
  width: 100%;
  border-collapse: collapse;
  border-radius: 0.5rem;
  overflow: hidden;
  max-width: 58.625rem;
  height: 2.5rem;
  box-shadow: 0px 0.837px 3.349px -0.837px rgba(0, 0, 0, 0.16);
  th {
    font-weight: 500;
    max-width: 8rem;
    padding: 0.2rem 1.188rem;
    ${fontStyle};
    @media only screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
      padding: 0.4rem 0.2rem;
      font-size: 0.419rem;
      font-style: normal;
      font-weight: 500;
      line-height: 0.485rem;
    }
  }

  th:last-child {
    text-align: right;
  }
  td {
    padding: 1rem 1.188rem;
    overflow: hidden;
    color: ${({ theme }) => theme.colors.bgDarkGrey};
    text-overflow: ellipsis;
    font-weight: 400;
    ${fontStyle};
    @media only screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
      padding: 0.1rem 0.4rem;
      font-size: 0.523rem;
      font-style: normal;
      font-weight: 400;
      line-height: 0.485rem;
    }
  }
`;
const IconsSize = css`
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
`;

export const Body = styled.tbody`
  tr {
    &:nth-child(even) {
      background-color: ${({ theme }) => theme.colors.bgLightLilac};
      border: 0.063rem solid ${({ theme }) => theme.colors.borderLightLilac};
    }

    &.disabled {
      opacity: 0.6;
    }

    &.ml-fc-dev {
      background-color: ${({ theme }) => theme.colors.bgLightWhite};
    }
  }
`;

export const Head = styled.thead<TableProgramProps>`
  background-color: ${({ theme, $isBarCustomColor, $barCustomColor }) => ($isBarCustomColor ? $barCustomColor : theme.colors.bgSecondary)};
  color: ${({ theme, $isBarCustomColor, $barTextCustomColor }) => ($isBarCustomColor ? $barTextCustomColor : theme.colors.bgWhite)};
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  @media only screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    padding: 0.1rem 0.2rem;
  }
`;

export const ActiveIcon = styled(SquareActiveSvg)`
  ${IconsSize};
  path {
    width: 1rem;
  }
  .circle-color {
    fill: ${({ theme }) => theme.colors.borderColor};
  }
`;
export const CheckedIcon = styled(SquareCheckSvg)<TableProgramProps>`
  ${IconsSize};
  rect {
    fill: ${({ $isIconCustomColor, $iconCustomColor }) => $isIconCustomColor && $iconCustomColor};
  }
  margin-right: 0.5rem;
  fill: ${({ theme }) => theme.colors.bgGreen};
`;
