import styled, { css } from 'styled-components';

interface PotentialMonthlyProps {
  $isHidden?: boolean;
}
const FontsStyles = css`
  font-size: 0.875rem;
  font-style: normal;
  line-height: 1.125rem;
  font-family: Poppins;
  color: ${(props) => props.theme.colors.textDarkGrey};
  @media only screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
    font-size: 0.638rem;
    line-height: 0.832rem;
    text-align: start;
  }
`;

const FlexStyles = css`
  display: flex;
  flex-direction: column;
`;

export const WrapperContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  border-radius: 1.125rem;
  gap: 1rem;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    align-items: center;
    gap: 0;
  }
`;

export const HeaderContainer = styled.div`
  ${FlexStyles};
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  :first-child {
    display: flex;
    font-family: Poppins;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    color: ${({ theme }) => theme.colors.textDarkGrey};
    span {
      font-size: 0.5rem;
      justify-content: flex-start;
    }
    @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      font-size: 0.923rem;
      text-align: center;
      line-height: 0.924rem;
    }
  }
`;

export const ChardContainer = styled.div<PotentialMonthlyProps>`
  display: ${({ $isHidden }) => $isHidden && 'none'};
  width: 40%;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: 54%;
  }
`;

export const ProgressContainer = styled.div`
  ${FlexStyles};
  border-radius: 1.125rem;
  gap: 1.7rem;
  width: 80%;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.xs}) {
    width: 100%;
  }
`;

const RowStyles = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ColStyles = css`
  ${FlexStyles};
  align-items: flex-start;
  min-width: 20%;
  p {
    text-align: right;
    font-weight: 400;
    ${FontsStyles};
    width: 100%;
  }
`;
export const WrapperPotentialContainer = styled.div<PotentialMonthlyProps>`
  display: ${({ $isHidden }) => ($isHidden ? 'none' : 'flex')};
  flex-direction: column;
  justify-content: space-between;
  border-radius: 1.125rem;
  box-shadow: 0rem 0.063rem 0.625rem 0rem rgba(0, 0, 0, 0.13);
  padding: 1.563rem;
  width: 55%;
  gap: 1rem;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    width: 100%;
    padding: 1rem;
  }
  h4 {
    margin: 0;
    margin: 0;
  }
`;

export const Row = styled.div`
  ${RowStyles};
`;

export const RowContainer = styled.div<PotentialMonthlyProps>`
  display: ${({ $isHidden }) => ($isHidden ? 'none' : 'flex')};
  flex-direction: column;
  gap: 0.3rem;
`;
export const ColPercent = styled.div`
  ${ColStyles};
  p {
    font-weight: 500;
  }
`;

export const Col = styled.div`
  ${ColStyles};

  span {
    font-weight: 600;
    ${FontsStyles};
    margin: 0;
    width: 100%;
  }
`;

export const ColMonthly = styled.div`
  ${ColStyles};
  p {
    color: ${({ theme }) => theme.colors.bgGreen};
    font-weight: 700;
  }
`;

export const Separator = styled.div`
  height: 0.063rem;
  background-color: ${(props) => props.theme.colors.bgSecondary};
  width: 100%;
`;
