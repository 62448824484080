import styled from 'styled-components';

interface Map {
  $isHidden: boolean;
}
export const Wrapper = styled.section<Map>`
  display: ${({ $isHidden }) => ($isHidden ? 'none' : 'flex')};
  justify-content: center;
  align-items: center;
  width: 70%;
  height: 31rem;
  background: white;
  border-radius: 0.688rem 0.688rem 0.688rem 0.688rem;
  @media only screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
    width: 100%;
    height: 13.5rem;
    margin-top: 1rem;
  }
`;

export const WrapperText = styled.section`
  display: flex;
`;
