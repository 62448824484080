import styled, { css } from 'styled-components';

export const Title = styled.h1`
  font-size: 4em;
  text-align: center;
`;

export const Container = styled.div`
  ${(props) => {
    return css`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem;
      @media only screen and (max-width: ${props.theme.breakpoints.sm}) {
      flex-direction: column;
      }
      }
    `;
  }}
`;
