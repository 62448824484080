import React, { useState } from 'react';
import { FullStoryWrapper, ButtonWrapper } from './FullStoryConsent.styles';
import { TextBlock } from '@/components/text/TextBlock';
import { TypeOfText } from '@/components/text/TextBlock';
import { Button } from '@components/Button/DefaultButton/Button';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { pages } from '@/defaultVerbiages';
import _ from 'lodash';
const FullStoryConsent = () => {
  const dispatch = useAppDispatch();
  const [openModal, setOpenModal] = useState(true);

  const loanApplication = useAppSelector((state) => state.LoanApplication);
  const templateConfig = useAppSelector((state) => state.TemplateConfig);
  const isHidden = templateConfig?.features?.fullStory?.isHidden ?? false;
  const isLoanDepot = templateConfig?.lenderName === 'loanDepot';
  const updateConsentValue = (value: boolean) => {
    const updatedLoanApplication = {
      ..._.cloneDeep(loanApplication),
      consent: {
        ...loanApplication.consent,
        fullstoryConsent: value,
      },
    };
    setOpenModal(false);
    dispatch({
      type: 'LoanApplication/updateValue',
      payload: updatedLoanApplication,
    });
  };
  if (isHidden || !openModal) return null;
  const {
    title = 'Service Consent',
    body = 'We use FullStory to monitor how consumers interact with our website in order to improve the digital experience. By clicking ‘Accept’, you consent to our use of FullStory in accordance with our privacy policy. For more information, go to <a target="_newtab" href="https://www.loandepot.com/privacypolicy">Privacy Policy | loanDepot</a>.',
  } = templateConfig?.features?.fullStory || {};
  const declineLabel = templateConfig?.pages?.features?.fullStory?.buttonDecline || 'Decline';
  const acceptLabel = templateConfig?.pages?.features?.fullStory?.buttonAccept || 'Accept';
  return (
    <>
      {isLoanDepot && (
        <FullStoryWrapper>
          <TextBlock text={title} direction="center" type={TypeOfText.H2} />
          <TextBlock text={body} type={TypeOfText.Subtitle} />
          <ButtonWrapper>
            <Button label={declineLabel} onClick={() => updateConsentValue(false)} variant="secondary" buttonSize="l" />
            <Button label={acceptLabel} onClick={() => updateConsentValue(true)} variant="primary" buttonSize="l" />
          </ButtonWrapper>
        </FullStoryWrapper>
      )}
    </>
  );
};
export default FullStoryConsent;
