import styled, { css } from 'styled-components';
import InfoIcons from '../../assets/images/info-icons.svg?react';
import { TypeOfText } from './TextBlock';
type TextBlockStyles = {
  $text?: string;
  $direction?: string;
  color?: string;
  width?: string;
  $isHidden?: boolean;
  $isCustomColor?: boolean;
  $customColor?: string;
  $infoIconColor?: string;
  type?: TypeOfText;
  $isUnderline?: boolean;
  $isLinkModal?: boolean;
};

const FontsStyles = css`
  font-family: Poppins;
  font-style: normal;
  line-height: normal;
`;

export const Container = styled.div<TextBlockStyles>`
  display: ${({ $isHidden }) => ($isHidden ? 'none' : 'flex')};
  ${({ $direction }) => css`
    justify-content: ${$direction};
  `}
  ${({ width }) => css`
    width: ${width};
  `}
  align-items: center;
`;
export const Text = styled.h1<TextBlockStyles>`
  ${FontsStyles};
  font-size: 1.188rem;
  margin: 0;
  color: ${({ $isCustomColor, $customColor, theme, color }) => ($isCustomColor ? $customColor : color === 'primary' ? theme.colors.primary : theme.colors?.textDarkGrey)};

  a {
    text-decoration: ${({ $isUnderline }) => ($isUnderline ? 'underline' : 'none')};
    cursor: pointer;
    &:hover {
      font-weight: 600;
      text-decoration: ${({ $isUnderline }) => (!$isUnderline ? 'underline' : 'none')};
      transform: none;
    }
  }

  ${({ type }) =>
    type === TypeOfText.H1 &&
    css`
      ${FontsStyles};
      text-align: center;
      font-size: 1.563rem;
      font-weight: 600;
      @media only screen and (max-width: ${({ theme }) => theme.breakpoints?.sm}) {
        font-size: 1.125rem;
      }
    `}
  ${({ type }) =>
    type === TypeOfText.H2 &&
    css`
      ${FontsStyles};
      font-size: 1.313rem;
      font-weight: 500;
      text-align: center;
      height: 2rem;

      @media only screen and (max-width: ${({ theme }) => theme.breakpoints?.sm}) {
        font-size: 1.125rem;
        font-weight: 400;
        letter-spacing: -0.011rem;
      }
    `}
  ${({ type }) =>
    type === TypeOfText.H3 &&
    css`
      font-weight: 400;
      font-size: 1.063rem;
      line-height: normal;
      font-style: normal;
      white-space: normal;
      a {
        color: ${({ theme }) => theme.colors.primary};
      }
      @media only screen and (max-width: ${({ theme }) => theme.breakpoints?.sm}) {
        font-size: 0.75rem;
        font-weight: 400;
      }
    `}
      ${({ type }) =>
    type === TypeOfText.H4 &&
    css`
      font-weight: 500;
      font-size: 1.063rem;
      ${FontsStyles};
      white-space: normal;
      text-align: center;
      text-decoration-line: underline;
      a {
        color: ${({ theme }) => theme.colors.primary};
      }
      @media only screen and (max-width: ${({ theme }) => theme.breakpoints?.sm}) {
        text-align: center;
        font-feature-settings:
          'liga' off,
          'clig' off;
        font-family: Poppins;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.125rem;
        text-decoration-line: underline;
      }
    `}
  ${({ type }) =>
    type === TypeOfText.Subtitle &&
    css`
      ${FontsStyles};
      font-size: 1rem;
      font-weight: 400;
      letter-spacing: -0.01rem;
      text-align: center;
    `}
    ${({ type }) =>
    type === TypeOfText.Text &&
    css`
      ${FontsStyles};
      font-size: 0.75rem;
      font-weight: 600;
    `}
    ${({ type }) =>
    type === TypeOfText.Subtitle &&
    css`
      ${FontsStyles};
      font-size: 1rem;
      font-weight: 400;
      letter-spacing: -0.01rem;
      text-align: center;
    `}
    ${({ $isLinkModal }) =>
    $isLinkModal &&
    css`
      text-decoration-line: none;
    `}
`;

export const InfoIcon = styled(InfoIcons)<TextBlockStyles>`
  width: 1.18rem;
  height: 1.3rem;
  margin-left: 1rem;
  cursor: pointer;
  path {
    fill: ${({ $infoIconColor, theme }) => ($infoIconColor ? $infoIconColor : theme.colors.primary)};
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints?.sm}) {
    display: none;
  }
`;
