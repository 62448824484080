import styled from 'styled-components';

export interface DefaultModalProps {
  $isOpen?: boolean;
  $isLoading?: boolean;
}

export const BackDrop = styled.div<DefaultModalProps>`
  display: ${(props) => (props.$isOpen ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1040;
  transition: opacity 0.3s ease;
  opacity: ${(props) => (props.$isOpen ? 1 : 0)};
  pointer-events: ${(props) => (props.$isOpen ? 'auto' : 'none')};
`;
export const Modal = styled.div<DefaultModalProps>`
  display: ${(props) => (props.$isOpen ? 'block' : 'none')};
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  border-radius: 0.5rem;
  box-shadow: 0 0.625rem 3.125rem rgba(0, 0, 0, 0.5);
  z-index: 1050;
  max-height: 50rem;
  min-height: 21.875rem;
  width: 35%;
  overflow-y: auto;
  @media only screen and (max-width: ${(props) => props.theme.breakpoints.lg}) {
    width: 95%;
  }
`;
export const ModalTitle = styled.div<DefaultModalProps>`
  font-weight: 600;
  margin-bottom: 0;
  font-size: ${(props) => (props.$isLoading ? '0.8rem' : '1.563rem')};
  display: flex;
  line-height: normal;
  justify-content: center;
  align-items: center;
  font-family: Poppins;
  text-align: center;
  color: ${(props) => (props.$isLoading ? props.theme.colors.textDarkGrey : props.theme.colors.textPrimaryLd)};
  @media only screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
    font-size: 1.2rem;
  }
`;
export const ModalBody = styled.div`
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Poppins, sans-serif;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem 4rem;
  @media only screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
    padding: 1.125rem;
  }
`;
export const ModalContent = styled.div<DefaultModalProps>`
  font-weight: 400;
  font-size: ${(props) => (props.$isLoading ? '0.8rem' : '1.25rem')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Poppins, sans-serif;
  line-height: normal;
  white-space: normal;
  text-align: center;
  gap: 0.5rem;
  @media only screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
    font-size: 1rem;
  }
`;
export const ModalFooter = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 20rem;
  margin: 0;
  width: 100%;
  p {
    padding: 1rem 4rem;
    text-align: center;
  }
  nav {
    margin-bottom: 0.5rem;
  }
  nav:nth-of-type(1) {
    button {
      width: 80%;
    }
  }
  nav:nth-of-type(2) {
    margin-bottom: 1rem;
    button {
      background-color: transparent;
      color: ${({ theme }) => theme.colors.primary};
      border: none;
    }
  }
`;
export const TextBlock = styled.div`
  text-align: center;
  margin-bottom: 0.625rem;
`;

export const BodyText = styled.p`
  font-size: 1rem;
  margin-bottom: 0.313rem;
  text-align: center;
`;

export const EmailText = styled.p`
  font-size: 1rem;
  color: gray;
`;

export const InputGroup = styled.div`
  display: flex;
  padding: 0.5rem 1rem;
  margin-bottom: 0.625rem;
  width: 100%;
  justify-content: center;
  border-radius: 1.875rem;
  input {
    background: ${({ theme }) => theme.colors.bgWhite};
    border: none;
    text-align: center;
    font-size: 24px;
    font-weight: 500;
  }
  input::placeholder {
    text-align: center;
    font-size: 32px;
    letter-spacing: 4px;
  }
  input:focus {
    text-align: center;
  }
`;

export const Message = styled.div<{ $validated: boolean }>`
  font-size: 0.9em;
  color: ${({ $validated }) => ($validated ? 'green' : 'red')};
  margin-bottom: 0.625rem;
`;
