import VerticalSeparator from '../assets/images/vertical-separator.svg?react';
import HorizontalSeparator from '../assets/images/horizontal-separator.svg?react';

export const getSeparator = (index: number, length: number, isMobile: boolean) => {
  if (length <= 1) return null;
  const isSeparator = index < length - 1;
  const Separator = isMobile ? <HorizontalSeparator className="horizontal-separator" /> : <VerticalSeparator />;

  return isSeparator ? Separator : null;
};
