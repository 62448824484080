import styled, { css } from 'styled-components';
import { getTextColor, getBackgroundColor } from '@/utils/getColors';
interface ProgramSelector {
  $isSelected?: boolean;
  $isHidden?: boolean;
  $isCustomColor?: boolean;
  $barCustomBackground?: string;
  $disabledProgramBackground?: string;
  $selectedProgramBackground?: string;
  $activeTextBottomColor?: string;
  $disabledTextBottomColor?: string;
  $titleIsCustomColor?: boolean;
  $titleCustomColor?: string;
  $subtitleIsCustomColor?: boolean;
  $subtitleCustomColor?: string;
  $isSingleProgram?: boolean;
}

export const WrapperContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

export const SelectorContainer = styled.div<ProgramSelector>`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 3.563rem;
  background-color: ${({ theme, $barCustomBackground, $isCustomColor }) => ($isCustomColor ? $barCustomBackground : theme.colors.bgSecondary)};
  border-radius: 0.356rem 0.356rem 0 0;
  padding: 0 1rem;
  width: 90%;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    width: 96%;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    height: 1.626rem;
  }
`;

export const ProgramButton = styled.div<ProgramSelector>`
  display: flex;
  cursor: pointer;
  color: ${(props) => getTextColor(props, props)};
  height: ${({ $isSelected }) => ($isSelected ? '3.063rem' : '2.813rem')};
  padding: ${({ $isSelected, $isSingleProgram }) => ($isSelected && !$isSingleProgram ? '0.313rem 3.125rem 0 3.125rem' : '0 1.875rem')};
  align-items: center;
  background-color: ${(props) => getBackgroundColor(props, props)};
  border-radius: 0.75rem 0.75rem 0 0;
  margin-bottom: ${({ $isSelected, $isSingleProgram }) => ($isSelected && !$isSingleProgram ? '-0.063rem' : $isSingleProgram ? '0.2rem' : '0')};
  font-weight: 600;
  line-height: 1.25rem;
  justify-content: center;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    height: ${({ $isSelected }) => ($isSelected ? '1.375rem' : '1.25rem')};
    width: 7rem;
    padding: 0;
    border-radius: 0.5rem 0.5rem 0 0;
  }
`;

export const ProgramTitle = styled.h6<ProgramSelector>`
  font-family: Poppins;
  font-size: ${({ $isSingleProgram }) => ($isSingleProgram ? '1.55rem' : '1.25rem')};
  font-weight: 600;
  line-height: ${({ $isSingleProgram }) => ($isSingleProgram ? '1.436rem' : '1.25rem')};
  margin: 0;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    font-feature-settings:
      'liga' off,
      'clig' off;
    font-size: 0.688rem;
    font-style: normal;
    font-weight: 500;
    line-height: 0.571rem;
  }
`;

export const DescriptionContainer = styled.div`
  padding: 1.688rem 1.563rem;
  border-radius: 0 0 0.356rem 0.356rem;
  display: flex;
  align-items: center;
  border: 0.063rem solid rgba(15, 27, 47, 0.15);
  border-top: none;
  justify-content: center;
  width: 90%;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    width: 96%;
    padding: 0.75rem 0.688rem;
  }
  :first-child {
    font-feature-settings:
      'liga' off,
      'clig' off;
    font-family: Poppins;
    font-size: 0.861rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.563rem;
    text-align: center;
    display: flex;
    width: 100%;
    justify-content: center;
    flex-shrink: 0;
    @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      font-size: 0.5rem;
      line-height: 0.714rem;
      width: 100%;
    }
  }
`;

const TitleSubtitleText = css`
  text-align: center;
  font-family: Poppins;
  font-style: normal;
  line-height: normal;
  justify-content: center;
`;

export const TitleContainer = styled.div<ProgramSelector>`
  display: ${({ $isHidden }) => ($isHidden ? 'none' : 'flex')};
  justify-content: center;
  align-items: center;
  padding: 1.563rem 0rem;
  :first-child {
    ${TitleSubtitleText};
    font-size: 1.375rem;
    font-weight: 700;
    color: ${({ theme, $titleIsCustomColor, $titleCustomColor }) => ($titleIsCustomColor ? $titleCustomColor : theme.colors.bgSecondary)};
    @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      font-size: 1rem;
    }
  }
`;

export const SubtitleContainer = styled.div<ProgramSelector>`
  display: ${({ $isHidden }) => ($isHidden ? 'none' : 'flex')};
  justify-content: center;
  align-items: center;
  padding: 0 0.5rem 1.563rem 0.5rem;
  max-width: 58rem;
  :first-child {
    ${TitleSubtitleText};
    font-size: 1.313rem;
    font-weight: 400;
    color: ${({ theme, $subtitleIsCustomColor, $subtitleCustomColor }) => ($subtitleIsCustomColor ? $subtitleCustomColor : theme.colors.bgSecondary)};
    @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      font-size: 0.938rem;
      line-height: 1.25rem;
    }
  }
`;
