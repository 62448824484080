import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { LoanApplicationState } from './LoanApplication.types';

const initialState: LoanApplicationState = {
  configurationId: '',
  loanId: '',
  loanNumber: '',
  loanType: '',
  estimatedMarketValue: 0,
  remainingMortgageBalance: 0,
  estimatedHomeEquity: 0,
  totalPropertyEquity: 250000,
  cltv: 60,
  minLineAmount: 100000,
  maxLineAmount: 250000,
  features: {
    vmoRedesign: false,
    btnNext: false,
  },
  maxAmountApproved: 0,
  minDrawAmount: 0,
  maxDrawAmount: 0,
  calculatedLTV: 60,
  lenderId: '',
  lineAmount: 0,
  apr: 0,
  feeAmount: 0,
  fixedFeeAmount: 0,
  feePercentage: 0,
  initialLineAmount: 250000,
  auxLineAmount: 250000,
  drawAmount: 0,
  fixedLineAmount: 10000,
  fixedApr: 10.25,
  fixedEstimatedMonthlyPayment: 0,
  fixedFeePercentage: 2.5,
  loanOriginationFee: 0.0,
  lenderName: '',
  term: 'Variable',
  loanTerm: '0',
  ipAddress: '',
  deviceSessionId: '',
  nationalId: '',
  propertyPrimaryUse: '',
  propertyType: '',
  isResume: false,
  isEligible: false,
  helocFees: [
    { feePercentage: 5.5, drawPercentage: 0 },
    { feePercentage: 5.4, drawPercentage: 51 },
  ],
  borrowers: [
    {
      appendValue: 0,
      borrowerLoanPurpose: '',
      borrowerFirstName: '',
      borrowerMiddleName: '',
      borrowerLastName: '',
      isPrimary: true,
      isSpouse: false,
      borrowerEmailAddress: '',
      borrowerPrimaryPhone: '',
      borrowerAlternatePhone: '',
      borrowerWorkPhone: '',
      borrowerSSN: '',
      creditScore: 0,
      creditScoreStated: false,
      borrowerPreferredContactMethod: '',
      borrowerMaritalStatus: '',
      employmentHistory: [
        {
          appendValue: 0,
          type: '',
          employerName: '',
          jobTitle: '',
          startDate: '',
          endDate: '',
          grossMonthlyIncome: 0,
          currentEmployment: null,
        },
      ],
      otherIncome: [],
      governmentHMDA: {
        applicantRace: [],
        applicantEthnicity: [],
        applicantSex: [],
      },
      declaration: {
        hasOutstandingJudgement: undefined,
        hasBankruptcy: undefined,
        hasBadLoan: undefined,
        isInLawsuit: undefined,
        isRelatedToBadLoan: undefined,
        isPresentlyDelinquent: undefined,
        hasSeparateMaintenance: undefined,
        isDownPaymentBorrowed: undefined,
        isEndorserOnNote: undefined,
        IsUSCitizen: undefined,
        IsPermanentResidentAlien: undefined,
        isPropertyPrimaryResidence: undefined,
        hasOwnerShipInterest: undefined,
        hasJudgeBankruptForeclosure: undefined,
        hasDeclareBankrupt: undefined,
        hasChapter13: undefined,
        hasJudgement: undefined,
        hasLawsuitParty: undefined,
        hasForeclosure: undefined,
        hasReposession: undefined,
        hasOtherObligation: undefined,
        hasPastDueBills: undefined,
        hasCoMaker: undefined,
        hasAlias: undefined,
        hasIncomeDecline: undefined,
        hasSuitsPending: undefined,
        hasAlimony: undefined,
        hasPropertyForSale: undefined,
        separateMaintenanceExpense: undefined,
        childSupport: undefined,
        alimony: undefined,
        recipientName: undefined,
        recipientAddress: undefined,
      },
    },
  ],
  borrowerProperty: {
    propertyStreetAddress: '',
    propertyStreetAddress2: '',
    propertyCity: '',
    propertyState: '',
    propertyCountry: '',
    propertyCounty: '',
    propertyZip: '',
    propertyLatitude: 0,
    propertyLongitude: 0,
    propertyOccupancyDate: '',
  },
  mailingAddress: {
    mailingStreetAddress: '',
    mailingStreetAddress2: '',
    mailingCity: '',
    mailingState: '',
    mailingCountry: '',
    mailingCounty: '',
    mailingZip: '',
  },
  consent: {
    eConsent: false,
    tcpaConsent: false,
    softPullConsent: false,
    employmentConsent: false,
    fullstoryConsent: false,
    eConsentCoborrower: false,
  },
  employmentVerification: {
    customerId: '',
    link: '',
  },
  selectedProgramType: 'Line of Credit',
  selectedProgramName: 'HELOC - Standard',
  selectedProgram: {},
  availablePrograms: {},
};

export const LoanApplication = createSlice({
  name: 'LoanApplication',
  initialState,
  reducers: {
    addValue: (state, action) => {
      return JSON.parse(JSON.stringify(action.payload));
    },
    updateValue: (state, action) => {
      return JSON.parse(JSON.stringify(action.payload));
    },
    updateLoanApplication: (state, action) => {
      return { ...state, ...JSON.parse(JSON.stringify(action.payload)) };
    },
    updateSpecificField: (state, action) => {
      let newState = JSON.parse(JSON.stringify(state));
      _.set(newState, action.payload.depth, action.payload.value);
      return { ...state, ...newState };
    },
  },
});

export const { addValue, updateValue, updateSpecificField } = LoanApplication.actions;

export default LoanApplication.reducer;
