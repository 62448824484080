import React, { useCallback, useState, useEffect } from 'react';
import { FooterCard, TitleContainer, WrapperButton, WrapperContainer, WrapperSectionAmount } from './CalculateAmount.styles';
import { useAppSelector, useAppDispatch } from '@app/hooks';
import { DefaultModal } from '../Modals/DefaultModal/DefaultModal';
import { Button } from '../Button/DefaultButton/Button';
import { AmountInput } from './AmountInput';
import { executeAction } from '@/utils/handleEndpoints';
import _ from 'lodash';
import { convertToNumber } from '@/utils/formatValues';
import { pages } from '@/defaultVerbiages';
export interface IModalInfoType {
  header?: string;
  bodyLow?: string;
  bodyHigh?: string;
  body?: string;
  button: string;
  auxMinValue?: number;
  auxMaxValue?: number;
  auxValue?: number;
}

interface CalculateAmount {
  setIsReprice: React.Dispatch<React.SetStateAction<boolean>>;
}
import { Spinner } from '../RefreshableCurrencyInput/RefreshableCurrencyInput.styles';

const CalculateAmount: React.FC<CalculateAmount> = ({ setIsReprice }) => {
  let TemplateConfig = useAppSelector((state) => state.TemplateConfig);
  let LoanApplication = useAppSelector((state) => state.LoanApplication);
  const [selectedLineAmount, setSelectedLineAmount] = useState<number>(0);
  const [selectedDrawAmount, setSelectedDrawAmount] = useState<number>(0);
  const [minLineAmount, setMinLineAmount] = useState<number>(0);
  const [maxLineAmount, setMaxLineAmount] = useState<number>(0);

  const [onRange, setOnRange] = useState<boolean>(false);
  const [disableRepriceButton, setDisableRepriceButton] = useState<boolean>(true);

  const [minCalculatedDrawAmount, setMinCalculatedDrawAmount] = useState<number>(LoanApplication?.minDrawAmount);
  const [maxDrawAmount, setMaxDrawAmount] = useState<number>(LoanApplication?.maxDrawAmount);
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const typeOfProgram = LoanApplication.propertyType === 'HEL' ? 'helData' : 'helocData';

  useEffect(() => {
    const isLowEquity = LoanApplication.estimatedHomeEquity < 50000;
    const lineAmount = isLowEquity ? 35000 : LoanApplication.availablePrograms[typeOfProgram].rates[0].minLineAmount;
    const drawAmount = isLowEquity ? 35000 : LoanApplication.availablePrograms[typeOfProgram].rates[0].minDrawAmount;
    setMinLineAmount(LoanApplication.availablePrograms[typeOfProgram].rates[0].minLineAmount);
    setSelectedLineAmount(lineAmount);
    setMaxLineAmount(LoanApplication.availablePrograms[typeOfProgram].rates[0].maxLineAmount);
    setSelectedDrawAmount(drawAmount);
    if (!isLowEquity) {
      setMinCalculatedDrawAmount(37500);
      setMaxDrawAmount(50000);
    }
    dispatch({
      type: 'LoanApplication/updateLoanApplication',
      payload: {
        ...LoanApplication,
        lineAmount,
        drawAmount,
        minLineAmount: isLowEquity ? undefined : LoanApplication.availablePrograms[typeOfProgram].rates[0].minLineAmount,
        maxLineAmount: isLowEquity ? undefined : LoanApplication.availablePrograms[typeOfProgram].rates[0].maxLineAmount,
      },
    });
  }, []);

  const minDrawPercentage = typeof TemplateConfig?.minDrawPercentage === 'number' ? TemplateConfig.minDrawPercentage / 100 : 0.75;
  const isLowLineAmount = LoanApplication?.availablePrograms?.helocData?.maxLineAmount <= 49999;

  const calculateAmount = TemplateConfig?.pages?.vmo?.vmoV2?.calculateAmount;
  const { title, sectionLineAmount, sectionDrawAmount } = calculateAmount || {};

  const [modalInfo, setModalInfo] = useState<null | IModalInfoType>(null);

  const handleModal = useCallback((info: IModalInfoType) => {
    setModalInfo(info);
  }, []);

  const handleRepriceRates = async () => {
    setLoading(true);
    let auxLoanApplication = _.cloneDeep(LoanApplication);
    const auxTemplateConfig = { ...TemplateConfig };
    auxLoanApplication.lineAmount = Number(selectedLineAmount);
    const minDrawAmount = auxTemplateConfig.minDrawPercentage ? (auxTemplateConfig.minDrawPercentage / 100) * auxLoanApplication.lineAmount : 0;
    auxLoanApplication.drawAmount = selectedDrawAmount || minDrawAmount || 0;
    auxLoanApplication.maxDrawAmount = auxLoanApplication.lineAmount;
    auxLoanApplication.features.vmoRedesign = true;
    auxLoanApplication.programType = undefined;
    auxLoanApplication.programName = undefined;
    auxLoanApplication.availablePrograms.helocData.rates.forEach((rate) => {
      rate.lineAmount = Number(selectedLineAmount);
      rate.drawAmount = Number(selectedDrawAmount);
      rate.maxDrawAmount = Number(selectedLineAmount);
      rate.minDrawAmount = Number(minDrawAmount);
    });
    if (auxLoanApplication?.availablePrograms?.helocData?.maxAmountApproved > auxLoanApplication.maxLineAmount)
      auxLoanApplication.maxLineAmount = auxLoanApplication.availablePrograms.helocData.maxAmountApproved;
    auxLoanApplication.drawAmount = Number(selectedDrawAmount);
    auxLoanApplication.minDrawAmount = minDrawAmount ?? auxLoanApplication.lineAmount;
    auxLoanApplication.minLineAmount = minLineAmount;
    auxLoanApplication.maxDrawAmount = auxLoanApplication.lineAmount;
    auxLoanApplication.equityResponse.estimatedHomeEquity = auxLoanApplication.estimatedHomeEquity;
    auxLoanApplication.initialLineAmount = auxLoanApplication.lineAmount;
    auxLoanApplication.helocData.minDrawPercentage = isLowLineAmount ? 100 : 75;
    auxLoanApplication.auxLineAmount = auxLoanApplication.lineAmount;
    auxLoanApplication.isReprice = true;
    try {
      const response = await executeAction(false, auxLoanApplication, dispatch, TemplateConfig, true);
      setLoading(false);

      if (response) {
        const responseData = response.data[typeOfProgram].rates[0];
        const updateProgram = auxLoanApplication.selectedProgram;
        if (updateProgram.fees) {
          updateProgram.fees[0].feeAmount = responseData.fees[0].feeAmount;
          updateProgram.fees[0].feePercentage = responseData.fees[0].feePercentage;
        }
        response.data[typeOfProgram].rates.map((program, index) => {
          const updatePrograms = {
            ...auxLoanApplication.availablePrograms[typeOfProgram].rates[index],
            lineAmount: program.lineAmount,
            maxDrawAmount: program.maxDrawAmount,
            maxLineAmount: program.maxLineAmount,
            minDrawAmount: program.minDrawAmount,
            minLineAmount: program.minLineAmount,
            apr: program.apr,
            estimatedMonthlyPayment: program.estimatedMonthlyPayment,
          };
          (auxLoanApplication.availablePrograms[typeOfProgram].rates[index].fees[0].feeAmount = program.fees[0].feeAmount),
            (auxLoanApplication.availablePrograms[typeOfProgram].rates[index].fees[0].feePercentage = program.fees[0].feePercentage),
            (auxLoanApplication.availablePrograms[typeOfProgram].rates[index] = updatePrograms);
        });
      }
      dispatch({
        type: 'LoanApplication/updateLoanApplication',
        payload: auxLoanApplication,
      });
    } catch (error) {
      console.error(error);
      setLoading(false);
      setIsReprice(false);
    }
    setIsReprice(false);
    setDisableRepriceButton(true);
  };

  const isOnRange = (value: number, propertyType: 'Line Amount' | 'Draw Amount') => {
    const auxMinValue = propertyType === 'Line Amount' ? minLineAmount : minCalculatedDrawAmount || LoanApplication?.minDrawAmount;
    const auxMaxValue = propertyType === 'Line Amount' ? maxLineAmount : maxDrawAmount || LoanApplication?.maxDrawAmount;
    const isValidValue = isNaN(value) || value == null;
    const onRange = isValidValue || value < auxMinValue || value > auxMaxValue;
    setOnRange(onRange);
  };

  const handleChange = (value: string, propertyType: 'Line Amount' | 'Draw Amount') => {
    setIsReprice(true);
    setDisableRepriceButton(true);
    const newValue = convertToNumber(value);
    const truncatedLineAmount = Math.trunc(Number(newValue) / 100) * 100;
    const auxMinCalculatedDrawAmount = Math.ceil((Number(minDrawPercentage) * truncatedLineAmount) / 100) * 100;

    if (propertyType === 'Draw Amount') {
      setSelectedDrawAmount(newValue);
    } else if (isLowLineAmount) {
      setSelectedLineAmount(newValue);
      setSelectedDrawAmount(newValue);
      setMinCalculatedDrawAmount(newValue);
      setMaxDrawAmount(newValue);
    } else {
      setSelectedLineAmount(newValue);
      setSelectedDrawAmount(auxMinCalculatedDrawAmount);
      setMinCalculatedDrawAmount(auxMinCalculatedDrawAmount);
      setMaxDrawAmount(truncatedLineAmount);
    }
  };

  const handleValidation = (e: React.ChangeEvent<HTMLInputElement> | string, propertyType: 'Line Amount' | 'Draw Amount') => {
    setDisableRepriceButton(false);
    const amountValue = typeof e === 'string' ? e : e?.target?.value?.replace(/[^0-9]/g, '') || (propertyType === 'Draw Amount' ? selectedDrawAmount : selectedLineAmount);
    const numericAmountValue = Number(amountValue);
    const roundedValue = Math.floor(numericAmountValue / 100) * 100;
    const minAmount = propertyType === 'Line Amount' ? minLineAmount : minCalculatedDrawAmount || LoanApplication?.minDrawAmount;
    const maxAmount = propertyType === 'Line Amount' ? maxLineAmount : maxDrawAmount || LoanApplication?.maxDrawAmount;
    const updateLineAmount = (value: number) => {
      const minCalcDraw = isLowLineAmount ? value : Math.ceil((Number(minDrawPercentage) * value) / 100) * 100;
      setSelectedLineAmount(value);
      setSelectedDrawAmount(minCalcDraw);
      setMinCalculatedDrawAmount(minCalcDraw);
      setMaxDrawAmount(value);
    };
    if (propertyType === 'Line Amount') {
      if (roundedValue < minAmount || amountValue === '') {
        updateLineAmount(minAmount);
      } else if (roundedValue > maxAmount) {
        updateLineAmount(maxAmount);
      } else if (roundedValue !== selectedLineAmount) {
        updateLineAmount(roundedValue);
      }
    } else {
      let roundedInputDraw = Math.max(Math.ceil(roundedValue / 100) * 100, minCalculatedDrawAmount || (isLowLineAmount ? selectedLineAmount : LoanApplication.minDrawAmount));
      roundedInputDraw = Math.min(roundedInputDraw, maxAmount);
      const minCalcDrawAmount = Math.ceil((Number(minDrawPercentage) * Number(selectedLineAmount)) / 100) * 100;
      if (numericAmountValue === 0 || roundedInputDraw === 0) {
        setSelectedDrawAmount(LoanApplication.minDrawAmount);
      } else if (numericAmountValue >= minCalcDrawAmount) {
        setSelectedDrawAmount(roundedInputDraw);
      } else {
        setSelectedDrawAmount(minCalcDrawAmount);
      }
    }
    isOnRange(minAmount, propertyType);
  };
  return (
    <WrapperContainer>
      <TitleContainer $isHidden={title?.isHidden ?? false}>
        <p>{title?.title || 'Enter your Line and Draw Amount'}</p>
      </TitleContainer>
      <WrapperSectionAmount>
        <AmountInput
          title={sectionLineAmount?.title || 'Line Amount'}
          valueAmount={selectedLineAmount}
          minAmount={minLineAmount}
          maxAmount={maxLineAmount}
          handleModal={handleModal}
          handleChange={handleChange}
          info={TemplateConfig?.pages?.vmo?.vmoV2?.calculateAmount?.sectionLineAmount?.infoIcon || pages?.vmo?.vmoV2?.calculateAmount?.sectionLineAmount?.infoIcon}
          handleValidation={handleValidation}
          isHidden={sectionLineAmount?.isHidden ?? false}
        />
        <AmountInput
          title={sectionDrawAmount?.title || 'Draw Amount'}
          valueAmount={selectedDrawAmount}
          minAmount={minCalculatedDrawAmount}
          maxAmount={maxDrawAmount}
          handleModal={handleModal}
          handleChange={handleChange}
          info={TemplateConfig?.pages?.vmo?.vmoV2?.calculateAmount?.sectionDrawAmount?.infoIcon || pages?.vmo?.vmoV2?.calculateAmount?.sectionDrawAmount?.infoIcon}
          handleValidation={handleValidation}
          isHidden={sectionDrawAmount?.isHidden ?? false}
        />
      </WrapperSectionAmount>
      <WrapperButton>
        <Button
          label={!loading ? 'Reprice' : <Spinner />}
          variant="primary"
          buttonSize="s"
          onClick={() => handleRepriceRates()}
          isDisabled={loading || onRange || disableRepriceButton}
        ></Button>
      </WrapperButton>
      <FooterCard />
      {modalInfo && <DefaultModal openModal={!!modalInfo} setOpenModal={() => setModalInfo(null)} infoModal={modalInfo} data-testid="modal" typeModal="default" />}
    </WrapperContainer>
  );
};

export default CalculateAmount;
