import React from 'react';
import { createRoot } from 'react-dom/client';
import App from '@app/App';
import * as serviceWorker from '@/serviceWorker';
import { store } from '@app/store';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { theme } from './Theme';
import GlobalStyles from './GlobalStyles';
const root = createRoot(document.getElementById('root')!);

export const index = root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <App />
    </ThemeProvider>
  </Provider>,
);

serviceWorker.unregister();
