import styled from 'styled-components';
import facebook from '../../assets/images/facebook_icon.svg?react';
import twitter from '../../assets/images/twitter_icon.svg?react';
import linkedin from '../../assets/images/linkedin_icon.svg?react';

export const Social = styled.nav`
  border-radius: 0.5rem;
  margin: 0rem 0.625rem;
  background: transparent;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  a {
    background: transparent;
    text-decoration: none;
    img {
      border-radius: 0.313rem;
    }
    path {
      fill: ${({ theme }) => (theme.colors.bgSidebar === theme.colors.primary ? theme.colors.sideBarColorSteps : theme.colors.primary)};
    }
  }
`;

export const LinkedinLogo = styled(linkedin)`
  width: 2rem;
  height: 2rem;
  &:hover {
    path {
      fill: ${(props) => props.theme.colors.hoverColor};
    }
  }
`;

export const FacebookLogo = styled(facebook)`
  width: 2rem;
  height: 2rem;
  :hover {
    fill: ${(props) => props.theme.colors.hoverColor};
  }
  &:hover {
    path {
      fill: ${(props) => props.theme.colors.hoverColor};
    }
  }
`;

export const TwitterLogo = styled(twitter)`
  width: 2rem;
  height: 2rem;
  margin: 1rem;
  &:hover {
    path {
      fill: ${(props) => props.theme.colors.hoverColor};
    }
  }
`;
