import styled, { css } from 'styled-components';
import Arrow from '../../../assets/images/arrow.svg?react';

interface ButtonStylesProps {
  $variant: 'primary' | 'secondary' | 'tertiary';
  $buttonSize: 's' | 'm' | 'l';
  $isCustomColor?: boolean;
  $customColor?: string;
  $customColorBorder?: string;
  $customColorText?: string;
  $isGetStarted?: boolean;
  $isMobile?: boolean;
}

export const ButtonStyles = styled.button<ButtonStylesProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => (props.$customColorText ? props.$customColorText : props.theme.colors.bgWhite)};
  font-size: 1em;
  font-size: 1.063em;
  line-height: normal;
  font-weight: 600;
  margin: 0.625rem 0.063rem;
  flex-shrink: 0;
  min-width: ${(props) => (props.$buttonSize === 's' ? '12rem' : props.$buttonSize === 'm' ? '9rem' : '12rem')};
  height: ${(props) => (props.$buttonSize === 's' ? '2.8rem' : props.$buttonSize === 'm' ? '4rem' : '5rem')};
  padding: 1rem 0;
  border: 0.125rem solid ${(props) => (props.$customColorBorder ? props.$customColorBorder : props.theme.colors.bgSecondary)};
  cursor: pointer;
  border-radius: 0.4rem;
  background: ${(props) => props.$isCustomColor && props.$customColor};
  box-shadow: 0.25rem 0.25rem 0.625rem 0px rgba(0, 0, 0, 0.25);
  ${(props) =>
    props.$variant === 'primary' &&
    !props.$isCustomColor &&
    css`
      background: ${(props) => props.theme.colors.bgSecondary};
      border: 0.125rem solid ${(props) => props.theme.colors.bgSecondary};
    `}

  ${(props) =>
    props.$variant === 'secondary' &&
    !props.$isCustomColor &&
    css`
      background: ${(props) => props.theme.colors.bgGreen};
      border: 0.125rem solid ${(props) => props.theme.colors.bgGreen};
    `}

  ${(props) =>
    props.$variant === 'tertiary' &&
    !props.$isCustomColor &&
    css`
      border: 0.063rem solid ${(props) => props.theme.colors.primary};
      background: ${(props) => props.theme.colors.bgWhite};
      color: ${(props) => props.theme.colors.primary};
    `}
  &:disabled {
    background: ${(props) => props.theme.colors.bgDisable};
    border: 0.125rem solid ${(props) => props.theme.colors.bgDisable};
    cursor: not-allowed;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints?.lg}) {
    min-width: 8rem;
    height: 2.5rem;
    font-size: 0.75rem;
  }
`;

export const ButtonContainer = styled.nav<ButtonStylesProps>`
  display: flex;
  justify-content: ${({ $isGetStarted }) => ($isGetStarted ? 'end' : 'space-between')};
  padding: 0 1.5rem;
  width: 100%;
  position: relative;
  margin: 2rem 0;
  h1 {
    text-decoration-line: none;
    :hover {
      text-decoration-line: none;
    }
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints?.lg}) {
    flex-wrap: ${({ $isMobile }) => $isMobile && 'wrap'};
    h1 {
      margin-top: 0.5rem;
    }
  }
`;

export const ArrowIcon = styled(Arrow)`
  width: 2.2rem;
  height: 1.5rem;
  flex-shrink: 0;
  padding-left: 1rem;
`;
