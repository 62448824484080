import React, { useState } from 'react';
import { WrapperDropDown } from './DropDown.styles';
import DropdownHeader from './DropdownHeader';
import DropdownBody from './DropdownBody';
import { useIsMobile } from '@/hooks/useIsMobile';
interface DropdownProps {
  page?: any;
  width?: string;
  marginTop?: boolean;
  isActive?: boolean;
}
const Dropdown: React.FC<DropdownProps> = ({ page, width, marginTop = false, isActive }) => {
  const [isOpen, setIsOpen] = useState(true);
  const { title, verbiage } = page || { title: 'Credit Information Reported', verbiage: 'on information reported on your credit repo' };
  const isMobile = useIsMobile(1024);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <WrapperDropDown width={width} $marginTop={marginTop} $isMobile={isMobile} $isActive={isActive}>
      <DropdownHeader title={title} isOpen={isOpen} toggleDropdown={toggleDropdown} />
      <DropdownBody isOpen={isOpen} verbiage={verbiage} />
    </WrapperDropDown>
  );
};

export default Dropdown;
