import styled from 'styled-components';
export const FullStoryWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  background-color: ${({ theme }) => theme.colors.textDarkGrey};
  box-shadow: 0 -0.125rem 0.313rem rgba(0, 0, 0, 0.1);
  margin: 0;
  padding: 1rem;
  font-family: Poppins, sans-serif;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  h1 {
    color: ${({ theme }) => theme.colors.bgWhite};
    font-style: normal;
    font-weight: 700;
    font-size: 1.313rem;
    line-height: 1.563rem;
    text-align: center;
  }
  & > *:nth-of-type(2) h1 {
    font-weight: 400;
    font-size: 1.375rem;
    line-height: 3rem;
    white-space: normal;
    a {
      color: ${({ theme }) => theme.colors.textOrange};
    }
  }
  @media (max-width: ${({ theme }) => theme.breakpoints?.md}) {
    flex-direction: column;
    padding: 1rem;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints?.sm}) {
    padding: 0.5rem;
  }
`;
export const ButtonWrapper = styled.div`
  display: flex;
  width: 50%;
  justify-content: space-around;

  button {
    border: none;
    font-weight: 400;
    font-size: 0.8571rem;
    line-height: 1.35rem;
    border-radius: 0.1875rem;
    padding: 0.688rem 1.375rem;
    cursor: pointer;
    min-width: 0.5rem;
    margin: 0.625rem 0.063rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints?.md}) {
    width: 100%;
    justify-content: center;

    button {
      min-width: 4rem;
      padding: 0.5rem 1rem;
    }
  }
`;
