import ActiveSvg from '../../../assets/images/circle-default.svg?react';
import CheckedSvg from '../../../assets/images/circle-checked.svg?react';
import styled, { css } from 'styled-components';
import SquareActiveSvg from '../../../assets/images/square-active.svg?react';
import SquareCheckSvg from '../../../assets/images/square-check.svg?react';

const IconsSize = css`
  width: 1.5rem;
  height: 1.5rem;
`;

export const CheckboxWrapper = styled.div`
  display: flex;
`;

export const StyledButton = styled.button`
  background: transparent;
  color: #000;
  box-shadow: none;
  border: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  &:disabled {
    cursor: not-allowed;
  }
`;

export const IconWrapper = styled.span``;

export const Text = styled.p`
  padding-left: 8px;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    font-size: 0.75rem;
  }
`;

export const ActiveIcon = styled(ActiveSvg)`
  ${IconsSize};
  path {
    width: 1.25rem;
  }
  .circle-color {
    fill: ${({ theme }) => theme.colors.borderColor};
  }
`;
export const CheckedIcon = styled(CheckedSvg)`
  ${IconsSize};
  fill: ${({ theme }) => theme.colors.bgGreen};
  path {
    fill: ${({ theme }) => theme.colors.primary};
  }
  .circle-color {
    fill: ${({ theme }) => theme.colors.bgWhite};
  }
`;

export const SquareActiveIcon = styled(SquareActiveSvg)`
  ${IconsSize};
  path {
    width: 1.25rem;
  }
  .circle-color {
    fill: ${({ theme }) => theme.colors.borderColor};
  }
`;

export const SquareCheckIcon = styled(SquareCheckSvg)`
  ${IconsSize};
  rect {
    fill: ${({ theme }) => theme.colors.primary};
  }

  .circle-color {
    fill: ${({ theme }) => theme.colors.bgGreen};
  }
`;
