import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`

:root {
  --slider-height: 0.75rem;
  --thumb-size: 1.25rem;
  --thumb-bg-color: #333;
  --thumb-text-color: #fff;
  --gradient-start: #fc0000;
  --gradient-mid1: #ff9900;
  --gradient-mid2: #faff01;
  --gradient-end: rgba(0, 255, 71, 0.96);
}

  body {
    font-family: 'Poppins', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    height: 100vh;
  }

  * {
    box-sizing: inherit;
  }

  p {
  margin: 0;
  }

  div {
    display: flex;
  }

.custom-slider {
  width: 100%;
  position: relative;
  background: linear-gradient(
    90deg,
    var(--gradient-start) 0%,
    var(--gradient-mid1) 28.65%,
    var(--gradient-mid2) 66.67%,
    var(--gradient-end) 100%
  );
  height: var(--slider-height);
  border-radius: 0.3125rem;
  display: flex;
  align-items: center;
}
.custom-thumb {
  width: var(--thumb-size);
  height: var(--thumb-size);
  background-color: var(--thumb-bg-color);
  border-radius: 50%;
  cursor: grab;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--thumb-text-color);
  font-size: 1rem;
  transition: transform 0.2s;
}
.custom-thumb:focus {
  outline: none;
  transform: scale(1.1);
  cursor: grabbing;
}
`;

export default GlobalStyles;
