import axios from 'axios';
import { ActionProps } from './services.types';
import { handleErrors } from '@/utils/handleErrors';
import { endpointFront } from './ExperienceService';

export const makeGetSummaryCall = async (action: ActionProps, loanApplication: any, templateConfig?: any) => {
  const endpoint = action?.actionProperties?.endpoint?.replace('%%loanId%%', loanApplication.loanId);

  if (!endpoint) {
    throw new Error('Endpoint is not defined');
  }

  const isICE = templateConfig?.isICE ?? false;
  const apiURL = endpointFront;

  try {
    const response = await axios.get(`${apiURL.experienceApi}/api/v1${endpoint}`, {
      headers: {
        'api-key': isICE ? 'none' : apiURL.apiKey,
        ...(apiURL.mocked ? { 'mocked-origin': apiURL.mocked } : {}),
      },
    });
    return response.data;
  } catch (error) {
    const detailsError = await handleErrors(error);
    throw detailsError;
  }
};
