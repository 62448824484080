import React from 'react';
import { InfoIcon, PriceContainer, SectionAmount } from './CalculateAmount.styles';
import { TextInput } from '../input/TextInput/TextInput';
import { IModalInfoType } from './CalculateAmount';
import { formatNumber } from '@/utils/formatValues';
import { useAppSelector } from '@app/hooks';
interface IAmountInputProps {
  title: string;
  handleModal: (info: IModalInfoType) => void;
  valueAmount: number;
  minAmount: number;
  maxAmount: number;
  handleValidation: (e: React.ChangeEvent<HTMLInputElement> | string, propertyType: 'Line Amount' | 'Draw Amount') => void;
  handleChange: (value: string, propertyType: 'Line Amount' | 'Draw Amount') => void;
  info: IModalInfoType;
  isHidden: boolean;
}
const handleDisplayFormat = (val?: number | null): string => {
  if (val == null) return '';
  return `$${Math.ceil(val).toLocaleString()}`;
};

const AmountInputComponent = ({ title = '', handleModal, valueAmount = 0, minAmount = 0, maxAmount = 0, handleChange, info, handleValidation, isHidden }: IAmountInputProps) => {
  let LoanApplication = useAppSelector((state) => state.LoanApplication);
  const infoModal = {
    body: LoanApplication.minLineAmount > 49000 ? info.bodyHigh : info.bodyLow,
    button: info.button,
  };
  return (
    <SectionAmount $isHidden={isHidden}>
      <p>
        {title} <InfoIcon data-testid="info-icon" onClick={() => handleModal(infoModal)} aria-label={`More line amount`} $infoIconColor={''} />
      </p>
      <PriceContainer>
        <TextInput
          value={handleDisplayFormat(valueAmount)}
          placeholder={''}
          propertyType={title}
          onChange={handleChange}
          handleValidation={handleValidation}
          size="10%"
          maxAmount={maxAmount}
          minAmount={minAmount}
        />
      </PriceContainer>
      <p>
        Enter an amount between <b>{formatNumber(minAmount)}</b> and <b>{formatNumber(maxAmount)}</b>
      </p>
    </SectionAmount>
  );
};

export const AmountInput = React.memo(AmountInputComponent);
