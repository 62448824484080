import React, { useEffect, useState } from 'react';
import { SideBarWrapper, SideBarTitle, SideBarLogo, FooterSideBar } from './SideBar.styles';
import { BarSteppers } from '../SideBarSteppers/SideBarSteppers';
import SocialLink from '../SocialLinks/SocialLinks';
import { useAppSelector } from '@app/hooks';
export interface SideBarProps {
  variant: 'primary' | 'secondary' | 'default';
}
export const SideBar: React.FC<SideBarProps> = ({ variant }) => {
  let templateConfig = useAppSelector((state) => state.TemplateConfig);
  const [loading, setLoading] = useState(!templateConfig?.lenderId);
  const isICE = templateConfig.isICE ?? false;
  const logo = isICE ? templateConfig?.globalTheme?.logo : templateConfig?.globalTheme?.images?.logo;
  useEffect(() => {
    if (Object.keys(templateConfig).length && templateConfig?.lenderId) {
      setLoading(false);
    }
  }, [templateConfig]);

  if (loading) {
    return null;
  }
  return (
    <SideBarWrapper $variant={variant} data-testid="side-bar">
      <SideBarLogo>
        <img src={logo} alt="My description" />
      </SideBarLogo>
      <SideBarTitle $variant={variant} data-testid="side-bar-title">
        Know Your Home's Equity
      </SideBarTitle>
      <BarSteppers variant={variant} />
      <FooterSideBar data-testid="social-link">
        <SocialLink socialLinks={templateConfig?.pages?.socialLinks} />
      </FooterSideBar>
    </SideBarWrapper>
  );
};
