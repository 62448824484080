import { Social, LinkedinLogo, FacebookLogo, TwitterLogo } from './SocialLinks.styles';

interface SocialLink {
  socialLinks: [
    {
      title: string;
      url: string;
    },
  ];
}

const SocialLink: React.FC<SocialLink> = ({ socialLinks }) => {
  const logoMap: { [key: string]: React.ReactNode } = {
    facebook: <FacebookLogo />,
    twitter: <TwitterLogo />,
    linkedin: <LinkedinLogo />,
  };

  return (
    <Social>
      {socialLinks?.map((link, index) => (
        <a key={index} href={link?.url} target="_blank" rel="noopener noreferrer" aria-label={link?.title}>
          {logoMap[link?.title]}
        </a>
      ))}
    </Social>
  );
};

export default SocialLink;
