import React from 'react';
import { DropDownBody } from './DropDown.styles';

interface IDropdownBodyProps {
  isOpen: boolean;
  verbiage: string;
}

const DropdownBody = ({ isOpen, verbiage = '' }: IDropdownBodyProps) => (
  <DropDownBody $isOpen={isOpen}>
    {verbiage ||
      'Based on information reported on your credit report. Your actual account balance and minimum monthly payment may differ. Note that you may be required to payoff accounts directly with the creditor. Any existing subordinate lien will need to be paid off as part of your HELOC transaction. Potential savings are estimates only and are based on your initial estimated monthly interest only period payment and the information reported on your credit report. Monthly savings are calculated by subtracting the minimum.'}
  </DropDownBody>
);

export default DropdownBody;
