import _ from 'lodash';
import { LoanApplicationState } from '@/reducers/LoanApplication.types';
import { Dispatch } from 'redux';
export const handleResponseInformation = (
  response: any,
  loanApplication: LoanApplicationState,
  dispatch: Dispatch,
  isFirstRequest?: boolean,
  templateConfig?: any,
  isReprice?: boolean,
) => {
  try {
    let updatedLoanApplication = _.cloneDeep(loanApplication);
    if (Array.isArray(response) && response.length > 0 && 'depth' in response[0]) {
      response.forEach((action) => {
        const { depth, payload } = action;
        if (Array.isArray(depth) && payload) {
          if (depth.length > 0 && payload) {
            _.set(updatedLoanApplication, depth, {
              ..._.get(updatedLoanApplication, depth, {}),
              ...payload,
            });
          } else {
            updatedLoanApplication = {
              ...updatedLoanApplication,
              ...payload,
            };
          }
        }
      });
    } else if ((typeof response === 'object' && 'isEligible' in response) || (typeof response?.payload === 'object' && 'isEligible' in response?.payload)) {
      let updatedLoanApplication = _.cloneDeep(loanApplication);
      updatedLoanApplication.isEligible = response.isEligible ?? response?.payload.isEligible;
      return dispatch({
        type: 'LoanApplication/updateValue',
        payload: { ...updatedLoanApplication },
      });
    } else {
      if (Object.keys(updatedLoanApplication.selectedProgram).length == 0) {
        updatedLoanApplication.helocData = {
          minDrawPercentage: response?.helocData?.rates[0]?.minDrawPercentage ?? templateConfig?.minDrawPercentage,
        };
        dispatch({
          type: 'LoanApplication/updateValue',
          payload: { ...updatedLoanApplication },
        });
      }
      const isICE = templateConfig?.isICE ?? false;
      if (isICE) {
        updatedLoanApplication.availableEquityToBorrow = 460000;
        updatedLoanApplication.showAvailableEquityToBorrow = true;
        if (response.isThresholdMet) updatedLoanApplication.borrowers[0].creditScore = 850;
        Object.keys(response).forEach((key) => {
          if (response[key] !== null && response[key] !== undefined) {
            if (key === 'helRates' || (response[key].length > 0 && key === 'helocRates')) {
              if (isReprice) {
                updatedLoanApplication.selectedProgram = response[key][0];
              } else {
                updatedLoanApplication.availablePrograms[key] = response[key];
              }
            } else {
              updatedLoanApplication[key] = response[key]; // @ts-ignore
            }
          }
        });
      }

      if (isFirstRequest) {
        if (updatedLoanApplication.lineAmount <= 49999 || loanApplication?.estimatedHomeEquity < 50000) {
          updatedLoanApplication.drawAmount = updatedLoanApplication.lineAmount;
          updatedLoanApplication.minDrawAmount = updatedLoanApplication.lineAmount;
          updatedLoanApplication.maxDrawAmount = updatedLoanApplication.lineAmount;
        } else {
          const maxApprovedAmount = updatedLoanApplication?.availablePrograms?.helocData?.maxAmountApproved || 0;
          if (maxApprovedAmount > updatedLoanApplication.maxLineAmount) {
            updatedLoanApplication.maxLineAmount = maxApprovedAmount;
          }
          updatedLoanApplication.maxDrawAmount = updatedLoanApplication.lineAmount;
        }

        if (updatedLoanApplication?.helocFees) updatedLoanApplication.feePercentage = updatedLoanApplication.helocFees[0]?.feePercentage;
        updatedLoanApplication.actualMaxAmountApproved = Math.trunc(updatedLoanApplication?.actualMaxAmountApproved / 100) * 100;
        updatedLoanApplication.maxAmountApproved = Math.trunc(updatedLoanApplication.maxAmountApproved / 100) * 100;

        if (updatedLoanApplication.maxAmountApproved > updatedLoanApplication.maxLineAmount) {
          updatedLoanApplication.maxAmountApproved = updatedLoanApplication.maxLineAmount;
        }
        if (updatedLoanApplication.drawAmount < Math.ceil(updatedLoanApplication.minDrawAmount / 100) * 100) {
          updatedLoanApplication.drawAmount = Math.ceil(updatedLoanApplication.minDrawAmount / 100) * 100;
        } else {
          updatedLoanApplication.drawAmount = Math.ceil(updatedLoanApplication.drawAmount / 100) * 100;
        }

        if (isFirstRequest) {
          updatedLoanApplication.availablePrograms = {};
          Object.assign(updatedLoanApplication.availablePrograms, response);
          Object.entries(updatedLoanApplication.availablePrograms).forEach(([key, value]) => {
            if (value.rates.length === 0) {
              delete updatedLoanApplication.availablePrograms[key];
            }
          });
          updatedLoanApplication = {
            ...updatedLoanApplication,
            initialLineAmount: Math.trunc(updatedLoanApplication.lineAmount / 100) * 100,
            fixedApr: updatedLoanApplication.apr,
            fixedFeePercentage: updatedLoanApplication?.helocFees?.[0]?.feePercentage || updatedLoanApplication?.fixedFeePercentage,
            lineAmount: 0,
            apr: 0,
            feePercentage: 0,
          };
          if (templateConfig && templateConfig?.maxLineAmount && updatedLoanApplication?.maxAmountApproved) {
            if (updatedLoanApplication?.maxAmountApproved > templateConfig?.maxLineAmount) {
              updatedLoanApplication.maxLineAmount = templateConfig?.maxLineAmount;
            } else {
              updatedLoanApplication.maxLineAmount = updatedLoanApplication?.maxAmountApproved;
            }
          }
        }
      }
    }
    if (window.FS) {
      let borrower = updatedLoanApplication.borrowers[0];
      window.FS.identify(`${updatedLoanApplication.loanId}`, {
        displayName: `${borrower.borrowerFirstName} ${borrower.borrowerLastName}`,
        email: borrower.borrowerEmailAddress,
        lenderName: updatedLoanApplication.lenderName,
        los: 'Meridian Link',
        loanNumber: `${updatedLoanApplication.loanNumber}`,
        system: 'POS 2.0',
        posWorkflow: 'Borrower Application',
      });
    }
    if (updatedLoanApplication.borrowers[0].creditScore !== 0 && updatedLoanApplication.borrowers[0].statedCreditRequired) {
      updatedLoanApplication.borrowers[0].statedCreditRequired = false;
    }
    return dispatch({
      type: 'LoanApplication/updateValue',
      payload: updatedLoanApplication,
    });
  } catch (error) {
    console.error(error, 'error handle response information');
  }
};
