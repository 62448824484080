import styled, { css } from 'styled-components';
import InfoIcons from '../../assets/images/info-icons.svg?react';

type IInfoIcons = {
  $infoIconColor?: string;
  $isHidden?: boolean;
};

const FlexColumn = css`
  display: flex;
  flex-direction: column;
`;

export const WrapperContainer = styled.div`
  ${FlexColumn}
  background: ${(props) => props.theme.colors.bgWhite};
  border-radius: 0px 0px 0.632rem 0.632rem;
  border: 0.063rem solid ${({ theme }) => theme.colors.bgMediumGrey};
  box-shadow:
    0px 0px 0.057rem -0.057rem rgba(15, 27, 47, 0.12),
    0px 0.057rem 0.115rem -0.057rem rgba(15, 27, 47, 0.16);
  font-family: Poppins;
  width: 40%;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints?.md}) {
    width: 100%;
  }
`;

export const TitleContainer = styled.div<IInfoIcons>`
  display: ${({ $isHidden }) => ($isHidden ? 'none' : 'flex')};
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 2.01rem;
  padding: 0.632rem 0.919rem;
  border-radius: 0.632rem 0.632rem 0px 0px;
  background: ${(props) => props.theme.colors.bgSecondary};
  p {
    color: ${(props) => props.theme.colors.textPrimary};
    font-size: 1.034rem;
    font-weight: 400;
    line-height: 1.436rem;
    margin-left: 0.9375rem;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    height: 1.792rem;
    padding: 0.563rem 0.819rem;
    p {
      font-size: 0.732rem;
      font-weight: 500;
      line-height: 0.89rem;
      width: 100%;
      text-align: center;
    }
  }
`;

export const WrapperSectionAmount = styled.div`
  ${FlexColumn}
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints?.sm}) {
    flex-direction: row;
    border-radius: 0.542rem;
    background: ${(props) => props.theme.colors.bgWhite};
    box-shadow: 0px 0.052rem 0.209rem -0.052rem rgba(0, 0, 0, 0.16);
    margin: 0.75rem;
  }
`;

export const SectionAmount = styled.div<IInfoIcons>`
  display: ${({ $isHidden }) => ($isHidden ? 'none' : 'flex')};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0.574rem;
  background: ${(props) => props.theme.colors.bgWhite};
  box-shadow: 0px 0.057rem 0.574rem 0px rgba(0, 0, 0, 0.13);
  margin: 1.375rem 1.375rem 0 1.375rem;
  position: relative;
  & > :first-child {
    color: ${(props) => props.theme.colors.textGrey};
    font-size: 0.861rem;
    font-weight: 600;
    margin-top: 0.75rem;
    display: flex;
  }
  & > :last-child {
    color: ${(props) => props.theme.colors.textDarkGrey};
    text-align: center;
    font-size: 0.746rem;
    font-weight: 400;
    letter-spacing: 0.006rem;
    margin-bottom: 0.375rem;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints?.sm}) {
    width: 43%;
    box-shadow: none;
    margin: 0.6875rem 0.3125rem 0 0.6875rem;
    & > :first-child {
      font-size: 0.628rem;
    }
    & > :last-child {
      font-size: 0.471rem;
    }
  }
`;

export const PriceContainer = styled.div`
  width: 85%;
  padding: 0px 0.632rem;
  margin: 0.75rem 2.5rem 1rem 2.5rem;

  input {
    color: ${(props) => props.theme.colors.textDarkGrey};
    width: 3.125rem;
    text-align: center;
    font-size: 1.625rem;
    font-weight: 300;
    height: 3.217rem;
    align-items: center;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints?.sm}) {
    padding: 0px 0.29rem;
    margin: 0.375rem 0.75rem;
    width: 100%;
    input {
      font-size: 0.837rem;
      padding: 0.5rem 0.6875rem;
      height: 1.938rem;
    }
    & > :first-child {
      min-width: 50%;
      width: 8.476rem;
      height: 2rem;
    }
  }
`;

export const InfoIcon = styled(InfoIcons)<IInfoIcons>`
  width: 1.1875rem;
  height: 1.1875rem;
  margin-left: 0.4375rem;
  cursor: pointer;
  path {
    fill: ${({ $infoIconColor, theme }) => ($infoIconColor ? $infoIconColor : theme.colors.primary)};
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints?.sm}) {
    width: 0.6875rem;
    height: 0.6875rem;
    margin-left: 0.125rem;
  }
`;

export const WrapperButton = styled.div`
  nav {
    font-size: 0.75rem;
    height: 2.3125rem;

    @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      height: 1.125rem;
      margin-bottom: 1.125rem;
    }

    button {
      font-size: 0.804rem;
      font-weight: 600;
      font-family: Poppins;
      font-style: normal;
      line-height: 1.149rem;
      font-family: ${(props) => props.theme.fonts.main};
      min-width: 4.375rem;
      height: 1.875rem;
      margin-top: 1.4375rem;
      padding: 0 0.5rem;
      border-radius: 0.1rem;
      @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
        font-size: 0.5rem;
        height: 1.125rem;
        min-width: 3.25rem;
        margin-top: 0px;
        margin-bottom: 0px;
      }
    }
  }
`;

export const FooterCard = styled.div`
  height: 0.574rem;
  border-radius: 0 0 0.632rem 0.632rem;
  background: ${(props) => props.theme.colors.bgFooterProgramCard};
`;
