import React, { useState } from 'react';
import { Container, Text, InfoIcon } from './TextBlock.styles';
import SafeHTML from '@utils/safeHTML';
import { DefaultModal } from '@components/Modals/DefaultModal/DefaultModal';

export enum TypeOfText {
  'H1',
  'H2',
  'H3',
  'H4',
  'Subtitle',
  'Text',
}
export interface TextBlockProps {
  text?: string;
  direction?: string;
  color?: 'primary' | 'default';
  width?: string;
  typeModal?: string;
  isInfoModal?: boolean;
  isLinkModal?: boolean;
  type?: TypeOfText;
  infoModal?: {
    header?: string;
    body?: string;
    button?: string;
  };
  isHidden?: boolean;
  isCustomColor?: boolean;
  customColor?: string;
  infoIconColor?: string;
  isUnderline?: boolean;
}

export const TextBlock: React.FC<TextBlockProps> = ({
  text = '',
  direction = 'flex-start',
  color = 'default',
  type = TypeOfText.H1,
  width = '100%',
  isInfoModal = false,
  infoModal = {},
  isLinkModal = false,
  isHidden = false,
  isCustomColor = false,
  customColor = '#000000',
  infoIconColor,
  isUnderline = false,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const hasModal = isInfoModal || isLinkModal;

  const handleModal = () => {
    setOpenModal(true);
  };

  return (
    <Container $direction={direction} data-testid="text-block-container" width={width} $isHidden={isHidden}>
      <Text color={color} type={type} data-testid="text-block-text" $isCustomColor={isCustomColor} $customColor={customColor} $isUnderline={isUnderline} $isLinkModal={isLinkModal}>
        {isLinkModal ? <SafeHTML tag="a" html={text.replace(/\n/g, '<br/>')} onClick={handleModal} /> : <SafeHTML tag="p" html={text.replace(/\n/g, '<br/>')} />}
      </Text>
      {isInfoModal && <InfoIcon data-testid="info-icon" onClick={handleModal} $infoIconColor={infoIconColor} />}
      {openModal && hasModal && (
        <DefaultModal openModal={openModal} setOpenModal={setOpenModal} infoModal={infoModal} data-testid="modal" typeModal="default" isErrorModal={false} />
      )}
    </Container>
  );
};
