import styled from 'styled-components';
import InfoIcons from '../../assets/images/info-icons.svg?react';
import ArrowDowns from '../../assets/images/drop-down-icon.svg?react';

type IInfoIcons = {
  $infoIconColor?: string;
  $isOpen?: boolean;
  width?: string;
  $marginTop?: boolean;
  $isMobile?: boolean;
  $isActive?: boolean;
};

export const WrapperDropDown = styled.div<IInfoIcons>`
  display: ${({ $isActive }) => ($isActive ? 'none' : 'flex')};
  flex-direction: column;
  width: ${({ width }) => width && width};
  margin-top: ${({ $marginTop, $isMobile }) => $marginTop && !$isMobile && '-10rem'};
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints?.lg}) {
    width: 100%;
  }
`;

export const DropDownHeader = styled.div<IInfoIcons>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 2.734rem;
  gap: 1.243rem;
  border-radius: 0.621rem;
  border: 0.062rem solid rgba(0, 0, 0, 0);
  background: ${(props) => props.theme.colors.bgSecondary};
  p {
    color: ${(props) => props.theme.colors.bgWhite};
    font-size: 0.843rem;
    font-weight: 500;
    text-align: start;
    flex: 1;
    font-family: Poppins;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints?.sm}) {
    width: 100%;
    p {
      font-size: 0.625rem;
    }
  }
`;

export const DropDownBody = styled.div<{ $isOpen: boolean }>`
  border-radius: 0.621rem;
  background: ${(props) => props.theme.colors.bgWhite};
  box-shadow: 0 0.062rem 0.621rem 0 rgba(0, 0, 0, 0.07);
  color: ${(props) => props.theme.colors.textSecondary};
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.118rem;
  padding: 1.5625rem 1.125rem;
  max-height: ${({ $isOpen }) => ($isOpen ? 'max-content' : '0')};
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
  display: ${({ $isOpen }) => ($isOpen ? 'block' : 'none')};
  font-family: Poppins;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints?.sm}) {
    font-size: 0.628rem;
    line-height: 0.936rem;
  }
`;

export const InfoIcon = styled(InfoIcons)<IInfoIcons>`
  width: 1.4375rem;
  height: 2.3125rem;
  margin-left: 1.125rem;
  cursor: pointer;
  path {
    fill: ${({ $infoIconColor, theme }) => ($infoIconColor ? $infoIconColor : theme.colors.primary)};
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints?.sm}) {
    width: 1.0625rem;
    height: 1.25rem;
    margin-left: 0.8125rem;
  }
`;

export const ArrowDown = styled(ArrowDowns)<IInfoIcons>`
  width: 0.5625rem;
  height: 0.75rem;
  margin-right: 0.9375rem;
  cursor: pointer;
  transform: ${({ $isOpen }) => ($isOpen ? 'rotate(180deg)' : 'rotate(0)')};
  transition: transform 0.3s ease-in-out;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints?.sm}) {
    width: 0.6875rem;
    height: 0.6875rem;
    margin-left: 0.125rem;
  }
`;
