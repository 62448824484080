import styled from 'styled-components';

export interface MainProps {
  $variant: 'primary' | 'secondary';
  $isVmoV2?: string;
}

export const MainContainer = styled.section<MainProps>`
  width: 100%;
  background: ${({ $variant, theme }) => ($variant === 'primary' ? theme.colors.bgLightGrey : theme.colors.bgGrey)};
  padding-top: 0.5rem;
  padding-bottom: ${({ $isVmoV2 }) => ($isVmoV2 === 'true' ? '0' : '3rem')};
  flex: 1;
`;
