import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Wrapper } from '../wrapper.styles';
import ProgramSelector from '@/components/ProgramSelector/ProgramSelector';
import AvailablePrograms from '@/components/Group/AvailablePrograms';
import { NavigationButton, PageOrigin } from '@components/Button/NavigationButton/NavigationButton';
import { useAppSelector } from '@app/hooks';

export interface VmoProps {
  onAccess: (page: string) => void;
}

const Vmo: React.FC<VmoProps> = ({ onAccess }) => {
  const navigate = useNavigate();
  let templateConfig = useAppSelector((state) => state.TemplateConfig);
  let loanApplication = useAppSelector((state) => state.LoanApplication);

  return (
    <>
      <Wrapper $gap="0rem">
        <ProgramSelector />
        <AvailablePrograms />
      </Wrapper>
      <NavigationButton
        variant="primary"
        nextButton={templateConfig?.pages?.vmo?.NavigationButton?.nextButton || 'Next'}
        isBackButton={templateConfig?.pages?.vmo?.NavigationButton?.backButton?.isHidden || false}
        backButton={templateConfig?.pages?.vmo?.NavigationButton?.backButton?.text || 'Back'}
        disabled={Object.keys(loanApplication.selectedProgram).length === 0}
        type="next"
        onAccess={onAccess}
        navigation={navigate}
        pageOrigin={PageOrigin.ESTIMATED_AVAILABLE_EQUITY}
        currentPage={PageOrigin.VMO}
        destinationPage={PageOrigin.VMO_LINE_AMOUNT}
      />
    </>
  );
};

export default Vmo;
