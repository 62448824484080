import styled, { css } from 'styled-components';

interface ButtonStylesProps {
  $variant: 'primary' | 'secondary' | 'tertiary';
  $buttonSize: 's' | 'm' | 'l';
  $isHidden?: boolean;
}

export const ButtonStyles = styled.button<ButtonStylesProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  line-height: 1.449rem;
  font-weight: bold;
  margin: 0.625rem 0.063rem;
  height: ${(props) => (props.$buttonSize === 's' ? '3rem' : props.$buttonSize === 'm' ? '3rem' : '2.668rem')};
  padding: 0.688rem 1.375rem;
  border: 0.125rem solid ${(props) => props.theme.colors.primary};
  cursor: pointer;
  border-radius: 0.4rem;
  min-width: ${(props) => (props.$buttonSize === 's' ? '7rem' : props.$buttonSize === 'm' ? '11rem' : '14.688rem')};
  ${(props) =>
    props.$variant === 'primary' &&
    css`
      background: ${(props) => props.theme.colors.bgSecondary};
      border: 0.125rem solid ${(props) => props.theme.colors.bgSecondary};
      color: ${(props) => props.theme.colors.bgWhite};
    `}

  ${(props) =>
    props.$variant === 'secondary' &&
    css`
      flex-shrink: 0;
      border-radius: 0.438rem;
      border: 0.125rem solid ${(props) => props.theme.colors.primary};
      background: ${(props) => props.theme.colors.bgWhite};
      color: ${(props) => props.theme.colors.primary};
    `}

  ${(props) =>
    props.$variant === 'tertiary' &&
    css`
      background: ${(props) => props.theme.colors.bgGrey};
      border: 0.125rem solid ${(props) => props.theme.colors.bgGrey};
      color: ${(props) => props.theme.colors.bgWhite};
    `}

  &:disabled {
    background: ${(props) => props.theme.colors.bgDisable};
    border: 0.125rem solid ${(props) => props.theme.colors.bgDisable};
    cursor: not-allowed;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    height: 2.5rem;
    min-width: 8rem;
    font-size: 0.75rem;
  }
`;

export const ButtonContainer = styled.nav<ButtonStylesProps>`
  display: ${({ $isHidden }) => ($isHidden ? 'none' : 'flex')};
  justify-content: center;
  position: relative;
  width: 100%;
  height: ${(props) => (props.$buttonSize === 's' ? '4rem' : props.$buttonSize === 'm' ? '4rem' : '4rem')};
  margin-bottom: 2rem;
`;
