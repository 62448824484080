import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
export interface FormState {
  [key: string]: any;
}

const initialState: FormState = {};
export const CurrentForm = createSlice({
  name: 'CurrentForm',
  initialState,
  reducers: {
    addField: (state, action: PayloadAction<FormState>) => {
      return { ...state, ...action.payload };
    },
    updateField: (state, action: PayloadAction<FormState>) => {
      return { ...state, ...action.payload };
    },
    removeField: (state, action: PayloadAction<string>) => {
      const newState = _.cloneDeep(state);
      delete newState[action.payload];
      return newState;
    },
    submitForm: (state) => {
      return state;
    },
    resetForm: () => {
      return initialState;
    },
  },
});

export const { addField, updateField, submitForm, resetForm, removeField } = CurrentForm.actions;
export default CurrentForm.reducer;
