import styled, { css } from 'styled-components';
import InfoIcons from '../../../assets/images/info-icons.svg?react';

interface AmountCardsPops {
  $isHidden?: boolean;
}
const FlexCenter = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BorderRadiusTop = css`
  border-radius: 0.688rem 0.688rem 0 0;
`;

const BorderRadiusBottom = css`
  border-radius: 0 0 0.688rem 0.688rem;
`;

const FontStyles = css`
  line-height: normal;
  font-style: normal;
`;
export const WrapperCard = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 0.688rem;
  width: 90%;
  box-shadow:
    0rem 0rem 0.063rem 0.063rem rgba(15, 27, 47, 0.12),
    0rem 0.063rem 0.125rem -0.063rem rgba(15, 27, 47, 0.16);
  @media only screen and (max-width: ${(props) => props.theme.breakpoints?.sm}) {
    width: 100%;
  }
`;

export const CardHeader = styled.div<AmountCardsPops>`
  display: ${({ $isHidden }) => ($isHidden ? 'none' : 'flex')};
  justify-content: center;
  align-items: flex-start;
  ${BorderRadiusTop};
  background: ${({ theme }) => theme.colors?.bgGrey400};
  padding: 1rem;
  p {
    color: ${({ theme }) => theme.colors?.textDarkGrey};
    text-align: center;
    font-size: 1.063rem;
    font-weight: 600;
    margin: 0;
  }
  @media only screen and (max-width: ${(props) => props.theme.breakpoints?.md}) {
    p {
      font-size: 0.875rem;
    }
  }
`;

export const CardBody = styled.div`
  display: flex;
  padding: 1rem 4.25rem;
  justify-content: space-around;
  align-items: center;
  flex: 1 0 0;
  ${BorderRadiusBottom};
  background-color: ${({ theme }) => theme.colors?.bgWhite};
  p {
    margin: 0;
  }
  .horizontal-separator {
    margin: 1rem 0;
  }
  @media only screen and (max-width: ${(props) => props.theme.breakpoints?.lg}) {
    padding: 1rem 0.75rem;
  }
  @media only screen and (max-width: ${(props) => props.theme.breakpoints?.md}) {
    width: auto;
    flex-direction: column;
    padding: 1rem 0.75rem;
  }
`;

export const CardSection = styled.div`
  ${FlexCenter};
  flex-direction: column;
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: center;
  h5 {
    ${FontStyles};
    text-align: center;
    font-family: Poppins;
    font-size: 1.875rem;
    font-weight: 500;
    margin: 0;
    color: ${({ theme }) => theme.colors.textDarkGrey};
    width: 100%;
    @media only screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
      padding: 0;
    }
  }
  p {
    font-family: 'Nunito';
    ${FontStyles};
    font-size: 0.813rem;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.textGrey};
    width: 100%;
    @media only screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
      font-size: 0.813rem;
    }
  }
`;

export const InfoIcon = styled(InfoIcons)`
  width: 1.6em;
  height: 1.313rem;
  cursor: pointer;
  path {
    fill: ${({ theme }) => theme.colors.primary};
  }
`;

export const ContainerInfoIcon = styled.div`
  height: 50%;
  display: flex;
  align-items: center;
  gap: 0.7rem;
`;
