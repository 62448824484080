import styled, { css } from 'styled-components';

interface HDMAprops {
  $isVisible?: boolean;
  $isHidden?: boolean;
}

export const FlexColumn = css`
  display: flex;
  flex-direction: column;
`;

export const TextContainerStyles = css`
  margin: 0;
  margin-bottom: 0.5rem;
  h1 {
    margin-top: 0.5rem;
    @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}) {
      margin-top: 1rem;
    }
  }
`;

export const Wrapper = styled.div`
  padding: 1.25rem 0.9rem;
`;

export const BorrowerContainer = styled.div`
  margin-bottom: 1.25rem;
  ${FlexColumn};
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    p {
      text-align: start;
      font-weight: 400;
    }
  }
`;

export const SubtitleContainer = styled.div`
  ${TextContainerStyles};
  h1 p {
    text-align: left;
    font-weight: 400;
    font-family: Poppins;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    p {
      font-size: 0.75rem;
    }
  }
`;

export const GroupTitleContainer = styled.div`
  ${TextContainerStyles};

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    p {
      font-size: 1rem;
    }
  }
`;

export const FormGroup = styled.div<HDMAprops>`
  display: ${({ $isHidden }) => ($isHidden ? 'none' : 'flex')};
  flex-direction: column;
  :first-child {
    text-align: left;
    font-weight: 500;
    padding: 0;
    font-family: Poppins;
    @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}) {
      font-weight: 400;
      margin-bottom: 0;
      letter-spacing: -0.01rem;
    }
    input {
      font-size: 0.9rem;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
    }
  }
`;

export const List = styled.div`
  gap: 1rem;
`;
export const RacerContainer = styled.ul`
  padding-left: 1.5rem;
  display: flex;
  flex-direction: row;
  gap: 6rem;
  width: 100%;
  justify-content: space-between;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    flex-direction: column;
    gap: 1rem;
  }
`;

export const CheckRacerGroup = styled.ul`
  ${FlexColumn};
  gap: 1rem;
  padding: 0;
`;

export const CheckboxContainer = styled.ul`
  padding-left: 1.5rem;
  ${FlexColumn};
  gap: 0.938rem;
`;

export const CheckboxContainerSex = styled.ul`
  padding-left: 0rem;
  ${FlexColumn};
  gap: 0.938rem;
`;

export const CheckboxHide = styled.span<HDMAprops>`
  display: ${({ $isVisible }) => ($isVisible ? 'block' : 'none')};
  :first-child {
    padding-left: 0.3rem;
    @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      width: 15rem;
    }
  }
`;
