import React, { useState, useEffect } from 'react';
import { Container, Background, Progress } from './ProgressBar.styles';

interface ProgressBarProps {
  percent: number;
  barColor?: string;
}
export const ProgressBar: React.FC<ProgressBarProps> = ({ percent = 0, barColor = '#3A149E' }) => {
  const [percentNumber, setPercent] = useState(percent);

  useEffect(() => {
    setPercent(percent);
  }, [percent]);

  return (
    <Container>
      <Background />
      <Progress $percent={percentNumber} $barColor={barColor} />
    </Container>
  );
};
