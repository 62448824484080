import styled, { css } from 'styled-components';

interface EditModalProps {
  $isOpen: boolean;
}

export const BackDrop = styled.div<EditModalProps>`
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1040;
  transition: opacity 0.3s ease;
  opacity: 1;
  pointer-events: auto;
  min-width: 10rem;
`;

export const Modal = styled.div<EditModalProps>`
  display: block;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  border-radius: 0.5rem;
  box-shadow: 0 0.625rem 3.125rem #00000080;
  z-index: 1050;
  min-width: 50rem;
  max-height: 90vh;
  overflow-y: auto;
  @media only screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
    min-width: 15rem;
  }
`;

export const ModalTitle = styled.div`
  ${(props) => {
    return css`
      max-height: 20vh;
      font-weight: 400;
      margin-bottom: 0;
      font-size: 1.25rem;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: Poppins;
      color: ${props.theme.colors.textSecondary};
      @media only screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
        font-size: 1rem;
      }
    `;
  }}
`;

export const ModalBody = styled.div`
  padding: 1rem;
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Poppins;
  flex-direction: column;
`;

export const ModalInput = styled.div`
  padding: 1rem;
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Poppins;
  flex-direction: column;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 1.313rem;
  padding: 1rem 3rem;
  @media only screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
    padding: 1rem 1rem;
    min-width: 15rem;
    width: 80%;
  }
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
