import { parse, differenceInMonths, startOfMonth } from 'date-fns';
import * as yup from 'yup';

export const formatOccupancyDate = (date: string) => {
  if (!/^\d{2}\/\d{4}$/.test(date)) {
    throw new Error('Invalid date format. Expected format: MM/YYYY');
  }
  const [month, year] = date.split('/');
  const formattedDate = `${year}-${month}-01`;
  return formattedDate;
};

export function formatPhoneNumber(number: string) {
  const cleanNumber = number.replace(/[()\s-]/g, '');
  return cleanNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
}

export const formatBirthDate = (date: string) => {
  if (!/^\d{2}\/\d{2}\/\d{4}$/.test(date)) {
    throw new Error('Invalid date format. Expected format: DD/MM/YYYY');
  }
  const [day, month, year] = date.split('/');
  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};

export const formatInput = (input: string, propertyName: string): any => {
  if (propertyName === 'propertyOccupancyDate') {
    const match = input.match(/^(\d{0,2})(\d{0,4})$/);
    if (match) {
      const [, month, year] = match;
      return year ? `${month}/${year}` : month;
    }
  } else {
    const dayMonthYearRegex = /^(\d{0,2})(\d{0,2})(\d{0,4})$/;
    const match = input.match(dayMonthYearRegex);
    if (match) {
      const [, day, month, year] = match;
      let formattedDate = day;
      if (month) {
        formattedDate += month;
      }
      if (year) {
        formattedDate += year;
        formattedDate = `${day}/${month}/${year}`;
      }
      return formattedDate;
    }
  }
  return input;
};

export const monthsBetweenDateAndToday = (dateStr: string, propertyName: string, endDate?: string): number => {
  let inputDate;
  const inputEndDate = endDate ?? new Date();
  if (propertyName === 'propertyOccupancyDate') {
    inputDate = parse(dateStr, 'MM/yyyy', new Date());
  } else {
    inputDate = parse(dateStr, 'MM/dd/yyyy', new Date());
  }
  inputDate.getTime();
  return differenceInMonths(startOfMonth(inputEndDate), startOfMonth(inputDate.getTime()));
};

export const createValidationSchema = (propertyName: string, isRequired: boolean) => {
  let schema = yup.string();
  if (isRequired) {
    schema = schema.required('Date is required');
  }
  return yup.object().shape({
    [propertyName]: schema,
  });
};

export const getCurrentDate = () => {
  const today = new Date();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');
  const year = today.getFullYear();

  return `${month}/${day}/${year}`;
};
