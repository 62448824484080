import styled from 'styled-components';
import ResumeExisting from '../../assets/images/resume-existing.svg?react';
import ResumeStart from '../../assets/images/resumeStart.svg?react';

interface IResumeProps {
  $isSelected?: boolean;
  $isHidden?: boolean;
}

export const ResumeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: ${({ theme }) => theme.colors.bgLightGrey};
  height: 100%;
  @media only screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
  }
`;

export const Header = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.563rem;
  position: relative;
  height: 5rem;
  width: 100%;
  background: ${(props) => props.theme.colors.primary};
  margin-bottom: 1rem;
  img {
    width: 10rem;
    height: 10rem;
  }
  @media only screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
    height: 3.5rem;
    padding: 1.563rem 0.625rem;
  }
`;

export const WrapperResume = styled.div<IResumeProps>`
  display: ${({ $isHidden }) => ($isHidden ? 'none' : 'flex')};
  margin: 4.125rem 0;
  gap: 3rem;
  cursor: pointer;
  :hover {
    filter: drop-shadow(0px 1px 10px rgba(0, 0, 0, 0.13));
  }
  @media only screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    gap: 0.688rem;
  }
`;

export const BTN = styled.div<IResumeProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 22.5rem;
  height: 15.813rem;
  border-radius: 0.438rem;
  background: ${(props) => props.theme.colors.bgWhite};
  border: ${(props) => (props.$isSelected ? `2px solid ${props.theme.colors.primary}` : 'none')};
  p {
    margin-top: 3.125rem;
    color: ${(props) => props.theme.colors.primary};
    font-weight: 600;
    font-size: 1.188rem;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: 10.313rem;
    height: 9.188rem;
    p {
      font-size: 0.875rem;
      margin-top: 0.875rem;
      text-align: center;
      max-width: 7.813rem;
    }
  }
`;

export const WrapperButtons = styled.div`
  gap: 5rem;
`;

export const ResumeStartImg = styled(ResumeStart)`
  width: 7.625rem;
  height: 6.063rem;
  margin-left: 0.5rem;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: 4.375rem;
    height: 3.438rem;
  }
`;

export const ResumeExistingImg = styled(ResumeExisting)`
  width: 7.625rem;
  height: 6.063rem;
  margin-left: 0.5rem;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: 4.375rem;
    height: 3.438rem;
  }
`;

export const WrapperInput = styled.div<IResumeProps>`
  display: ${({ $isHidden }) => ($isHidden ? 'none' : 'block')};
  width: 30rem;

  border-radius: 0.563rem;
  margin: 4.125rem 0;
  position: relative;
  :nth-child(1) {
    border-radius: 0.563rem;
    width: auto;
    background: ${(props) => props.theme.colors.bgWhite};
  }
  span {
    background: ${({ theme }) => theme.colors.bgLightGrey};
    position: absolute;
    top: 3.2rem;
    svg {
      background: ${({ theme }) => theme.colors.bgLightGrey} !important;
    }
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: 90%;
  }
`;

export const WrapperButtonRestartCode = styled.div`
  nav:nth-of-type(1) {
    button {
      background-color: transparent;
      color: ${({ theme }) => theme.colors.primary};
      border: none;
    }
  }
`;

export const WrapperLoanList = styled.div<IResumeProps>`
  display: ${({ $isHidden }) => ($isHidden ? 'none' : 'flex')};
  justify-content: center;
  flex-direction: column;
  justify-content: space-between;
  width: 703px;
  gap: 1rem;
  margin: 64px 0 80px 0;
  cursor: pointer;
  :hover {
    filter: drop-shadow(0px 1px 10px rgba(0, 0, 0, 0.13));
  }
`;

export const Loans = styled.div<IResumeProps>`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background: ${(props) => props.theme.colors.bgWhite};
  height: 64px;
  :hover {
    filter: none;
  }
  border: ${(props) => (props.$isSelected ? `2px solid ${props.theme.colors.primary}` : 'none')};
  p {
    background: ${(props) => props.theme.colors.bgWhite};
  }
`;
