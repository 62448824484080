import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as yup from 'yup';
import { Modal, ModalBody, ModalTitle, BackDrop, ModalFooter, ModalContent, TextBlock, BodyText, EmailText, Message, InputGroup } from './CodeInput.styles';
import { Button } from '@/components/Button/DefaultButton/Button';
import { pages } from '@/defaultVerbiages';
import { ModalSubmit } from '@/components/Modals/ModalSubmit/ModalSubmit';
import { LoanApplicationState } from '@/reducers/LoanApplication.types';
import { makePostCall } from '@/services/makePostCall';
import { useAppSelector } from '@/app/hooks';
import _ from 'lodash';
import { TextInput } from '../TextInput/TextInput';
interface CodeProps {
  disabled?: boolean;
  loanApplication: LoanApplicationState;
  isResume: boolean;
  setIsResume: React.Dispatch<React.SetStateAction<boolean>>;
  onAccess: (page: string) => void;
  children?: React.ReactNode;
}

const CodeInput: React.FC<CodeProps> = ({ isResume, setIsResume, loanApplication, onAccess }) => {
  const [value, setValue] = useState<string>('');
  const [validated, setValidated] = useState<boolean>(true);
  const [isValid, setIsValid] = useState<boolean>(false);
  const [openSoftPull, setOpenSoftPull] = useState<boolean>(false);
  let templateConfig = useAppSelector((state) => state.TemplateConfig);
  let LoanApplication = useAppSelector((state) => state.LoanApplication);
  let auxLoanApplication = _.cloneDeep(LoanApplication);

  const currentForm = useSelector((state: any) => state.CurrentForm);
  const dispatch = useDispatch();
  const RESUME_CODE_REGEX = /^[a-zA-Z0-9]{6}$/;

  const yupParams = yup
    .string()
    .transform((value) => value.replace(/-/g, ''))
    .matches(RESUME_CODE_REGEX, { excludeEmptyString: false });
  const formSchema = yup.object().shape({
    'resume-code-input': yupParams,
  });

  const checkValidation = async (inputData: Record<string, string>) => {
    const varIsValid = await formSchema.isValid(inputData);
    setIsValid(varIsValid);
  };

  const sendCodeToEmail = async () => {
    try {
      const endpoint = '/auth/sendCode';
      const payload = { email: currentForm.email };
      const newAction = {
        actionProperties: {
          endpoint,
        },
      };
      await makePostCall(newAction, loanApplication, payload, dispatch, templateConfig);
    } catch (error) {
      setValidated(false);
    } finally {
    }
  };

  const verifyCode = async () => {
    try {
      const endpoint = '/auth/verifyCode';
      const payload = { email: currentForm.email, code: value };
      const newAction = {
        actionProperties: {
          endpoint,
        },
      };
      const response = await makePostCall(newAction, loanApplication, payload, dispatch, templateConfig);

      if (response) {
        const responseData = response.data;
        const borrowerProperty = responseData.borrowerProperty;
        const borrowers = responseData.borrowers[0];

        const updatedBorrower = {
          ...auxLoanApplication.borrowers[0],
          borrowerLoanPurpose: borrowers.borrowerLoanPurpose,
          borrowerFirstName: borrowers.borrowerFirstName,
          borrowerMiddleName: borrowers.middleName || '',
          borrowerLastName: borrowers.borrowerLastName,
          borrowerEmailAddress: borrowers.borrowerEmailAddress,
          borrowerPreferredContactMethod: borrowers.borrowerPreferredContactMethod || '',
        };

        const updatedProperty = {
          ...auxLoanApplication.borrowerProperty,
          propertyCity: borrowerProperty.propertyCity,
          propertyStreetAddress: borrowerProperty.propertyCombinedStreetAddress,
          propertyZip: borrowerProperty.propertyZip,
          propertyState: borrowerProperty.propertyState,
        };
        auxLoanApplication.loanId = responseData.loanId;
        auxLoanApplication.loanNumber = responseData.loanNumber;
        auxLoanApplication.propertyPrimaryUse = responseData.propertyPrimaryUse;
        auxLoanApplication.propertyType = responseData.propertyType;
        auxLoanApplication.borrowers[0] = updatedBorrower;
        auxLoanApplication.borrowerProperty = updatedProperty;
        auxLoanApplication.isEligible = true;
        auxLoanApplication.isResume = true;

        dispatch({ type: 'LoanApplication/updateLoanApplication', payload: auxLoanApplication });
        setValidated(true);
        closeModal();
      }
    } catch (error) {
      setValidated(false);
    } finally {
    }
  };

  const closeModal = () => {
    setOpenSoftPull(true);
    if (setIsResume) setIsResume(false);
  };

  useEffect(() => {
    sendCodeToEmail();
  }, []);
  return (
    <>
      <BackDrop $isOpen={isResume} onClick={closeModal} data-testid="modal-backdrop" />
      <Modal $isOpen={isResume}>
        <ModalBody>
          <ModalTitle>{templateConfig?.pages?.LetsGetStarted?.modals?.resumeInquiry?.title || 'Existing Loan Inquiry Found'}</ModalTitle>
          <ModalContent>
            <BodyText>
              {templateConfig?.pages?.LetsGetStarted?.modals?.resumeInquiry?.subtitle ||
                `We found an existing loan inquiry associated with this email. To continue, enter the 6-digit code sent to your email and click 'Resume My Inquiry.`}
            </BodyText>
            <TextBlock>
              <EmailText>{currentForm.email}</EmailText>
            </TextBlock>

            <InputGroup>
              <TextInput
                placeholder={'--- ---'}
                size={'32%'}
                propertyType={'code-input'}
                onChange={(values) => {
                  setValue(values.toString().replace(/-/g, ''));
                  checkValidation({ 'resume-code-input': values.toString() });
                }}
              />
            </InputGroup>

            <Message $validated={validated}>
              {validated
                ? templateConfig?.pages?.LetsGetStarted?.modals?.resumeInquiry?.expiredCode || 'This code expires after one-time use.'
                : templateConfig?.pages?.LetsGetStarted?.modals?.resumeInquiry?.incorrectCode || 'Incorrect code. Please check and try again.'}
            </Message>
          </ModalContent>
        </ModalBody>
        <ModalFooter>
          <Button
            label={templateConfig?.pages?.LetsGetStarted?.modals?.resumeInquiry?.resumeButton || 'Resume My Inquiry'}
            onClick={verifyCode}
            isDisabled={!isValid}
            variant="primary"
            buttonSize="l"
          />
          <BodyText>
            {templateConfig?.pages?.LetsGetStarted?.modals?.resumeInquiry?.incorrectCode ||
              `To start a new inquiry, click 'Restart My Loan Inquiry' below. (Creating a new inquiry will archive the existing one.`}
          </BodyText>

          <Button
            label={templateConfig?.pages?.LetsGetStarted?.modals?.resumeInquiry?.restartButton || 'Restart My Inquiry'}
            onClick={closeModal}
            variant="primary"
            buttonSize="l"
          />
        </ModalFooter>
      </Modal>
      {openSoftPull && <ModalSubmit openModal={openSoftPull} setOpenModal={() => {}} infoSubmitModal={pages?.LetsGetStarted?.modals?.infoSubmitModal} onAccess={onAccess} />}
    </>
  );
};

export default CodeInput;
