import axios from 'axios';
import { handleErrors } from '../utils/handleErrors';
import { ActionProps } from './services.types';
import { handleResponseInformation } from './handleResponseInformation';
import { Dispatch } from 'redux';
import { stopLoading } from '@/reducers/currentPage';
import { endpointFront } from './ExperienceService';

export const makePatchCall = async (action: ActionProps, loanApplication: any, payload: any, dispatch: Dispatch, templateConfig?: any, isReprice?: boolean) => {
  try {
    const endpoint = action?.actionProperties?.endpoint?.replace('%%loanId%%', loanApplication.loanId);

    if (!endpoint) {
      throw new Error('Endpoint is not defined');
    }
    const isICE = templateConfig?.isICE ?? false;
    const apiURL = endpointFront;

    const response = await axios.patch(`${apiURL.experienceApi}/api/v1${endpoint}`, payload, {
      headers: {
        'api-key': isICE ? 'none' : apiURL.apiKey,
        ...(apiURL.mocked ? { 'mocked-origin': apiURL.mocked } : {}),
      },
    });
    const isFirstRequest = action.actionProperties.endpoint.includes('rates') && !isReprice ? true : false;

    if (!isReprice) {
      return handleResponseInformation(response.data, loanApplication, dispatch, isFirstRequest, templateConfig);
    }
    return response;
  } catch (error) {
    const detailsError = await handleErrors(error);
    throw detailsError;
  } finally {
    dispatch(stopLoading());
  }
};
