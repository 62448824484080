import { createSlice } from '@reduxjs/toolkit';
import { TemplateConfigState } from './TemplateConfig.types';
const initialState: Partial<TemplateConfigState> = {};
export const TemplateConfig = createSlice({
  name: 'TemplateConfig',
  initialState: initialState,
  reducers: {
    updateData: (state, action) => {
      let tempState = JSON.parse(JSON.stringify(state));
      if (tempState.layout.coordinator.steps.length > action.payload.step) {
        tempState.layout.coordinator.steps[action.payload.step].progressVisible = action.payload.completed;
      }
      tempState.layout.coordinator.currentStep = action.payload.step + 1;
      return tempState;
    },
    showAdverseActionPage: (state, action) => {
      let tempState = JSON.parse(JSON.stringify(state));
      tempState.layout.coordinator.steps = [action.payload];
      return tempState;
    },
    updateTemplateConfig: (state, action) => {
      return { ...state, ...JSON.parse(JSON.stringify(action.payload)) };
    },
  },
});

export const { updateData } = TemplateConfig.actions;

export default TemplateConfig.reducer;
