export const HandleModal = (label: string, templateConfig: any) => {
  let infoIcon;
  switch (label) {
    case 'LINE AMOUNT':
      infoIcon = {
        bodyHigh: templateConfig?.pages?.vmo?.lineAmount?.infoIcon?.bodyHigh,
        bodyLow: templateConfig?.pages?.vmo?.lineAmount?.infoIcon?.bodyLow,
        button: templateConfig?.pages?.vmo?.lineAmount?.infoIcon?.button,
      };
      break;
    case 'LOAN AMOUNT':
      infoIcon = {
        bodyHigh: templateConfig?.pages?.vmo?.loanAmount?.infoIcon?.bodyHigh,
        bodyLow: templateConfig?.pages?.vmo?.loanAmount?.infoIcon?.bodyLow,
        button: templateConfig?.pages?.vmo?.loanAmount?.infoIcon?.button,
      };
      break;
    case 'ESTIMATED APR':
      infoIcon = templateConfig?.pages?.vmo?.estimatedApr?.infoIcon;
      break;
    case 'EST MONTHLY PMT':
      infoIcon = templateConfig?.pages?.vmo?.estimateMonthlyPmt?.infoIcon;
      break;
    case 'ORIGINATION FEE':
      infoIcon = templateConfig?.pages?.vmo?.originationFee?.infoIcon;
      break;
    case 'INITIAL DRAW':
      infoIcon = templateConfig?.pages?.vmo?.initialDraw?.infoIcon;
      break;
    case 'MINIMUM DRAW':
      infoIcon = templateConfig?.pages?.vmo?.minimumDraw?.infoIcon;
      break;
    case 'MAXIMUM DRAW':
      infoIcon = templateConfig?.pages?.vmo?.maximumDraw?.infoIcon;
      break;
    default:
      infoIcon = { bodyHigh: 'Default High Tex', bodyLow: 'Default Low Text', button: 'Close' };
  }

  return infoIcon;
};
