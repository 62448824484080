import styled, { css } from 'styled-components';
import ActiveSvg from '../../assets/images/circle-default.svg?react';
import CheckedSvg from '../../assets/images/circle-checked.svg?react';

export interface SideBarStepsProps {
  $variant?: 'primary' | 'secondary' | 'default';
  $isCurrent: boolean;
  $isChecked?: boolean;
}

export const SideBarIcon = styled.nav`
  display: flex;
`;

export const ActiveIcon = styled(ActiveSvg)<SideBarStepsProps>`
  ${(props) => {
    return css`
      width: 1.2rem;
      height: 1.2rem;
      margin-right: 0.625rem;
      path {
        width: 1.4rem;
        fill: ${(props) => props.theme.colors.sideBarColorSteps};
      }
      .circle-color {
        fill: ${props.$isCurrent
          ? props.$variant === 'primary'
            ? props.theme.colors.sideBarColorSteps
            : props.theme.colors.fillCustomCircleCheck
          : props.$variant === 'primary'
            ? props.theme.colors.sideBarColorSteps
            : props.theme.colors.sidebarStrokeCircles};
      }
    `;
  }}
`;
export const CheckedIcon = styled(CheckedSvg)<SideBarStepsProps>`
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.625rem;
  path {
    fill: ${({ theme }) => theme.colors.fillCustomCircleChecked};
  }
  .circle-color {
    fill: ${({ theme }) => (theme.colors.colorCustomCircleChecked ? theme.colors.colorCustomCircleChecked : theme.colors.primary)};
  }
`;

export const SideBarSteppers = styled.nav`
  font-size: 0.875rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 1.5625rem;
  position: relative;
  //height: 35rem;
`;

export const SideBarSteps = styled.div<SideBarStepsProps>`
  ${(props) => {
    return css`
      color: ${props.$isChecked
        ? props.$variant === 'primary'
          ? props.theme.colors.sideBarColorStepsChecked
          : props.theme.colors.primary
        : props.$variant === 'primary'
          ? props.theme.colors.sideBarColorSteps
          : props.theme.colors.primary};
      padding: 0 0 2.1875rem 0.625rem;
      font-size: 0.875rem;
      display: flex;
      font-weight: ${props.$isCurrent ? 700 : 500};
      flex-direction: row;
      align-items: center;
      line-height: 1.375rem;
      font-style: normal;
      font-family: ${props.theme.fonts.main};
      position: relative;

      &:not(:last-child)::before {
        content: '';
        position: absolute;
        top: 0.2rem;
        left: 1.25rem;
        width: 0.0625rem;
        height: 100%;
        background-color: ${props.theme.colors.sideBarColorSteps};
        z-index: -1;
      }
    `;
  }}
`;
