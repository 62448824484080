import React, { ReactElement } from 'react';

interface ProtectedRouteProps {
  element: ReactElement;
  isProtected?: boolean;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element }) => {
  return element;
};

export default ProtectedRoute;
