import { FooterWrapper, FooterDisclosures, FooterLogo, FooterVersion, DisclosuresContent, EqualHousingIcon, NcuaIconContainer, HoverText, HoverTextTwo } from './Footer.styles';
import SafeHTML from '@utils/safeHTML';
import { useAppSelector } from '@app/hooks';
export interface FooterProps {
  variant: 'primary' | 'secondary';
}

export const Footer: React.FC<FooterProps> = ({ variant }) => {
  const currentPage = useAppSelector((state) => state.currentPage.step);
  const isVmoPage = currentPage === 3;
  let templateConfig = useAppSelector((state) => state.TemplateConfig);
  const lenderName = templateConfig?.lenderName || '';
  const currentYear = new Date().getFullYear();
  const isVmoV2 = (templateConfig?.features?.vmoRedesign?.isEnabled && isVmoPage) ?? false;
  const footerOptions = isVmoV2 ? templateConfig?.pages?.footer?.footerVmoV2 : templateConfig?.pages?.footer?.defaultFooter;

  return (
    <FooterWrapper $variant={variant} data-testid="footer">
      <FooterDisclosures $isVmoV2={isVmoV2}>
        <DisclosuresContent $variant={variant} $isVmoV2={isVmoV2} data-testid="footer-disclosures-one" $isHidden={footerOptions?.footerDisclosuresOne?.isHidden ?? false}>
          <SafeHTML tag="p" html={footerOptions?.footerDisclosuresOne?.text || `©${currentYear} ${lenderName} (NMLS #12345)`} />
        </DisclosuresContent>
        <DisclosuresContent $variant={variant} $isVmoV2={isVmoV2} data-testid="footer-disclosures-two" $isHidden={footerOptions?.footerDisclosuresTwo?.isHidden ?? false}>
          <SafeHTML tag="p" html={footerOptions?.footerDisclosuresTwo?.text || 'Contact Us 866.243.1111 current'} />
        </DisclosuresContent>
        <FooterVersion $variant={variant} $isVmoV2={isVmoV2} data-testid="footer-version" $isHidden={footerOptions?.support?.isHidden ?? false}>
          {footerOptions?.support?.text || 'current version 0.2.60.3'}
        </FooterVersion>
      </FooterDisclosures>
      {!isVmoV2 && (
        <FooterLogo>
          <EqualHousingIcon $isHidden={footerOptions?.compliance?.logo2?.isHidden ?? false}>
            <img
              src={
                footerOptions?.compliance?.logo2?.url ||
                'https://firstclosenonprodmedia.blob.core.windows.net/media/3_ld_default_application/Equal-Housing-Opportunity-logo-52-BB024373-seeklogo-com.png'
              }
              alt="logo2"
            />
            <HoverText>{footerOptions?.compliance?.logo2?.text}</HoverText>
          </EqualHousingIcon>
          <NcuaIconContainer $isHidden={footerOptions?.compliance?.logo?.isHidden ?? false}>
            <img
              src={
                footerOptions?.compliance?.logo?.url ||
                'https://firstclosenonprodmedia.blob.core.windows.net/media/3_ld_default_application/Equal-Housing-Opportunity-logo-52-BB024373-seeklogo-com.png'
              }
              alt="logo2"
            />
            <HoverTextTwo>{footerOptions?.compliance?.logo2?.text}</HoverTextTwo>
          </NcuaIconContainer>
        </FooterLogo>
      )}
    </FooterWrapper>
  );
};
