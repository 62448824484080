import React, { useState } from 'react';
import { TitleContainer } from '../../pages/wrapper.styles';
import { TextBlock, TypeOfText } from '@/components/text/TextBlock';
import {
  ResumeContainer,
  ResumeExistingImg,
  ResumeStartImg,
  WrapperButtons,
  BTN,
  WrapperInput,
  WrapperResume,
  WrapperButtonRestartCode,
  WrapperLoanList,
  Loans,
  Header,
} from './Resume.styles';
import { Button } from '../Button/DefaultButton/Button';
import { TextInput } from '../input/TextInput/TextInput';
import _ from 'lodash';
import ModalErrorCode from '../Modals/ModalErrorCode/ModalErrorCode';
import ModalVerificationCode from '../Modals/ModalVerificationCode/ModalVerificationCode';
import { useAppSelector } from '@/app/hooks';
import { makePostCall } from '@/services/makePostCall';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { setIsResume } from '@/reducers/currentPage';
import { handleGetStarted } from '@/utils/handleEndpoints';
import { Spinner } from '../RefreshableCurrencyInput/RefreshableCurrencyInput.styles';

const stepsResume = [
  {
    title: 'Welcome Back! Choose How You’d Like to Continue',
    subTitle: 'You can resume your existing application or start a new one.',
    email: { isHidden: true, text: '' },
    backButton: { isHidden: true, text: '' },
    code: { isHidden: true, text: '' },
    buttonRestart: {
      isHidden: true,
      text: '',
    },
    listLoanSelection: {
      isHidden: true,
      loans: [],
    },
  },
  {
    title: 'Enter Your Email Address',
    subTitle: 'To access your application, please enter the email you used originally.',
    email: { isHidden: false, text: 'Email Address' },
    backButton: { isHidden: false, text: 'Back' },
    code: { isHidden: true, text: '' },
    buttonRestart: {
      isHidden: true,
      text: '',
    },
    listLoanSelection: {
      isHidden: true,
      loans: [],
    },
  },
  {
    title: 'Your Applications',
    subTitle: 'Select an application to continue from where you left off.',
    email: { isHidden: true, text: '' },
    backButton: { isHidden: false, text: 'Back' },
    code: { isHidden: true, text: '' },
    buttonRestart: {
      isHidden: true,
      text: ``,
    },
    listLoanSelection: {
      isHidden: false,
      loans: [
        { address: '123 Main St, City, ST 11111', loan: 'Loan #12345', startDate: '01/01/2024' },
        { address: '456 First St, City, ST 22222', loan: 'Loan #67890', startDate: '02/02/2024' },
        { address: '123 Main St, City, ST 11111', loan: 'Loan #12345', startDate: '01/01/2024' },
      ],
    },
  },
];

const infoModal = {
  unableSendEmail: {
    title: 'Unable to Send Email',
    description: 'We were unable to send the verification code to your email.  Start a new application or check your email address and try again.',
    labelButtonLeft: 'Start New Application',
    labelButtonRight: 'Try Another Email',
  },
};

export enum PropertyType {
  Email = 'email',
  Code = 'code-input',
}

const Resume = () => {
  const currentForm = useAppSelector((state) => state.CurrentForm);
  const { value, isLoading } = useAppSelector((state) => state.currentPage);
  const [isSelected, setIsSelected] = useState<'start' | 'existing' | 'loanList' | undefined>(undefined);
  const [step, setStep] = useState<number>(0);
  const [formValues, setFormValues] = useState({ email: currentForm?.email || '' });
  const [isUnable, setIsUnable] = useState(false);
  const [isOpenEnterCode, setIsOpenEnterCode] = useState(false);
  const [infoModalError, setInfoModalError] = useState({ title: '', description: '', labelButtonLeft: '', labelButtonRight: '' });
  const [loadSelected, setLoadSelected] = useState<number>();

  let templateConfig = useAppSelector((state) => state.TemplateConfig);
  let loanApplication = useAppSelector((state) => state.LoanApplication);

  const dispatch = useDispatch();

  const formSchema = yup.object({
    email: yup
      .string()
      .email()
      .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/),
  });

  const handleSelection = (type: 'start' | 'existing' | 'loanList') => {
    setIsSelected(type);
  };

  const { title, subTitle, email, backButton, buttonRestart, listLoanSelection } = stepsResume[step];

  const handleChange = _.debounce((value: string | number | boolean, name: string) => {
    setFormValues((prevValues: any) => ({
      ...prevValues,
      [name]: value,
    }));
    let obj: any = {};
    obj[name] = value;
  }, 100);

  const handleClick = async () => {
    let auxLoanApplication = { ...loanApplication };
    try {
      if (typeof step !== 'number' || step > 2) {
        return;
      }
      switch (step) {
        case 0:
          if (isSelected === 'start') {
            return closeResume();
          }
          setStep(1);
          break;
        case 1:
          const response = await handleGetStarted(true, auxLoanApplication, dispatch, formValues.email);
          if (response && response.data.isEligible) {
            return sendCodeToEmail();
          }
          return closeResume();
          break;
        case 2:
          setStep(0);
          dispatch(setIsResume(false));
          break;
        default:
          setStep(step + 1);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleIsDisabled = (): boolean => {
    try {
      switch (step) {
        case 0:
          return !isSelected;
        case 1:
          const email = formValues?.email || '';
          return !formSchema.isValidSync({ email });
        case 2:
          return isSelected !== 'loanList';
        default:
          return false;
      }
    } catch (error) {
      console.error(error);
      return true;
    }
  };

  const sendCodeToEmail = async () => {
    try {
      const endpoint = '/auth/sendCode';
      const payload = { email: formValues.email };
      const newAction = {
        actionProperties: {
          endpoint,
        },
      };
      await makePostCall(newAction, loanApplication, payload, dispatch, templateConfig);
      setIsOpenEnterCode(true);
    } catch (error) {
      setInfoModalError(infoModal.unableSendEmail);
      setStep(1);
      setIsUnable(true);
      console.error();
    } finally {
    }
  };

  const closeResume = () => {
    if (value !== 'Get Started') {
      window.location.replace('/get-started');
    }
    dispatch(setIsResume(false));
  };

  const handleLoanSelection = (index: number) => {
    setLoadSelected(index);
    handleSelection('loanList');
  };
  // TODO: hardcoded data to backend integration
  const { propertyStreetAddress, propertyCity, propertyState, propertyZip, propertyOccupancyDate } = loanApplication?.borrowerProperty;
  const { loanNumber } = loanApplication;
  const address = `${propertyStreetAddress}, ${propertyCity}, ${propertyState} ${propertyZip}`;
  const startDate = propertyOccupancyDate;
  const loan = `Loan #${loanNumber}`;
  const loansSelection = propertyStreetAddress ? [{ address, loan, startDate }] : listLoanSelection?.loans;

  const logo = templateConfig?.globalTheme?.logo;
  return (
    <ResumeContainer>
      <Header>
        <img src={logo} alt="logo" />{' '}
      </Header>
      <TitleContainer data-testid="title-container">
        <TextBlock text={title} direction={'center'} color="primary" type={TypeOfText.H1} />
        <TextBlock text={subTitle} direction={'center'} color="default" type={TypeOfText.Subtitle} />
      </TitleContainer>
      <WrapperResume $isHidden={step !== 0}>
        <BTN onClick={() => handleSelection('existing')} $isSelected={isSelected === 'existing'}>
          <ResumeExistingImg />
          <p>Resume Existing Application</p>
        </BTN>
        <BTN $isSelected={isSelected === 'start'} onClick={() => handleSelection('start')}>
          <ResumeStartImg />
          <p>Start New Application</p>
        </BTN>
      </WrapperResume>
      <WrapperInput $isHidden={email.isHidden}>
        <TextInput
          propertyType={PropertyType.Email}
          placeholder={email.text}
          size={'50%'}
          typeOfIcon="email"
          type="email"
          onChange={handleChange}
          isHidden={email.isHidden ?? false}
        />
      </WrapperInput>
      <WrapperLoanList $isHidden={listLoanSelection?.isHidden}>
        {loansSelection?.map((loans, index) => {
          const { address, loan, startDate } = loans;
          return (
            <Loans key={index} $isSelected={loadSelected === index} onClick={() => handleLoanSelection(index)}>
              <p>{address}</p>
              <p>{loan}</p>
              <p>{startDate}</p>
            </Loans>
          );
        })}
      </WrapperLoanList>
      <WrapperButtons>
        <Button label={backButton.text} onClick={() => setStep(step - 1)} variant="secondary" buttonSize="l" isDisabled={!isSelected} isHidden={backButton.isHidden ?? false} />
        <Button label={!isLoading ? 'Continue' : <Spinner />} onClick={handleClick} variant="primary" buttonSize="l" isDisabled={handleIsDisabled() || isLoading} />
      </WrapperButtons>
      <WrapperButtonRestartCode>
        <Button label={buttonRestart?.text} onClick={() => setStep(2)} variant="primary" buttonSize="l" isHidden={buttonRestart.isHidden ?? false} />
      </WrapperButtonRestartCode>
      <ModalErrorCode
        isUnable={isUnable}
        setIsUnable={setIsUnable}
        title={infoModalError.title}
        description={infoModalError.description}
        labelButtonLeft={infoModalError.labelButtonLeft}
        labelButtonRight={infoModalError.labelButtonRight}
        closeResume={closeResume}
      />
      <ModalVerificationCode
        open={isOpenEnterCode}
        setIsOpenEnterCode={setIsOpenEnterCode}
        email={formValues.email}
        isSelected={isSelected}
        setStep={setStep}
        sendCodeToEmail={sendCodeToEmail}
      />
    </ResumeContainer>
  );
};

export default Resume;
