import { LoanApplicationState } from '../reducers/LoanApplication.types';

export const handleDrawAmount = (numberValue: number, auxLoanApplication: LoanApplicationState) => {
  const minDrawAmount = numberValue;
  auxLoanApplication.drawAmount = minDrawAmount;

  if (auxLoanApplication.lineAmount <= 49999) {
    auxLoanApplication.drawAmount = numberValue;
    auxLoanApplication.minDrawAmount = numberValue;
  } else {
    auxLoanApplication.drawAmount = minDrawAmount;
    auxLoanApplication.minDrawAmount = minDrawAmount;
  }

  if (auxLoanApplication?.estimatedHomeEquity < 50000) {
    auxLoanApplication.drawAmount = numberValue;
  }
  return auxLoanApplication;
};
