import React from 'react';
import CompletionCongratulations from '@/components/CompletionCongratulations/CompletionCongratulations';
import { Wrapper } from './Completion.styles';
import NextStepsButton from '@/components/Button/NextSteps/NextStepsButton';

const Completion: React.FC = () => {
  return (
    <Wrapper data-testid="wrapper-container">
      <CompletionCongratulations />
      <NextStepsButton />
    </Wrapper>
  );
};

export default Completion;
