import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Wrapper } from '../wrapper.styles';
import HMDAFormGroup from '@/components/Group/HDMAFormGroup/HDMAFormGroup';
import { NavigationButton } from '@/components/Button/NavigationButton/NavigationButton';
import { PageOrigin } from '@/components/Button/NavigationButton/NavigationButton';
import { useAppSelector } from '@app/hooks';

interface GovernmentMonitoringInformationProps {
  onAccess: (page: string) => void;
}

const GovernmentMonitoringInformation: React.FC<GovernmentMonitoringInformationProps> = ({ onAccess }) => {
  const navigate = useNavigate();
  const templateConfig = useAppSelector((state) => state.TemplateConfig);
  const [formError, setFormError] = React.useState(false);

  const hasDeclarations = !!templateConfig?.pages?.declarations;
  const nextPage = hasDeclarations ? PageOrigin.DECLARATIONS : PageOrigin.INQUIRY_SUMMARY;

  return (
    <>
      <Wrapper>
        <HMDAFormGroup setFormError={setFormError} />
      </Wrapper>
      <NavigationButton
        variant="primary"
        nextButton={templateConfig?.pages?.governmentMonitoringInformation?.NavigationButton?.nextButton || 'Next'}
        isBackButton={templateConfig?.pages?.governmentMonitoringInformation?.NavigationButton?.backButton?.isHidden || false}
        backButton={templateConfig?.pages?.governmentMonitoringInformation?.NavigationButton?.backButton?.text || 'Back'}
        disabled={formError}
        type="next"
        onAccess={onAccess}
        navigation={navigate}
        pageOrigin={PageOrigin.EMPLOYMENT_INFORMATION}
        currentPage={PageOrigin.GOVERNMENT_MONITORING_INFORMATION}
        destinationPage={nextPage}
      />
    </>
  );
};

export default GovernmentMonitoringInformation;
