import * as Yup from 'yup';

export const createArrayValidation = (required: boolean, minItems?: number, maxItems?: number, validationMessage?: string): Yup.ArraySchema<any, Yup.AnyObject> => {
  let yupSchema = Yup.array();
  if (required) {
    yupSchema = yupSchema.required(validationMessage || 'Is required');
  }
  if (minItems !== undefined) {
    yupSchema = yupSchema.min(minItems, `At least ${minItems} items are required`);
  }
  if (maxItems !== undefined) {
    yupSchema = yupSchema.max(maxItems, `No more than ${maxItems} items are allowed`);
  }
  return yupSchema;
};
