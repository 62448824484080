import styled, { css } from 'styled-components';
export interface WrapperProps {
  $titleIsCustomColor?: boolean;
  $titleCustomColor?: string;
  width: string;
}

const TitleSubtitleText = css`
  text-align: center;
  font-family: Poppins;
  font-style: normal;
  line-height: normal;
  justify-content: center;
`;

export const TitleContainer = styled.div<WrapperProps>`
  justify-content: center;
  align-items: center;
  width: ${({ width }) => width};
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: 100%;
  }
  :first-child {
    ${TitleSubtitleText};
    font-size: 1.688rem;
    font-weight: 700;
    color: ${({ theme, $titleIsCustomColor, $titleCustomColor }) => ($titleIsCustomColor ? $titleCustomColor : theme.colors.bgSecondary)};
    @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      font-size: 1rem;
    }
  }
`;
