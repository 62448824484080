import styled, { keyframes } from 'styled-components';
import InfoIcons from '@/assets/images/info-icons.svg?react';

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export interface DefaultModalProps {
  $isOpen?: boolean;
  $isLoading?: boolean;
}

export const BackDrop = styled.div<DefaultModalProps>`
  display: ${(props) => (props.$isOpen ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1040;
  transition: opacity 0.3s ease;
  opacity: ${(props) => (props.$isOpen ? 1 : 0)};
  pointer-events: ${(props) => (props.$isOpen ? 'auto' : 'none')};
`;
export const Modal = styled.div<DefaultModalProps>`
  display: ${(props) => (props.$isOpen ? 'block' : 'none')};
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  border-radius: 0.5rem;
  box-shadow: 0 0.625rem 3.125rem rgba(0, 0, 0, 0.5);
  z-index: 1050;
  max-height: 50rem;
  min-height: 21.875rem;
  max-width: ${(props) => (props.$isLoading ? '27.875rem' : '48rem')};
  width: 45%;
  overflow-y: auto;
  border: 4px solid ${(props) => props.theme.colors.primary};
  padding: 50px;
  @media only screen and (max-width: ${(props) => props.theme.breakpoints.lg}) {
    width: 95%;
  }
`;

export const ModalBody = styled.div`
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Poppins, sans-serif;
  flex-direction: column;
  @media only screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
    padding: 1.125rem;
  }
`;

export const ModalTitle = styled.div<DefaultModalProps>`
  font-weight: 500;
  margin-bottom: 30px;
  font-size: ${(props) => (props.$isLoading ? '1.563rem' : '1.563rem')};
  display: flex;
  line-height: normal;
  justify-content: center;
  align-items: center;
  font-family: Poppins;
  text-align: center;
  color: ${(props) => props.theme.colors.textPrimaryLd};
  @media only screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
    font-size: 1.2rem;
  }
`;
export const ModalContent = styled.div<DefaultModalProps>`
  font-weight: 400;
  font-size: 1.25rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Poppins, sans-serif;
  line-height: normal;
  white-space: normal;
  text-align: center;
  @media only screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
    font-size: 1rem;
  }
`;
export const ModalFooter = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 20rem;
  margin: 0;
  width: 100%;
  nav {
    margin-bottom: 0;
  }
`;

export const BodyText = styled.p`
  font-size: 1rem;
  margin-bottom: 0.313rem;
  text-align: center;
`;

export const InfoIcon = styled(InfoIcons)`
  width: 1.5rem;
  height: 1.714rem;
  cursor: pointer;
  @media only screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
    width: 3.25rem;
  }
`;

export const WrapperInput = styled.div`
  width: 30rem;
  background: ${(props) => props.theme.colors.bgWhite};
  margin: 2rem 0;
  padding: 0 50px;
  :nth-child(1) {
    border-radius: 0.563rem;
    width: auto;
  }
  input {
    text-align: center !important;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: 90%;
  }
`;

export const WrapperButtonRestartCode = styled.div`
  nav:nth-of-type(1) {
    button {
      background-color: transparent;
      color: ${({ theme }) => theme.colors.primary};
      border: none;
    }
  }
`;

export const Message = styled.div<{ $validated: boolean }>`
  font-size: 0.9em;
  color: ${({ $validated }) => ($validated ? 'green' : 'red')};
  margin-bottom: 0.625rem;
`;

export const Spinner = styled.div`
  width: 3.438rem;
  height: 3.438rem;
  margin-bottom: 3rem;
  border: 0.375rem solid ${({ theme }) => theme.colors.bgWhite};
  border-top: 0.375rem solid ${({ theme }) => theme.colors.bgSecondary};
  border-radius: 50%;
  animation: ${spin} 1s linear infinite;
`;

export const WrapperButtons = styled.div`
  display: 'flex';
`;
