import styled, { css, keyframes } from 'styled-components';
import spinnerIcon from '../../assets/images/spinner.svg?react';

const FlexCenter = css`
  display: flex;
  align-items: center;
`;

export const Reprice = styled.div`
  ${FlexCenter}
  flex-direction: column;
  padding: 0 1rem;
  max-width: 26.375rem;
  @media only screen and (max-width: ${(props) => props.theme.breakpoints?.xs}) {
    padding: 0;
  }
  nav {
    width: 35%;
    margin-bottom: 0rem;
    height: 4.2rem;
    @media only screen and (max-width: ${(props) => props.theme.breakpoints?.lg}) {
      height: 2.5rem;
    }
    button {
      min-width: 5rem;
      height: 2.5rem;
      border-radius: 0.2rem;
      margin-top: 0.9rem;
      &:disabled {
        width: 6.5rem;
        height: 2.5rem;
        border-radius: 0.2rem;
        margin-top: 0.9rem;
      }
      @media only screen and (max-width: ${(props) => props.theme.breakpoints?.lg}) {
        width: 1rem;
        min-width: 5rem;
        font-size: 0.75rem;
        margin-top: 0.4rem;
        height: 2rem;
      }
    }
  }
`;

export const InputContainer = styled.div`
  ${FlexCenter}
  flex-direction: row;
  width: 100%;
  @media only screen and (max-width: ${(props) => props.theme.breakpoints?.lg}) {
    align-items: normal;
    gap: 0.8rem;
  }
  div {
    @media only screen and (max-width: ${(props) => props.theme.breakpoints?.sm}) {
      width: 7rem;
      min-width: 6rem;
      flex-grow: 0;
      height: 2.7rem;
    }
  }
`;

export const LimitAmount = styled.div`
  ${FlexCenter}
  flex-direction: row;
  text-align: center;
  font-family: Poppins;
  font-size: 0.813rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0 0.5rem;
  @media only screen and (max-width: ${(props) => props.theme.breakpoints?.lg}) {
    padding-top: 0.5rem;
    font-size: 0.572rem;
  }
`;

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled(spinnerIcon)`
  width: 1rem;
  height: 2rem;
  margin-bottom: 0rem;
  animation: ${spin} 1s linear infinite;
`;
