import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Wrapper } from '../wrapper.styles';
import { FormGroupComponent } from '@components/input/FormGroup/FormGroup';
import { PhoneInput } from '@/components/input/PhoneInput/PhoneInput';
import { TextInput } from '../../components/input/TextInput/TextInput';
import { TextBlock } from '@components/text/TextBlock';
import { TypeOfText } from '@components/text/TextBlock';
import { DropDownInput } from '@/components/input/DropDownInput/DropDownInput';
import DataPicker from '@/components/input/DatePicker/DatePicker';
import { useAppSelector, useAppDispatch } from '@app/hooks';
import { CheckConsent } from '@/components/input/CheckConsent/CheckConsent';
import { NavigationButton, PageOrigin } from '@/components/Button/NavigationButton/NavigationButton';
import { useIsMobile } from '@/hooks/useIsMobile';
import { PersonalInfoWrapper, ButtonWrapper, ConfirmMessageContainer } from './PersonalInformation.styles';
import { Button } from '@/components/Button/DefaultButton/Button';
import _ from 'lodash';
import { fieldFilled } from '@/utils/handleFieldsFilled';
interface PersonalInformationProps {
  onAccess: (page: string) => void;
}

type Borrower = {
  selectStatus: any;
  dateBirthday: string | undefined;
  SSN: any;
  contactMethod: any;
  citizenship: any;
  mobilePhone?: string;
  homePhone?: string;
  workPhone?: string;
};

type CoBorrower = {
  firstName: string;
  lastName: string;
  relationship: string;
  selectStatusCoborrower: any;
  dateBirthdayCoBorrower: string | undefined;
  mobilePhoneCoBorrower?: string;
  email: string;
  contactMethodCoBorrower: any;
  SSNCoBorrower: any;
  workPhoneCoBorrower?: string;
  homePhoneCoBorrower?: string;
};

export enum PropertyType {
  Status = 'selectStatus',
  DateBirthday = 'dateBirthday',
  SSN = 'SSN',
  MobilePhone = 'mobilePhone',
  HomePhone = 'homePhone',
  WorkPhone = 'workPhone',
  ContactMethod = 'contactMethod',
  Citizenship = 'citizenship',
  MailAddress = 'emailAddress',
  FirstNameCoBorrower = 'firstNameCoBorrower',
  MiddleName = 'middleName',
  LastNameCoborrower = 'lastNameCoborrower',
  Suffix = 'suffix',
  Relationship = 'relationship',
  StatusCoBorrower = 'selectStatusCoborrower',
  DateBirthdayCoBorrower = 'dateBirthdayCoBorrower',
  MobilePhoneCoBorrower = 'mobilePhoneCoBorrower',
  HomePhoneCoBorrower = 'homePhoneCoBorrower',
  WorkPhoneCoBorrower = 'workPhoneCoBorrower',
  EmailCoborrower = 'emailCoborrower',
  ContactMethodCoBorrower = 'contactMethodCoBorrower',
  SSNCoBorrower = 'SSNCoBorrower',
}
const PersonalInformation: React.FC<PersonalInformationProps> = ({ onAccess }) => {
  const [coBorrower, setCoBorrower] = useState<number>(0);
  const [, setBorrowerInfo] = useState({ date: '', email: '' });
  let loanApplication = useAppSelector((state) => state.LoanApplication);
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const dispatch = useAppDispatch();
  let templateConfig = useAppSelector((state) => state.TemplateConfig);
  const {
    selectStatus,
    SSN,
    mobilePhone,
    workPhone,
    homePhone,
    contactMethod,
    citizenship,
    emailAddress,
    firstNameCoBorrower,
    middleNameCoBorrower,
    lastNameCoborrower,
    suffix,
    relationship,
    selectStatusCoborrower,
    dateBirthdayCoBorrower,
    mobilePhoneCoBorrower,
    homePhoneCoBorrower,
    workPhoneCoBorrower,
    emailCoborrower,
    contactMethodCoBorrower,
    SSNCoBorrower,
    TCPA,
  } = useAppSelector((state) => state.CurrentForm);
  const hasCoBorrower = coBorrower !== 0;
  const frontProgramType = loanApplication?.selectedProgramType === 'Line of Credit' ? 'helocData' : 'helData';
  const isICE = templateConfig.isICE ?? false;
  const isHelProgram = frontProgramType === 'helData' || isICE;
  const hasDrawAmountPage = (templateConfig?.pages?.hasDrawAmountPage || !isHelProgram) ?? false;
  const pageNavigation = hasDrawAmountPage ? PageOrigin.VMO_DRAW_AMOUNT : PageOrigin.VMO_LINE_AMOUNT;
  const previousPage = templateConfig?.features?.vmoRedesign?.isEnabled ? PageOrigin.VMO : pageNavigation;
  const hasDateBirthday = loanApplication?.borrowers[0]?.borrowerDOB !== '';
  const [formValues, setFormValues] = useState({
    selectStatus: selectStatus || '',
    dateBirthday: loanApplication?.borrowers[0]?.borrowerDOB,
    SSN: SSN || '',
    mobilePhone: mobilePhone || '',
    homePhone: homePhone || '',
    workPhone: workPhone || '',
    contactMethod: contactMethod || '',
    citizenship: citizenship || '',
    emailAddress: emailAddress || '',
    firstNameCoBorrower: firstNameCoBorrower || '',
    middleNameCoBorrower: middleNameCoBorrower || '',
    lastNameCoborrower: lastNameCoborrower || '',
    suffixCoborrower: suffix || '',
    relationship: relationship || '',
    selectStatusCoborrower: selectStatusCoborrower || '',
    dateBirthdayCoBorrower: dateBirthdayCoBorrower || '',
    mobilePhoneCoBorrower: mobilePhoneCoBorrower || '',
    homePhoneCoBorrower: homePhoneCoBorrower || '',
    workPhoneCoBorrower: workPhoneCoBorrower || '',
    emailCoborrower: emailCoborrower || '',
    contactMethodCoBorrower: contactMethodCoBorrower || '',
    SSNCoBorrower: SSNCoBorrower || '',
    TCPA: TCPA || false,
  });
  const [formError, setFormError] = React.useState(false);
  const handleChange = _.debounce((value: string | number | boolean, name: string) => {
    setFormValues((prevValues: any) => ({
      ...prevValues,
      [name]: value,
    }));
    let obj: any = {};
    obj[name] = value;
    dispatch({ type: 'CurrentForm/addField', payload: { ...formValues, [name]: value } });
  }, 200);
  const requiredValues = {
    borrower: {
      selectStatus: formValues.selectStatus,
      dateBirthday: hasDateBirthday ? loanApplication?.borrowers[0]?.borrowerDOB : formValues.dateBirthday,
      SSN: formValues.SSN,
      contactMethod: formValues.contactMethod,
      citizenship: formValues.citizenship,
      TCPA: formValues.TCPA,
    } as Borrower,
    coBorrower: {
      firstName: formValues.firstNameCoBorrower,
      lastName: formValues.lastNameCoborrower,
      relationship: formValues.relationship,
      selectStatusCoborrower: formValues.selectStatusCoborrower,
      dateBirthdayCoBorrower: formValues.dateBirthdayCoBorrower,
      email: formValues.emailCoborrower,
      contactMethodCoBorrower: formValues.contactMethodCoBorrower,
      SSNCoBorrower: formValues.SSNCoBorrower,
    } as CoBorrower,
  };

  if (formValues.contactMethodCoBorrower === 'cell') {
    requiredValues.coBorrower.mobilePhoneCoBorrower = formValues.mobilePhoneCoBorrower;
  } else if (formValues.contactMethodCoBorrower === 'home') {
    requiredValues.coBorrower.homePhoneCoBorrower = formValues.homePhoneCoBorrower;
  } else if (formValues.contactMethodCoBorrower === 'work') {
    requiredValues.coBorrower.workPhoneCoBorrower = formValues.workPhoneCoBorrower;
  }

  if (formValues.contactMethod === 'cell') {
    requiredValues.borrower.mobilePhone = formValues.mobilePhone;
  } else if (formValues.contactMethod === 'home') {
    requiredValues.borrower.homePhone = formValues.homePhone;
  } else if (formValues.contactMethod === 'work') {
    requiredValues.borrower.workPhone = formValues.workPhone;
  }
  const allRequiredValues = hasCoBorrower ? { ...requiredValues.borrower, ...requiredValues.coBorrower } : requiredValues.borrower;
  useEffect(() => {
    const allFieldsFilled = fieldFilled(allRequiredValues);
    setFormError(allFieldsFilled);
  }, [formValues, hasCoBorrower]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (loanApplication?.borrowers[1]) {
      setCoBorrower(1);
    }
    if (loanApplication?.borrowers[0]?.borrowerDOB) {
      setBorrowerInfo({ date: loanApplication?.borrowers[0]?.borrowerDOB, email: loanApplication?.borrowers[0]?.borrowerEmailAddress });
    }
  }, []);
  useEffect(() => {
    let auxLoanApplication = _.cloneDeep(loanApplication);

    const updatedBorrower = {
      ...auxLoanApplication.borrowers[0],
      borrowerPrimaryPhone: formValues.mobilePhone,
      borrowerAlternatePhone: formValues.homePhone,
      borrowerWorkPhone: formValues.workPhone,
      borrowerCitizenship: formValues.citizenship,
      borrowerMaritalStatus: formValues.selectStatus,
      borrowerPreferredContactMethod: formValues.contactMethod,
      borrowerSSN: formValues.SSN,
      borrowerDOB: formValues.dateBirthday,
    };
    if (hasCoBorrower) {
      const updatedCoBorrower = {
        ...auxLoanApplication.borrowers[1],
        borrowerFirstName: formValues.firstNameCoBorrower,
        borrowerMiddleName: formValues.middleNameCoBorrower,
        borrowerLastName: formValues.lastNameCoborrower,
        borrowerRelationship: formValues.relationship,
        borrowerMaritalStatus: formValues.selectStatusCoborrower,
        borrowerDOB: formValues.dateBirthdayCoBorrower,
        borrowerEmailAddress: formValues.emailCoborrower,
        borrowerPrimaryPhone: formValues.mobilePhoneCoBorrower,
        borrowerAlternatePhone: formValues.homePhoneCoBorrower,
        borrowerWorkPhone: formValues.workPhoneCoBorrower,
        borrowerPreferredContactMethod: formValues.contactMethodCoBorrower,
        borrowerSSN: formValues.SSNCoBorrower,
        appendValue: 1,
        isPrimary: false,
        employmentHistory: [
          {
            appendValue: 0,
          },
        ],
        governmentHMDA: {
          applicantRace: [],
          applicantEthnicity: [],
          applicantSex: [],
        },
        otherIncome: [],
      };
      auxLoanApplication.borrowers[1] = updatedCoBorrower;
    }
    auxLoanApplication.isTcpaAccepted = formValues.TCPA;
    auxLoanApplication.isTcpaAccepted = formValues.TCPA;
    auxLoanApplication.borrowers[0] = updatedBorrower;

    dispatch({
      type: 'LoanApplication/updateValue',
      payload: { ...auxLoanApplication },
    });
  }, [formValues]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleIsRequired = (value: 'cell' | 'home' | 'work' | 'Email', type: 'borrower' | 'coBorrower'): boolean => {
    const contactMethod = type === 'borrower' ? formValues?.contactMethod : formValues?.contactMethodCoBorrower;

    return value === contactMethod;
  };

  return (
    <>
      <PersonalInfoWrapper $isMobile={isMobile}>
        <Wrapper $gap={isMobile ? '0rem' : '1.563rem'} $isMobile={isMobile}>
          <TextBlock
            text={`${templateConfig?.pages?.personalInformation?.placeHolder?.text || 'Applicant:'} ${loanApplication?.borrowers?.[0].borrowerFirstName || 'Marisol'} ${loanApplication?.borrowers?.[0].borrowerLastName || 'Testcase'}`}
            direction={'flex-start'}
            color="default"
            type={TypeOfText.H2}
            isHidden={templateConfig?.pages?.personalInformation?.placeHolder?.isHidden}
          />
          <FormGroupComponent data-testid="address-form" gap="1.563rem" onChange={handleChange}>
            <DropDownInput
              label={templateConfig?.pages?.personalInformation?.formInputs?.selectStatus?.label || 'Marital Status'}
              placeHolder={templateConfig?.pages?.personalInformation?.formInputs?.selectStatus?.placeHolder || 'Select Status'}
              options={templateConfig?.pages?.personalInformation?.formInputs?.selectStatus?.options}
              size="23%"
              typeOfIcon="dollar"
              labelContainer={true}
              hasAsterisk={true}
              propertyType={PropertyType.Status}
              isHidden={templateConfig?.pages?.personalInformation?.formInputs?.selectStatus?.isHidden}
            />
            <DataPicker
              label={templateConfig?.pages?.personalInformation?.formInputs?.dateBirth?.label || 'Date of Birth'}
              labelContainer={true}
              placeholder={templateConfig?.pages?.personalInformation?.formInputs?.dateBirth?.placeHolder || 'MM/DD/YYYY'}
              typeOfIcon="date"
              propertyName={'dateOfBirth'}
              propertyType={PropertyType.DateBirthday}
              hasAsterisk={true}
              value={loanApplication?.borrowers[0]?.borrowerDOB || ''}
              isHidden={templateConfig?.pages?.personalInformation?.formInputs?.dateBirth?.isHidden ?? false}
            />
            <TextInput
              label={templateConfig?.pages?.personalInformation?.formInputs?.SSN?.label || 'Social Security Number (SSN)'}
              placeholder={templateConfig?.pages?.personalInformation?.formInputs?.SSN?.placeHolder || '### - ## - ####'}
              size="25%"
              typeOfIcon="text"
              isInfoModal={false}
              hasAsterisk={true}
              propertyType={PropertyType.SSN}
              isHidden={templateConfig?.pages?.personalInformation?.formInputs?.SSN?.isHidden ?? false}
            />
            <ConfirmMessageContainer $isHidden={templateConfig?.pages?.personalInformation?.messages?.ssnMessages?.isHidden ?? false}>
              <TextBlock
                text={templateConfig?.pages?.personalInformation?.messages?.ssnMessages?.title}
                type={TypeOfText.H1}
                color="primary"
                isHidden={templateConfig?.pages?.personalInformation?.messages?.ssnMessages?.isHidden}
              />
              <TextBlock
                text={templateConfig?.pages?.personalInformation?.messages?.ssnMessages?.body}
                type={TypeOfText.H1}
                isHidden={templateConfig?.pages?.personalInformation?.messages?.ssnMessages?.isHidden}
              />
            </ConfirmMessageContainer>
            <PhoneInput
              placeHolder={templateConfig?.pages?.personalInformation?.formInputs?.mobilePhone?.placeHolder || '(###) ### - ####'}
              labelContainer={true}
              label={templateConfig?.pages?.personalInformation?.formInputs?.mobilePhone?.label || 'Mobile Phone'}
              size={'30%'}
              type="borrowerAlternatePhone"
              options={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.phone?.options}
              propertyName={'personal-information'}
              hasAsterisk={handleIsRequired('cell', 'borrower')}
              propertyType={PropertyType.MobilePhone}
              isHidden={templateConfig?.pages?.personalInformation?.formInputs?.mobilePhone?.isHidden ?? false}
            />
            <PhoneInput
              placeHolder={templateConfig?.pages?.personalInformation?.formInputs?.homePhone?.placeHolder || '(###) ### - ####'}
              labelContainer={true}
              label={templateConfig?.pages?.personalInformation?.formInputs?.homePhone?.label || 'Home Phone'}
              size={'30%'}
              type="borrowerPrimaryPhone"
              options={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.phone?.options}
              isHidden={templateConfig?.pages?.personalInformation?.formInputs?.homePhone?.isHidden ?? false}
              propertyName={'personal-information'}
              hasAsterisk={handleIsRequired('home', 'borrower')}
              propertyType={PropertyType.HomePhone}
            />
            <PhoneInput
              placeHolder={templateConfig?.pages?.personalInformation?.formInputs?.workPhone?.placeHolder || '(###) ### - ####'}
              labelContainer={true}
              label={templateConfig?.pages?.personalInformation?.formInputs?.workPhone?.label || 'Work Phone'}
              size={'30%'}
              type="borrowerWorkPhone"
              options={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.phone?.options}
              isHidden={templateConfig?.pages?.personalInformation?.formInputs?.workPhone?.isHidden ?? false}
              propertyName={'personal-information'}
              hasAsterisk={handleIsRequired('work', 'borrower')}
              propertyType={PropertyType.WorkPhone}
            />
            <DropDownInput
              label={templateConfig?.pages?.personalInformation?.formInputs?.contactMethod?.label || 'Preferred Contact Method'}
              options={templateConfig?.pages?.personalInformation?.formInputs?.contactMethod?.options}
              size="28%"
              placeHolder={templateConfig?.pages?.personalInformation?.formInputs?.contactMethod?.placeHolder || 'Select Preference'}
              labelContainer={true}
              hasAsterisk={true}
              propertyType={PropertyType.ContactMethod}
              isHidden={templateConfig?.pages?.personalInformation?.formInputs?.contactMethod?.isHidden ?? false}
            />
            <DropDownInput
              label={templateConfig?.pages?.personalInformation?.formInputs?.citizenship?.label || 'Citizenship'}
              options={templateConfig?.pages?.personalInformation?.formInputs?.citizenship?.options}
              size="28%"
              placeHolder={templateConfig?.pages?.personalInformation?.formInputs?.citizenship?.placeHolder || 'Select status'}
              labelContainer={true}
              typeOfIcon="location"
              hasAsterisk={true}
              propertyType={PropertyType.Citizenship}
              isHidden={templateConfig?.pages?.personalInformation?.formInputs?.citizenship?.isHidden ?? false}
            />
            <TextInput
              label={templateConfig?.pages?.personalInformation?.formInputs?.emailAddress?.label || 'Email Address'}
              placeholder={templateConfig?.pages?.personalInformation?.formInputs?.emailAddress?.placeHolder || 'you@email.com'}
              size={'32%'}
              typeOfIcon="email"
              type="email"
              value={loanApplication?.borrowers[0]?.borrowerEmailAddress || ''}
              hasAsterisk={false}
              propertyType={PropertyType.MailAddress}
              isHidden={templateConfig?.pages?.personalInformation?.formInputs?.emailAddress?.isHidden || false}
            />
          </FormGroupComponent>
          {hasCoBorrower ? (
            <TextBlock
              text={templateConfig?.pages?.personalInformation?.formInputs?.Coborrower?.title?.text || 'Co-Borrower'}
              isHidden={templateConfig?.pages?.personalInformation?.formInputs?.Coborrower?.title?.isHidden ?? false}
              direction={'flex-start'}
              color="default"
              type={TypeOfText.H2}
            />
          ) : (
            <></>
          )}
          {coBorrower < 1 ? (
            <ButtonWrapper $isHidden={templateConfig?.pages?.personalInformation?.button?.add?.isHidden ?? false}>
              <Button
                label={templateConfig?.pages?.personalInformation?.button?.add?.text || 'Add Co-Applicant'}
                hasIcon={true}
                onClick={() => setCoBorrower(coBorrower + 1)}
                data-testid="button"
                variant="secondary"
              />
            </ButtonWrapper>
          ) : (
            <ButtonWrapper $isHidden={templateConfig?.pages?.personalInformation?.button?.remove?.isHidden ?? false}>
              <Button
                label={templateConfig?.pages?.personalInformation?.button?.remove?.text || '- Remove Co-Applicant'}
                onClick={() => setCoBorrower(coBorrower - 1)}
                data-testid="button"
                variant="secondary"
              />
            </ButtonWrapper>
          )}
          {coBorrower !== 0 &&
            Array.from({ length: coBorrower }).map((_, index) => (
              <FormGroupComponent key={index} onChange={handleChange}>
                <ConfirmMessageContainer $isHidden={templateConfig?.pages?.personalInformation?.messages?.coBorrowerMessages?.isHidden ?? false}>
                  <TextBlock
                    text={templateConfig?.pages?.personalInformation?.messages?.coBorrowerMessages?.title}
                    type={TypeOfText.H1}
                    color="primary"
                    isHidden={templateConfig?.pages?.personalInformation?.messages?.coBorrowerMessages?.isHidden}
                  />
                  <TextBlock
                    text={templateConfig?.pages?.personalInformation?.messages?.coBorrowerMessages?.body}
                    type={TypeOfText.H1}
                    isHidden={templateConfig?.pages?.personalInformation?.messages?.coBorrowerMessages?.isHidden}
                  />
                </ConfirmMessageContainer>
                <TextInput
                  label={templateConfig?.pages?.personalInformation?.formInputs?.Coborrower?.firstName?.label || 'First Name'}
                  placeholder={templateConfig?.pages?.personalInformation?.formInputs?.Coborrower?.firstName?.placeHolder || 'First Name'}
                  typeOfIcon="text"
                  size={'30%'}
                  propertyType={PropertyType.FirstNameCoBorrower}
                  isHidden={templateConfig?.pages?.personalInformation?.formInputs?.Coborrower?.firstName?.isHidden ?? false}
                />
                <TextInput
                  label={templateConfig?.pages?.personalInformation?.formInputs?.Coborrower?.middleName?.label || 'Middle Initial'}
                  placeholder={templateConfig?.pages?.personalInformation?.formInputs?.Coborrower?.middleName?.placeHolder || 'Middle'}
                  typeOfIcon="text"
                  size={'20%'}
                  isRequired={false}
                  propertyType={PropertyType.MiddleName}
                  isHidden={templateConfig?.pages?.personalInformation?.formInputs?.Coborrower?.middleName?.isHidden ?? false}
                />
                <TextInput
                  label={templateConfig?.pages?.personalInformation?.formInputs?.Coborrower?.lastName?.label || 'Last Name'}
                  placeholder={templateConfig?.pages?.personalInformation?.formInputs?.Coborrower?.lastName?.placeHolder || 'Last Name'}
                  typeOfIcon="text"
                  size={'30%'}
                  propertyType={PropertyType.LastNameCoborrower}
                  isHidden={templateConfig?.pages?.personalInformation?.formInputs?.Coborrower?.lastName?.isHidden ?? false}
                />
                <DropDownInput
                  label={templateConfig?.pages?.personalInformation?.formInputs?.Coborrower?.suffix?.label || 'Suffix'}
                  placeHolder={templateConfig?.pages?.personalInformation?.formInputs?.Coborrower?.suffix?.title || 'Suffix'}
                  options={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.suffix?.options}
                  isRequired={false}
                  labelContainer={true}
                  size={'10%'}
                  propertyType={PropertyType.Suffix}
                  isHidden={templateConfig?.pages?.personalInformation?.formInputs?.Coborrower?.suffix?.isHidden || false}
                />
                <DropDownInput
                  labelContainer={true}
                  label={templateConfig?.pages?.personalInformation?.formInputs?.Coborrower?.relationship?.label || 'Relationship to Primary Applicant'}
                  placeHolder={templateConfig?.pages?.personalInformation?.formInputs?.Coborrower?.relationship?.placeHolder || 'Select Relationship'}
                  options={templateConfig?.pages?.personalInformation?.formInputs?.Coborrower?.relationship?.options}
                  isRequired={false}
                  size={'23%'}
                  propertyType={PropertyType.Relationship}
                  isHidden={templateConfig?.pages?.personalInformation?.formInputs?.Coborrower?.relationship?.isHidden ?? false}
                />
                <DropDownInput
                  label={templateConfig?.pages?.personalInformation?.formInputs?.Coborrower?.selectStatus?.label || 'Marital Status'}
                  placeHolder={templateConfig?.pages?.personalInformation?.formInputs?.Coborrower?.selectStatus?.placeHolder || 'Select status'}
                  options={templateConfig?.pages?.personalInformation?.formInputs?.Coborrower?.selectStatus?.options}
                  size="25%"
                  typeOfIcon="dollar"
                  labelContainer={true}
                  isInfoModal={isMobile ? true : false}
                  propertyType={PropertyType.StatusCoBorrower}
                  isHidden={templateConfig?.pages?.personalInformation?.formInputs?.Coborrower?.selectStatus?.isHidden ?? false}
                />
                <DataPicker
                  label={templateConfig?.pages?.personalInformation?.formInputs?.Coborrower?.dateBirth?.label || 'Date of Birth'}
                  labelContainer={true}
                  placeholder={templateConfig?.pages?.personalInformation?.formInputs?.Coborrower?.dateBirth?.placeHolder || 'MM/DD/YYYY'}
                  typeOfIcon="date"
                  propertyName={'dateOfBirth'}
                  propertyType={PropertyType.DateBirthdayCoBorrower}
                  isHidden={templateConfig?.pages?.personalInformation?.formInputs?.Coborrower?.dateBirth?.isHidden ?? false}
                />
                <PhoneInput
                  placeHolder={templateConfig?.pages?.personalInformation?.formInputs?.Coborrower?.mobilePhone?.placeHolder || '(###) ### - ####'}
                  labelContainer={true}
                  label={templateConfig?.pages?.personalInformation?.formInputs?.Coborrower?.mobilePhone?.label || 'Mobile Phone'}
                  size={'24%'}
                  type="borrowerAlternatePhone"
                  options={
                    templateConfig?.pages?.LetsGetStarted?.consumerInformation?.phone?.options as [
                      {
                        label: string;
                        beValue: string;
                      },
                    ]
                  }
                  isHidden={templateConfig?.pages?.personalInformation?.formInputs?.Coborrower?.mobilePhone?.isHidden ?? false}
                  propertyName={'personal-information'}
                  hasAsterisk={handleIsRequired('cell', 'coBorrower')}
                  propertyType={PropertyType.MobilePhoneCoBorrower}
                />
                <PhoneInput
                  placeHolder={templateConfig?.pages?.personalInformation?.formInputs?.Coborrower?.homePhone?.placeHolder || '(###) ### - ####'}
                  labelContainer={true}
                  label={templateConfig?.pages?.personalInformation?.formInputs?.Coborrower?.homePhone?.label || 'Home Phone'}
                  size={'28%'}
                  type="borrowerPrimaryPhone"
                  options={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.phone?.options}
                  isHidden={templateConfig?.pages?.personalInformation?.formInputs?.Coborrower?.homePhone?.isHidden ?? false}
                  propertyName={'personal-information'}
                  hasAsterisk={handleIsRequired('home', 'coBorrower')}
                  propertyType={PropertyType.HomePhoneCoBorrower}
                />
                <PhoneInput
                  placeHolder={templateConfig?.pages?.personalInformation?.formInputs?.Coborrower?.workPhone?.placeHolder || '(###) ### - ####'}
                  labelContainer={true}
                  label={templateConfig?.pages?.personalInformation?.formInputs?.Coborrower?.workPhone?.label || 'Work Phone (optional)'}
                  size={'30%'}
                  type="borrowerWorkPhone"
                  options={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.phone?.options}
                  isHidden={templateConfig?.pages?.personalInformation?.formInputs?.Coborrower?.workPhone?.isHidden ?? false}
                  propertyName={'personal-information'}
                  hasAsterisk={handleIsRequired('work', 'coBorrower')}
                  propertyType={PropertyType.WorkPhoneCoBorrower}
                />
                <TextInput
                  label={templateConfig?.pages?.personalInformation?.formInputs?.Coborrower?.emailAddress?.label || 'Email Address'}
                  placeholder={templateConfig?.pages?.personalInformation?.formInputs?.Coborrower?.emailAddress?.placeHolder || 'you@email.com'}
                  size={'32%'}
                  typeOfIcon="email"
                  type="email"
                  hasAsterisk={handleIsRequired('Email', 'coBorrower')}
                  propertyType={PropertyType.EmailCoborrower}
                  isHidden={templateConfig?.pages?.personalInformation?.formInputs?.Coborrower?.emailAddress?.isHidden ?? false}
                />
                <DropDownInput
                  label={templateConfig?.pages?.personalInformation?.formInputs?.Coborrower?.contactMethod?.label || 'Preferred Contact Method'}
                  options={templateConfig?.pages?.personalInformation?.formInputs?.Coborrower?.contactMethod?.options}
                  size="23%"
                  placeHolder={templateConfig?.pages?.personalInformation?.formInputs?.Coborrower?.contactMethod?.placeHolder || 'Select Preference'}
                  labelContainer={true}
                  propertyType={PropertyType.ContactMethodCoBorrower}
                  isHidden={templateConfig?.pages?.personalInformation?.formInputs?.Coborrower?.contactMethod?.isHidden ?? false}
                />
                <TextInput
                  label={templateConfig?.pages?.personalInformation?.formInputs?.Coborrower?.SSN?.label || 'Social Security Number (SSN)'}
                  placeholder={templateConfig?.pages?.personalInformation?.formInputs?.Coborrower?.SSN?.placeHolder || '### - ## - ####'}
                  size="30%"
                  typeOfIcon="text"
                  hasAsterisk={true}
                  propertyType={PropertyType.SSNCoBorrower}
                  isInfoModal={false}
                  isHidden={templateConfig?.pages?.personalInformation?.formInputs?.Coborrower?.SSN?.isHidden ?? false}
                />
                {coBorrower !== 0 && (
                  <CheckConsent
                    text={
                      templateConfig?.pages?.personalInformation?.formInputs?.Coborrower?.coborrowerConsent?.text ||
                      'I agree to the Consent to Receive Electronic Loan Documents and understand that communications, loan documents, and other disclosures will be provided to me electronically. Read <a href="https://docs.google.com/gview?embedded=true&amp;url=https://start.loandepot.com/HELOC/forms/loandepot_heloc_econsent.pdf" target="_blank">Read eConsent</a> to receive documents electronically is required for this program'
                    }
                    isInfoModal={false}
                    direction={'center'}
                    isUnderline={false}
                    onChange={handleChange}
                    type={'coapplicantConsent'}
                    isHidden={templateConfig?.pages?.personalInformation?.formInputs?.Coborrower?.coborrowerConsent?.isHidden ?? false}
                  />
                )}
              </FormGroupComponent>
            ))}
        </Wrapper>
        <Wrapper $minHeight="0" $gap="1rem" $padding="1.25rem 0.938rem" $isMobile={isMobile}>
          {isMobile && (
            <TextBlock text={templateConfig?.pages?.LetsGetStarted?.typeOfInformation?.consent?.text || 'Consent'} direction={'flex-start'} color="default" type={TypeOfText.H2} />
          )}
          <CheckConsent
            text={
              templateConfig?.pages?.personalInformation?.checkConsent ||
              'By clicking here I agree to the Lenders <a href="#">TCPA disclosure </a>allowing the lending team to contact the me via phone.'
            }
            isInfoModal={false}
            direction={'center'}
            isUnderline={false}
            onChange={handleChange}
            type={'TCPA'}
            isHidden={false}
          />
        </Wrapper>
      </PersonalInfoWrapper>
      <NavigationButton
        variant="primary"
        nextButton={templateConfig?.pages?.personalInformation?.NavigationButton?.nextButton || 'Next'}
        isBackButton={templateConfig?.pages?.personalInformation?.NavigationButton?.backButton?.isHidden || false}
        backButton={templateConfig?.pages?.personalInformation?.NavigationButton?.backButton?.text || 'Back'}
        disabled={!formError}
        type="next"
        onAccess={onAccess}
        navigation={navigate}
        pageOrigin={previousPage}
        currentPage={PageOrigin.PERSONAL_INFORMATION}
        destinationPage={PageOrigin.EMPLOYMENT_INFORMATION}
      />
    </>
  );
};

export default PersonalInformation;
