import React from 'react';
import { Title } from './NotFound.styles';
import { Wrapper } from '../wrapper.styles';

const NotFound: React.FC = () => {
  return (
    <Wrapper>
      <Title>Not Found Page!</Title>
    </Wrapper>
  );
};

export default NotFound;
