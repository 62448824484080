import styled, { css } from 'styled-components';
import ActiveSvg from '../../../assets/images/circle-default.svg?react';
import CheckedSvg from '../../../assets/images/circle-checked.svg?react';
export type RadioButtonProps = {
  width?: string;
  $active?: boolean;
  disabled?: boolean;
  checked?: boolean;
  $checkIconColor?: string;
  $checkIconBackground?: string;
  $borderColorActive?: string;
  $isHidden?: boolean;
  $border?: string;
  $maxWidth?: string;
};
export const Col = styled.div<RadioButtonProps>`
  height: 4rem;
  width: ${(props) => props.width};
  height: 3.5rem;
  flex-shrink: 0;
  flex-grow: 1;
  min-width: 11rem;
  display: ${({ $isHidden }) => ($isHidden ? 'none' : 'flex')};
  flex-direction: column;
  max-width: ${(props) => props.$maxWidth};
  @media only screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
    width: 17rem;
    min-width: 15rem;
  }
`;

export const Label = styled.label<RadioButtonProps>`
  display: block;
  font-weight: bold;
  font-family: Poppins;
  font-size: 1.063rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: ${({ theme }) => theme.colors.bgGrey};
`;

export const Button = styled.button<RadioButtonProps>`
  text-align: left;
  padding: 0.75rem;
  border-radius: 3.125rem;
  width: 100%;
  background: transparent;
  border: ${({ $border }) => $border};
  border-color: ${({ $active, $borderColorActive, theme }) => (!$active ? theme.colors.borderColor : $borderColorActive ? $borderColorActive : theme.colors.secondary)};
  box-shadow: none;
  height: 3.125rem;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  display: flex;
  align-items: center;
  transition: border-color 0.1s ease;
`;

export const Icon = styled.div`
  margin-right: 0.5rem;
  margin-right: 0.5rem;
`;

export const Text = styled.div`
  flex-grow: 1;
  padding-left: 0.5rem;
  font-family: Poppins;
  font-size: 1.188rem;
  font-weight: 300;
  color: ${({ theme }) => theme.colors.textDarkGrey};
  line-height: 1.188rem;
  @media only screen and (max-width: ${(props) => props.theme.breakpoints.xs}) {
    font-size: 1rem;
  }
`;

export const ActiveIcon = styled(ActiveSvg)`
  ${(props) => {
    return css`
      width: 1.75rem;
      height: 1.75rem;
      path {
        width: 1.25rem;
      }
      .circle-color {
        fill: ${props.theme.colors.borderColor};
      }
    `;
  }}
`;
export const CheckedIcon = styled(CheckedSvg)<RadioButtonProps>`
  ${(props) => {
    return css`
      width: 1.75rem;
      height: 1.75rem;
      path {
        fill: ${({ theme }) => theme.colors.primary};
      }
      .circle-color {
        fill: ${props.$checkIconColor ? props.$checkIconColor : props.theme.colors.bgWhite};
      }
    `;
  }}
`;
