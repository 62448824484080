import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { CheckboxWrapper, StyledButton, IconWrapper, Text, ActiveIcon, CheckedIcon, SquareActiveIcon, SquareCheckIcon } from './CheckBox.styles';

interface CheckboxProps {
  propertyName: string;
  disabled?: boolean;
  text: string;
  value: string;
  handleChange: (value: string, propertyName: string) => void;
  parseDisplayValue?: (sourceString: string, nameOfProperty: string) => string | undefined;
}

const Checkbox: React.FC<CheckboxProps> = ({ propertyName, disabled = false, text, value, handleChange, parseDisplayValue }) => {
  const [isChecked, setIsChecked] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    const obj = { [propertyName]: isChecked ? value : '' };
    dispatch({ type: 'CurrentForm/addField', payload: obj });

    return () => {
      dispatch({ type: 'CurrentForm/removeField', payload: propertyName });
    };
  }, [isChecked, dispatch, propertyName, value]);

  const setChecked = useCallback(() => {
    handleChange(value, propertyName);
    setIsChecked((prevChecked) => !prevChecked);
  }, [value, propertyName, handleChange]);

  const displayValue = useMemo(() => {
    if (!parseDisplayValue || !value) return '';
    const displayVal = parseDisplayValue(value, propertyName || '') || '';
    return displayVal;
  }, [parseDisplayValue, value, propertyName]);
  const valueWithCircleIcon = [
    'I do not wish to furnish this information',
    'notHispanic',
    'black',
    'white',
    'americanIndianOrAlaskaNative',
    'Male',
    'Female',
    'hispanic',
    'asian',
    'pacificIslander',
  ];
  const getIcon = () => {
    if (displayValue === value) {
      return valueWithCircleIcon.includes(value) ? <CheckedIcon /> : <SquareCheckIcon />;
    }
    return valueWithCircleIcon.includes(value) ? <ActiveIcon /> : <SquareActiveIcon />;
  };

  return (
    <CheckboxWrapper>
      <StyledButton disabled={disabled} onClick={setChecked}>
        <IconWrapper data-testid="icon">{getIcon()}</IconWrapper>
        <Text>{text}</Text>
      </StyledButton>
    </CheckboxWrapper>
  );
};

export default Checkbox;
