import React, { useState, useEffect } from 'react';
import ReactSlider from 'react-slider';
import { Container, TextContainer, ValueContent, SliderContainer, TextWrapper } from './Slider.styles';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '@app/hooks';

interface SliderProps {
  value?: number;
}

const score = {
  Poor: '300-619',
  Fair: '620-679',
  Good: '680-719',
  Excellent: '720+',
};

export const Slider: React.FC<SliderProps> = ({ value = 0 }) => {
  const [currentValue, setCurrentValue] = useState(value);
  const dispatch = useDispatch();
  let templateConfig = useAppSelector((state) => state.TemplateConfig);

  useEffect(() => {
    if (currentValue !== value) {
      dispatch({
        type: 'LoanApplication/updateSpecificField',
        payload: {
          depth: ['borrowers', 0, 'creditScore'],
          value: currentValue,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentValue, dispatch, value]);

  const handleChange = (newValue: number) => {
    setCurrentValue(newValue);
  };
  const maxScore = templateConfig?.isICE ? 850 : 900;
  return (
    <Container>
      <ValueContent>
        <h2>{currentValue}</h2>
      </ValueContent>
      <SliderContainer>
        <ReactSlider
          className="custom-slider"
          thumbClassName="custom-thumb"
          trackClassName="custom-track"
          min={300}
          max={maxScore}
          value={currentValue}
          onChange={handleChange}
          renderThumb={(props) => {
            const { key, ...restProps } = props;
            return <div key={key} {...restProps} className="custom-thumb"></div>;
          }}
          renderTrack={(props, state) => {
            const { key, ...restProps } = props;
            return <div key={key} {...restProps} className={`custom-track ${state.index === 0 ? 'custom-track-completed' : ''}`} />;
          }}
        />
        <TextWrapper>
          {Object.entries(templateConfig?.pages?.slider?.score || score).map(([key, value], index) => (
            <TextContainer key={`${key}+${index}`}>
              <span>{key}</span>
              <p>{value}</p>
            </TextContainer>
          ))}
        </TextWrapper>
      </SliderContainer>
    </Container>
  );
};
