import React, { useState, useEffect } from 'react';
import { InputDataPicker, DataPickerContainer, Label, DateInputIcon, IconContainer, InputContainer } from './DataPicker.styles';
import { ErrorMessage } from '../TextInput/TextInput.styled';
import { formatInput, monthsBetweenDateAndToday } from '@/utils/handleDate';
import { createValidationSchema } from '@/utils/handleDate';
import { useAppDispatch, useAppSelector } from '@/app/hooks';

export interface DatePickerProps {
  propertyName: string;
  value?: string;
  placeholder?: string;
  label?: string;
  onChange?: (option: string, propertyType: string) => void;
  propertyType: string;
  isSoftPull?: boolean;
  typeOfIcon?: string;
  iconColor?: string;
  size?: string;
  isOccupancyDate?: boolean;
  labelContainer?: boolean;
  hasAsterisk?: boolean;
  setIsErrorForm?: React.Dispatch<React.SetStateAction<boolean>>;
  isHidden: boolean;
}

export const DatePicker: React.FC<DatePickerProps> = ({
  propertyType,
  propertyName,
  value = '',
  placeholder = '',
  onChange,
  typeOfIcon,
  iconColor = '#5140E9',
  size = '18rem',
  labelContainer = false,
  label = '',
  hasAsterisk = false,
  setIsErrorForm,
  isHidden,
}) => {
  const currentForm = useAppSelector((state) => state.CurrentForm);
  const [formattedValue, setFormattedValue] = useState(value || '');
  const [validationDateMessage, setValidationDateMessage] = useState('');
  const [invalidDate, setInvalidDate] = useState(false);
  const [maxLength, setMaxLength] = useState<number | undefined>(undefined);
  const isRequired = true;
  const formSchema = createValidationSchema(propertyName, isRequired);
  const isOccupancyDate = propertyName === 'propertyOccupancyDate';
  const dispatch = useAppDispatch();
  let loanApplication = useAppSelector((state) => state.LoanApplication);
  const isSoftPullDate = (propertyName: string): boolean => {
    return propertyName !== 'propertyOccupancyDate' && propertyName !== 'dateOfBirth';
  };
  const isSoftPull = isSoftPullDate(propertyName);

  useEffect(() => {
    const inputValue = value.replace(/[^0-9/]/g, '');
    const formatted = formatInput(inputValue, propertyName);
    setFormattedValue(formatted || '');
  }, [value]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (propertyName === 'propertyOccupancyDate') {
      setMaxLength(7);
    } else {
      setMaxLength(10);
    }
    const inputValue = event.target.value.replace(/[^0-9/]/g, '');
    const formatted = formatInput(inputValue, propertyName);
    setFormattedValue(formatted);
    if (onChange) onChange(inputValue, propertyType);

    if (propertyName === 'birthData') {
      updateData(formatted);
    }
  };

  const updateData = (value: string) => {
    const updatedBorrowers = loanApplication.borrowers.map((borrower, index) => (index === 0 ? { ...borrower, borrowerDOB: value } : borrower));
    const auxLoanApplication = {
      ...loanApplication,
      borrowers: updatedBorrowers,
    };
    dispatch({
      type: 'LoanApplication/updateValue',
      payload: auxLoanApplication,
    });
  };

  const onBlur = async () => {
    try {
      if (propertyName === 'propertyOccupancyDate') {
        await formSchema.validate({ [propertyName]: formattedValue });
        const minMonths = 6;
        const hasMinMonthsOfOwnership = monthsBetweenDateAndToday(formattedValue, propertyName) >= minMonths;
        if (formattedValue.length < 7 || formattedValue.length > 8) {
          setValidationDateMessage('Date must be in MM/YYYY format');
          setInvalidDate(true);
        } else if (!hasMinMonthsOfOwnership) {
          setValidationDateMessage(`This program requires at least ${minMonths} months of ownership`);
          setInvalidDate(true);
        } else {
          setInvalidDate(false);
        }
      } else {
        await formSchema.validate({ [propertyName]: formattedValue });
        const minMonths = 216;
        const hasMinMonthsOfOwnership = monthsBetweenDateAndToday(formattedValue, propertyName) >= minMonths;

        if (formattedValue.length < 10 || formattedValue.length > 11) {
          setValidationDateMessage('Date must be in MM/DD/YYYY format');
          setInvalidDate(true);
        } else if (!hasMinMonthsOfOwnership) {
          setValidationDateMessage(`You must be of legal age`);
          setInvalidDate(true);
        } else {
          setValidationDateMessage('');
          setInvalidDate(false);
        }
      }
    } catch {
      setValidationDateMessage('Date is required');
      setInvalidDate(true);
    }
  };
  useEffect(() => {
    if (setIsErrorForm) {
      setIsErrorForm(!invalidDate);
    }
  }, [invalidDate]);

  useEffect(() => {
    if (currentForm[propertyType]) {
      const formatted = formatInput(currentForm[propertyType], propertyName);
      setFormattedValue(formatted);
    }
  }, [propertyType, currentForm]);

  return (
    <DataPickerContainer $hasAsterisk={hasAsterisk} $isHidden={isHidden}>
      {labelContainer && <Label $hasAsterisk={hasAsterisk}>{label}</Label>}
      <InputContainer $isError={invalidDate} $isOccupancyDate={isOccupancyDate}>
        {typeOfIcon && (
          <IconContainer>
            <DateInputIcon $iconColor={iconColor} />
          </IconContainer>
        )}
        <InputDataPicker
          placeholder={placeholder}
          onFocus={() => setInvalidDate(false)}
          onBlur={onBlur}
          onChange={handleChange}
          value={formattedValue}
          $isSoftPull={isSoftPull}
          size={size}
          maxLength={maxLength}
        />
      </InputContainer>

      {invalidDate && <ErrorMessage>{validationDateMessage}</ErrorMessage>}
    </DataPickerContainer>
  );
};

export default DatePicker;
