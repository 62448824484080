import React from 'react';
import { Wrapper } from '../wrapper.styles';
import { useIsMobile } from '@/hooks/useIsMobile';
import SectionSummary from '@/components/SectionSummary/SectionSummary';
import { SummaryWrapper } from './InquirySummary.styles';
import { useAppSelector } from '@app/hooks';
import { NavigationButton, PageOrigin } from '@/components/Button/NavigationButton/NavigationButton';
import { useNavigate } from 'react-router-dom';
export interface InquirySummaryProps {
  onAccess: (page: string) => void;
}

const InquirySummary: React.FC<InquirySummaryProps> = ({ onAccess }) => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  let templateConfig = useAppSelector((state) => state.TemplateConfig);
  let loanApplication = useAppSelector((state) => state.LoanApplication);
  const hasCoBorrower = loanApplication?.borrowers.length > 1;
  const summary = templateConfig.pages?.summary ?? {};

  const hasDeclarations = !!templateConfig?.pages?.declarations;
  const pageOrigin = hasDeclarations ? PageOrigin.DECLARATIONS : PageOrigin.GOVERNMENT_MONITORING_INFORMATION;

  return (
    <SummaryWrapper data-testid="section-summary">
      <Wrapper $gap={isMobile ? '1rem' : '0.938rem'} $isMobile={isMobile}>
        <SectionSummary summary={summary.borrower} />
        {hasCoBorrower && <SectionSummary summary={summary.coBorrower} />}
      </Wrapper>
      <NavigationButton
        data-testid="navigation-button"
        variant="primary"
        nextButton={templateConfig?.pages?.summary?.NavigationButton?.nextButton || 'Next'}
        isBackButton={templateConfig?.pages?.summary?.NavigationButton?.backButton?.isHidden || false}
        backButton={templateConfig?.pages?.summary?.NavigationButton?.backButton?.text || 'Back'}
        disabled={false}
        isSummaryPage={true}
        navigation={navigate}
        onAccess={onAccess}
        type="submit"
        pageOrigin={pageOrigin}
        currentPage={PageOrigin.INQUIRY_SUMMARY}
      />
    </SummaryWrapper>
  );
};

export default InquirySummary;
