import styled, { css } from 'styled-components';

const fontStyle = css`
  text-align: center;
  font-family: Nunito;
  font-style: normal;
  line-height: normal;
`;

export const Container = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
  @media only screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
    width: 100%;
    margin: 0;
  }
`;

export const ValueContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  height: 3rem;
  align-items: center;
  margin-bottom: 1.3rem;
  h2 {
    ${fontStyle}
    font-size: 2.188rem;
    font-weight: 400;
    @media only screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
      font-size: 1.394rem;
    }
  }
`;

export const SliderContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 1rem;
`;

export const TextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TextContainer = styled.div`
  height: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
  span {
    ${fontStyle}
    font-size: 1.063rem;
    font-weight: 700;
    @media only screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
      font-size: 0.75rem;
    }
  }
  p {
    width: max-content;
    ${fontStyle}
    font-size: 1.063rem;
    font-weight: 300;
    @media only screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
      font-size: 0.678rem;
    }
  }
`;

export const BaseBox = styled.div`
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;

  border-radius: 0.188rem;
  transition: width 10s ease-in-out;
`;

export const Background = styled(BaseBox)`
  background: linear-gradient(90deg, #fc0000 0%, #ff9900 28.65%, #faff01 66.67%, rgba(0, 255, 71, 0.96) 100%);
  width: 100%;
  height: 1rem;
  border-radius: 1rem;
`;

export const SliderThumb = styled.div`
  width: 1.875rem;
  height: 1.875rem;
  background-color: ${({ theme }) => theme.colors.textDarkGrey};
  border: 0.125rem solid ${({ theme }) => theme.colors.textDarkGrey};
  border-radius: 50%;
  cursor: pointer;
  transition: left 0.2s ease;
`;

export const SliderTrack = styled.div`
  background-color: rgba(0, 0, 0, 0.1);
  height: 0.375rem;
  border-radius: 1rem;
  &.custom-track-completed {
    background: linear-gradient(90deg, #fc0000 0%, #ff9900 28.65%, #faff01 66.67%, rgba(0, 255, 71, 0.96) 100%);
  }
`;
