import React from 'react';
import { FormGroup } from '@/pages/wrapper.styles';

interface FormGroupComponentProps {
  children: React.ReactNode;
  onChange?: (value: string | number, name: string, index?: number) => void;
  gap?: string;
}

export const FormGroupComponent: React.FC<FormGroupComponentProps> = ({ children, onChange, gap }) => {
  const validChildren = React.Children.toArray(children).filter(React.isValidElement);

  const childrenWithProps = React.Children.map(validChildren, (child) => React.cloneElement(child as React.ReactElement, { onChange }));

  return <FormGroup $gap={gap}>{childrenWithProps}</FormGroup>;
};
