import { configureStore } from '@reduxjs/toolkit';
import initialReducer from '@/reducers/initialSlices';
import currentPageReducer from '@/reducers/currentPage';
import LoanApplicationReducer from '@/reducers/LoanApplication';
import CurrentFormReducer from '@/reducers/CurrentForm';
import TemplateConfigReducer from '@/reducers/TemplateConfig';
export const store = configureStore({
  reducer: {
    initial: initialReducer,
    currentPage: currentPageReducer,
    LoanApplication: LoanApplicationReducer,
    CurrentForm: CurrentFormReducer,
    TemplateConfig: TemplateConfigReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
