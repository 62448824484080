import styled from 'styled-components';

export const FooterWrapper = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 2.5rem 7.563rem 1.813rem 8rem;
  background: ${({ theme }) => theme.colors.bgLightViolet};
  p {
    color: ${({ theme }) => theme.colors.textSecondary};
    font-family: Poppins;
    font-size: 0.688rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.125rem;
    margin: 0;
  }
  @media only screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
    align-items: center;
    padding: 1.988rem 1.413rem 1.57rem 1.413rem;
    p {
      text-align: center;
      font-size: 0.419rem;
      line-height: 0.523rem;
    }
  }
`;
