import React, { Fragment, useState, useCallback } from 'react';
import { CardBody, CardHeader, CardSection, WrapperCard, Col, ContainerInfoIcon, InfoIcon } from './AmountCard.styles';
import { useIsMobile } from '@/hooks/useIsMobile';
import { getSeparator } from '@/utils/getSeparator';
import { HandleModal } from '@/utils/handleInfoIcon';
import { DefaultModal } from '@Modals/DefaultModal/DefaultModal';
import { useAppSelector } from '@/app/hooks';
import { formatNumber } from '@/utils/formatValues';
export interface InfoModal {
  body?: string;
  button?: string;
}

type InfoItem = {
  title: string;
  isHiddenHeloc: boolean;
  isHiddenHel: boolean;
  infoIcon: {
    body: string;
    button: string;
  };
};

export interface IAmountCardProps {
  title?: {
    isHidden: boolean;
    text: string;
  };
  cardSectionInfo?: [
    {
      lineAmount: InfoItem;
      loanAmount: InfoItem;
      apr: InfoItem;
      loanOriginationFee: InfoItem;
    },
  ];
}

const AmountCard: React.FC<IAmountCardProps> = ({ title = { isHidden: false, text: '' }, cardSectionInfo = [] }) => {
  const isMobile = useIsMobile();
  const [infoIcon, setInfoIcon] = useState<InfoModal>({});
  const [openModal, setOpenModal] = useState(false);
  const loanApplication = useAppSelector((state) => state.LoanApplication);
  const templateConfig = useAppSelector((state) => state.TemplateConfig);
  const isICE = templateConfig.isICE ?? false;
  const frontProgramType = loanApplication?.selectedProgramType === 'Line of Credit' ? 'helocData' : 'helData';
  const hiddenTypeOfProgram = isICE || frontProgramType === 'helData' ? 'isHiddenHel' : 'isHiddenHeloc';

  const handleModal = useCallback((label: string) => {
    const handle = HandleModal(label, templateConfig);
    const infoModal = {
      body: loanApplication.minLineAmount > 49000 ? handle.bodyHigh : handle.bodyLow,
      button: handle.button,
    };
    setInfoIcon(infoModal);
    setOpenModal(true);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const visibleItems = cardSectionInfo?.map((section) => Object.entries(section).filter(([, value]) => !value[hiddenTypeOfProgram]));
  return (
    <WrapperCard data-testid="amount-card-container">
      <CardHeader $isHidden={title?.isHidden ?? false}>
        <p>{title?.text}</p>
      </CardHeader>
      <CardBody>
        {cardSectionInfo?.map((section, index) => (
          <Fragment key={index}>
            {Object.entries(section).map(
              ([key, value], entryIndex) =>
                !value[hiddenTypeOfProgram] && (
                  <Fragment key={key}>
                    <CardSection>
                      <Col>
                        <ContainerInfoIcon>
                          <p>{value.title}</p>
                          <InfoIcon data-testid="info-icon" onClick={() => handleModal(value.title || '')} />
                        </ContainerInfoIcon>
                        <h5>
                          {key === 'loanOriginationFee'
                            ? formatNumber(loanApplication?.feeAmount)
                            : key === 'apr'
                              ? `${loanApplication?.apr}%`
                              : key === 'loanAmount' && isICE
                                ? formatNumber(loanApplication?.minLoanAmount)
                                : key === 'estimateMonthlyPmt'
                                  ? loanApplication?.estimatedMonthlyPayment && Math.round(loanApplication?.estimatedMonthlyPayment)
                                  : formatNumber(loanApplication?.initialLineAmount)}
                        </h5>
                      </Col>
                    </CardSection>
                    {getSeparator(entryIndex, visibleItems[0].length + 1, isMobile)}
                  </Fragment>
                ),
            )}
          </Fragment>
        ))}
      </CardBody>
      {openModal && <DefaultModal openModal={openModal} setOpenModal={setOpenModal} infoModal={infoIcon} />}
    </WrapperCard>
  );
};

export default AmountCard;
