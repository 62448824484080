export const listPageOrder = [
  "Let's Get Started",
  'Estimated Available Equity',
  'View My Offer',
  'Personal Information',
  'Employment Information',
  'Government Monitoring',
  'Declarations',
  'Inquiry Summary',
  'Completion',
];
