import { parse } from 'date-fns';

export const formatCurrency = (value: number): string => {
  return `$${value.toLocaleString()}`;
};

export const formatCodeInput = (str: string) => {
  return str.replace(/([a-zA-Z0-9]{3})([a-zA-Z0-9]{3})/, '$1-$2');
};

export const formatNumber = (value?: number) => {
  if (value == null || isNaN(value)) {
    return '$0';
  }
  const formattedValue = value.toLocaleString();
  return `$${formattedValue}`;
};

export const convertToNumber = (value: string) => {
  const cleanedString: string = value.replace(/[$,]/g, '');
  const numberValue: number = parseFloat(cleanedString);
  return Math.round(numberValue);
};

export const formatSSNInput = (input: string): string => {
  const SSN = /^(\w{0,3})(\w{0,2})(\w{0,4})$/;
  const match = input.match(SSN);
  if (!match || input.length > 11) return input;
  const [, num1, num2, num3] = match;
  let formattedDate = num1;
  if (num2) formattedDate += num2;
  if (num3) formattedDate = `${num1}-${num2}-${num3}`;
  return formattedDate;
};

export const convertToDate = (value: string): Date | null => {
  if (typeof value !== 'string') {
    return null;
  }

  const date = parse(value, 'MM/dd/yyyy', new Date());

  if (isNaN(date.getTime())) {
    return null;
  }

  return date;
};
