export const handleErrors = (error: any) => {
  const status = error?.status;
  const errorMessage = error?.response?.data?.response?.error?.appActionMessage || error?.response?.data?.response?.error;
  const defaultMessage =
    'Thank you for applying with Credit Union. Our Home Equity products are currently not available in your state. If you are interested in exploring other Credit Union products. Please return to see our other offerings.';
  switch (status) {
    case 400:
    case 401:
    case 403:
      console.error(error);
      return errorMessage
        ? errorMessage
        : 'Thank you for applying. Unfortunately, we were not able to pre-qualify you, but we will contact you to discuss your application within 1-2 business days.';
    case 404:
      console.error(error);
      return defaultMessage;
    case 500:
      console.error(error);
      return 'Internal server error';
    default:
      if (errorMessage) {
        return errorMessage;
      }
      return 'An unexpected error occurred.';
  }
};
