import axios from 'axios';
import { LoanApplicationState } from '@/reducers/LoanApplication.types';
import { handleErrors } from '../utils/handleErrors';
import { ActionProps } from './services.types';
import { handleResponseInformation } from './handleResponseInformation';
import { Dispatch } from 'redux';
import { stopLoading } from '@/reducers/currentPage';
import { endpointFront } from './ExperienceService';

export const makeGetCall = async (action: ActionProps, loanApplication: LoanApplicationState, dispatch: Dispatch) => {
  try {
    const endpoint = action?.actionProperties?.endpoint
      ?.replace('%%loanId%%', loanApplication.loanId)
      ?.replace('%%email%%', encodeURIComponent(loanApplication?.borrowers[0]?.borrowerEmailAddress || 'test@test.com'));

    if (!endpoint) {
      throw new Error('Endpoint is not defined');
    }

    const response = await axios.get(`${endpointFront.experienceApi}/api/v1${endpoint}`, {
      headers: {
        'api-key': endpointFront.apiKey || 'none',
        ...(endpointFront.mocked ? { 'mocked-origin': endpointFront.mocked } : {}),
      },
    });

    handleResponseInformation(response.data, loanApplication, dispatch);
    return response;
  } catch (error) {
    const detailsError = await handleErrors(error);
    throw detailsError;
  } finally {
    dispatch(stopLoading());
  }
};
