import styled from 'styled-components';

interface PersonalInfoWrapper {
  $isMobile?: boolean;
  $isHidden?: boolean;
}
export const PersonalInfoWrapper = styled.section<PersonalInfoWrapper>`
  display: flex;
  flex-direction: column;
  gap: ${({ $isMobile }) => ($isMobile ? '1rem' : '0rem')};
`;

export const ConfirmMessageContainer = styled.div<PersonalInfoWrapper>`
  display: ${({ $isHidden }) => ($isHidden ? 'none' : 'flex')};
  flex-direction: column;
  width: 100%;
  min-height: 2rem;
  margin-bottom: 0.3rem;
  border-radius: 0.5rem;
  padding: 1.563rem;
  background: ${({ theme }) => theme.colors.bgMediumGrey};
  h1 {
    font-family: Poppins;
    font-size: 0.987rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    height: auto;
    text-align: left;
    @media only screen and (max-width: ${(props) => props.theme.breakpoints.lg}) {
      font-weight: 500;
    }
    @media only screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
      justify-content: center;
      font-size: 0.75rem;
    }
  }
`;

export const ButtonWrapper = styled.section<PersonalInfoWrapper>`
  width: 100%;
  display: ${({ $isHidden }) => $isHidden && 'none'};
  nav {
    margin-bottom: 0rem;
    justify-content: start;
    height: max-content;
    @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
      padding: 0 0.5rem;
    }
    button {
      height: max-content;
      padding: 0.5rem 0;
      margin: 0;
      background: transparent;
      border: none;
      font-size: 0.938rem;
      font-weight: 400;
      font-family: 'Poppins';
      color: ${({ theme }) => theme.colors.textPrimaryLd};
      @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
        font-size: 0.75rem;
      }
    }
  }
`;
