import React, { useState, useCallback, useEffect } from 'react';
import { TextBlock } from '@/components/text/TextBlock';
import { useDispatch } from 'react-redux';
import { formatNumber } from '@/utils/formatValues';

import {
  PointIcon,
  DownIcon,
  ActiveIcon,
  WrapperContainer,
  FooterDescription,
  SelectorContainer,
  Row,
  ProgramName,
  DescriptionContainer,
  Verbiage,
  Col,
  Footer,
  ContainerPrograms,
  CheckedIcon,
  VerbiageContainer,
  ContainerIcon,
  DetailsContainer,
  Program,
  DivisorIcon,
  InfoIcon,
  ContainerInfoIcon,
  TitleContainer,
  InitialProgramName,
  FooterNote,
} from './AvailablePrograms.styles';
import { TypeOfText } from '@/components/text/TextBlock';
import { useAppSelector } from '@app/hooks';
import { DefaultModal } from '../Modals/DefaultModal/DefaultModal';
import { HandleModal } from '@/utils/handleInfoIcon';
import _ from 'lodash';
import { useIsMobile } from '@/hooks/useIsMobile';
import { convertToNumber } from '@/utils/formatValues';
import { handleProgramSelection } from '@/utils/handleVmo';
import { typeOfAmount } from '@/utils/typeOfAmount';
import { executeAction } from '@/utils/handleEndpoints';
interface AvailableProgramsProps {
  type?: string;
  name?: string;
  lineAmount?: string;
  estimatedApr?: string;
  minLineAmount?: string;
  maxLineAmount?: string;
  verbiage?: string;
  backgroundColor?: string;
  textColorProgramName?: string;
  textColorVerbiage?: string;
  showLineAmount?: boolean;
  showEstimatedApr?: boolean;
  showEstimatedMonthlyPmt?: boolean;
  isBackgroundCustomColor?: boolean;
  isVerbiageCustomColor?: boolean;
  isTextCustomColor?: boolean;
  isVerbiageCustomBackground?: boolean;
  backgroundColorVerbiage?: string;
  titleIsCustomColor?: boolean;
  titleCustomColor?: string;
  isHidden?: boolean;
}

interface Program {
  apr: number;
  programType: string;
  programName: string;
  maxAmountApproved: number;
  description: string;
  term: string;
  lineAmount: string;
  estimatedMonthlyPayment: string;
  estimatedApr: string;
  minLineAmount: string;
  maxLineAmount: string;
  customVerbiage: string;
  originationFee: string;
  minDrawAmount: string;
}

const AvailablePrograms: React.FC<AvailableProgramsProps> = ({
  backgroundColor = '#5140E9',
  textColorProgramName = '#ffffff',
  textColorVerbiage = '#ffffff',
  isVerbiageCustomColor = false,
  isBackgroundCustomColor = false,
  isTextCustomColor = false,
  isVerbiageCustomBackground = false,
  backgroundColorVerbiage = '#5140E9',
  titleIsCustomColor = false,
  titleCustomColor = '#5140E9',
  isHidden = false,
}) => {
  const dispatch = useDispatch();
  const loanApplication = useAppSelector((state) => state.LoanApplication);
  const [openModal, setOpenModal] = useState(false);
  const [selectedProgram, setSelectedProgram] = useState<Program>();
  const [openPrograms, setOpenPrograms] = useState<Set<string>>(new Set());
  const [infoIcon, setInfoIcon] = useState({});
  let templateConfig = useAppSelector((state) => state.TemplateConfig);
  let LoanApplication = useAppSelector((state) => state.LoanApplication);
  const [programName, setProgramName] = useState('');
  const iceProgramType = 'helRates';
  const isICE = templateConfig?.isICE ?? false;
  const frontProgramType = loanApplication?.selectedProgramType === 'Line of Credit' && loanApplication?.availablePrograms.helocData !== undefined ? 'helocData' : 'helData';
  const initialSelectedProgram = isICE ? iceProgramType : frontProgramType;
  const isHelProgram = isICE || frontProgramType === 'helData' ? 'isHiddenHel' : 'isHiddenHeloc';
  const isHiddenLineAmount = !templateConfig?.pages?.vmo?.lineAmount[isHelProgram];
  const isHiddenLoanAmount = !templateConfig?.pages?.vmo?.loanAmount[isHelProgram];
  const isHiddenEstimatedApr = !templateConfig?.pages?.vmo?.estimateMonthlyPmt[isHelProgram];
  const isHiddenEstimatedMonthlyPmt = !templateConfig?.pages?.vmo?.estimatedApr[isHelProgram];
  const isMobile = useIsMobile(943);
  let auxLoanApplication = _.cloneDeep(loanApplication);

  useEffect(() => {
    setOpenPrograms(new Set());
  }, [initialSelectedProgram]);

  const obtainEndpoint = async (payload) => {
    try {
      await executeAction(isICE, payload, dispatch, templateConfig, true);
    } catch (error) {
      console.error(error);
    }
  };

  const handleProgramTypeSelection = useCallback(
    (program: Program) => {
      setSelectedProgram((prevProgram) => (prevProgram === program ? undefined : program));
      auxLoanApplication.selectedProgramType = program.programType === 'HEL' ? 'Home Equity Loan' : 'Line of Credit';
      auxLoanApplication.auxActualMaxAmountApproved = Number(program.maxLineAmount);
      auxLoanApplication.actualMaxAmountApproved = 0;
      auxLoanApplication.maxAmountApproved = Number(program.maxLineAmount);
      const payload = handleProgramSelection(program, auxLoanApplication, isICE);
      dispatch({
        type: 'LoanApplication/updateValue',
        payload: { ...payload },
      });
      if (isICE) obtainEndpoint(payload);
    },
    [loanApplication, dispatch],
  );

  const handleModal = useCallback((label: string) => {
    const handle = HandleModal(label, templateConfig);
    const infoModal = {
      body: LoanApplication.minLineAmount > 49000 ? handle.bodyHigh : handle.bodyLow,
      button: handle.button,
    };
    setInfoIcon(infoModal);
    setOpenModal(true);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const toggleVerbiage = useCallback((programType: string) => {
    setOpenPrograms((prevOpenPrograms) => {
      const newOpenPrograms = new Set(prevOpenPrograms);
      if (newOpenPrograms.has(programType)) {
        newOpenPrograms.delete(programType);
      } else {
        newOpenPrograms.add(programType);
      }
      return newOpenPrograms;
    });
  }, []);
  const availablePrograms = isICE ? loanApplication?.availablePrograms?.[initialSelectedProgram] : loanApplication?.availablePrograms?.[initialSelectedProgram].rates;

  useEffect(() => {
    setProgramName(loanApplication?.selectedProgram?.programName || '');
  }, [selectedProgram?.programName]);

  return (
    <WrapperContainer>
      <TitleContainer $isHidden={isHidden} $titleIsCustomColor={titleIsCustomColor} $titleCustomColor={titleCustomColor}>
        <TextBlock
          text={templateConfig?.pages?.vmo?.ProgramSelectorTitle?.text || 'Select a program that works for you.'}
          type={TypeOfText.Subtitle}
          isHidden={templateConfig?.pages?.vmo?.ProgramSelectorTitle?.isHidden || false}
        />
      </TitleContainer>
      {availablePrograms.map((program: Program, index: React.Key | null | undefined) => (
        <ContainerPrograms key={index}>
          <SelectorContainer $backgroundColor={backgroundColor} $isBackgroundCustomColor={isBackgroundCustomColor}>
            <Program>
              <ContainerIcon onClick={() => handleProgramTypeSelection(program)}>{programName === program?.programName ? <CheckedIcon /> : <ActiveIcon />}</ContainerIcon>
              <ProgramName color={textColorProgramName} $isTextCustomColor={isTextCustomColor} $textColorProgramName={textColorProgramName}>
                <InitialProgramName $isHidden={templateConfig?.pages?.vmo?.typeOfProgram?.isHidden ?? false}> {program.programType}</InitialProgramName> {program?.programName}
              </ProgramName>
            </Program>
            <Verbiage color={textColorVerbiage} $isVerbiageCustomColor={isVerbiageCustomColor} $textColorVerbiage={textColorVerbiage}>
              {isMobile ? 'Learn more' : templateConfig?.pages?.vmo?.verbiage?.text || 'Learn more about this loan program'}{' '}
              <DownIcon onClick={() => toggleVerbiage(program?.programName)} $isOpen={openPrograms.has(program?.programName)} data-testid="down-icon" />
            </Verbiage>
          </SelectorContainer>
          <DetailsContainer>
            {!openPrograms.has(program?.programName) ? (
              <>
                <DescriptionContainer>
                  <TextBlock text={program?.programName} type={TypeOfText.Text} direction="center" isHidden={templateConfig?.pages?.vmo?.titleOfProgram?.isHidden ?? false} />
                  <Row>
                    {isHiddenLineAmount && (
                      <>
                        <Col>
                          <ContainerInfoIcon>
                            <p>{typeOfAmount('amountLabel', frontProgramType, isICE, program, templateConfig) || 'LINE AMOUNT'}</p>
                            <InfoIcon data-testid="info-icon" onClick={() => handleModal(templateConfig?.pages?.vmo?.lineAmount.title || '')} />
                          </ContainerInfoIcon>
                          <h5>{formatNumber(Number(typeOfAmount('amountValue', frontProgramType, isICE, program, templateConfig)))}</h5>
                        </Col>
                        <DivisorIcon />
                      </>
                    )}
                    {isHiddenLoanAmount && (
                      <>
                        <Col>
                          <ContainerInfoIcon>
                            <p>{typeOfAmount('amountLabel', frontProgramType, isICE, program, templateConfig) || 'LOAN AMOUNT'}</p>
                            <InfoIcon data-testid="info-icon" onClick={() => handleModal(templateConfig?.pages?.vmo?.loanAmount.title || '')} />
                          </ContainerInfoIcon>
                          <h5>{formatNumber(Number(typeOfAmount('amountValue', frontProgramType, isICE, program, templateConfig)))}</h5>
                        </Col>
                        <DivisorIcon />
                      </>
                    )}
                    {isHiddenEstimatedApr && (
                      <>
                        <Col>
                          <ContainerInfoIcon>
                            <p>{templateConfig?.pages?.vmo?.estimateMonthlyPmt?.title || 'EST MONTHLY PMT'}</p>
                            <InfoIcon data-testid="info-icon" onClick={() => handleModal(templateConfig?.pages?.vmo?.estimateMonthlyPmt?.title || '')} />
                          </ContainerInfoIcon>
                          <h5>{program?.estimatedMonthlyPayment ? `$${isICE ? program?.estimatedMonthlyPayment : convertToNumber(program?.estimatedMonthlyPayment)}` : 'N/A'}</h5>
                        </Col>
                        {isHiddenEstimatedMonthlyPmt && <DivisorIcon />}
                      </>
                    )}
                    {isHiddenEstimatedMonthlyPmt && (
                      <>
                        <Col>
                          <ContainerInfoIcon>
                            <p>{templateConfig?.pages?.vmo?.estimatedApr?.title || 'ESTIMATED APR'}</p>
                            <InfoIcon data-testid="info-icon" onClick={() => handleModal(templateConfig?.pages?.vmo?.estimatedApr?.title || '')} />
                          </ContainerInfoIcon>
                          <h5>{`${program?.apr}%`}</h5>
                        </Col>
                      </>
                    )}
                  </Row>
                </DescriptionContainer>
                <Footer>
                  <FooterDescription $isHidden={templateConfig?.pages?.vmo?.footerMaxAmount?.isHidden ?? false}>
                    {typeOfAmount('minAmountLabel', frontProgramType, isICE, program, templateConfig) || `MIN LINE AMOUNT`}{' '}
                    {formatNumber(typeOfAmount('minValue', frontProgramType, isICE, program, templateConfig))}
                  </FooterDescription>
                  <PointIcon />
                  <FooterDescription $isHidden={templateConfig?.pages?.vmo?.footerMaxAmount?.isHidden ?? false}>
                    {typeOfAmount('maxAmountLabel', frontProgramType, isICE, program, templateConfig) || `MAX LINE AMOUNT`}
                    {formatNumber(typeOfAmount('maxValue', frontProgramType, isICE, program, templateConfig))}
                  </FooterDescription>
                </Footer>
              </>
            ) : (
              <VerbiageContainer $isVerbiageCustomBackground={isVerbiageCustomBackground} $backgroundColorVerbiage={backgroundColorVerbiage}>
                <TextBlock text={program?.customVerbiage || 'This is a default verbiage'} type={TypeOfText.Subtitle} />
              </VerbiageContainer>
            )}
          </DetailsContainer>
        </ContainerPrograms>
      ))}
      <FooterNote>
        <TextBlock
          text={templateConfig?.pages?.vmo?.footer?.text || 'Select a program that works for you.'}
          type={TypeOfText.Subtitle}
          isHidden={templateConfig?.pages?.vmo?.footer?.isHidden || false}
        />
      </FooterNote>
      {openModal && <DefaultModal openModal={openModal} setOpenModal={setOpenModal} infoModal={infoIcon} />}
    </WrapperContainer>
  );
};

export default AvailablePrograms;
