import React, { useRef, useEffect } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { WrapperDoughnut } from './Chart.styles';
import { chartTotalText } from '@/utils/chartTotalText';
ChartJS.register(ArcElement, Tooltip, Legend);

interface ChartProps {
  loanDepot?: number;
  otherDebts?: number;
  potentialSavings?: number;
  loanDepotBg?: string;
  otherDebtsBg?: string;
  potentialSavingsBg?: string;
  estimatedPayment?: number;
}

export const Chart: React.FC<ChartProps> = ({
  loanDepot = 0,
  otherDebts = 0,
  potentialSavings = 0,
  estimatedPayment = 0,
  loanDepotBg = '#3A149E',
  otherDebtsBg = '#7A59D0',
  potentialSavingsBg = '#B3D8AD',
}) => {
  const chartRef = useRef<ChartJS<'doughnut'> | null>(null);
  const data = {
    labels: [],
    datasets: [
      {
        labels: 'Data',
        data: [loanDepot, otherDebts, potentialSavings],
        backgroundColor: [loanDepotBg, otherDebtsBg, potentialSavingsBg],
      },
    ],
  };
  useEffect(() => {
    const chartInstance = chartRef.current;

    return () => {
      if (chartInstance) {
        chartInstance.destroy();
      }
    };
  }, []);

  return (
    <WrapperDoughnut>
      <Doughnut ref={chartRef} data={data} options={{ cutout: '85%' }} plugins={[chartTotalText(estimatedPayment)]} />
    </WrapperDoughnut>
  );
};
