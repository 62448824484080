export const pageListOrder = [
  'get-started',
  'get-started-address',
  'estimated-available-equity',
  'vmo',
  'vmo-line-amount',
  'vmo-draw-amount',
  'personal-information',
  'employment-information',
  'government-monitoring-information',
  'declarations',
  'inquiry-summary',
  'completion',
];

export const pages = {
  isICE: false,
  hasDrawAmountPage: true,
  features: {
    fullStory: {
      isHidden: true,
      title: 'Service Consent',
      body: 'We use FullStory to monitor how consumers interact with our website in order to improve the digital experience. By clicking ‘Accept’, you consent to our use of FullStory in accordance with our privacy policy. For more information, go to <a target="_newtab" href="https://www.loandepot.com/privacypolicy">Privacy Policy | loanDepot</a>.',
      buttonAccept: 'Accept',
      buttonDecline: 'Decline',
    },
  },
  pages: [
    `Let's Get Started`,
    `Let's Get Started`,
    'Estimated Equity',
    'View My Offer! (Available Programs)',
    'View My Offer!',
    'View My Offer!',
    'Personal Information',
    'Employment Details',
    'Government Info',
    'Declarations',
    'Summary',
    'Completion',
  ],
  socialLinks: [
    {
      title: 'facebook',
      url: 'https://www.instagram.com/loandepot/',
    },
    {
      title: 'twitter',
      url: 'https://twitter.com/loanDepot',
    },
    {
      title: 'linkedin',
      url: 'https://www.linkedin.com/company/loandepot/',
    },
  ],
  slider: {
    score: {
      Poor: '300-619',
      Fair: '620-679',
      Good: '680-719',
      Excellent: '720+',
    },
  },
  navBar: {
    connectSupport: 'connect to support',
    phoneHelp: 'We are here to help: (123) 456-7890',
    loanNumber: 'Loan Number:',
  },
  LetsGetStarted: {
    pageContainer: {
      title: {
        title: 'We’ll help you reach your goal!',
        isHidden: false,
      },
      subtitle: {
        title: 'From applying to closing, we’ll help you every step of the way.',
        isHidden: false,
      },
    },
    consumerInformation: {
      firstName: 'First Name',
      middleName: 'Middle Initial',
      lastName: 'Last Name',
      suffix: {
        title: 'Suffix',
        isHidden: true,
        options: [
          {
            label: 'I',
            beValue: 'I',
          },
          {
            label: 'II',
            beValue: 'II',
          },
          {
            label: 'III',
            beValue: 'III',
          },
          {
            label: 'IV',
            beValue: 'IV',
          },
          {
            label: 'JR',
            beValue: 'JR',
          },
          {
            label: 'SR',
            beValue: 'SR',
          },
          {
            label: 'V',
            beValue: 'V',
          },
          {
            label: 'VI',
            beValue: 'VI',
          },
          {
            label: 'VII',
            beValue: 'VII',
          },
          {
            label: 'VIII',
            beValue: 'VIII',
          },
        ],
      },
      email: 'Email Address',
      phone: {
        titleInput: '(000) 000-00000',
        titleDropDown: 'Select',
        isHidden: true,
        options: [
          {
            label: 'Mobil Phone',
            beValue: 'Mobil Phone',
          },
          {
            label: 'Work Phone',
            beValue: 'Work Phone',
          },
          {
            label: 'Home Phone',
            beValue: 'Home Phone',
          },
        ],
      },
      purposeOfLoan: {
        text: 'Purpose of loan',
        options: [
          {
            label: 'Debt Consolidation',
            beValue: 'Debt Consolidation',
          },
          {
            label: 'Home Improvement',
            beValue: 'Home Improvement',
          },
          {
            label: 'Personal',
            beValue: 'Personal',
          },
          {
            label: 'Other',
            beValue: 'Other',
          },
        ],
      },
      addressSearch: 'Search for property address',
      suit: 'Unit/Suite',
      unitType: {
        text: 'Unit Type',
        options: [
          {
            label: 'Apartment',
            beValue: 'Apartment',
          },
          {
            label: 'Basement',
            beValue: 'Basement',
          },
          {
            label: 'Suite',
            beValue: 'Suite',
          },
          {
            label: 'Unit',
            beValue: 'Unit',
          },
        ],
        isHidden: true,
      },
      city: 'City',
      state: {
        text: 'State',
        options: [
          { label: 'AL', beValue: 'AL' },
          { label: 'AK', beValue: 'AK' },
          { label: 'AZ', beValue: 'AZ' },
          { label: 'AR', beValue: 'AR' },
          { label: 'CA', beValue: 'CA' },
          { label: 'CO', beValue: 'CO' },
          { label: 'CT', beValue: 'CT' },
          { label: 'DE', beValue: 'DE' },
          { label: 'FL', beValue: 'FL' },
          { label: 'GA', beValue: 'GA' },
          { label: 'HI', beValue: 'HI' },
          { label: 'ID', beValue: 'ID' },
          { label: 'IL', beValue: 'IL' },
          { label: 'IN', beValue: 'IN' },
          { label: 'IA', beValue: 'IA' },
          { label: 'KS', beValue: 'KS' },
          { label: 'KY', beValue: 'KY' },
          { label: 'LA', beValue: 'LA' },
          { label: 'ME', beValue: 'ME' },
          { label: 'MD', beValue: 'MD' },
          { label: 'MA', beValue: 'MA' },
          { label: 'MI', beValue: 'MI' },
          { label: 'MN', beValue: 'MN' },
          { label: 'MS', beValue: 'MS' },
          { label: 'MO', beValue: 'MO' },
          { label: 'MT', beValue: 'MT' },
          { label: 'NE', beValue: 'NE' },
          { label: 'NV', beValue: 'NV' },
          { label: 'NH', beValue: 'NH' },
          { label: 'NJ', beValue: 'NJ' },
          { label: 'NM', beValue: 'NM' },
          { label: 'NY', beValue: 'NY' },
          { label: 'NC', beValue: 'NC' },
          { label: 'ND', beValue: 'ND' },
          { label: 'OH', beValue: 'OH' },
          { label: 'OK', beValue: 'OK' },
          { label: 'OR', beValue: 'OR' },
          { label: 'PA', beValue: 'PA' },
          { label: 'RI', beValue: 'RI' },
          { label: 'SC', beValue: 'SC' },
          { label: 'SD', beValue: 'SD' },
          { label: 'TN', beValue: 'TN' },
          { label: 'TX', beValue: 'TX' },
          { label: 'UT', beValue: 'UT' },
          { label: 'VT', beValue: 'VT' },
          { label: 'VA', beValue: 'VA' },
          { label: 'WA', beValue: 'WA' },
          { label: 'WV', beValue: 'WV' },
          { label: 'WI', beValue: 'WI' },
          { label: 'WY', beValue: 'WY' },
          { label: 'DC', beValue: 'DC' },
          { label: 'AS', beValue: 'AS' },
          { label: 'GU', beValue: 'GU' },
          { label: 'MP', beValue: 'MP' },
          { label: 'PR', beValue: 'PR' },
          { label: 'VI', beValue: 'VI' },
        ],
      },
      zip: 'Zip Code',
      radioButton: {
        propertyType: {
          primaryResidence: {
            text: 'Primary Residence',
            beValue: 'Primary Residence',
            isHidden: false,
            width: '10%',
          },
          secondaryResidence: {
            text: 'Secondary Residence',
            beValue: 'Secondary Residence',
            isHidden: false,
            width: '10%',
          },
          investment: {
            text: 'Investment',
            beValue: 'Investment',
            isHidden: true,
            width: '10%',
          },
        },
        propertyUse: {
          singleFamily: {
            text: 'Single-Family',
            beValue: 'Single Family',
            isHidden: false,
            width: '10%',
          },
          townHouse: {
            text: 'Townhouse',
            beValue: 'Townhouse',
            isHidden: false,
            width: '10%',
          },
          condo: {
            text: 'Condo',
            beValue: 'Condo',
            isHidden: false,
            width: '10%',
          },
          multiFamily: {
            text: 'Multi-Family',
            beValue: 'Multi Family',
            isHidden: false,
            width: '10%',
          },
        },
      },
      purchaseDate: 'MM/YYYY',
      NavigationButton: {
        nextButton: 'Next',
        backButton: {
          isHidden: true,
          text: 'Back',
        },
      },
    },
    protectInformation: '<a href="#">Learn how we protect your information.</a>',
    propertyMailingAddress: {
      checkText: 'This property is also my mailing address.',
      information: 'Please provide your mailing address',
    },
    typeOfInformation: {
      consumerInformation: 'Consumer Information',
      PropertyInformation: 'Property Information',
      consent: 'Consent',
    },
    question: {
      basicInformation: 'Let’s get some basic information to get started.',
      addressProperty: 'What is the address of your property?',
      useOfProperty: 'What’s the use of this property?',
      propertyType: 'What’s your property type?',
      purchaseDate: 'What was the purchase date of this property?',
    },
    softPull: 'I agree to allow the Lender to conduct a softpull on my credit.',
    eConsent: 'I agree to provide eConsent allowing the Lender to contact me electronically about my loan inquiry.',
    modals: {
      resumeInquiry: {
        title: 'Existing Loan Inquiry Found',
        subtitle: `We found an existing loan inquiry associated with this email. To continue, enter the 6-digit code sent to your email and click 'Resume My Inquiry.`,
        expiredCode: 'This code expires after one-time use.',
        incorrectCode: 'Incorrect code. Please check and try again.',
        body: `To start a new inquiry, click 'Restart My Loan Inquiry' below. (Creating a new inquiry will archive the existing one.`,
        resumeButton: 'Resume My Inquiry',
        restartButton: 'Restart My Inquiry',
      },
      infoSoftPull: {
        header: 'Soft Credit Pull',
        body: 'By authorizing FirstClose to obtain your credit via soft inquiry, in compliance with the Fair Credit Reporting Act, you are allowing FirstClose to obtain such information solely for a pre-qualification for credit. This soft credit inquiry will NOT affect your credit score.',
        button: 'Close',
      },
      protectInformation: {
        header: 'How we protect your data...',
        body: 'We only request and share the information that is required to provide you with your Home Equity Report and a loan pre-qualification determination. All information is encrypted and secure. We not only follow applicable laws, but also industry best practices that go above and beyond whats required to ensure we are at the forefront of the industry in protecting our customers. We adhere to the following privacy principles: We collect and use customers information only as necessary to conduct business. We take steps to prevent identity theft and guard against unauthorized transactions, including engaging qualified industry leading third-party service providers to assist with security services.We only do business with third-parties when we have confirmed such third-party has the appropriate policies, procedures, and systems in place to meet our rigorous security and privacy standards.',
        button: 'Close',
      },
      loadingModal: {
        textOne: 'Your request is being processed.',
        textTwo: 'Please avoid refreshing or closing your browser. ',
      },
      infoSubmitModal: {
        header: 'Soft Credit Pull',
        body: 'By authorizing FirstClose to obtain your credit via soft inquiry, in compliance with the Fair Credit Reporting Act, you are allowing FirstClose to obtain such information solely for a pre-qualification for credit. This soft credit inquiry will NOT affect your credit score. ',
        label: 'Please provide your date birth',
        button: 'Submit',
      },
      exampleModal: {
        header: 'Custom Title',
        body: 'APR 411. In legere consequuntur est, affert tacimates vim id. Mea ei fierent euripidis, id mei tale voluptaria definitiones. Ad case iusto saperet vel, eu assum convenire ius. Cu vero graeco interesset vis, nonumy electram eu sed. Velit pertinax incorrupte ad eam. Prodesset persecuti cotidieque eos ut, ex nec feugiat volutpat percipitur.',
        button: 'Close',
      },
      infoEconsent: {
        type: 'confirm',
        header: 'eConsent Document',
        acceptButton: 'Read Econsent',
        button: 'Close',
        body: 'This document contains information about electronic communications. To read the full eConsent document please click the button below.',
        link: 'https://docs.google.com/gview?embedded=true&url=https://start.loandepot.com/HELOC/forms/loandepot_heloc_econsent.pdf',
      },
      creditScoreNeeded: {
        isHidden: false,
        title: 'Credit Score Needed',
        body: 'We were unable to obtain credit with the information provided. <b>Please select the best estimate of your credit score below to continue.',
        button: 'Submit',
      },
    },
  },
  LetsGetStartedAddress: {
    placeHolder: {
      button: 'Check My Equity',
    },
    PropertyCard: {
      title: 'My Property',
      address: 'Address',
      suit: 'Unit/Suite',
      city: 'City',
      state: 'State',
      zip: 'Zip',
      country: 'Country',
    },
    modals: {
      editAddress: {
        header: 'Oops! Not the right property?',
        subtitle: 'Provide your correct address below',
        button: 'Update Address',
      },
    },
    NavigationButton: {
      nextButton: 'Next',
      backButton: {
        isHidden: false,
        text: 'Back',
      },
    },
  },
  EstimatedAvailableEquity: {
    placeHolder: {
      button: 'View My Offer',
    },
    EquityCalculator: {
      estimatedMarket: {
        title: 'Estimated Market Value',
        text: 'The Estimated Market Value is based on and automated valuation model (AVM) used by many lenders and real estate platforms to initiate home equity valuations.',
      },
      configureMessage: {
        text: 'We were unable to retrieve the estimated market value and remaining mortgage balance for this property. You can manually enter the estimated market value and remaining mortgage balance, then click "confirm" to continue with your loan inquiry.',
        button: 'Confirm',
      },
      remainingMortgage: {
        title: 'Remaining Mortgage Balance',
        text: 'The Remaining Mortgage Balance is an estimate of what you owe based on public information. You can adjust the amount remaining here, if the publicly available information is incorrect.',
      },
      estimatedHome: {
        title: 'Estimated Home Equity',
        text: 'Your Estimated Home Equity is the total amount of equity in your home.',
      },
      availableEquity: {
        title: 'Available Equity To Borrow',
        text: 'Your available equity to borrow is an estimate based on program loan-to-value (LTV) criteria; keep in mind you may be eligible for more or less depending on program LTV limits.',
      },
    },
    NavigationButton: {
      nextButton: 'Next',
      backButton: {
        isHidden: false,
        text: 'Back',
      },
    },
  },
  vmo: {
    programs: [
      {
        id: 1,
        title: 'Line of Credit',
        description:
          'HELOC is an open-end line of credit secured by your home that provides some flexibility much like a credit card.(1) Need cash for a remodel? Have high interest debt? Tap into your home’s value, use the funds as you need them during the 36 month draw period and enjoy the ease of interest only payments for the first 10 years of your 30 year term. Your home, your equity, your way.',
      },
    ],
    titleOneProgram: 'Congratulations, you’re prequalified for a credit line up to %%maxLineAmount%%!',
    titleMultipleProgram: 'Congratulations, you&apos;re prequalified for a credit line up to!',
    title: 'Congratulations, you’re prequalified for a credit line up to %%maxLineAmount%%!',
    subtitle: 'Compare your options by clicking between Line of Credit and Home Equity Loan and select the path that best suits your needs',
    ProgramSelectorTitle: 'Select a program that works for you.',
    verbiage: 'Learn more about this loan program',
    placeHolder: {
      button: 'Next',
    },
    lineAmount: {
      title: 'LINE AMOUNT',
      isHidden: false,
      infoIcon: {
        bodyLow: 'This is the Line Amount Info Low Amount',
        bodyHigh: 'This is the Line Amount Info High Amount',
        button: 'Close',
      },
    },
    loanAmount: {
      title: 'LOAN AMOUNT',
      isHidden: false,
      infoIcon: {
        bodyLow: 'This is the Loan Amount Info Low Amount',
        bodyHigh: 'This is the Loan Amount Info High Amount',
        button: 'Close',
      },
    },
    estimatedApr: {
      title: 'ESTIMATED APR',
      isHidden: false,
      infoIcon: {
        bodyLow: 'This is the Estimated APR Info Low Amount',
        bodyHigh: 'This is the Estimated APR Info High Amount',
        button: 'Close',
      },
    },
    estimateMonthlyPmt: {
      title: 'EST MONTHLY PMT',
      isHidden: false,
      infoIcon: {
        bodyLow: 'This is the Estimated Monthly PMT Info Low Amount',
        bodyHigh: 'This is the Estimated Monthly PMT Info High Amount',
        button: 'Close',
      },
    },
    originationFee: {
      title: 'ORIGINATION FEE',
      isHidden: false,
      infoIcon: {
        bodyLow: 'This is the Origination Fee Info Low Amount',
        bodyHigh: 'This is the Origination Feet Info High Amount',
        button: 'Close',
      },
    },
    initialDraw: {
      title: 'INITIAL DRAW',
      isHidden: false,
      infoIcon: {
        bodyLow: 'This is the Initial Draw Amount Info Low Amount',
        bodyHigh: 'This is the Initial Draw Amount Info High Amount',
        button: 'Close',
      },
    },
    minimumDraw: {
      title: 'MINIMUM DRAW',
      isHidden: false,
      infoIcon: {
        bodyLow: 'This is the Minimum Draw Amount Info Low Amount',
        bodyHigh: 'This is the Minimum Draw Amount Info High Amount',
        button: 'Close',
      },
    },
    maximumDraw: {
      title: 'MAXIMUM DRAW',
      isHidden: false,
      infoIcon: {
        bodyLow: 'This is the Maximum Draw Amount Info Low Amount',
        bodyHigh: 'This is the Maximum Draw Amount Info High Amount',
        button: 'Close',
      },
    },
    minLineAmount: 'MIN LINE AMOUNT',
    maxLineAmount: 'MAX LINE AMOUNT',
    minLoanAmount: 'MIN LOAN AMOUNT',
    maxLoanAmount: 'MAX LOAN AMOUNT',
    terms: {
      title: 'Congratulations, you’re prequalified for a credit line up to %%maxLineAmount%%!',
      cardAmount: {
        title: 'Your Selected Line Amount and Terms',
        infoSection: [
          {
            lineAmount: {
              title: 'LINE AMOUNT',
              isHidden: false,
              infoIcon: { body: 'This is the Line Amount Info', button: 'Close' },
            },
            loanAmount: {
              title: 'LOAN AMOUNT',
              isHidden: false,
              infoIcon: { body: 'This is the Loan Amount Info', button: 'Close' },
            },
            apr: {
              title: 'ESTIMATED APR',
              isHidden: false,
              infoIcon: { body: 'This is the Estimated APR Info', button: 'Close' },
            },
            loanOriginationFee: {
              title: 'ORIGINATION FEE',
              isHidden: true,
              infoIcon: { body: 'This is the Origination Fee Info', button: 'Close' },
            },
          },
        ],
      },
    },
    vmoCalculate: {
      title: 'How much of your line amount would you like to draw at closing?',
      vmoLineAmount: {
        titleContainer: {
          text: 'Calculate Your Line Amount Terms',
          isHidden: false,
        },
        title: {
          text: 'Enter your desired <b>Line amount</b> and click the reprice button to see estimated terms.',
          isHidden: false,
        },
        subtitle: {
          text: 'The line amount requested is subject to credit approval and may be reduced',
          isHidden: false,
        },
        amountTerms: [
          {
            lineAmount: {
              title: 'LINE AMOUNT',
              isHidden: false,
            },
            apr: {
              title: 'EST MONTHLY PMT',
              isHidden: false,
            },
            originationFee: {
              title: 'ESTIMATED APR',
              isHidden: false,
            },
          },
        ],
        rangeAmount: {
          text: 'Enter an amount between %%minLineAmount%% and %%maxLineAmount%%',
          isHidden: false,
        },
        footer: {
          text: 'The variable minimum monthly payment during the draw period will be sufficient to pay the sum of all finance charges and other charges accrued each month; and during the repayment phase, an amount sufficient to repay the outstanding principle balance, interest and fees over the remaining term at the applicable APR. Minimum payment is $100.00',
          isHidden: false,
        },
        pageOrigin: 'VMO_LINE_AMOUNT',
        pageDestination: 'VMO_DRAW_AMOUNT',
      },
      vmoLoanAmount: {
        titleContainer: {
          text: 'Calculate Your Loan Amount Terms',
          isHidden: false,
        },
        title: {
          text: 'Enter your desired <b>Loan amount</b> and click the reprice button to see estimated terms.',
          isHidden: false,
        },
        subtitle: {
          text: 'The line amount requested is subject to credit approval and may be reduced',
          isHidden: false,
        },
        amountTerms: [
          {
            lineAmount: {
              title: 'LOAN AMOUNT',
              value: '$60,000',
              isHidden: false,
            },
            apr: {
              title: 'EST MONTHLY PMT',
              value: '$600',
              isHidden: false,
            },
            originationFee: {
              title: 'ESTIMATED APR',
              value: '9.25%',
              isHidden: false,
            },
          },
        ],
        rangeAmount: {
          text: 'Enter an amount between $%%minLineAmount%% and $%%maxLineAmount%%',
          isHidden: false,
        },
        footer: {
          text: 'The variable minimum monthly payment during the draw period will be sufficient to pay the sum of all finance charges and other charges accrued each month; and during the repayment phase, an amount sufficient to repay the outstanding principle balance, interest and fees over the remaining term at the applicable APR. Minimum payment is $100.00',
          isHidden: false,
        },
      },
      vmoDrawAmount: {
        titleContainer: 'Initial Draw Amount at Closing',
        title: 'Enter your desired <b>Initial Draw amount</b> (funds disbursed to you immediately at closing.)',
        subtitle: '',
        amountTerms: [
          {
            lineAmount: {
              title: 'INITIAL DRAW',
              isHidden: false,
            },
            minimumDraw: {
              title: 'MINIMUM DRAW',
              isHidden: false,
            },
            originationFee: {
              title: 'MAXIMUM DRAW',
              isHidden: false,
            },
          },
        ],
        rangeAmount: 'Enter an amount between %%minLineAmount%% and %%maxLineAmount%%',
        footer:
          'The variable minimum monthly payment during the draw period will be sufficient to pay the sum of all finance charges and other charges accrued each month; and during the repayment phase, an amount sufficient to repay the outstanding principle balance, interest and fees over the remaining term at the applicable APR. Minimum payment is $100.00',
        pageOrigin: 'VMO_DRAW_AMOUNT',
        pageDestination: 'PERSONAL_INFORMATION',
      },
      calculateAmount: {
        title: 'Enter your Line and Draw Amount',
        sectionLineAmount: { title: 'Line Amount' },
        sectionDrawAmount: { title: 'Draw Amount' },
      },
      button: 'Reprice',
    },
    vmoV2: {
      modal: {
        infoDebConsolidation: {
          header: 'You can consolidate high interest loans with a First Close HELOC!',
          body: 'Would you like to explore Debt Consolidation?',
          button: 'No',
          acceptButton: 'Yes, Let´s do it!',
        },
        tableAmountReached: {
          header: 'Total Line Amount Reached',
          body: 'Your included accounts exceed your stated line amount. Please increase your line amount to include more accounts.',
          button: 'Close',
        },
      },
      active: true,
      titleProgramSection: 'Enter your desired line amount to compare and choose between your offers.',
      subtitleProgramSection: 'Pick the HELOC that’s right for you.',
      calculateAmount: {
        title: 'Enter your Line and Draw Amount',
        sectionLineAmount: {
          titleLoan: 'Loan Amount',
          title: 'Line Amount',
          infoIcon: {
            bodyLow: 'This is the Line Amount Info Low Amount',
            bodyHigh: 'This is the Line Amount Info High Amount',
            button: 'Close',
          },
        },
        sectionDrawAmount: {
          title: 'Draw Amount',
          infoIcon: {
            bodyLow: 'This is the Draw Amount Info Low Amount',
            bodyHigh: 'This is the Draw Amount Info High Amount',
            button: 'Close',
          },
        },
        button: 'Reprice',
      },
      programCard: {
        estimatedAPR: {
          labelEstimatedAPR: 'Estimated',
          subLabelEstimatedApr: 'APR',
          isInfoIconEstimatedApr: true,
          infoIcon: {
            bodyLow: 'This is the Estimated APR Info Low Amount',
            bodyHigh: 'This is the Estimated APR Info High Amount',
            button: 'Close',
          },
        },
        interestOnlyPayment: {
          labelEstimatedMonth: 'Estimated',
          subBLabelEstimatedMonth: 'Interest Only Payment',
          isInfoIconEstimatedMonth: true,
          infoIcon: {
            bodyLow: 'This is the Interest Only Payment Info Low Amount',
            bodyHigh: 'This is the Interest Only Payment Info High Amount',
            button: 'Close',
          },
        },
        originationFee: {
          labelOriginalFee: '',
          subLabelOriginalFee: 'Origination Fee',
          isInfoIconOriginalFee: true,
          infoIcon: {
            bodyLow: 'This is the Origination Fee Info Low Amount',
            bodyHigh: 'This is the Origination Fee Info High Amount',
            button: 'Close',
          },
        },
        interestOnlyPeriod: {
          labelInterest: '',
          subLabelInterest: 'Interest Only Period',
          isInfoIconInterest: false,
          infoIcon: {
            bodyLow: 'This is the Interest Only Period Info Low Amount',
            bodyHigh: 'This is the Interest Only Period Info High Amount',
            button: 'Close',
          },
        },
        APR: {
          title: 'APR ',
          textOneAPR: 'Estimated ',
          APRHidden: false,
          infoIcon: {
            bodyLow: 'This is the Estimated APR Info Low Amount',
            bodyHigh: 'This is the Estimated APR Info High Amount',
            button: 'Close',
          },
        },
        drawPeriod: {
          title: 'Draw Period',
          textOneDrawPeriod: 'Year',
        },
        Repayment: {
          title: 'Repayment',
          textOneRepayment: 'Year',
        },
      },
      debtConsolidation: {
        title: 'Discover the Triple Advantage',
        subtitle: 'Debt Consolidation, Lower Monthly Payment, and Cash in Hand with a loanDepot HELOC',
        tableQuestions:
          'Questions? Call (866)790-3940 to be connected to a Digital Loan Consultant between 5am-6pm PT. After hours? No problem, call and leave a message. A return call will be made by 9AM PT the following business day.',
        wrapperAmount: {
          lineAmount: {
            text: 'Line Amount Used',
          },
          totalUsed: {
            text: 'Total used',
          },
          cashBack: {
            text: 'Cash back to you',
          },
          totalDraw: {
            text: 'Total draw amount',
          },
        },
        potentialMonthly: {
          title: 'Potential Monthly Payment Savings',
          superScript: '(4)',
          yourLoan: {
            title: 'Your loanDepot HELOC',
            subtitle: 'Estimated I/O payment',
          },
          otherDebts: {
            title: 'Other Debts',
            subtitle: 'Monthly payment',
          },
          potentialMonthly: {
            title: 'Potential Savings',
            subtitle: 'Potential monthly savings',
          },
        },
      },
      tableProgram: {
        head: {
          include: 'Include',
          account: 'Account',
          balance: 'Balance',
          monthlyPayment: 'Monthly Payment',
          remove: 'Not Yours or Old? <br>Remove From Calculation',
        },
      },
      creditReported: {
        title: 'Credit Information Reported',
        verbiage:
          'Based on information reported on your credit report. Your actual account balance and minimum monthly payment may differ. Note that you may be required to payoff accounts directly with the creditor. Any existing subordinate lien will need to be paid off as part of your HELOC transaction.',
      },
      potentialEstimate: {
        title: 'Potential Savings Estimate',
        verbiage:
          'Potential savings are estimates only and are based on your initial estimated monthly interest only period payment and the information reported on your credit report. Monthly savings are calculated by subtracting the minimum payment amount for debts you select to pay off from your estimated initial HELOC payment.​ Monthly savings do not account for the interest rate on your existing debts, which may be higher or lower than the interest rate that will apply to your loanDepot HELOC. Additionally, loanDepot’s HELOC has a variable rate. Because your interest rate may change and your account balance may increase or decrease as you take additional draws or repay the balance, the monthly minimum payment due will also change. Further, your payment period payment will include principal, in addition to interest, which may cause your minimum payment to increase. Except for your final payment, your minimum monthly payment will always be at least $100.',
      },
      footer: [
        'Available funds are accessible via ACH/Wire only.',
        'The amount of the initial draw deposited to your account will be reduced by the amount of the origination fee.',
        'The monthly payment is an estimate of your initial variable rate interest only monthly payment and is based on the line amount shown. The estimated payments assume that the applicable interest rate and account balances as of the draw period and repayment period are the same as the line amount listed above. The estimated payment amount adjusts as you change the line amount and hit the reprice button.',
        'Potential monthly savings are estimates and calculated by subtracting the minimum payment amounts for the debts you select to pay off from your estimated initial interest only HELOC payment.',
        'Rates and terms may vary based on requested line amount, credit history and available equity.',
        '<b>Your HELOC is a variable rate line of credit.</b> Because your interest rate and account balance may change over the life of your HELOC, the minimum payment due may also change. During the repayment period, your monthly payment will include principal, in addition to interest and other charges. Estimated payments are based on a 31-day billing cycle. Billing cycles may vary and can impact the actual payment due.',
        'For additional information about how your minimum payments will be calculated and for other important details, please see your Important Terms of Our Home Equity Line of Credit document provided with your initial disclosures.',
      ],
      NavigationButton: {
        nextButton: 'Next',
        backButton: {
          isHidden: false,
          text: 'Back',
        },
      },
    },
    NavigationButton: {
      nextButton: 'Next',
      backButton: {
        isHidden: false,
        text: 'Back',
      },
    },
  },
  personalInformation: {
    placeHolder: {
      button: 'Next',
    },
    messages: {
      ssnMessages: {
        title: 'Why do we ask for your SSN?',
        body: 'loanDepot will obtain a consumer credit report at the time of inquiry and may also do so for updates, renewals, extensions, and collection activity on an approved account. loanDepot will inform you if we obtained a report, and if so, the name and address of the consumer reporting agency that provided it. You agree that loanDepot can obtain any credit, employment and income information about you, can keep this inquiry and supporting information, and if the inquiry is approved, you will abide by the agreement for this account.',
        isHidden: false,
      },
      coBorrowerMessages: {
        title: 'Adding a co-applicant to this loan?',
        body: 'This loan program requires co-applicants to submit the loan inquiry as Joint credit applicants. Joint credit makes it possible to open an account with another borrower where repayment and responsibility are shared. In the case of joint credit, two or more people apply together for approval and become account owners that are equally responsible for the balance.',
        isHidden: false,
      },
    },
    checkConsent: 'By clicking here I agree to the Lenders <a href="#">TCPA disclosure </a>allowing the lending team to contact the me via phone.',
    formInputs: {
      selectStatus: {
        placeHolder: 'Select Status',
        label: 'Marital Status',
        options: [
          {
            label: 'married',
            beValue: 'Married',
          },
          {
            label: 'separated',
            beValue: 'separated',
          },
          {
            label: 'unmarried',
            beValue: 'unmarried',
          },
        ],
      },
      dateBirth: {
        placeHolder: 'MM/DD/YYYY',
        label: 'Date of Birth',
      },
      SSN: {
        label: 'Social Security Number (SSN)',
        placeHolder: '### - ## - ####',
      },
      homePhone: {
        label: 'Home Phone',
        placeHolder: '(###) ### - ####',
      },
      workPhone: {
        label: 'Work Phone',
        placeHolder: '(###) ### - ####',
      },
      mobilePhone: {
        label: 'Mobile Phone',
        placeHolder: '(###) ### - ####',
      },
      emailAddress: {
        label: 'Email Address',
        placeHolder: 'you@email.com',
        isHidden: true,
      },
      contactMethod: {
        label: 'Preferred Contact Method',
        placeHolder: 'Select Preference',
        options: [
          {
            label: 'email',
            beValue: 'Email',
          },
          {
            label: 'home',
            beValue: 'home',
          },
          {
            label: 'work',
            beValue: 'work',
          },
          {
            label: 'cell',
            beValue: 'cell',
          },
        ],
      },
      citizenship: {
        label: 'Citizenship',
        placeHolder: 'Select Status',
        options: [
          {
            label: 'U.S. Citizen',
            beValue: 'USCitizen',
          },
          {
            label: 'Permanent Resident',
            beValue: 'Permanent Resident',
          },
          {
            label: 'Non-resident Alien',
            beValue: 'Non-resident Alien',
          },
        ],
      },
      Coborrower: {
        coborrowerConsent:
          'I agree to the Consent to Receive Electronic Loan Documents and understand that communications, loan documents, and other disclosures will be provided to me electronically. Read <a href="https://docs.google.com/gview?embedded=true&amp;url=https://start.loandepot.com/HELOC/forms/loandepot_heloc_econsent.pdf" target="_blank">Read eConsent</a> to receive documents electronically is required for this program',
        text: 'Adding a %coApplicantLabelLowercase% to this loan?',
        subtitle:
          'This loan program requires %coApplicantLabelLowercase%s to submit the loan inquiry as Joint credit applicants. Joint credit makes it possible to open an account with another borrower where repayment and responsibility are shared. In the case of joint credit, two or more people apply together for approval and become account owners that are equally responsible for the balance.',
        add: 'Add Co-Borrower',
        remove: '- Remove Co-Borrower',
        firstName: {
          label: 'First Name',
          placeHolder: 'First Name',
        },
        middleName: {
          label: 'Middle Initial',
          placeHolder: 'Middle Initial',
        },
        lastName: {
          label: 'Last Name',
          placeHolder: 'Last Name',
        },
        suffix: {
          label: 'Suffix',
          title: 'Suffix',
          isHidden: true,
          options: [
            {
              label: 'I',
              beValue: 'I',
            },
            {
              label: 'II',
              beValue: 'II',
            },
            {
              label: 'III',
              beValue: 'III',
            },
            {
              label: 'IV',
              beValue: 'IV',
            },
            {
              label: 'JR',
              beValue: 'JR',
            },
            {
              label: 'SR',
              beValue: 'SR',
            },
            {
              label: 'V',
              beValue: 'V',
            },
            {
              label: 'VI',
              beValue: 'VI',
            },
            {
              label: 'VII',
              beValue: 'VII',
            },
            {
              label: 'VIII',
              beValue: 'VIII',
            },
          ],
        },
        email: {
          label: 'Email Address',
          placeHolder: 'Email Address',
        },
        relationship: {
          label: 'Relationship to Applicant',
          placeHolder: 'Select Relationship',
          options: [
            {
              label: 'spouse',
              beValue: 'spouse',
            },
            {
              label: 'other',
              beValue: 'other',
            },
          ],
        },
        selectStatus: {
          placeHolder: 'Select Status',
          label: 'Marital Status',
          options: [
            {
              label: 'married',
              beValue: 'Married',
            },
            {
              label: 'separated',
              beValue: 'separated',
            },
            {
              label: 'unmarried',
              beValue: 'unmarried',
            },
          ],
        },
        dateBirth: {
          placeHolder: 'MM/DD/YYYY',
          label: 'Date of Birth',
        },
        SSN: {
          label: 'Social Security Number (SSN)',
          placeHolder: '### - ## - ####',
        },
        homePhone: {
          label: 'Home Phone (optional)',
          placeHolder: '(###) ### - ####',
        },
        workPhone: {
          label: 'Work Phone (optional)',
          placeHolder: '(###) ### - ####',
        },
        mobilePhone: {
          label: 'Mobile Phone',
          placeHolder: '(###) ### - ####',
        },
        emailAddress: {
          label: 'Email Address',
          placeHolder: 'you@email.com',
          isHidden: true,
        },
        contactMethod: {
          label: 'Preferred Contact Method',
          placeHolder: 'Select Preference',
          options: [
            {
              label: 'email',
              beValue: 'Email',
            },
            {
              label: 'home',
              beValue: 'home',
            },
            {
              label: 'work',
              beValue: 'work',
            },
            {
              label: 'cell',
              beValue: 'cell',
            },
          ],
        },
        citizenship: {
          label: 'Citizenship',
          placeHolder: 'Select Status',
          options: [
            {
              label: 'U.S. Citizen',
              beValue: 'U.S. Citizen',
            },
            {
              label: 'Permanent Resident',
              beValue: 'Permanent Resident',
            },
            {
              label: 'Non-resident Alien',
              beValue: 'Non-resident Alien',
            },
          ],
        },
      },
    },
    button: {
      add: 'Add Co-Applicant',
      remove: '- Remove Co-Applicant',
    },
    NavigationButton: {
      nextButton: 'Next',
      backButton: {
        isHidden: false,
        text: 'Back',
      },
    },
  },
  employedInformation: {
    currencyEmployment: 'Current Employment',
    employmentType: {
      label: 'Employment Type',
      placeHolder: 'Select',
      options: [
        {
          label: 'Employed',
          beValue: 'Employed',
        },
        {
          label: 'Retired',
          beValue: 'Retired',
        },
        {
          label: 'Military',
          beValue: 'Military',
        },
        {
          label: 'Self Employed',
          beValue: 'Self Employed',
        },
      ],
    },
    yourEmployment: {
      label: 'Employer Name',
      placeHolder: 'Employer Name',
    },
    jobTitle: {
      label: 'Job Title or Position',
      placeHolder: 'Job Title',
    },
    grossMonthlyIncome: {
      label: 'Gross Monthly Income',
      placeHolder: '$0.00',
    },
    employmentStartDate: {
      label: 'Employment Start Date',
      placeHolder: 'MM/DD/YYYY',
    },
    employmentEndDate: {
      label: 'Employment End Date',
      placeHolder: 'MM/DD/YYYY',
    },
    current: {
      label: 'Current Employment',
      beValue: 'Current',
      placeholder: 'Current',
    },
    contactMethod: {
      label: 'Preferred Contact Method',
      placeHolder: 'Select',
      options: [
        {
          label: 'email',
          beValue: 'email',
        },
        {
          label: 'home',
          beValue: 'home',
        },
        {
          label: 'work',
          beValue: 'work',
        },
        {
          label: 'cell',
          beValue: 'cell',
        },
      ],
    },
    citizenship: {
      label: 'Citizenship',
      placeHolder: 'Select',
      options: [
        {
          label: 'U.S. Citizen',
          beValue: 'U.S. Citizen',
        },
        {
          label: 'Permanent Resident',
          beValue: 'Permanent Resident',
        },
        {
          label: 'Non-resident Alien',
          beValue: 'Non-resident Alien',
        },
      ],
    },
    typeOfIncome: {
      titleIncome: 'Other Sources of Income',
      subTitleIncome: 'Alimony, child support or separate maintenance income need not be revealed if you do not wish to have it considered as a basis for repayment.',
      label: 'Type of Income',
      placeHolder: 'Type of Income',
    },
    totalCurrencyIncome: 'Total current monthly income: $ 0',
    totalEmploymentHistory: 'Total employment history: 0 years (0 months)',
    consent: 'I would like to continue my loan inquiry with less than 2 years employment history.',
    information: 'We need a minimum of 2 years employment information.',
    button: {
      employment: {
        add: 'Employment',
        remove: '- Remove Employment',
      },
      income: {
        add: 'Additional Income',
        remove: '- Remove Income',
      },
    },
    NavigationButton: {
      nextButton: 'Next',
      backButton: {
        isHidden: false,
        text: 'Back',
      },
    },
  },
  GovernmentMonitoringInformation: {
    title: 'Applicant:',
    labels: {
      applicant: 'Applicant',
      coApplicant: 'Co-Applicant',
    },
    subtitle:
      'The purpose of collecting this information is to help ensure that all applicants are treated fairly and that the housing needs of communities and neighborhoods are being fulfilled. For residential mortgage lending, Federal law requires that we ask applicants for their demographic information (ethnicity, race, and sex) in order to monitor our compliance with equal credit opportunity, fair housing, and home mortgage disclosure laws. You are not required to provide this information, but are encouraged to do so. You may select one or more designations for "Ethnicity" and one or more designations for "Race". The law provides that we may not discriminate on the basis of this information, or on whether you choose to provide it. However, if you choose not to provide the information and you have made this application in person, Federal regulations require us to note your ethnicity, race, and sex on the basis of visual observation or surname. If you do not wish to provide some or all of this information, please check below.',
    checkList: {
      primaryEthnicity: {
        notWish: 'I do not wish to furnish this information',
        notHispanic: 'Not Hispanic or Latino',
        hispanic: 'Hispanic or Latino',
        hispanicMexican: 'Mexican',
        hispanicPuertoRican: 'Puerto Rican',
        hispanicCuban: 'Cuban',
        hispanicOther: 'Other Hispanic or Latino',
      },
      raceGroup: {
        notProvided: 'I do not wish to furnish this information',
        asianIndian: 'Asian Indian',
        asianChinese: 'Chinese',
        asianFilipino: 'Filipino',
        asianJapanese: 'Japanese',
        asianKorean: 'Korean',
        asianVietnamese: 'Vietnamese',
        asianOther: 'Other Asian',
        otherAsianPlaceholder: 'Other Asian Description',
        pacificIslander: 'Native Hawaiian/Other Pacific Islander',
        pacificIslanderHawaiian: 'Native Hawaiian',
        pacificIslanderGuamanian: 'Guamanian or Chamorro',
        pacificIslanderSamoan: 'Samoan',
        pacificIslanderOther: 'Other Pacific Islander',
        pacificOther: 'ther Pacific Islander Description',
        black: 'Black or African American',
        americanIndianOrAlaskaNative: 'American Indian or Alaska Native',
        otherAmericanOrAlaskan: 'Enter name of enrolled principal',
      },
      sexProperty: {
        notProvided: 'I do not wish to furnish this information',
        male: 'Male',
        female: 'Female',
      },
    },
    NavigationButton: {
      nextButton: 'Next',
      backButton: {
        isHidden: false,
        text: 'Back',
      },
    },
  },
  summary: {
    borrower: {
      title: 'Review Your Application',
      applicant: 'Applicant',
      subTitle: 'Please review your application information for accuracy.',
      consumer: 'Carson Testcase',
      summaryContainer: [
        {
          section: 'basicInformation',
          items: [
            { key: 'title', value: 'Basic Information' },
            { key: 'fullName', value: '%%name%%' },
            { key: 'email', value: '%%email%%' },
            { key: 'birthday', value: '%%birthday%%' },
            { key: 'home', value: '%%homePhone%%' },
            { key: 'maritalStatus', value: '%%maritalStatus%%' },
            { key: 'cell', value: '%%cellPhone%%' },
            { key: 'preferredContact', value: '%%contactMethod%%' },
            { key: 'work', value: '%%workPhone%%' },
          ],
        },
        {
          section: 'propertyInformation',
          items: [
            { key: 'title', value: 'Property Information' },
            { key: 'propertyAddress', value: '%%address%%' },
            { key: 'propertyType', value: '%%propertyType%%' },
            { key: 'propertyUse', value: '%%propertyUse%%' },
          ],
        },
        {
          section: 'estimatedAvailableEquity',
          items: [
            { key: 'title', value: 'Estimated Available Equity' },
            { key: 'estimatedMarketValue', value: '%%estimatedMarket%%' },
            { key: 'remainingMortgageBalance', value: '%%remainingMortgage%%' },
          ],
        },
        {
          section: 'loanDetails',
          items: [
            { key: 'title', value: 'Loan Details' },
            { key: 'lineOfCredit', value: '%%lineAmount%%' },
            { key: 'initialDrawAmount', value: '%%drawAmount%%' },
          ],
        },
        {
          section: 'employment',
          items: [
            { key: 'title', value: 'Employment' },
            { key: 'employer', value: '%%employer%%' },
            { key: 'monthlyIncome', value: '%%income%%' },
            { key: 'joined', value: '%%startDate%%' },
            { key: 'totalMonths', value: '%%totalMonths%%' },
          ],
        },
        {
          section: 'HMDADetails',
          items: [
            { key: 'title', value: 'HMDA Details' },
            { key: 'Ethnicity', value: '%%ethnicity%%' },
            { key: 'race', value: '%%race%%' },
            { key: 'Gender', value: '%%gender%%' },
          ],
        },
      ],
    },
    coBorrower: {
      title: 'Review Your Application',
      applicant: 'Co-Applicant',
      subTitle: 'Please review your application information for accuracy.',
      consumer: 'Carson Testcase',
      summaryContainer: [
        {
          section: 'basicInformation',
          items: [
            { key: 'title', value: 'Basic Information' },
            { key: 'fullName', value: '%%nameCoBorrower%%' },
            { key: 'email', value: '%%emailCoBorrower%%' },
            { key: 'birthday', value: '%%birthdayCoBorrower%%' },
            { key: 'home', value: '%%homePhoneCoBorrower%%' },
            { key: 'maritalStatus', value: '%%maritalStatusCoBorrower%%' },
            { key: 'cell', value: '%%cellPhoneCoBorrower%%' },
            { key: 'preferredContact', value: '%%contactMethodCoBorrower%%' },
            { key: 'work', value: '%%workPhoneCoBorrower%%' },
          ],
        },
        {
          section: 'employment',
          items: [
            { key: 'title', value: 'Employment' },
            { key: 'employer', value: '%%employerCoBorrower%%' },
            { key: 'monthlyIncome', value: '%%incomeCoBorrower%%' },
            { key: 'joined', value: '%%startDateCoBorrower%%' },
            { key: 'totalMonths', value: '%%totalMonthsCoBorrower%%' },
          ],
        },
        {
          section: 'HMDADetails',
          items: [
            { key: 'title', value: 'HMDA Details' },
            { key: 'Ethnicity', value: '%%ethnicityCoBorrower%%' },
            { key: 'race', value: '%%raceCoBorrower%%' },
            { key: 'Gender', value: '%%genderCoBorrower%%' },
          ],
        },
      ],
    },
    modalSubmit: {
      infoSubmitModal: {
        header: 'Review Notice',
        body: 'Custom  message. I’ve read and accept PW bank to email me, call me, and this is general counsel compliance language that effectively allows the bank to email the loan applicant with referral information, adverse action information, or genral contact to complete their application.',
        button: 'Cancel',
        acceptButton: 'Submit Application',
      },
    },
    NavigationButton: {
      nextButton: 'Next',
      backButton: {
        isHidden: false,
        text: 'Back',
      },
    },
  },
  completion: {
    title: 'Congratulations! You’ve been preapproved for a Home Equity Line of Credit.',
    subTitle: '',
    loanDetails: [
      { title: 'Loan number', value: '%%loanNumber%%' },
      { title: 'Line Amount', value: '%%lineAmount%%' },
      { title: 'Initial Disbursement ', value: '%%drawAmount%%' },
      { title: 'Initial Rate and APR*', value: '%%apr%%' },
    ],
    verbiage:
      '*Quoted interest rate is the initial interest rate for your HELOC and is subject to change without notice. Please note that the interest rate does not include costs other than interest and will vary over the life of your loan. Rate and terms are not guaranteed and may change before account opening without notice. Credit and collateral approval is required. Final initial rate will be set at the time application is fully underwritten.',
    nextSteps: {
      verbiage:
        'For instructions, please log into your secure portal by clicking on the link sent to the email address provided in the application. Once logged in, you can provide, review and sign documents directly from the portal. ',
      description: 'We sent a temporary password to the email address you provided in your application.',
      button: 'Go To My Portal!',
      contact: 'Question? Contact us at <a href="#">ColumCu.org</a>',
      isButtonPortal: false,
    },
  },
  footer: {
    defaultFooter: {
      footerDisclosuresOne: '©2023 Lender Name (NMLS #12345)',
      footerDisclosuresTwo: 'Contact Us 866.243.1111 current',
      support: 'current version 0.2.60.3',
    },
    footerVmoV2: {
      footerDisclosuresOne:
        '*Rates and terms are not guaranteed and may change before account opening without notice. Rates and terms may vary based on requested line amount, credit history and available equity. Final initial rate will be set at the time application is fully underwritten. To check the rates and terms to which you may qualify, we will conduct a soft credit pull that will not impact your credit score. If you continue and submit an inquiry, however, we will request your full credit report from one or more consumer reporting agencies which is considered a hard credit pull and may affect your credit score.',
      footerDisclosuresTwo:
        '<a href="#">loanDepot.com LLC </a>, an Equal Housing Opportunity Lender, 6561 Irvine Center Drive, Irvine, CA 92618 All rights reserved. NMLS #174457 - NMLS Consumer Access Site. To view important notices regarding loanDepot licensing, please visit <a href="#">www.loandepot.com/licensing.</a>',
      support: 'current version 0.2.60.3',
    },
  },
};
