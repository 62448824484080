import { pages } from '@/defaultVerbiages';
export const handleCurrentVmoPage = (templateConfig: boolean, hasDrawPage: boolean, declarations?: boolean) => {
  const isVmoV2 = templateConfig;
  let filterPages = pages?.pages;
  if (isVmoV2) {
    filterPages = pages?.pages.filter((page) => page !== 'View My Offer!');
  } else if (!hasDrawPage) {
    const pageIndex = pages?.pages.indexOf('View My Offer!');
    if (pageIndex !== -1) {
      const updatedPages = [...pages.pages];
      updatedPages.splice(pageIndex, 1);
      filterPages = updatedPages;
    }
  }
  if (!declarations) {
    filterPages = filterPages.filter((page) => page !== 'Declarations');
  }
  return filterPages;
};
