import { LoanApplicationState } from '@/reducers/LoanApplication.types';
import { convertToNumber } from './formatValues';
interface TradeLine {
  include?: boolean;
  accountNumber: string;
  tradelineName: string;
  tradelineBalance: string;
  tradelineMonthlyPayment: string;
  remove?: boolean;
}
export const calculateTotalTradeLineBalance = (tradeLines: TradeLine[]): number =>
  tradeLines.reduce((total, { include, tradelineBalance }) => total + (include ? convertToNumber(tradelineBalance) || 0 : 0), 0);
export const calculatePercent = (used: number, amount: number): number => Math.round(Math.min((used / amount) * 100, 100));
export const cashBack = (loanApplication: LoanApplicationState, totalUsed: number): number => {
  const originationFee = loanApplication?.selectedProgram?.fees?.[0]?.feeAmount || loanApplication?.feeAmount || 0;
  const amountUsed = loanApplication.drawAmount || loanApplication.lineAmount || 0;
  return amountUsed - (totalUsed + originationFee);
};
