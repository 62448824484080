import styled, { css } from 'styled-components';
import InfoIcons from '../../assets/images/info-icons.svg?react';

interface DrawAmount {
  $isLineDrawAmount?: boolean;
  $barCustomBackground?: string;
  $isBarCustomBackground?: boolean;
  $titleIsCustomColor?: boolean;
  $titleCustomColor?: string;
  $barTitleIsCustomColor?: boolean;
  $barTitleCustomColor?: string;
  $isHidden?: boolean;
}

const FlexCenter = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BorderRadiusTop = css`
  border-radius: 0.688rem 0.688rem 0 0;
`;

const BorderRadiusBottom = css`
  border-radius: 0 0 0.688rem 0.688rem;
`;

const BodyTitleSubTitle = css`
  justify-content: center;
  @media only screen and (max-width: ${(props) => props.theme.breakpoints?.md}) {
    padding: 0.8rem 0 0.688rem 0;
  }
  p {
    color: ${({ theme }) => theme.colors?.textSecondary};
    text-align: center;
    font-size: 1.063rem;
    font-weight: 400;
    margin: 0;
  }
  @media only screen and (max-width: ${(props) => props.theme.breakpoints?.md}) {
    p {
      font-size: 0.749rem;
      padding: 0.5rem;
    }
  }
`;

const FontStyles = css`
  line-height: normal;
  font-style: normal;
`;
export const WrapperCard = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 0.688rem;
  width: 90%;
  box-shadow:
    0rem 0rem 0.063rem 0.063rem rgba(15, 27, 47, 0.12),
    0rem 0.063rem 0.125rem -0.063rem rgba(15, 27, 47, 0.16);
  @media only screen and (max-width: ${(props) => props.theme.breakpoints?.sm}) {
    width: 100%;
  }
`;

export const WrapperContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    width: 100%;
  }
`;
export const TitleContainer = styled.div<DrawAmount>`
  ${FlexCenter}
  padding: 1.563rem 1rem;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    padding: 1.25rem 0 1.563rem 0;
  }
  :first-child {
    font-family: Poppins;
    font-feature-settings:
      'liga' off,
      'clig' off;
    font-size: 1.563rem;
    font-weight: 400;
    ${FontStyles};
    color: ${({ theme, $titleIsCustomColor, $titleCustomColor }) => ($titleIsCustomColor ? $titleCustomColor : theme.colors.bgSecondary)};
    @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}) {
      font-size: 1rem;
      letter-spacing: -0.01rem;
    }
  }
`;

export const CardHeader = styled.div<DrawAmount>`
  display: ${({ $isHidden }) => ($isHidden ? 'none' : 'flex')};
  justify-content: center;
  align-items: flex-start;
  ${BorderRadiusTop};
  background: ${({ theme, $isBarCustomBackground, $barCustomBackground }) => ($isBarCustomBackground ? $barCustomBackground : theme.colors?.bgSecondary)};
  padding: 1rem;
  p {
    color: ${({ theme, $barTitleIsCustomColor, $barTitleCustomColor }) => ($barTitleIsCustomColor ? $barTitleCustomColor : theme.colors?.bgWhite)};
    text-align: center;
    font-size: 1.063rem;
    font-weight: 600;
    margin: 0;
    ${FontStyles};
  }
  @media only screen and (max-width: ${(props) => props.theme.breakpoints?.md}) {
    p {
      font-size: 0.749rem;
    }
  }
`;

export const BodyTitle = styled.div<DrawAmount>`
  display: ${({ $isHidden }) => ($isHidden ? 'none' : 'flex')};
  padding: 2rem 0 1rem 0;
  ${BodyTitleSubTitle};
`;

export const BodySubTitle = styled.div<DrawAmount>`
  display: ${({ $isHidden }) => ($isHidden ? 'none' : 'flex')};
  padding-bottom: 1rem;
  ${BodyTitleSubTitle};
`;
export const CardBody = styled.div`
  display: flex;
  padding: 1rem 2rem;
  justify-content: space-around;
  align-items: center;
  flex: 1 0 0;
  ${BorderRadiusBottom};
  background-color: ${({ theme }) => theme.colors?.bgWhite};
  p {
    margin: 0;
  }

  .horizontal-separator {
    margin: 1rem 0;
  }
  @media only screen and (max-width: ${(props) => props.theme.breakpoints?.lg}) {
    padding: 1.409rem 0.75rem;
  }
  @media only screen and (max-width: ${(props) => props.theme.breakpoints?.lg}) {
    width: auto;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0.2rem 0.704rem;
    display: flex;
    align-items: center;
  }
`;

export const InformationContainer = styled.div<DrawAmount>`
  display: ${({ $isHidden }) => $isHidden && 'none'};
  text-align: center;
  font-family: Poppins;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 300;
  line-height: 0.938rem;
  max-width: 58.625rem;
  margin-top: 1rem;
  @media only screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
    font-size: 0.523rem;
    font-weight: 400;
    border-radius: 1.125rem;
    background: ${(props) => props.theme.colors.bgWhite};
    box-shadow:
      0rem 0rem 0.063rem 0.063rem rgba(15, 27, 47, 0.12),
      0rem 0.063rem 0.125rem -0.063rem rgba(15, 27, 47, 0.16);
    margin-bottom: 1.5rem;
    padding: 1rem 0.523rem;
    margin: 1.5rem 0;
  }
`;

export const CardSection = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
`;

export const CardDrawLine = styled.div`
  ${FlexCenter};
  flex-direction: column;
`;

export const Col = styled.div<DrawAmount>`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: ${({ $isLineDrawAmount }) => ($isLineDrawAmount ? '0.1rem' : '1rem')};
  @media only screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
    align-items: normal;
    margin-bottom: 1rem;
  }
  h5 {
    ${FontStyles};
    font-family: 'Nunito';
    text-align: center;
    font-size: 1.875rem;
    font-weight: 700;
    margin: 0;
    color: ${({ theme }) => theme.colors.textDarkGrey};
    width: 100%;
    @media only screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
      padding: 0;
      font-size: 1.321rem;
    }
  }
  p {
    font-family: 'Nunito';
    ${FontStyles};
    font-size: 0.813rem;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.textGrey};
    width: 100%;
    @media only screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
      font-size: 0.572rem;
    }
  }
`;

export const InfoIcon = styled(InfoIcons)`
  width: 1.6em;
  height: 1.313rem;
  cursor: pointer;
  path {
    fill: ${({ theme }) => theme.colors.primary};
  }
  @media only screen and (max-width: ${(props) => props.theme.breakpoints.lg}) {
    width: 0.9rem;
    height: 1rem;
  }
`;

export const ContainerInfoIcon = styled.div<DrawAmount>`
  height: 50%;
  display: flex;
  text-align: center;
  gap: 0.5rem;
  align-items: center;
  ${({ $isLineDrawAmount }) =>
    $isLineDrawAmount &&
    css`
      padding-left: 4rem;
      width: 100%;
      @media only screen and (max-width: ${(props) => props.theme.breakpoints.lg}) {
        width: 47%;
        padding-left: 2rem;
      }
      @media only screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
        width: 65%;
        text-align: end;
      }
      p {
        width: 32%;
        @media only screen and (max-width: ${(props) => props.theme.breakpoints.lg}) {
          width: 100%;
          font-size: 0.572rem;
        }
      }
    `}
`;
