import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { EmploymentWrapper, Separator, CurrentMonthlyWrapper } from './EmploymentInformation.styles';
import { Wrapper } from '../wrapper.styles';
import { TextInput } from '../../components/input/TextInput/TextInput';
import { TextBlock } from '@components/text/TextBlock';
import { TypeOfText } from '@components/text/TextBlock';
import { DropDownInput } from '@/components/input/DropDownInput/DropDownInput';
import { FormGroupComponent } from '@/components/input/FormGroup/FormGroup';
import { NavigationButton } from '@/components/Button/NavigationButton/NavigationButton';
import { useIsMobile } from '@/hooks/useIsMobile';
import { PageOrigin } from '@/components/Button/NavigationButton/NavigationButton';
import DateEmploymentPicker from '@/components/input/DateEmployedPicker/DateEmployedPicker';
import { Button } from '@/components/Button/DefaultButton/Button';
import { CheckConsent } from '@/components/input/CheckConsent/CheckConsent';
import _ from 'lodash';
import { useAppSelector, useAppDispatch } from '@app/hooks';
import { convertToDate, convertToNumber, formatNumber } from '@/utils/formatValues';
import { RadioButton } from '@/components/input/RadioButton/RadioButton';
import { selectIsGoingBack } from '@/reducers/currentPage';

interface EmploymentInformationProps {
  onAccess: (page: string) => void;
}

export enum PropertyType {
  Type = 'type',
  EmployerName = 'employment',
  JobTitle = 'jobTitle',
  StartDate = 'startDate',
  EndDate = 'endDate',
  GrossMonthly = 'monthly',
  CurrentEmployment = 'currentEmployment',
  ContactMethod = 'contactMethod',
  Citizenship = 'citizenship',
  TypeIncome = 'typeIncome',
  GrossMonthlyIncome = 'monthlyIncome',
  TypeCoBorrower = 'typeCoBorrower',
  EmploymentCoBorrower = 'employmentCoBorrower',
  JobTitleCoBorrower = 'jobTitleCoBorrower',
  StartDateCoBorrower = 'startDateCoBorrower',
  EndDateCoBorrower = 'endDateCoBorrower',
  MonthlyCoBorrower = 'monthlyCoBorrower',
  CurrentEmploymentCoBorrower = 'currentEmploymentCoBorrower',
  ContactMethodCoBorrower = 'contactMethodCoBorrower',
  CitizenshipCoBorrower = 'citizenshipCoBorrower',
  TypeIncomeCoBorrower = 'typeIncomeCoBorrower',
  GrossMonthlyIncomeCoBorrower = 'grossMonthlyIncomeCoBorrower',
  TotalMonthsCoBorrower = 'totalMonthsCoBorrower',
}

type EmploymentForm = {
  type: string;
  employment: string;
  jobTitle: string;
  startDate: string;
  endDate: string;
  monthly: string;
  currentEmployment: boolean;
  totalMonths: number;
  lessThanTwoYears: boolean;
};

type CoBorrowerForm = {
  jobTitleCoBorrower: string;
  startDateCoBorrower: string;
  endDateCoBorrower: string;
  monthlyCoBorrower: string;
  currentEmploymentCoBorrower: boolean;
  totalMonths: number;
  monthlyIncome: string;
  typeCoBorrower: string;
  employmentCoBorrower: string;
  lessThanTwoYearsCoBorrower: boolean;
};

type IncomeFormValues = {
  typeIncome: string;
  monthlyIncome: string;
};

type IncomeCoBorrowersFormValues = {
  typeIncomeCoBorrower: string;
  grossMonthlyIncomeCoBorrower: string;
};

const EmploymentInformation: React.FC<EmploymentInformationProps> = ({ onAccess }) => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const dispatch = useAppDispatch();
  const [formError, setFormError] = React.useState(false);
  let loanApplication = useAppSelector((state) => state.LoanApplication);
  const borrowerEmploy = loanApplication?.borrowers[0]?.employmentHistory?.length;
  const coborrowerEmploy = loanApplication?.borrowers[1]?.employmentHistory?.length;
  const borrowerIncome = loanApplication?.borrowers[0]?.otherIncome?.length;
  const coborrowerIncome = loanApplication?.borrowers[1]?.otherIncome?.length;
  const [selectedOption, setSelectedOption] = React.useState('');
  const [selectedCoBorrowerOption, setSelectedCoBorrowerOption] = React.useState('');
  const [indexForm, setIndexForm] = useState<number>(borrowerEmploy);
  const [indexIncomeForm, setIndexIncomeForm] = useState<number>(borrowerIncome);
  const [indexCoBorrowerForm, setIndexCoBorrowerForm] = useState<number>(coborrowerEmploy);
  const [indexIncomeCoBorrowerForm, setIndexIncomeCoBorrowerForm] = useState<number>(coborrowerIncome);
  const [otherMonthlyIncome, setOtherMonthlyIncome] = useState<number>(0);
  const [otherCoBorrowerMonthlyIncome, setOtherCoBorrowerMonthlyIncome] = useState<number>(0);
  const isGoingBack = useAppSelector(selectIsGoingBack);
  const [isRemoveBorrowerForm, setIsRemoveBorrowerForm] = useState<boolean>(false);
  const [isRemoveIncomeForm, setIsRemoveIncomeForm] = useState<boolean>(false);
  const [isRemoveCoBorrowerForm, setIsRemoveCoBorrowerForm] = useState<boolean>(false);
  const [isRemoveIncomeCoBorrowerForm, setIsRemoveIncomeCoBorrowerForm] = useState<boolean>(false);
  const coBorrower = loanApplication?.borrowers.length > 1;
  let auxLoanApplication = _.cloneDeep(loanApplication);

  const [employmentForms, setEmploymentForms] = useState<EmploymentForm[]>(
    loanApplication.borrowers[0]?.employmentHistory?.map((employmentInfo) => ({
      type: employmentInfo.type || '',
      employment: employmentInfo.employerName || '',
      jobTitle: employmentInfo.jobTitle || '',
      startDate: employmentInfo.startDate || '',
      endDate: employmentInfo.endDate || '',
      monthly: formatNumber(employmentInfo.grossMonthlyIncome) || '',
      currentEmployment: employmentInfo.currentEmployment ? true : false,
      totalMonths: employmentInfo.totalMonths || 0,
      lessThanTwoYears: false,
    })) || [
      {
        type: '',
        employment: '',
        jobTitle: '',
        startDate: '',
        endDate: '',
        monthly: '',
        currentEmployment: false,
        totalMonths: 0,
      },
    ],
  );
  const [coBorrowerForms, setCoBorrowerForms] = useState<CoBorrowerForm[]>(
    loanApplication.borrowers[1]?.employmentHistory?.map((coBorrowerInfo) => ({
      jobTitleCoBorrower: coBorrowerInfo?.jobTitle || '',
      startDateCoBorrower: coBorrowerInfo?.startDate || '',
      endDateCoBorrower: !coBorrowerInfo?.currentEmployment && coBorrowerInfo?.endDate ? coBorrowerInfo?.endDate : '',
      monthlyCoBorrower: formatNumber(coBorrowerInfo?.grossMonthlyIncome) || '',
      currentEmploymentCoBorrower: coBorrowerInfo?.currentEmployment ? true : false,
      totalMonths: coBorrowerInfo?.totalMonths || 0,
      monthlyIncome: formatNumber(coBorrowerInfo?.grossMonthlyIncome) || '',
      typeCoBorrower: coBorrowerInfo?.type || '',
      employmentCoBorrower: coBorrowerInfo?.employerName || '',
      lessThanTwoYearsCoBorrower: false,
    })) || [
      {
        jobTitleCoBorrower: '',
        startDateCoBorrower: '',
        endDateCoBorrower: '',
        monthlyCoBorrower: '',
        currentEmploymentCoBorrower: false,
        monthlyIncome: '',
        typeCoBorrower: false,
        employmentCoBorrower: 0,
      },
    ],
  );
  const [incomeBorrowersForms, setIncomeBorrowerForms] = useState<IncomeFormValues[]>(
    loanApplication.borrowers[0]?.otherIncome?.map((incomeInfo) => ({
      typeIncome: incomeInfo.incomeType || '',
      monthlyIncome: formatNumber(incomeInfo.grossMonthlyIncome) || '',
    })) || [],
  );

  const [incomeCoBorrowersForms, setIncomeCoBorrowerForms] = useState<IncomeCoBorrowersFormValues[]>(
    loanApplication.borrowers[1]?.otherIncome?.map((incomeInfo) => ({
      typeIncomeCoBorrower: incomeInfo.incomeType || '',
      grossMonthlyIncomeCoBorrower: formatNumber(incomeInfo.grossMonthlyIncome) || '',
    })) || [],
  );
  const [currentIndex, setCurrentIndex] = useState<number[]>(
    employmentForms.map((employmentInfo, index) => (employmentInfo.currentEmployment ? index : null)).filter((index) => index !== null) || [],
  );

  const [currentCoBorrowerIndex, setCurrentCoBorrowerIndex] = useState<number[]>(
    coBorrowerForms?.map((employmentInfo, index) => (employmentInfo.currentEmploymentCoBorrower ? index : null)).filter((index) => index !== null) || [],
  );
  const isCurrentEmployment = selectedOption !== '' || employmentForms[indexForm]?.type === 'Retired' ? true : false;
  const isCurrentCoBorrowerEmployment = selectedCoBorrowerOption !== '' || coBorrowerForms[indexCoBorrowerForm]?.typeCoBorrower === 'Retired' ? true : false;
  const [changeIndex, setChangeIndex] = useState(0);
  const currentEmploymentDates = employmentForms[changeIndex] || {};
  const currentCoBorrowerDates = coBorrowerForms[changeIndex] || {};
  const { startDateCoBorrower, endDateCoBorrower, currentEmploymentCoBorrower, monthlyCoBorrower } = currentCoBorrowerDates;
  const { startDate, endDate, currentEmployment, monthly } = currentEmploymentDates;
  const [totalBorrowerMonths, setTotalBorrowerMonths] = useState(0);
  const [totalCoBorrowerMonths, setTotalCoBorrowerMonths] = useState(0);
  const [backPage, setBackPage] = useState(false);
  const hasLessThanTwoYears = totalBorrowerMonths > 24;
  const hasCoBorrowerLessThanTwoYears = totalCoBorrowerMonths > 24;

  useEffect(() => {
    if (isGoingBack) {
      setBackPage(true);
    }
  }, []);
  const handleAddForm = (type: 'employment' | 'coBorrower' | 'incomeBorrower' | 'incomeCoBorrower') => {
    if (type === 'employment') {
      setIndexForm(indexForm + 1);
      return setEmploymentForms((prevForms) => [
        ...prevForms,
        {
          type: '',
          employment: '',
          jobTitle: '',
          startDate: '',
          endDate: '',
          monthly: '',
          currentEmployment: false,
          totalMonths: 0,
          lessThanTwoYears: false,
        },
      ]);
    } else if (type === 'coBorrower') {
      setIndexCoBorrowerForm(indexCoBorrowerForm + 1);
      setCoBorrowerForms((prevForms) => [
        ...prevForms,
        {
          jobTitleCoBorrower: '',
          startDateCoBorrower: '',
          endDateCoBorrower: '',
          monthlyCoBorrower: '',
          currentEmploymentCoBorrower: false,
          typeIncomeCoBorrower: '',
          grossMonthlyIncomeCoBorrower: '',
          totalMonths: 0,
          monthlyIncome: '',
          typeCoBorrower: '',
          employmentCoBorrower: '',
          lessThanTwoYearsCoBorrower: false,
        },
      ]);
    } else if (type === 'incomeBorrower') {
      setIndexIncomeForm(indexIncomeForm + 1);
      setIncomeBorrowerForms((prevForms) => [
        ...prevForms,
        {
          typeIncome: '',
          monthlyIncome: '',
        },
      ]);
    } else {
      setIndexIncomeCoBorrowerForm(indexIncomeCoBorrowerForm + 1);
      setIncomeCoBorrowerForms((prevForms) => [
        ...prevForms,
        {
          typeIncomeCoBorrower: '',
          grossMonthlyIncomeCoBorrower: '',
        },
      ]);
    }
  };

  const handleRemoveForm = (index: number, type: 'employment' | 'coBorrower' | 'incomeBorrower' | 'incomeCoBorrower') => {
    if (type === 'employment') {
      setIsRemoveBorrowerForm(true);
      return setEmploymentForms((prevForms) => prevForms.filter((_, i) => i !== index));
    } else if (type === 'coBorrower') {
      setIsRemoveCoBorrowerForm(true);
      return setCoBorrowerForms((prevForms) => prevForms.filter((_, i) => i !== index));
    } else if (type === 'incomeBorrower') {
      setIsRemoveIncomeForm(true);
      return setIncomeBorrowerForms((prevForms) => prevForms.filter((_, i) => i !== index));
    } else {
      setIsRemoveIncomeCoBorrowerForm(true);
      return setIncomeCoBorrowerForms((prevForms) => prevForms.filter((_, i) => i !== index));
    }
  };

  const handleChangeRefactor = _.debounce(
    (value: string | number | boolean, name: string, index: number, type: 'employment' | 'coBorrower' | 'incomeBorrower' | 'incomeCoBorrower') => {
      setBackPage(false);
      setChangeIndex(index);
      const item = templateConfig?.pages?.employedInformation?.employmentType?.options.find((option) => option.beValue === value);
      const isEndDateRequired = item ? item.endDateRequired : false;
      const handleEmploymentChange = (prev: any) =>
        prev.map((section: any, i: number) => {
          if (i !== index) return section;

          if (name === 'type' && value === 'Retired') {
            return {
              ...section,
              [name]: value,
              jobTitle: 'Retired',
              employment: 'Retired',
              currentEmployment: isEndDateRequired || false,
            };
          }
          if (name === 'type') {
            return {
              ...section,
              [name]: value,
              currentEmployment: isEndDateRequired || false,
            };
          }
          if (name === 'typeCoBorrower' && value === 'Retired') {
            return {
              ...section,
              [name]: value,
              jobTitleCoBorrower: 'Retired',
              employmentCoBorrower: 'Retired',
              currentEmploymentCoBorrower: isEndDateRequired || false,
            };
          }
          if (name === 'typeCoBorrower') {
            return {
              ...section,
              [name]: value,
              currentEmploymentCoBorrower: isEndDateRequired || false,
            };
          }
          return { ...section, [name]: value };
        });

      if (type === 'employment') {
        setEmploymentForms((prev: any) => handleEmploymentChange(prev));

        let obj: any = {};
        value = value === 'Current' ? !isCurrentEmployment : value;
        obj[name] = value;

        return;
      } else if (type === 'coBorrower') {
        setCoBorrowerForms((prev: any) => handleEmploymentChange(prev));

        let obj: any = {};
        value = value === 'Current' ? !isCurrentCoBorrowerEmployment : value;
        obj[name] = value;

        return;
      } else if (type === 'incomeBorrower') {
        return setIncomeBorrowerForms((prev: any) => prev.map((section: any, i: number) => (i === index ? { ...section, [name]: value } : section)));
      } else {
        return setIncomeCoBorrowerForms((prev: any) => prev.map((section: any, i: number) => (i === index ? { ...section, [name]: value } : section)));
      }
    },
    300,
  );

  const validateForms = useCallback(
    (forms: Record<string, any>[], isIncome?: true | undefined) => {
      const requiredFields: {
        endDate: 'currentEmployment' | undefined;
        endDateCoBorrower: 'currentEmploymentCoBorrower' | undefined;
        monthlyIncome: true | undefined;
        typeIncomeCoBorrower: true | undefined;
        grossMonthlyIncomeCoBorrower: true | undefined;
        totalMonths: true | undefined;
        totalMonthsCoBorrower: true | undefined;
        lessThanTwoYears: true | undefined;
        lessThanTwoYearsCoBorrower: true | undefined;
      } = {
        endDate: 'currentEmployment',
        endDateCoBorrower: 'currentEmploymentCoBorrower',
        monthlyIncome: isIncome ? undefined : true,
        typeIncomeCoBorrower: true,
        grossMonthlyIncomeCoBorrower: isIncome ? undefined : true,
        totalMonths: true,
        totalMonthsCoBorrower: true,
        lessThanTwoYears: true,
        lessThanTwoYearsCoBorrower: true,
      };

      return forms.every((form) => {
        return Object.entries(form).every(([key, value]) => {
          if (key in requiredFields) {
            const field = requiredFields[key as keyof typeof requiredFields];
            if (!hasLessThanTwoYears && key === 'lessThanTwoYears' && value !== true) return false;
            if (!hasCoBorrowerLessThanTwoYears && key === 'lessThanTwoYearsCoBorrower' && value !== true) return false;
            if (field === true) return true;
            if (field && form[field]) return true;
          }
          if (typeof value === 'boolean') return true;
          if (typeof value === 'number') return value > 0;
          if (typeof value === 'string') return value.trim() !== '';

          return false;
        });
      });
    },
    [employmentForms, coBorrowerForms, coBorrower, incomeBorrowersForms, indexIncomeForm, incomeCoBorrowersForms, indexIncomeCoBorrowerForm, hasLessThanTwoYears],
  ); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const allFieldsFilled = coBorrower ? validateForms(employmentForms) && validateForms(coBorrowerForms) : validateForms(employmentForms);

    let allFieldsIncomes = true;
    if (indexIncomeForm !== 0) {
      allFieldsIncomes = incomeBorrowersForms ? validateForms(incomeBorrowersForms, true) : true;
    }
    if (indexIncomeCoBorrowerForm !== 0) {
      allFieldsIncomes = allFieldsIncomes && (incomeCoBorrowersForms ? validateForms(incomeCoBorrowersForms, true) : true);
    }
    const allFieldsValid = allFieldsFilled && allFieldsIncomes;
    setFormError(allFieldsValid);
  }, [employmentForms, coBorrowerForms, coBorrower, incomeBorrowersForms, indexIncomeForm, incomeCoBorrowersForms, indexIncomeCoBorrowerForm, hasLessThanTwoYears]); // eslint-disable-line react-hooks/exhaustive-deps

  let templateConfig = useAppSelector((state) => state.TemplateConfig);

  useEffect(() => {
    if (incomeBorrowersForms[indexIncomeForm - 1]?.monthlyIncome || monthly) {
      const currentEmployments = employmentForms.filter((emp) => emp.currentEmployment);
      let income = 0;
      if (currentEmployments.length > 0) {
        income = currentEmployments.reduce((sum, emp) => {
          return sum + convertToNumber(emp.monthly || '0');
        }, 0);
      }
      const totalOtherIncome = incomeBorrowersForms.reduce((sum, form, index) => {
        if (form?.monthlyIncome && index !== indexIncomeForm) {
          return sum + convertToNumber(form.monthlyIncome);
        }
        return sum;
      }, 0);
      setOtherMonthlyIncome(totalOtherIncome + income);
    }
    if (incomeCoBorrowersForms[indexIncomeCoBorrowerForm - 1]?.grossMonthlyIncomeCoBorrower || monthlyCoBorrower) {
      const currentEmployments = coBorrowerForms.filter((emp) => emp.currentEmploymentCoBorrower);
      let incomeCoBorrower = 0;
      if (currentEmployments.length > 0) {
        incomeCoBorrower = currentEmployments.reduce((sum, emp) => {
          return sum + convertToNumber(emp.monthlyCoBorrower || '0');
        }, 0);
      }
      const totalOtherCoBorrowerIncome = incomeCoBorrowersForms.reduce((sum, form, index) => {
        if (form?.grossMonthlyIncomeCoBorrower && index !== indexIncomeCoBorrowerForm) {
          return sum + convertToNumber(form?.grossMonthlyIncomeCoBorrower);
        }
        return sum;
      }, 0);
      setOtherCoBorrowerMonthlyIncome(totalOtherCoBorrowerIncome + incomeCoBorrower);
    }
  }, [incomeBorrowersForms, monthly, incomeCoBorrowersForms, currentEmploymentCoBorrower, monthlyCoBorrower, currentEmployment]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!backPage && !isGoingBack) {
      const totalBorrower = true;
      if (totalBorrower) {
        if (!isRemoveBorrowerForm) {
          employmentForms.map((emp, indexEmploy) => {
            const updatedBorrower = {
              ...auxLoanApplication.borrowers[0].employmentHistory[indexEmploy],
              type: emp?.type,
              employerName: emp.type === 'Retired' ? 'Retired' : emp?.employment,
              jobTitle: emp?.type === 'Retired' ? 'Retired' : emp?.jobTitle,
              startDate: emp?.startDate,
              endDate: emp?.endDate,
              grossMonthlyIncome: emp?.monthly ? convertToNumber(emp?.monthly) : 0,
              currentEmployment: emp?.currentEmployment || emp?.type === 'Retired' ? true : false,
              totalMonths: emp?.totalMonths,
            };
            auxLoanApplication.borrowers[0].employmentHistory[indexEmploy] = updatedBorrower;
          });
        } else {
          setIsRemoveBorrowerForm(false);
          setIndexForm(indexForm - 1);
          auxLoanApplication.borrowers[0].employmentHistory.pop();
        }
      }
      if (coBorrower) {
        if (!isRemoveCoBorrowerForm) {
          coBorrowerForms.map((coEmp, indexCoEmploy) => {
            const updatedCoBorrower = {
              ...auxLoanApplication.borrowers[1].employmentHistory[indexCoEmploy],
              type: coEmp?.typeCoBorrower || '',
              employerName: coEmp?.typeCoBorrower === 'Retired' ? 'Retired' : coEmp?.employmentCoBorrower,
              jobTitle: coEmp?.typeCoBorrower === 'Retired' ? 'Retired' : coEmp?.jobTitleCoBorrower,
              startDate: coEmp.startDateCoBorrower,
              endDate: !coEmp.currentEmploymentCoBorrower && coEmp.endDateCoBorrower ? coEmp.endDateCoBorrower : '',
              grossMonthlyIncome: coEmp?.monthlyCoBorrower ? convertToNumber(coEmp?.monthlyCoBorrower) : 0,
              currentEmployment: coEmp?.currentEmploymentCoBorrower || coEmp?.typeCoBorrower === 'Retired' ? true : false,
              totalMonths: coEmp.totalMonths,
            };
            auxLoanApplication.borrowers[1].employmentHistory[indexCoEmploy] = updatedCoBorrower;
          });
        } else {
          setIsRemoveCoBorrowerForm(false);
          setIndexCoBorrowerForm(indexCoBorrowerForm - 1);
          auxLoanApplication.borrowers[1].employmentHistory.pop();
        }
      }
      if (incomeBorrowersForms.length !== 0 || indexIncomeForm !== 0) {
        if (!isRemoveIncomeForm) {
          incomeBorrowersForms.map((income, indexIncome) => {
            const updateIncome = {
              ...auxLoanApplication.borrowers[0].otherIncome[indexIncome],
              incomeType: income.typeIncome,
              grossMonthlyIncome: convertToNumber(income.monthlyIncome),
            };
            auxLoanApplication.borrowers[0].otherIncome[indexIncome] = updateIncome;
          });
        } else {
          setIsRemoveIncomeForm(false);
          setIndexIncomeForm(indexIncomeForm - 1);
          auxLoanApplication.borrowers[0].otherIncome.pop();
        }
      }
      if (coBorrower) {
        if (incomeCoBorrowersForms.length !== 0 || indexIncomeCoBorrowerForm !== 0) {
          if (!isRemoveIncomeCoBorrowerForm) {
            incomeCoBorrowersForms.map((incomeCoBorrower, indexIncomeCoBorrower) => {
              const updateIncome = {
                ...auxLoanApplication.borrowers[1].otherIncome[indexIncomeCoBorrower],
                incomeType: incomeCoBorrower.typeIncomeCoBorrower,
                grossMonthlyIncome: convertToNumber(incomeCoBorrower.grossMonthlyIncomeCoBorrower),
              };
              auxLoanApplication.borrowers[1].otherIncome[indexIncomeCoBorrower] = updateIncome;
            });
          } else {
            setIsRemoveIncomeCoBorrowerForm(false);
            setIndexIncomeCoBorrowerForm(indexIncomeCoBorrowerForm - 1);
            auxLoanApplication.borrowers[1].otherIncome.pop();
          }
        }
      }

      dispatch({
        type: 'LoanApplication/updateValue',
        payload: { ...auxLoanApplication },
      });
    }
  }, [employmentForms, coBorrowerForms, incomeBorrowersForms, incomeCoBorrowersForms]); // eslint-disable-line react-hooks/exhaustive-deps

  const calculateTotalMonths = (employment: any[], isCurrent: string, startDate: string, endDate: string): number => {
    const dates: { start: Date; end: Date }[] = [];
    const typeOfEmployment = startDate === 'startDate' ? 'employment' : 'coBorrower';
    employment.forEach((emp) => {
      const start = convertToDate(emp[startDate]);
      const end = emp[isCurrent] ? new Date() : convertToDate(emp[endDate]);

      if (start && end) {
        dates.push({ start, end });
      }
    });

    // Case: one job
    if (dates.length === 1) {
      const singleRange = dates[0];
      const totalMonths = (singleRange.end.getFullYear() - singleRange.start.getFullYear()) * 12 + (singleRange.end.getMonth() - singleRange.start.getMonth());

      handleChangeRefactor(totalMonths, 'totalMonths', changeIndex, typeOfEmployment);
      return totalMonths;
    }

    // Case: Multiple jobs
    dates.sort((a, b) => a.start.getTime() - b.start.getTime());

    const mergedDates: { start: Date; end: Date }[] = [];
    dates.forEach((range) => {
      if (mergedDates.length > 0 && mergedDates[mergedDates.length - 1].end >= range.start) {
        // Combine ranges if they overlap
        mergedDates[mergedDates.length - 1].end = new Date(Math.max(mergedDates[mergedDates.length - 1].end.getTime(), range.end.getTime()));
      } else {
        mergedDates.push(range);
      }
    });

    let total = 0;
    mergedDates.forEach((range) => {
      total += (range.end.getFullYear() - range.start.getFullYear()) * 12 + (range.end.getMonth() - range.start.getMonth());
    });

    handleChangeRefactor(total, 'totalMonths', changeIndex, typeOfEmployment);
    return total;
  };

  useEffect(() => {
    const borrowerTotal = calculateTotalMonths(employmentForms, 'currentEmployment', 'startDate', 'endDate');

    setTotalBorrowerMonths(borrowerTotal);
  }, [startDate, endDate, currentEmployment]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const coBorrowerTotal = calculateTotalMonths(coBorrowerForms, 'currentEmploymentCoBorrower', 'startDateCoBorrower', 'endDateCoBorrower');
    setTotalCoBorrowerMonths(coBorrowerTotal);
  }, [startDateCoBorrower, endDateCoBorrower, currentEmploymentCoBorrower]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleTotalYears = (totalMonths: number) => {
    const years = Math.floor(totalMonths / 12);
    const months = totalMonths % 12;
    return `Total employment history: ${years} years${months > 0 ? ` (${months} months)` : ''}`;
  };

  const formattedTotalBorrowerYears = useMemo(() => {
    return handleTotalYears(totalBorrowerMonths) || '';
  }, [totalBorrowerMonths]);
  const formattedTotalCoBorrowerYears = useMemo(() => {
    return handleTotalYears(totalCoBorrowerMonths) || '';
  }, [totalCoBorrowerMonths]);

  return (
    <>
      <EmploymentWrapper $isMobile={isMobile}>
        <Wrapper $gap={'1.563rem'}>
          <TextBlock
            text={templateConfig?.pages?.employedInformation?.currencyEmployment?.text || 'Current Employment'}
            direction={'flex-start'}
            color="default"
            type={TypeOfText.H2}
            isHidden={templateConfig?.pages?.employedInformation?.currencyEmployment?.isHidden || false}
          />
          {employmentForms?.map((employment, index) => (
            <FormGroupComponent key={index} data-testid="address-form" gap="1.563rem" onChange={(value, name) => handleChangeRefactor(value, name, index, 'employment')}>
              <DropDownInput
                label={templateConfig?.pages?.employedInformation?.employmentType?.label || 'Employment Type'}
                placeHolder={templateConfig?.pages?.employedInformation?.employmentType?.placeHolder || 'Select one'}
                options={templateConfig?.pages?.employedInformation?.employmentType?.options}
                size="13%"
                typeOfIcon="employment"
                labelContainer={true}
                isInfoModal={false}
                hasAsterisk={true}
                index={index}
                propertyType={PropertyType.Type}
                value={employment.type ?? ''}
                isHidden={templateConfig?.pages?.employedInformation?.employmentType?.isHidden || false}
              />
              <TextInput
                label={templateConfig?.pages?.employedInformation?.yourEmployment?.label || 'Who is your employer?'}
                hasAsterisk={true}
                placeholder={
                  loanApplication?.borrowers[0]?.employmentHistory[index]?.type === 'Retired'
                    ? 'Retired'
                    : templateConfig?.pages?.employedInformation?.yourEmployment?.placeHolder || 'Company Name'
                }
                size="28%"
                isDisabled={loanApplication?.borrowers[0]?.employmentHistory[index]?.type === 'Retired'}
                typeOfIcon="employment"
                isInfoModal={false}
                index={index}
                value={employment.employment || ''}
                propertyType={PropertyType.EmployerName}
                isHidden={templateConfig?.pages?.employedInformation?.yourEmployment?.isHidden || false}
              />
              <TextInput
                label={templateConfig?.pages?.employedInformation?.jobTitle?.label || 'Job Title or Position'}
                placeholder={
                  loanApplication?.borrowers[0]?.employmentHistory[index]?.type === 'Retired'
                    ? 'Retired'
                    : templateConfig?.pages?.employedInformation?.jobTitle?.placeHolder || 'Job Title'
                }
                isDisabled={loanApplication?.borrowers[0]?.employmentHistory[index]?.type === 'Retired'}
                size="22%"
                typeOfIcon="employment"
                value={loanApplication?.borrowers[0]?.employmentHistory[index]?.jobTitle || ''}
                isInfoModal={false}
                hasAsterisk={true}
                index={index}
                propertyType={PropertyType.JobTitle}
                isHidden={templateConfig?.pages?.employedInformation?.jobTitle?.isHidden || false}
              />
              <TextInput
                label={templateConfig?.pages?.employedInformation?.grossMonthlyIncome?.label || 'Gross Monthly Income'}
                hasAsterisk={true}
                placeholder={templateConfig?.pages?.employedInformation?.grossMonthlyIncome?.placeHolder || '$0'}
                size="32%"
                typeOfIcon="dollar"
                isInfoModal={false}
                index={index}
                propertyType={PropertyType.GrossMonthly}
                value={formatNumber(loanApplication?.borrowers[0]?.employmentHistory[index]?.grossMonthlyIncome) || 0}
                isHidden={templateConfig?.pages?.employedInformation?.grossMonthlyIncome?.isHidden || false}
              />
              <DateEmploymentPicker
                label={templateConfig?.pages?.employedInformation?.employmentStartDate?.label || 'Employment Start Date'}
                hasAsterisk={true}
                labelContainer={true}
                placeholder={templateConfig?.pages?.employedInformation?.employmentStartDate?.placeHolder || 'MM/DD/YYYY'}
                typeOfIcon="date"
                propertyName={'startDate'}
                size="24%"
                index={index}
                hasLessThanTwoYears={hasLessThanTwoYears}
                propertyType={PropertyType.StartDate}
                selectedOption={selectedOption}
                value={loanApplication?.borrowers[0]?.employmentHistory[index]?.startDate || ''}
                isHidden={templateConfig?.pages?.employedInformation?.employmentStartDate?.isHidden || false}
              />
              <DateEmploymentPicker
                label={templateConfig?.pages?.employedInformation?.employmentEndDate?.label || 'Employment End Date'}
                labelContainer={true}
                hasAsterisk={currentIndex.includes(index) || loanApplication?.borrowers[0]?.employmentHistory[index]?.currentEmployment ? false : true}
                placeholder={templateConfig?.pages?.employedInformation?.employmentEndDate?.placeHolder || 'MM/DD/YYYY'}
                typeOfIcon="date"
                propertyName={'endDate'}
                size="25%"
                index={index}
                propertyType={PropertyType.EndDate}
                selectedOption={selectedOption}
                value={loanApplication?.borrowers[0]?.employmentHistory[index]?.endDate || ''}
                isHidden={templateConfig?.pages?.employedInformation?.employmentEndDate?.isHidden || false}
                isDisabled={currentIndex.includes(index) || loanApplication?.borrowers[0]?.employmentHistory[index]?.currentEmployment || false}
              />
              {loanApplication?.borrowers[0]?.employmentHistory[index]?.type !== 'Retired' ? (
                <RadioButton
                  label={templateConfig?.pages?.employedInformation?.current?.label || 'Current Employment'}
                  propertyType={PropertyType.CurrentEmployment}
                  key={index}
                  value={templateConfig?.pages?.employedInformation?.current?.placeholder || 'Current'}
                  beValue={templateConfig?.pages?.employedInformation?.current?.beValue || ''}
                  width={'15%'}
                  selectedOption={selectedOption}
                  setSelectedOption={setSelectedOption}
                  isHidden={templateConfig?.pages?.employedInformation?.employmentEndDate?.isHidden || false}
                  index={index}
                  setCurrentIndex={setCurrentIndex}
                  currentIndex={currentIndex}
                />
              ) : (
                <></>
              )}
              {employmentForms.length > 0 && index > 0 && (
                <Button
                  label={templateConfig?.pages?.employedInformation?.button?.employment?.remove || '- Remove Employment'}
                  onClick={() => handleRemoveForm(index, 'employment')}
                  data-testid="button"
                  variant="secondary"
                  isHidden={templateConfig?.pages?.employedInformation?.button?.employment?.isHidden ?? false}
                />
              )}
              {!isMobile && <Separator />}
              {index === 0 && (
                <Button
                  label={templateConfig?.pages?.employedInformation?.button?.employment?.add || 'Employment'}
                  hasIcon={true}
                  onClick={() => handleAddForm('employment')}
                  data-testid="button"
                  variant="secondary"
                  isHidden={templateConfig?.pages?.employedInformation?.button?.employment?.isHidden ?? false}
                />
              )}
            </FormGroupComponent>
          ))}

          {!isMobile && employmentForms.length === 1 && <Separator />}
          <Button
            label={templateConfig?.pages?.employedInformation?.button?.income?.add || 'Additional Income'}
            hasIcon={true}
            onClick={() => handleAddForm('incomeBorrower')}
            data-testid="button"
            variant="secondary"
            isHidden={templateConfig?.pages?.employedInformation?.button?.income?.isHidden ?? false}
          />
          {indexIncomeForm !== 0 &&
            incomeBorrowersForms.map((_, index) => (
              <FormGroupComponent key={index} onChange={(value, name) => handleChangeRefactor(value, name, index, 'incomeBorrower')}>
                <TextBlock
                  text={templateConfig?.pages?.employedInformation?.typeOfIncome?.titleIncome?.text || 'Other Sources of Income'}
                  direction={'flex-start'}
                  color="default"
                  type={TypeOfText.H2}
                  isHidden={templateConfig?.pages?.employedInformation?.typeOfIncome?.titleIncome?.isHidden || false}
                />
                <TextBlock
                  text={
                    templateConfig?.pages?.employedInformation?.typeOfIncome?.subTitleIncome?.text ||
                    'Alimony, child support or separate maintenance income need not be revealed if you do not wish to have it considered as a basis for repayment.'
                  }
                  direction={'flex-start'}
                  color="default"
                  type={TypeOfText.H3}
                  isHidden={templateConfig?.pages?.employedInformation?.typeOfIncome?.subTitleIncome?.isHidden || false}
                />
                <TextInput
                  label={templateConfig?.pages?.employedInformation?.typeOfIncome?.label?.text || 'Type of Income'}
                  placeholder={templateConfig?.pages?.employedInformation?.typeOfIncome?.placeHolder?.text || 'Type of Income'}
                  size="25%"
                  typeOfIcon="employment"
                  isInfoModal={false}
                  index={index}
                  propertyType={PropertyType.TypeIncome}
                  value={loanApplication?.borrowers[0]?.otherIncome[index]?.incomeType || ''}
                  isHidden={templateConfig?.pages?.employedInformation?.typeOfIncome?.placeHolder?.isHidden || false}
                />
                <TextInput
                  label={templateConfig?.pages?.employedInformation?.grossMonthlyIncome?.label || 'Gross Monthly Income *'}
                  placeholder={templateConfig?.pages?.employedInformation?.grossMonthlyIncome?.placeHolder || '$0.00'}
                  size="25%"
                  typeOfIcon="dollar"
                  index={index}
                  isInfoModal={false}
                  value={formatNumber(loanApplication?.borrowers[0]?.otherIncome[index]?.grossMonthlyIncome) || ''}
                  propertyType={PropertyType.GrossMonthlyIncome}
                  isHidden={templateConfig?.pages?.employedInformation?.grossMonthlyIncome?.isHidden || false}
                />
                <Button
                  label={templateConfig?.pages?.employedInformation?.button?.income?.remove || '- Remove Income'}
                  onClick={() => handleRemoveForm(index, 'incomeBorrower')}
                  data-testid="button"
                  variant="secondary"
                  isHidden={templateConfig?.pages?.employedInformation?.typeOfIncome?.titleIncome?.isHidden || false}
                />
              </FormGroupComponent>
            ))}
          {!hasLessThanTwoYears ? (
            <>
              <CheckConsent
                text={templateConfig?.pages?.employedInformation?.consent?.text || 'I would like to continue my loan inquiry with less than 2 years employment history.'}
                direction="center"
                isHidden={templateConfig?.pages?.employedInformation?.consent?.isHidden || false}
                type={'lessThanTwoYears'}
                onChange={(value, name) => handleChangeRefactor(value, name, 0, 'employment')}
              />
              <TextBlock
                text={templateConfig?.pages?.employedInformation?.information?.text || 'I would like to continue my loan inquiry with less than 2 years employment history.'}
                direction={'flex-start'}
                width="100%"
                color="primary"
                isCustomColor={true}
                customColor="#ce3939"
                type={TypeOfText.Subtitle}
              />
            </>
          ) : null}
        </Wrapper>
        <CurrentMonthlyWrapper>
          <TextBlock
            text={`${templateConfig?.pages?.employedInformation?.totalCurrencyIncome} ${formatNumber(otherMonthlyIncome) || 0}`}
            direction={'flex-start'}
            width="50%"
            color="default"
            type={TypeOfText.Subtitle}
          />
          <TextBlock text={formattedTotalBorrowerYears || ''} direction={'flex-end'} width="50%" color="default" type={TypeOfText.Subtitle} />
        </CurrentMonthlyWrapper>
        {coBorrower && (
          <>
            <Wrapper $gap={'1.563rem'}>
              <TextBlock
                text={templateConfig?.pages?.employedInformation?.currencyEmployment?.text || 'Co-borrower Employment'}
                direction={'flex-start'}
                color="default"
                type={TypeOfText.H2}
                isHidden={templateConfig?.pages?.employedInformation?.currencyEmployment?.isHidden || false}
              />
              {coBorrowerForms.map((_, index) => (
                <FormGroupComponent key={index} data-testid="address-form" gap="1.563rem" onChange={(value, name) => handleChangeRefactor(value, name, index, 'coBorrower')}>
                  <DropDownInput
                    label={templateConfig?.pages?.employedInformation?.employmentType?.label || 'Employment Type'}
                    placeHolder={templateConfig?.pages?.employedInformation?.employmentType?.placeHolder || 'Select one'}
                    options={templateConfig?.pages?.employedInformation?.employmentType?.options}
                    size="13%"
                    typeOfIcon="employment"
                    labelContainer={true}
                    isInfoModal={false}
                    index={index}
                    value={loanApplication?.borrowers[1]?.employmentHistory[index]?.type || ''}
                    hasAsterisk={true}
                    propertyType={PropertyType.TypeCoBorrower}
                    isHidden={templateConfig?.pages?.employedInformation?.employmentType?.isHidden || false}
                  />
                  <TextInput
                    label={templateConfig?.pages?.employedInformation?.yourEmployment?.label || 'Who is your employer?'}
                    hasAsterisk={true}
                    placeholder={
                      loanApplication?.borrowers[1]?.employmentHistory[index]?.type === 'Retired'
                        ? 'Retired'
                        : templateConfig?.pages?.employedInformation?.yourEmployment?.placeHolder || 'Company Name'
                    }
                    size="28%"
                    isDisabled={loanApplication?.borrowers[1]?.employmentHistory[index]?.type === 'Retired'}
                    value={loanApplication?.borrowers[1]?.employmentHistory[index]?.employerName || ''}
                    typeOfIcon="employment"
                    isInfoModal={false}
                    index={index}
                    propertyType={PropertyType.EmploymentCoBorrower}
                    isHidden={templateConfig?.pages?.employedInformation?.yourEmployment?.isHidden || false}
                  />
                  <TextInput
                    label={templateConfig?.pages?.employedInformation?.jobTitle?.label || 'Job Title or Position'}
                    placeholder={
                      loanApplication?.borrowers[1]?.employmentHistory[index]?.type === 'Retired'
                        ? 'Retired'
                        : templateConfig?.pages?.employedInformation?.jobTitle?.placeHolder || 'Job Title'
                    }
                    size="22%"
                    isDisabled={loanApplication?.borrowers[1]?.employmentHistory[index]?.type === 'Retired'}
                    typeOfIcon="employment"
                    value={loanApplication?.borrowers[1]?.employmentHistory[index]?.jobTitle || ''}
                    isInfoModal={false}
                    index={index}
                    hasAsterisk={true}
                    propertyType={PropertyType.JobTitleCoBorrower}
                    isHidden={templateConfig?.pages?.employedInformation?.jobTitle?.isHidden || false}
                  />
                  <TextInput
                    label={templateConfig?.pages?.employedInformation?.grossMonthlyIncome?.label || 'Gross Monthly Income'}
                    hasAsterisk={true}
                    placeholder={templateConfig?.pages?.employedInformation?.grossMonthlyIncome?.placeHolder || '$0'}
                    size="32%"
                    typeOfIcon="dollar"
                    isInfoModal={false}
                    value={formatNumber(loanApplication?.borrowers[1]?.employmentHistory[index]?.grossMonthlyIncome) || 0}
                    index={index}
                    propertyType={PropertyType.MonthlyCoBorrower}
                    isHidden={templateConfig?.pages?.employedInformation?.grossMonthlyIncome?.isHidden || false}
                  />
                  <DateEmploymentPicker
                    label={templateConfig?.pages?.employedInformation?.employmentStartDate?.label || 'Employment Start Date'}
                    hasAsterisk={true}
                    labelContainer={true}
                    placeholder={templateConfig?.pages?.employedInformation?.employmentStartDate?.placeHolder || 'MM/DD/YYYY'}
                    typeOfIcon="date"
                    propertyName={'startDateCoBorrower'}
                    size="24%"
                    index={index}
                    hasLessThanTwoYears={hasCoBorrowerLessThanTwoYears}
                    propertyType={PropertyType.StartDateCoBorrower}
                    selectedOption={selectedCoBorrowerOption}
                    value={loanApplication?.borrowers[1]?.employmentHistory[index]?.startDate || ''}
                    isHidden={templateConfig?.pages?.employedInformation?.employmentStartDate?.isHidden || false}
                  />
                  <DateEmploymentPicker
                    label={templateConfig?.pages?.employedInformation?.employmentEndDate?.label || 'Employment End Date'}
                    labelContainer={true}
                    hasAsterisk={currentCoBorrowerIndex.includes(index) || loanApplication?.borrowers[1]?.employmentHistory[index]?.currentEmployment ? false : true}
                    placeholder={templateConfig?.pages?.employedInformation?.employmentEndDate?.placeHolder || 'MM/DD/YYYY'}
                    typeOfIcon="date"
                    propertyName={'endDateCoBorrower'}
                    size="25%"
                    index={index}
                    isDisabled={currentCoBorrowerIndex.includes(index) || loanApplication?.borrowers[1]?.employmentHistory[index]?.currentEmployment || false}
                    value={loanApplication?.borrowers[1]?.employmentHistory[index]?.endDate || ''}
                    propertyType={PropertyType.EndDateCoBorrower}
                    selectedOption={selectedCoBorrowerOption}
                    isHidden={templateConfig?.pages?.employedInformation?.employmentEndDate?.isHidden || false}
                  />
                  {loanApplication?.borrowers[1]?.employmentHistory[index]?.type !== 'Retired' ? (
                    <RadioButton
                      label={templateConfig?.pages?.employedInformation?.current?.label || 'Current Employment'}
                      propertyType={PropertyType.CurrentEmploymentCoBorrower}
                      key={index}
                      value={templateConfig?.pages?.employedInformation?.current?.placeholder || 'Current'}
                      beValue={templateConfig?.pages?.employedInformation?.current?.beValue || ''}
                      width={'15%'}
                      selectedOption={selectedCoBorrowerOption}
                      setSelectedOption={setSelectedCoBorrowerOption}
                      isHidden={templateConfig?.pages?.employedInformation?.current?.isHidden || false}
                      index={index}
                      setCurrentIndex={setCurrentCoBorrowerIndex}
                      currentIndex={currentCoBorrowerIndex}
                    />
                  ) : (
                    <></>
                  )}
                  {coBorrowerForms.length > 0 && index > 0 && (
                    <Button
                      label={templateConfig?.pages?.employedInformation?.button?.employment?.remove || '- Remove Employment'}
                      onClick={() => handleRemoveForm(index, 'coBorrower')}
                      data-testid="button"
                      variant="secondary"
                      isHidden={templateConfig?.pages?.employedInformation?.button?.employment?.isHidden || false}
                    />
                  )}
                  {!isMobile && <Separator />}
                  {index === 0 && (
                    <Button
                      label={templateConfig?.pages?.employedInformation?.button?.employment?.add || 'Employment'}
                      hasIcon={true}
                      onClick={() => handleAddForm('coBorrower')}
                      data-testid="button"
                      variant="secondary"
                      isHidden={templateConfig?.pages?.employedInformation?.button?.employment?.isHidden || false}
                    />
                  )}
                </FormGroupComponent>
              ))}
              {!isMobile && coBorrowerForms.length === 1 && <Separator />}
              <Button
                label={templateConfig?.pages?.employedInformation?.button?.income?.add || 'Additional Income'}
                hasIcon={true}
                onClick={() => handleAddForm('incomeCoBorrower')}
                data-testid="button"
                variant="secondary"
                isHidden={templateConfig?.pages?.employedInformation?.button?.income?.isHidden || false}
              />
              {indexIncomeCoBorrowerForm !== 0 &&
                incomeCoBorrowersForms.map((_, index) => (
                  <FormGroupComponent key={index} onChange={(value, name) => handleChangeRefactor(value, name, index, 'incomeCoBorrower')}>
                    <TextBlock
                      text={templateConfig?.pages?.employedInformation?.typeOfIncome?.titleIncome?.text || 'Other Sources of Income'}
                      direction={'flex-start'}
                      color="default"
                      type={TypeOfText.H2}
                      isHidden={templateConfig?.pages?.employedInformation?.typeOfIncome?.titleIncome?.isHidden || false}
                    />
                    <TextBlock
                      text={
                        templateConfig?.pages?.employedInformation?.typeOfIncome?.subTitleIncome?.text ||
                        'Alimony, child support or separate maintenance income need not be revealed if you do not wish to have it considered as a basis for repayment.'
                      }
                      direction={'flex-start'}
                      color="default"
                      type={TypeOfText.H3}
                      isHidden={templateConfig?.pages?.employedInformation?.typeOfIncome?.subTitleIncome?.isHidden || false}
                    />
                    <TextInput
                      label={templateConfig?.pages?.employedInformation?.typeOfIncome?.label?.text || 'Type of Income'}
                      placeholder={templateConfig?.pages?.employedInformation?.typeOfIncome?.placeHolder?.text || 'Type of Income'}
                      size="25%"
                      typeOfIcon="employment"
                      isInfoModal={false}
                      value={loanApplication?.borrowers[1]?.otherIncome[index]?.incomeType || ''}
                      propertyType={PropertyType.TypeIncomeCoBorrower}
                      isHidden={templateConfig?.pages?.employedInformation?.typeOfIncome?.label?.isHidden || false}
                    />
                    <TextInput
                      label={templateConfig?.pages?.employedInformation?.grossMonthlyIncome?.label || 'Gross Monthly Income *'}
                      placeholder={templateConfig?.pages?.employedInformation?.grossMonthlyIncome?.placeHolder || '$0.00'}
                      size="25%"
                      typeOfIcon="dollar"
                      isInfoModal={false}
                      value={formatNumber(loanApplication?.borrowers[1]?.otherIncome[index]?.grossMonthlyIncome) || ''}
                      propertyType={PropertyType.GrossMonthlyIncomeCoBorrower}
                      isHidden={templateConfig?.pages?.employedInformation?.grossMonthlyIncome?.isHidden || false}
                    />
                    <Button
                      label={templateConfig?.pages?.employedInformation?.button?.income?.remove || '- Remove Income'}
                      onClick={() => handleRemoveForm(index, 'incomeCoBorrower')}
                      data-testid="button"
                      variant="secondary"
                      isHidden={templateConfig?.pages?.employedInformation?.button?.income?.isHidden || false}
                    />
                  </FormGroupComponent>
                ))}
              {!hasCoBorrowerLessThanTwoYears ? (
                <>
                  <CheckConsent
                    text={templateConfig?.pages?.employedInformation?.consent?.text || 'I would like to continue my loan inquiry with less than 2 years employment history.'}
                    direction="center"
                    onChange={(value, name) => handleChangeRefactor(value, name, 0, 'coBorrower')}
                    isHidden={templateConfig?.pages?.employedInformation?.consent?.isHidden || false}
                    type={'lessThanTwoYearsCoBorrower'}
                  />
                  <TextBlock
                    text={templateConfig?.pages?.employedInformation?.information?.text || 'I would like to continue my loan inquiry with less than 2 years employment history.'}
                    direction={'flex-start'}
                    width="100%"
                    color="primary"
                    isCustomColor={true}
                    customColor="#ce3939"
                    type={TypeOfText.Subtitle}
                  />
                </>
              ) : null}
            </Wrapper>
            <CurrentMonthlyWrapper>
              <TextBlock
                text={`${templateConfig?.pages?.employedInformation?.totalCurrencyIncome}  ${formatNumber(otherCoBorrowerMonthlyIncome) || 0}`}
                direction={'flex-start'}
                width="50%"
                color="default"
                type={TypeOfText.Subtitle}
              />
              <TextBlock text={formattedTotalCoBorrowerYears || ''} direction={'flex-end'} width="50%" color="default" type={TypeOfText.Subtitle} />
            </CurrentMonthlyWrapper>
          </>
        )}
      </EmploymentWrapper>

      <NavigationButton
        variant="primary"
        nextButton={templateConfig?.pages?.employedInformation?.NavigationButton?.nextButton || 'Next'}
        isBackButton={templateConfig?.pages?.employedInformation?.NavigationButton?.backButton?.isHidden || false}
        backButton={templateConfig?.pages?.employedInformation?.NavigationButton?.backButton?.text || 'Back'}
        disabled={!formError}
        type="next"
        onAccess={onAccess}
        navigation={navigate}
        pageOrigin={PageOrigin.PERSONAL_INFORMATION}
        currentPage={PageOrigin.EMPLOYMENT_INFORMATION}
        destinationPage={PageOrigin.GOVERNMENT_MONITORING_INFORMATION}
      />
    </>
  );
};

export default EmploymentInformation;
