export const chartTotalText = (totalAmount: number) => ({
  id: 'centerText',
  beforeDraw: function (chart: any) {
    const { width, height, ctx } = chart;

    const drawCenteredText = (text: string, fontSize: string, fontWeight: string, color: string, offsetY: number) => {
      ctx.fillStyle = color;
      ctx.font = `${fontWeight} ${fontSize} Poppins`;
      const textX = Math.round((width - ctx.measureText(text).width) / 2);
      const textY = height / 2 + offsetY;
      ctx.fillText(text, textX, textY);
    };

    ctx.restore();

    drawCenteredText('Total', '0.75rem', '400', '#000000', -10);

    drawCenteredText(`$${totalAmount}`, '1rem', '700', '#008000', 15);

    ctx.save();
  },
});
