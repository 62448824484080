import React from 'react';
import { BackDrop, BodyText, InfoIcon, Modal, ModalContent, ModalFooter, ModalTitle } from './ModalErrorCode.styles';
import { ModalBody } from '../DefaultModal/DefaultModal.styles';
import { Button } from '@/components/Button/DefaultButton/Button';

interface ModalErrorCodeProps {
  isUnable: boolean;
  setIsUnable: any;
  title: string;
  description: string;
  labelButtonLeft: string;
  labelButtonRight: string;
  closeResume: () => void;
}

const ModalErrorCode = ({ isUnable, setIsUnable, title, description, labelButtonLeft, labelButtonRight, closeResume }: ModalErrorCodeProps) => {
  const closeModal = () => {
    if (setIsUnable) setIsUnable(false);
  };
  return (
    <>
      <BackDrop $isOpen={isUnable} data-testid="modal-backdrop" />
      <Modal $isOpen={isUnable}>
        <ModalBody>
          <InfoIcon />
          <ModalTitle>{title || 'Unable to Send Email'}</ModalTitle>
          <ModalContent>
            <BodyText>{description || `We were unable to send the verification code to your email.  Start a new application or check your email address and try again.`}</BodyText>
          </ModalContent>
        </ModalBody>
        <ModalFooter>
          <Button label={labelButtonLeft || 'Start New Application'} variant="secondary" buttonSize="l" onClick={closeResume} />
          <Button label={labelButtonRight || 'Try Another Email'} onClick={closeModal} variant="secondary" buttonSize="l" />
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ModalErrorCode;
