import React from 'react';
import DOMPurify from 'dompurify';

interface SafeHTMLProps {
  html: string;
  tag?: keyof JSX.IntrinsicElements;
  [key: string]: any;
}

const SafeHTML: React.FC<SafeHTMLProps> = ({ html, tag = 'div', ...props }) => {
  const cleanHTML = DOMPurify.sanitize(html, { ADD_ATTR: ['target'] });

  return React.createElement(tag, { dangerouslySetInnerHTML: { __html: cleanHTML }, ...props });
};

export default SafeHTML;
