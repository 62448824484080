import React, { useState, useCallback, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import GetStarted from '@pages/GetStarted/GetStarted';
import GetStartedAddress from '@pages/GetStartedAddress/GetStartedAddress';
import EstimatedAvailableEquity from '@pages/EstimatedAvailableEquity/EstimatedAvailableEquity';
import Vmo from '@pages/Vmo/Vmo';
import VmoDrawAmount from '@pages/VmoDrawAmount/VmoDrawAmount';
import PersonalInformation from '@pages/PersonalInformation/PersonalInformation';
import EmploymentInformation from '@pages/EmploymentInformation/EmploymentInformation';
import GovernmentMonitoringInformation from '@pages/GovernmentMonitoringInformation/GovernmentMonitoringInformation';
import InquirySummary from '@pages/InquirySummary/InquirySummary';
import Completion from '@pages/Completion/Completion';
import NotFound from '@pages/NotFound/NotFound';
import Declarations from '@pages/Declarations/Declarations';
import ProtectedRoute from '@/ProtectedRoute';
import LineAmountCalculator from '@pages/LineAmountCalculator/LineAmountCalculator';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { addCurrentPage, resetGoingBack, incrementStep, selectIsGoingBack } from '@/reducers/currentPage';
import { pageListOrder } from './defaultVerbiages';
import VmoV2 from '@/pages/VmoV2/VmoV2';
import ExperienceService from '@services/ExperienceService';
import FullStoryConsent from '@components/Layout/FullStoryConsent/FullStoryConsent';
import { handleCurrentVmoPage } from '@/utils/handleCurrentPage';
import { DefaultModal } from '@Modals/DefaultModal/DefaultModal';
import { theme } from './Theme';
import { pages } from './defaultVerbiages';
import useGtag from './hooks/useGtag';
import ScrollToTop from './components/ScrolllTop/ScrollTop';
type FullStoryWindow = typeof window & {
  FS?: {
    restart: () => void;
    shutdown: () => void;
    setUserVars: (key: string, vars: Record<string, string>) => void;
  };
};

const AppRouter = () => {
  const experienceService = new ExperienceService();
  const [accessedPages, setAccessedPages] = useState<Set<string>>(new Set());

  const currentPage = window.location.pathname;
  const [FSCurrentStep, setFSCurrentStep] = useState(currentPage);
  const dispatch = useAppDispatch();
  let loanApplication = useAppSelector((state) => state.LoanApplication);
  let templateConfig = useAppSelector((state) => state.TemplateConfig);
  const currentStep = useAppSelector((state) => state.currentPage.step);
  const pageName = useAppSelector((state) => state.currentPage.value);
  const { isEnabled: isVmoRedesignEnabled = false } = templateConfig?.features?.vmoRedesign ?? {};
  const [errorResponse, setErrorResponse] = useState<string>('');
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const isICE = templateConfig.isICE ?? false;
  const frontProgramType = loanApplication?.selectedProgramType === 'Line of Credit' ? 'helocData' : 'helData';
  const isHelProgram = frontProgramType === 'helData' || isICE;
  const hasDrawPage = (templateConfig?.pages?.hasDrawAmountPage || !isHelProgram) ?? false;
  const hasDeclarations = !!templateConfig?.pages?.declarations;
  const pagesWithVmoVersion = handleCurrentVmoPage(isVmoRedesignEnabled, hasDrawPage, hasDeclarations);
  const [loading, setLoading] = useState(true);
  const [isPageReloaded, setIsPageReloaded] = useState(false);
  const { gtag } = useGtag();
  const getInitialData = useCallback(async () => {
    try {
      await experienceService.getGlobalConfig();
    } catch (error) {
      console.error(error);
      setErrorResponse(String(error));
      throw error;
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    getInitialData();
  }, [getInitialData]);
  const handlePageAccess = useCallback((page: string) => {
    setAccessedPages((prevPages) => new Set(prevPages).add(page));
  }, []);
  const isGoingBack = useAppSelector(selectIsGoingBack);
  const setGlobalTheme = () => {
    theme.colors.bgSidebar = templateConfig?.globalTheme?.backgroundSidebarColor ?? theme.colors.bgSidebar;
    theme.colors.primary = templateConfig?.globalTheme?.primaryColor ?? theme.colors.primary;
    theme.colors.secondary = templateConfig?.globalTheme?.secondaryColor ?? theme.colors.secondary;
    theme.colors.errorColor = templateConfig?.globalTheme?.errorColor ?? theme.colors.errorColor;
    theme.colors.bgLightGrey = templateConfig?.globalTheme?.backgroundColorLight ?? theme.colors.bgLightGrey;
    theme.colors.bgSecondary = templateConfig?.globalTheme?.primaryColor ?? theme.colors.bgSecondary;
    theme.colors.textPrimaryLd = templateConfig?.globalTheme?.primaryColor ?? theme.colors.textPrimaryLd;
    theme.colors.sideBarColorSteps = templateConfig?.globalTheme?.sidebarColorSteps ?? theme.colors.sideBarColorSteps;
    theme.colors.sideBarColorStepsChecked = templateConfig?.globalTheme?.sidebarColorStepsChecked ?? theme.colors.primary;
    theme.colors.sidebarStrokeCircles = templateConfig?.globalTheme?.sidebarStrokeCircles ?? '#047f30';
    theme.colors.fillCustomCircleCheck = templateConfig?.globalTheme?.fillCustomCircleCheck ?? '#047f30';
    theme.colors.sideBarColorHeader = templateConfig?.globalTheme?.sidebarColorHeader ?? theme.colors.sideBarColorHeader;
    theme.colors.colorCustomCircleChecked = templateConfig?.globalTheme?.colorCustomCircleChecked ?? '#047f30';
    theme.colors.fillCustomCircleChecked = templateConfig?.globalTheme?.fillCustomCircleChecked || '#ffffff';

    theme.colors.bgSelectProgramButton = templateConfig?.globalTheme?.backgroundColorLight ?? theme.colors.bgSelectProgramButton;
  };

  useEffect(() => {
    if (errorResponse) {
      setErrorResponse(errorResponse);
      setOpenErrorModal(true);
    }
  }, [errorResponse, setErrorResponse]);

  useEffect(() => {
    if (Object.keys(templateConfig).length && templateConfig?.lenderId) {
      setGlobalTheme();
      setLoading(false);
    }
  }, [templateConfig]);

  const fullStoryManager = (fullstoryConsent: boolean) => {
    const FS = (window as FullStoryWindow).FS;
    if (FS) {
      if (fullstoryConsent) {
        console.log('Fullstory restart');
        FS.restart();
      } else {
        console.log('Fullstory shutdown');
        FS.shutdown();
      }
    }
  };
  useEffect(() => {
    const consent = loanApplication?.consent?.fullstoryConsent;
    fullStoryManager(Boolean(consent));
  }, [loanApplication?.consent?.fullstoryConsent]);

  const notIndexPages = ['/get-started'];
  const formatPath = (path: string): string => {
    const formattedPath = path.trimStart() ? path.slice(1) : path;
    return formattedPath
      .split('-')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const createVmoV1Arrays = (isVmoDrawAmountEnabled: boolean) => {
    const pages = ['vmo-line-amount'];
    const components = [Vmo, LineAmountCalculator];

    if (isVmoDrawAmountEnabled) {
      pages.push('vmo-draw-amount');
      components.push(VmoDrawAmount);
    }

    return { pages, components };
  };

  const NOT_FOUND_PAGE = '/not-found';
  const HOME_PAGE = '/';
  const isNotSpecialPage = currentPage !== NOT_FOUND_PAGE && currentPage !== HOME_PAGE;
  const isNotIndexPage = !notIndexPages.includes(currentPage);

  const isVmoV2 = templateConfig?.features?.vmoRedesign?.isEnabled ?? false;
  const { pages: vmoV1Pages } = createVmoV1Arrays(templateConfig?.pages?.hasDrawAmountPage || pages?.hasDrawAmountPage);
  const { components: vmoV1Components } = createVmoV1Arrays(templateConfig?.pages?.hasDrawAmountPage || pages?.hasDrawAmountPage);
  const vmoV2Components = [VmoV2];
  const vmoComponentsList = isVmoV2 ? vmoV1Components : vmoV2Components;

  const componentsList: any = [
    GetStarted,
    GetStartedAddress,
    EstimatedAvailableEquity,
    VmoV2,
    Vmo,
    LineAmountCalculator,
    VmoDrawAmount,
    PersonalInformation,
    EmploymentInformation,
    GovernmentMonitoringInformation,
    Declarations,
    InquirySummary,
    Completion,
  ];
  const filterList = componentsList.filter((component: React.FC) => !vmoComponentsList.includes(component));

  const filterPages = isVmoV2 ? pageListOrder.filter((page) => !vmoV1Pages.includes(page)) : pageListOrder;

  useEffect(() => {
    if (isNotSpecialPage) {
      dispatch(addCurrentPage(formatPath(window.location.pathname)));
      if (isNotIndexPage && isPageReloaded && !isGoingBack) {
        dispatch(incrementStep());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNotSpecialPage, isNotIndexPage, isPageReloaded, dispatch, currentPage]);

  useEffect(() => {
    const hasVisited = sessionStorage.getItem('hasVisited');
    if (hasVisited) {
      if (isGoingBack) dispatch(resetGoingBack());
      setIsPageReloaded(true);
    } else {
      sessionStorage.setItem('hasVisited', 'true');
    }
    gtag('event', 'screen_view', {
      pageId: currentPage,
      stepNumber: `Step ${currentStep} Page ${pageName}`,
      pageName: pageName,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, dispatch, isGoingBack]);
  const updateFullStoryPageInfo = (currentPage: string, currentStep: number, pagesWithVmoVersion: string[]) => {
    const FS = (window as FullStoryWindow).FS;
    if (FS) {
      FS.setUserVars('page', {
        pageId: currentPage,
        stepNumber: `Step ${currentStep}`,
        stepName: pagesWithVmoVersion[currentStep] || '',
      });
    }
  };

  useEffect(() => {
    if (currentPage !== FSCurrentStep || currentPage === 'get-started') {
      updateFullStoryPageInfo(currentPage, currentStep, pagesWithVmoVersion);
      setFSCurrentStep(currentPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, currentStep, pagesWithVmoVersion]);

  const canAccessPage = useCallback(
    (page: string) => {
      const currentIndex = filterPages.indexOf(page);
      const previousPages = filterPages.slice(0, currentIndex);
      return previousPages.every((prevPage) => accessedPages.has(prevPage));
    },
    [accessedPages, filterPages],
  );

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const renderProtectedRoute = (path: string, Component: React.FC<{ onAccess?: (page: string) => void }>, isProtected: boolean): React.ReactNode => (
    <Route key={path} path={path} element={<ProtectedRoute element={<Component onAccess={handlePageAccess} />} isProtected={isProtected} />} />
  );

  if (loading) {
    return (
      <div>
        <p>Loading ....</p>
      </div>
    );
  }
  return (
    <>
      <Router>
        {openErrorModal && errorResponse && (
          <DefaultModal openModal={openErrorModal} setOpenModal={setOpenErrorModal} isErrorModal={true} infoModal={{ body: errorResponse, button: 'Return to %%lenderName%%' }} />
        )}
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Navigate to="/get-started" />} />
          {filterPages?.map((page, index) => renderProtectedRoute(page, filterList[index], canAccessPage(page)))}
          <Route path="not-found" element={<NotFound />} />
          <Route path="*" element={<Navigate to="not-found" />} />
        </Routes>
        {currentPage === '/get-started' && <FullStoryConsent />}
      </Router>
    </>
  );
};

export default AppRouter;
