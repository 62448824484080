import styled from 'styled-components';
import addressInputIcon from '../../../assets/images/address-icons.svg?react';
import errorTriangle from '../../../assets/images/error_triangle.svg?react';

export type AddressProps = {
  $isDirection?: boolean;
  size?: string;
  $hasError?: boolean;
  $iconColor?: string;
  $isHidden?: boolean;
};
export const Container = styled.div<AddressProps>`
  position: relative;
  display: ${({ $isHidden }) => ($isHidden ? 'none' : 'flex')};
  flex-wrap: wrap;
  flex-direction: column;
  min-width: ${(props) => props.size};
  flex-shrink: 0;
  flex-grow: 1;
  @media only screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
    width: 100%;
    flex-shrink: revert;
  }
`;
export const InputContainer = styled.div<AddressProps>`
  height: 3.5rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-color: ${(props) => props.$hasError && props.theme.colors.errorColor};
`;

export const ErrorMessage = styled.span`
  display: flex;
  color: ${(props) => props.theme.colors.errorColor};
  font-family: Poppins;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const IconContainer = styled.div`
  position: relative;
  width: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
`;

export const AddressIcon = styled(addressInputIcon)<AddressProps>`
  width: 1.75rem;
  height: 1.75rem;
  path {
    width: 1.25rem;
    fill: ${({ theme }) => theme.colors.primary};
  }
  .circle-color {
    fill: ${(props) => (props.$iconColor ? props.$iconColor : 'inherent')};
  }
`;

export const ErrorIcon = styled(errorTriangle)`
  width: 0.75rem;
  height: 0.75rem;
  path {
    width: 0.25rem;
  }
  .circle-color {
    fill: ${(props) => props.theme.colors.borderColor};
  }
`;
