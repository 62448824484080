import React, { useCallback, useMemo, useState } from 'react';
import { InfoIcon, DropDownHeader, ArrowDown } from './DropDown.styles';
import { useAppSelector } from '@app/hooks';
import { DefaultModal } from '../Modals/DefaultModal/DefaultModal';

interface IModalInfoType {
  header: string;
  body: string;
  button: string;
}

interface IDropdownHeaderComponent {
  title: string;
  isOpen: boolean;
  toggleDropdown: () => void;
}

const DropdownHeader = ({ title = '', isOpen = false, toggleDropdown }: IDropdownHeaderComponent) => {
  const [modalInfo, setModalInfo] = useState<null | IModalInfoType>(null);
  let templateConfig = useAppSelector((state) => state.TemplateConfig);

  const modalInfoExample = useMemo(() => templateConfig?.pages?.LetsGetStarted?.modals?.exampleModal || { header: '', body: '', button: '' }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleModal = useCallback(() => {
    setModalInfo(modalInfoExample);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <DropDownHeader>
        <InfoIcon data-testid="info-icon" onClick={handleModal} aria-label={`More drop down info`} $infoIconColor={''} />
        <p>{title || 'Credit Information Reported'}</p>
        <ArrowDown data-testid="arrow-down-icon" aria-label="drop down arrow" $isOpen={isOpen} onClick={toggleDropdown} />
      </DropDownHeader>
      {modalInfo && <DefaultModal openModal={!!modalInfo} setOpenModal={() => setModalInfo(null)} infoModal={modalInfo} data-testid="modal" typeModal="default" />}
    </>
  );
};

export default DropdownHeader;
