import React, { useState } from 'react';
import { Button } from '@/components/Button/DefaultButton/Button';
import { Modal, ModalTitle, ModalBody, ModalContent, ModalFooter, BackDrop } from './EditModal.styles';
import { AddressAutoComplete } from '@components/input/AddressAutoComplete/AddressAutoComplete';
import { DropDownInput } from '@components/input/DropDownInput/DropDownInput';
import { TextInput } from '@components/input/TextInput/TextInput';
import { useAppSelector, useAppDispatch } from '@app/hooks';
import { FormGroupComponent } from '@components/input/FormGroup/FormGroup';
import _ from 'lodash';

export interface EditModalProps {
  openModal: boolean;
  setOpenModal: (isOpen: boolean) => void;
  size?: string;
  infoModal?: {
    header?: string;
    subtitle?: string;
    body?: string;
    button?: string;
  };
}

export enum PropertyType {
  Address = 'address',
  Suit = 'suit',
  City = 'city',
  State = 'state',
  Zip = 'zip',
  Country = 'country',
}

export const EditModal: React.FC<EditModalProps> = ({ openModal, setOpenModal, infoModal, size = '100%' }) => {
  let loanApplication = useAppSelector((state) => state.LoanApplication);
  let templateConfig = useAppSelector((state) => state.TemplateConfig);
  const currentValues = loanApplication.borrowerProperty;

  const dispatch = useAppDispatch();
  const [formValues, setFormValues] = useState({
    address: currentValues.propertyStreetAddress || '',
    suit: currentValues.propertyStreetAddress2 || '',
    city: currentValues.propertyCity || '',
    state: currentValues.propertyState || '',
    zip: currentValues.propertyZip || '',
    country: currentValues.propertyCountry || '',
  });

  const closeModal = () => {
    setOpenModal(false);
    let auxLoanApplication = _.cloneDeep(loanApplication);
    const updateProperty = {
      ...auxLoanApplication.borrowerProperty,
      propertyStreetAddress2: formValues.suit || currentValues.propertyStreetAddress2,
      propertyStreetAddress: formValues.address || currentValues.propertyStreetAddress,
      propertyCity: formValues.city || currentValues.propertyCity,
      propertyState: formValues.state || currentValues.propertyState,
      propertyCountry: formValues.country || currentValues.propertyCountry,
      propertyZip: formValues.zip || currentValues.propertyZip,
    };
    auxLoanApplication.borrowerProperty = updateProperty;

    dispatch({
      type: 'LoanApplication/updateValue',
      payload: { ...auxLoanApplication },
    });
  };
  const handleChange = _.debounce((value: string | number | boolean, name: string) => {
    setFormValues((prevValues: any) => ({
      ...prevValues,
      [name]: value,
    }));
    let obj: any = {};
    obj[name] = value;
    dispatch({ type: 'CurrentForm/addField', payload: { ...formValues, [name]: value } });
  }, 100);

  return (
    <>
      <BackDrop $isOpen={openModal} onClick={closeModal} data-testid="modal-backdrop" />
      <Modal $isOpen={openModal}>
        <ModalBody data-testid="modal-edit">
          <ModalTitle>{infoModal?.header}</ModalTitle>
          <ModalTitle>{infoModal?.subtitle}</ModalTitle>
          <ModalContent>
            <FormGroupComponent onChange={handleChange} data-testid="address-form">
              <AddressAutoComplete isDirection={true} size={size} value={loanApplication?.borrowerProperty?.propertyStreetAddress} />
              <TextInput
                placeholder={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.suit?.text || 'Suit'}
                value={formValues.suit}
                size={size}
                propertyType={PropertyType.Suit}
              />
              <TextInput
                placeholder={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.city?.text || 'City'}
                value={loanApplication?.borrowerProperty?.propertyCity}
                size={size}
                propertyType={PropertyType.City}
              />
              <DropDownInput
                placeHolder={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.state?.text || 'State'}
                options={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.state?.options}
                value={loanApplication?.borrowerProperty?.propertyState}
                size={size}
                propertyType={PropertyType.State}
              />
              <TextInput
                value={loanApplication?.borrowerProperty?.propertyZip || 'Zip'}
                placeholder={templateConfig?.pages?.LetsGetStarted?.consumerInformation?.zip?.text}
                size={size}
                propertyType={PropertyType.Zip}
              />
              <TextInput
                value={loanApplication?.borrowerProperty?.propertyCountry || 'Country'}
                placeholder={templateConfig?.pages?.LetsGetStartedAddress?.PropertyCard?.country}
                size={size}
                propertyType={PropertyType.Country}
              />
            </FormGroupComponent>
          </ModalContent>
          {infoModal?.button && (
            <ModalFooter>
              <Button label={infoModal.button} onClick={closeModal} variant="primary" buttonSize="s" />
            </ModalFooter>
          )}
        </ModalBody>
      </Modal>
    </>
  );
};
