import styled from 'styled-components';

export const WrapperContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 1.563rem;
  padding: 1.563rem;
  border-radius: 0.688rem;
  background: ${(props) => props.theme.colors.bgWhite};
`;

export const WrapperText = styled.div`
  margin-top: 1rem;
  :first-child {
    font-size: 0.75rem;
    font-weight: 400;
  }
`;

export const WrapperButton = styled.div`
  :first-child {
    margin-bottom: 0;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    nav {
      margin-top: 1rem;
    }
  }
`;
