import { createSlice } from '@reduxjs/toolkit';

export const InitialSlice = createSlice({
  name: 'InitialSlice',
  initialState: {},
  reducers: {},
});

export const {} = InitialSlice.actions;

export default InitialSlice.reducer;
