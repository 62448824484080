import styled from 'styled-components';

export const WrapperRadioButtons = styled.div`
  display: flex;
  justify-content: flex-start;
  div {
    width: 5rem;
    min-width: 8rem;
    button {
      padding: 2rem;
    }
    div {
      min-width: 1rem;
    }
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    div {
      width: 6rem;
      min-width: 5rem;
      div {
        min-width: 1rem;
      }
    }
  }
`;

export const WrapperQuestions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
  width: 100%;
`;

export const WrapperAlimony = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  width: 100%;
`;
