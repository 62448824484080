import React, { useState, useEffect } from 'react';
import { Wrapper } from '@/pages/wrapper.styles';
import { TextBlock } from '@/components/text/TextBlock';
import { HeaderContainer, SubtitleContainer, TableHelpContainer, AmountContainer, AmountVerbiageContainer } from './DebtConsolidation.styles';
import { useAppSelector } from '@app/hooks';
import { TypeOfText } from '@/components/text/TextBlock';
import { WrapperAmount } from '../WrapperAmount/WrapperAmount';
import { PotentialMonthly } from '../WrapperPotentialMonthly/PotentialMonthly';
import TableProgram from '../TableProgram/TableProgram';
import Dropdown from '@/components/DropDown/DropDown';
import { useIsMobile } from '@/hooks/useIsMobile';
import { pages } from '@/defaultVerbiages';
import { ConfirmationModal } from '@/components/Modals/ConfirmModal/ConfirmModal';
export interface DebtConsolidationProps {
  isHidden?: boolean;
  titleIsCustomColor?: boolean;
  titleCustomColor?: string;
  subtitleIsCustomColor?: boolean;
  subtitleCustomColor?: string;
  isReprice: boolean;
}

export const DebtConsolidation: React.FC<DebtConsolidationProps> = ({
  isHidden = false,
  titleIsCustomColor = false,
  titleCustomColor = '#3A149E',
  subtitleIsCustomColor = false,
  subtitleCustomColor = '#3A149E',
  isReprice,
}) => {
  const isMobile = useIsMobile();
  let templateConfig = useAppSelector((state) => state.TemplateConfig);
  const [openModal, setOpenModal] = useState(false);
  const [showDebtConsolidation, setShowDebtConsolidation] = useState(false);
  let loanApplication = useAppSelector((state) => state.LoanApplication);
  const isProgramSelected = loanApplication?.programName && loanApplication?.programName !== '';
  const hasTradelines = loanApplication?.borrowers[0]?.tradelines ?? false;
  useEffect(() => {
    if (isProgramSelected) {
      setOpenModal(true);
    } else {
      setShowDebtConsolidation(false);
    }
  }, [isProgramSelected]);

  useEffect(() => {
    if (isReprice) {
      setShowDebtConsolidation(false);
    }
  }, [isReprice]);

  const handleModalClose = (confirmed: boolean) => {
    if (confirmed) {
      setShowDebtConsolidation(true);
    }
    setOpenModal(false);
  };

  return (
    <>
      {openModal && hasTradelines && (
        <ConfirmationModal openModal={openModal} setOpenModal={setOpenModal} handleModalClose={handleModalClose} infoModal={pages?.vmo?.vmoV2?.modal?.infoDebConsolidation} />
      )}
      {showDebtConsolidation && hasTradelines ? (
        <Wrapper $isMobile={isMobile} $padding="0.5rem">
          <HeaderContainer $isHidden={isHidden} $titleIsCustomColor={titleIsCustomColor} $titleCustomColor={titleCustomColor}>
            <TextBlock
              text={templateConfig?.pages?.vmo?.vmoV2?.debtConsolidation?.title?.text || 'Discover the Triple Advantage'}
              type={TypeOfText.H1}
              isHidden={templateConfig?.pages?.vmo?.vmoV2?.debtConsolidation?.title?.isHidden || false}
            />
            <SubtitleContainer $isHidden={isHidden} $subtitleIsCustomColor={subtitleIsCustomColor} $subtitleCustomColor={subtitleCustomColor}>
              <TextBlock
                text={templateConfig?.pages?.vmo?.vmoV2?.debtConsolidation?.subtitle?.text || 'Debt Consolidation, Lower Monthly Payment, and Cash in Hand with a loanDepot HELOC'}
                type={TypeOfText.Subtitle}
                isHidden={templateConfig?.pages?.vmo?.vmoV2?.debtConsolidation?.subtitle?.isHidden || false}
              />
            </SubtitleContainer>
          </HeaderContainer>
          <TableProgram />
          <TableHelpContainer>
            <TextBlock
              text={
                templateConfig?.pages?.vmo?.vmoV2?.debtConsolidation?.tableQuestions?.text ||
                'Questions? Call (866)790-3940 to be connected to a Digital Loan Consultant between 5am-6pm PT. After hours? No problem, call and leave a message. A return call will be made by 9AM PT the following business day.'
              }
              type={TypeOfText.Subtitle}
              isHidden={templateConfig?.pages?.vmo?.vmoV2?.debtConsolidation?.tableQuestions?.isHidden ?? false}
            />
          </TableHelpContainer>
          <AmountContainer>
            <AmountVerbiageContainer>
              <WrapperAmount />
              <PotentialMonthly />
            </AmountVerbiageContainer>
            <Dropdown
              page={templateConfig?.pages?.vmo?.vmoV2?.creditReported || pages?.vmo?.vmoV2?.creditReported}
              width="40%"
              marginTop={true}
              isActive={templateConfig?.pages?.vmo?.vmoV2?.creditReported?.isHidden}
            />
            <Dropdown
              page={templateConfig?.pages?.vmo?.vmoV2?.potentialEstimate || pages?.vmo?.vmoV2?.potentialEstimate}
              width="55%"
              isActive={templateConfig?.pages?.vmo?.vmoV2?.potentialEstimate?.isHidden}
            />
          </AmountContainer>
        </Wrapper>
      ) : null}
    </>
  );
};
