import { useSelector, useDispatch } from 'react-redux';
import { geocodeByAddress, geocodeByPlaceId, getLatLng } from 'react-places-autocomplete';
import _ from 'lodash';
import { RootState } from '@app/store';

interface AddressComponents {
  types: string[];
  short_name: string;
}

interface GeolocationData {
  propertyStreetAddress: string;
  propertyCity: string | undefined;
  propertyState: string;
  propertyCounty: string | undefined;
  propertyZip: string;
  propertyLatitude: string;
  propertyLongitude: string;
  propertyCountry: string;
  propertyStreetAddress2?: string;
}

interface UpdatePayload {
  borrowerProperty?: Partial<GeolocationData>;
  mailingAddress?: Partial<GeolocationData>;
}

interface UnitData {
  propertyUnit?: string;
}

interface CountyManual {
  propertyCounty?: string;
}

interface CityManual {
  propertyCity?: string;
}

interface IdPlace {
  propertyIdPlace?: string;
}

export default class GeolocationService {
  dispatch = useDispatch();
  loanApplication = useSelector((state: RootState) => state.LoanApplication);
  currentForm = useSelector((state: RootState) => state.CurrentForm);

  getGeolocationData = async (addressString: string, unitData: UnitData, countyManual: CountyManual, cityManual: CityManual, idPlace: IdPlace) => {
    const borrowerProperty = _.get(this.loanApplication, ['borrowerProperty']);
    const mailingAddress = _.get(this.loanApplication, ['mailingAddress']);
    const propertyIsMailingAddress = _.get(this.loanApplication, ['propertyIsMailingAddress']);

    let results;
    /* validate if is autocomplete or manual search */
    if (idPlace?.propertyIdPlace) {
      results = await geocodeByPlaceId(idPlace.propertyIdPlace);
    } else {
      results = await geocodeByAddress(addressString);
    }

    const coordinates = await getLatLng(results[0]);
    const addressComponents: AddressComponents[] = results[0].address_components;

    const streetNumber = addressComponents.find((item) => item?.types?.includes('street_number'));
    const route = addressComponents.find((item) => item?.types?.includes('route'));
    const locality = addressComponents.find((item) => item?.types?.includes('locality') || item?.types?.includes('administrative_area_level_3'));
    const stateOrProvince = addressComponents.find((item) => item?.types?.includes('administrative_area_level_1'));
    const county = addressComponents.find((item) => item?.types?.includes('administrative_area_level_2'));
    const postalCode = addressComponents.find((item) => item?.types?.includes('postal_code'));

    let streetAddress;

    if (streetNumber?.short_name && route?.short_name) {
      streetAddress = `${streetNumber.short_name} ${route.short_name}`;
    } else {
      if (addressString) {
        streetAddress = addressString.split(', ')[0];
      } else {
        streetAddress = '';
      }
    }

    let isGoogleCounty = true;
    let isManualCity = !!cityManual?.propertyCity;

    if (!county?.short_name) {
      isGoogleCounty = false;
    }

    const addressDetailsObject: GeolocationData = {
      propertyStreetAddress: streetAddress ?? '',
      propertyCity: isManualCity ? cityManual?.propertyCity : (locality?.short_name ?? ''),
      propertyState: stateOrProvince?.short_name ?? '',
      propertyCounty: isGoogleCounty ? county?.short_name : (countyManual.propertyCounty ?? ''),
      propertyZip: postalCode?.short_name ?? '',
      propertyLatitude: coordinates?.lat?.toString() ?? '',
      propertyLongitude: coordinates?.lng?.toString() ?? '',
      propertyCountry: 'United States',
      propertyStreetAddress2: unitData?.propertyUnit || '',
    };

    const updatePayload: UpdatePayload = {
      borrowerProperty: {
        ...borrowerProperty,
        ...addressDetailsObject,
      },
    };

    if (propertyIsMailingAddress) {
      const mailingAddressDetailsObject: GeolocationData = {
        propertyStreetAddress: streetAddress ?? '',
        propertyCity: locality?.short_name ?? '',
        propertyState: stateOrProvince?.short_name ?? '',
        propertyCounty: isGoogleCounty ? county?.short_name : (countyManual.propertyCounty ?? ''),
        propertyZip: postalCode?.short_name ?? '',
        propertyLatitude: coordinates?.lat?.toString() ?? '',
        propertyLongitude: coordinates?.lng?.toString() ?? '',
        propertyCountry: 'United States',
      };

      updatePayload.mailingAddress = {
        ...mailingAddress,
        ...mailingAddressDetailsObject,
      };
    }

    this.dispatch({
      type: 'LoanApplication/updateValue',
      payload: { ...this.loanApplication, ...updatePayload },
    });
  };
}
