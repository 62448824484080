interface BorrowerDetails {
  'type-0'?: string;
  'employment-0'?: string;
  'jobTitle-0'?: string;
  'startDate-0'?: string;
  'endDate-0'?: string;
  current?: boolean;
  monthly?: string;
  contactMethod?: string;
  citizenship?: string;
  selectStatus?: string;
  dateBirthday?: string;
  SSN?: string;
  mobilePhone?: string;
  emailAddress?: string;
  firstName?: string;
  lastName?: string;
  relationship?: string;
  selectStatusCoborrower?: string;
  dateBirthdayCoBorrower?: string;
  mobilePhoneCoBorrower?: string;
  email?: string;
  contactMethodCoBorrower?: string;
  SSNCoBorrower?: string;
}
export const fieldFilled = (requiredValues: BorrowerDetails): boolean => {
  return Object.values(requiredValues).every((value) => {
    if (typeof value === 'boolean') return value;
    if (typeof value === 'string') return value.trim() !== '';
    return false;
  });
};
