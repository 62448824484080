import { useEffect, useState } from 'react';

declare global {
  interface Window {
    dataLayer: any[];
  }
}

const GTM_SCRIPT_URL = 'https://www.googletagmanager.com/gtag/js';
const GTM_SCRIPT_PARAM_NAME = 'id';

const useGtag = () => {
  const [isDatalayerDefined, setIsDatalayerDefined] = useState(false);

  function gtag(...args: any[]) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(args);
  }

  useEffect(() => {
    if (!isDatalayerDefined) {
      gtag('js', new Date());
      setIsDatalayerDefined(true);
    }
  }, [isDatalayerDefined]);

  interface GtmConfig {
    id: string;
  }

  const addGtmScript = (gmt: GtmConfig): void => {
    const script = document.createElement('script');
    script.src = `${GTM_SCRIPT_URL}?${GTM_SCRIPT_PARAM_NAME}=${gmt.id}`;
    script.async = true;
    script.onload = () => {
      gtag('config', gmt.id);
    };
    script.onerror = () => {
      console.log(`${gmt.id}:FAIL`);
    };
    document.head.insertBefore(script, document.head.childNodes[0]);
  };

  return { addGtmScript, gtag };
};

export default useGtag;
