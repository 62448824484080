import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { TextBlock, TypeOfText } from '@/components/text/TextBlock';
import { TextInput } from '@/components/input/TextInput/TextInput';
import Checkbox from '@components/input/CheckBox/CheckBox';
import _ from 'lodash';
import {
  Wrapper,
  BorrowerContainer,
  FormGroup,
  List,
  RacerContainer,
  CheckRacerGroup,
  CheckboxContainer,
  CheckboxHide,
  SubtitleContainer,
  GroupTitleContainer,
  CheckboxContainerSex,
} from './HDMAFormGroup.styles';
import { createArrayValidation } from '@/utils/arrayValidator';

interface OtherData {
  isOtherHispanicOrLatino: boolean;
  isAmericanIndianOrAlaskaNative: boolean;
  isOtherAsian: boolean;
  isOtherPacificIslander: boolean;
  americanIndianOrAlaskaNative: boolean;
  pacificIslanderOther: boolean;
  asianOther: boolean;
  hispanicOther: boolean;
  [key: string]: boolean;
  setFormError: boolean;
}

const delimiter = '-';

const ethnicityProperty = (index: number) => {
  return 'applicantEthnicity' + delimiter + index;
};
const raceProperty = (index: number) => {
  return 'applicantRace' + delimiter + index;
};
const sexProperty = (index: number) => {
  return 'applicantSex' + delimiter + index;
};

const parsePropertyForLoanApplication = (property: string) => {
  const splitProp = property.split(delimiter);
  return splitProp.length === 1 ? property : splitProp[0];
};

const HMDAFormGroup = ({ setFormError }) => {
  let loanApplication = useAppSelector((state) => state.LoanApplication);
  const dispatch = useAppDispatch();
  let templateConfig = useAppSelector((state) => state.TemplateConfig);

  let combinedEthnicityData = [];
  let combinedRaceEthnicity = [];
  let combinedSexData = [];
  const coApplicantLabel = templateConfig?.pages?.governmentMonitoringInformation?.labels?.coApplicant?.text || 'Co-Applicant';
  const applicantLabel = templateConfig?.pages?.governmentMonitoringInformation?.labels?.applicant?.text || 'Applicant';
  useEffect(() => {
    setupValidation();
    checkResume();
  });
  const [other, setOther] = useState<OtherData[]>([]);

  const setupValidation = () => {
    const borrowers = loanApplication?.borrowers || [];
    const allValidations = borrowers.map((element, index) => {
      const { applicantEthnicity, applicantRace, applicantSex } = element.governmentHMDA;
      const validations = {
        ethnicity: createArrayValidation(true, 1).isValidSync(applicantEthnicity),
        race: createArrayValidation(true, 1).isValidSync(applicantRace),
        sex: createArrayValidation(true, 1).isValidSync(applicantSex),
      };
      dispatch({
        type: 'Validation/updateField',
        payload: {
          [ethnicityProperty(index)]: validations.ethnicity,
          [raceProperty(index)]: validations.race,
          [sexProperty(index)]: validations.sex,
        },
      });
      return Object.values(validations).every(Boolean);
    });
    setFormError(!(allValidations.length > 0 && allValidations.every(Boolean)));
  };

  const checkResume = () => {
    if (loanApplication?.isResume) {
      const borrowers = loanApplication?.borrowers;
      borrowers.forEach((borrower, index) => {
        if (borrower.governmentHMDA) {
          let resumeObj: { [key: string]: any } = {};
          resumeObj[`applicantEthnicity-${index}`] = borrower.governmentHMDA.applicantEthnicity;
          resumeObj[`applicantRace-${index}`] = borrower.governmentHMDA.applicantRace;
          resumeObj[`applicantSex-${index}`] = borrower.governmentHMDA.applicantSex;

          dispatch({ type: 'CurrentForm/updateField', payload: resumeObj });
        }
      });
    }
  };

  const handleChangeInBorrower = (value: string[], propertyName: string, borrowerIndex = 0) => {
    let loanCopy = _.cloneDeep(loanApplication.borrowers[borrowerIndex].governmentHMDA);
    const parsedProperty = parsePropertyForLoanApplication(propertyName);
    _.set(loanCopy, [parsedProperty], value);

    let obj: { [key: string]: any } = {};
    obj[propertyName] = value ?? '';

    dispatch({ type: 'CurrentForm/addField', payload: obj });
    dispatch({
      type: 'LoanApplication/updateSpecificField',
      payload: {
        depth: ['borrowers', borrowerIndex, 'governmentHMDA'],
        value: loanCopy,
      },
    });

    if (propertyName.includes('applicantEthnicity') && (value.includes('notHispanic') || value.includes('I do not wish to furnish this information'))) {
      let newOther = [...other];
      if (!newOther[borrowerIndex]) {
        // @ts-ignore
        newOther[borrowerIndex] = {};
      }
      newOther[borrowerIndex] = {
        ...newOther[borrowerIndex],
        hispanicOther: false,
      };

      setOther([...newOther]);
    }
    if (propertyName.includes('applicantRace') && value.includes('I do not wish to furnish this information')) {
      let newOther = [...other];
      if (!newOther[borrowerIndex]) {
        // @ts-ignore
        newOther[borrowerIndex] = {};
      }
      newOther[borrowerIndex] = {
        ...newOther[borrowerIndex],
        americanIndianOrAlaskaNative: false,
        asianOther: false,
        pacificIslanderOther: false,
      };

      setOther([...newOther]);
    }
  };

  const handleDidNotDisclose =
    (borrowerIndex = 0) =>
    (value: string, propertyName: string) => {
      return handleChangeInBorrower([value], propertyName, borrowerIndex);
    };

  const handleEthnicity =
    (borrowerIndex = 0) =>
    (value: string, propertyName: string) => {
      return handleChangeInBorrower([value], propertyName, borrowerIndex);
    };

  const handleEthnicities =
    (borrowerIndex = 0) =>
    (value: string, propertyName: string) => {
      let loanData = _.cloneDeep(loanApplication.borrowers[borrowerIndex].governmentHMDA.applicantEthnicity);
      let prohibitedValues = ['notHispanic', 'I do not wish to furnish this information'];
      if (loanData.includes(value)) {
        combinedEthnicityData = loanData.filter((item) => item !== value);
      } else {
        combinedEthnicityData = [...loanData, value];
      }
      let removedProhibitedValues = combinedEthnicityData.filter((combinedEthnicityValue) => !prohibitedValues.includes(combinedEthnicityValue));
      let uniqueEthnicityValues = [...new Set(removedProhibitedValues)];
      handleChangeInBorrower(uniqueEthnicityValues, propertyName, borrowerIndex);
    };

  const handleChangeOtherText =
    (subGroup: string, borrowerIndex = 0) =>
    (value: string, propertyName: string) => {
      const parsedProperty = parsePropertyForLoanApplication(propertyName);
      // @ts-ignore
      let loanData = _.cloneDeep(loanApplication.borrowers[borrowerIndex].governmentHMDA[parsedProperty]);

      // subGroup+|+value
      let updatedLoan = loanData.map((item: string, index: number) => {
        if (item.includes(subGroup)) {
          let data = item.split('|');
          if (value != ' ') {
            data[1] = value;
          } else {
            data[1] = '';
          }
          return data.join('|');
        } else {
          return item;
        }
      });
      handleChangeInBorrower(updatedLoan, propertyName, borrowerIndex);
    };

  /** display the other text input value
   * example payload : [hispanicOther|testValue, mexican]
   */
  const parseDisplayOtherText =
    (subGroup: string, borrowerIndex = 0) =>
    (value: string, propertyName: string) => {
      const parsedProperty = parsePropertyForLoanApplication(propertyName);
      // @ts-ignore
      let displayString = loanApplication.borrowers[borrowerIndex]?.governmentHMDA[parsedProperty];

      if (!displayString) return '';
      if (displayString === '') return '';
      if (typeof displayString.forEach !== 'function') {
        return '';
      }
      let newArr = displayString.filter((val: string) => val.includes(subGroup));

      if (newArr.length > 0) {
        return newArr[0].split('|')[1];
      }
      return '';
    };

  const handleChangeOther =
    (subGroup: string, parentGroup: string, borrowerIndex = 0) =>
    (value: string, propertyName: string) => {
      let newOther = [...other];
      if (!newOther[borrowerIndex]) {
        // @ts-ignore
        newOther[borrowerIndex] = {};
      }
      newOther[borrowerIndex] = {
        ...newOther[borrowerIndex],
        [subGroup]: newOther[borrowerIndex] ? !newOther[borrowerIndex][subGroup] : true,
      };

      setOther([...newOther]);

      const parsedProperty = parsePropertyForLoanApplication(propertyName);
      // @ts-ignore
      let loanData = _.cloneDeep(loanApplication.borrowers[borrowerIndex].governmentHMDA[parsedProperty]);
      let prohibitedValues = 'I do not wish to furnish this information';
      if (loanData.includes(prohibitedValues)) {
        loanData = loanData.filter((item: string) => item !== prohibitedValues);
      }
      let existed = false;
      loanData.forEach((item: string, index: number) => {
        if (item.includes(subGroup)) {
          existed = true;
          return loanData.splice(index, 1);
        }
      });

      if (!existed) {
        loanData.push(value);
      }

      if (parentGroup && !loanData.includes(parentGroup) && loanData.includes(subGroup)) {
        loanData = loanData.filter((data: string) => data != 'notHispanic' && data != '');
        loanData.push(parentGroup);
      }
      handleChangeInBorrower(loanData, propertyName, borrowerIndex);
    };

  const parseOtherCheckbox =
    (subGroup: string, borrowerIndex = 0) =>
    (value: string, propertyName: string) => {
      const parsedProperty = parsePropertyForLoanApplication(propertyName);
      // @ts-ignore
      let displayString = loanApplication.borrowers[borrowerIndex].governmentHMDA[parsedProperty];

      if (!displayString) return '';
      if (displayString === '') return '';
      if (typeof displayString.forEach !== 'function') {
        return '';
      }
      let selected = false;
      displayString.forEach((string: string) => {
        if (string.includes(subGroup)) {
          selected = true;
        }
      });
      return selected ? value : '';
    };

  const handleRace =
    (borrowerIndex = 0) =>
    (value: string, propertyName: string) => {
      let loanData = loanApplication.borrowers[borrowerIndex].governmentHMDA.applicantRace;
      if (!loanData) return;
      if (loanData.includes(value)) {
        combinedRaceEthnicity = loanData.filter((item) => item !== value);
      } else {
        combinedRaceEthnicity = [...loanData, value];
      }
      let prohibitedValues = ['notHispanic', 'I do not wish to furnish this information'];
      let removedProhibitedValues = combinedRaceEthnicity.filter((combinedRaceEthnicityValue) => !prohibitedValues.includes(combinedRaceEthnicityValue));
      let uniqueEthnicityValues = [...new Set(removedProhibitedValues)];
      handleChangeInBorrower(uniqueEthnicityValues, propertyName, borrowerIndex);
    };

  const interceptParseDisplay =
    (borrowerIndex = 0) =>
    (value: string, propertyName: string) => {
      const parsedProperty = parsePropertyForLoanApplication(propertyName);
      let displayString = _.get(loanApplication, ['borrowers', borrowerIndex, 'governmentHMDA', parsedProperty], []);
      if (!displayString.length) {
        return '';
      }
      return displayString.includes(value) ? value : '';
    };

  const handleGender = (index: number) => (value: string, propertyName: string) => {
    let loanData = _.cloneDeep(loanApplication.borrowers[index].governmentHMDA.applicantSex);
    let prohibitedValues = ['I do not wish to furnish this information'];
    if (loanData.includes(value)) {
      combinedSexData = loanData.filter((item) => item !== value);
    } else {
      combinedSexData = [...loanData, value];
    }
    let removedProhibitedValues = combinedSexData.filter((combinedSexValue) => !prohibitedValues.includes(combinedSexValue));
    let uniqueSexValues = [...new Set(removedProhibitedValues)];
    handleChangeInBorrower(uniqueSexValues, propertyName, index);
  };
  return (
    <Wrapper data-testid="HDMA_form">
      <BorrowerContainer>
        <TextBlock
          type={TypeOfText.H1}
          direction="left"
          text={
            `${templateConfig?.pages?.governmentMonitoringInformation?.title?.text} ${loanApplication?.borrowers[0].borrowerFirstName} ${loanApplication?.borrowers[0].borrowerLastName}` ||
            'Applicant: Firstname Lastname'
          }
          isHidden={templateConfig?.pages?.governmentMonitoringInformation?.title?.isHidden || false}
        />
        <SubtitleContainer>
          <TextBlock
            type={TypeOfText.Subtitle}
            direction="left"
            text={
              templateConfig?.pages?.governmentMonitoringInformation?.subtitle?.text ||
              `The purpose of collecting this information is to help ensure that all applicants are treated fairly and that the housing needs of communities and neighborhoods are being fulfilled. For residential mortgage lending, Federal law requires that we ask applicants for their demographic information (ethnicity, race, and sex) in order to monitor our compliance with equal credit opportunity, fair housing, and home mortgage disclosure laws. You are not required to provide this information, but are encouraged to do so. You may select one or more designations for "Ethnicity" and one or more designations for "Race". The law provides that we may not discriminate on the basis of this information, or on whether you choose to provide it. However, if you choose not to provide the information and you have made this application in person, Federal regulations require us to note your ethnicity, race, and sex on the basis of visual observation or surname. If you do not wish to provide some or all of this information, please check below.`
            }
            isHidden={templateConfig?.pages?.governmentMonitoringInformation?.subtitle?.isHidden || false}
          />
        </SubtitleContainer>
        {loanApplication?.borrowers.map((borrower, index) => (
          <BorrowerContainer key={`${borrower.creditScore}-${index}`}>
            <FormGroup $isHidden={templateConfig?.pages?.governmentMonitoringInformation?.checkList?.primaryEthnicity?.isHidden ?? false}>
              <GroupTitleContainer>
                <TextBlock
                  text={index === 0 ? `Primary ${applicantLabel} Ethnicity` : `${coApplicantLabel} Ethnicity`}
                  direction={'flex-start'}
                  color="default"
                  type={TypeOfText.H2}
                />
              </GroupTitleContainer>

              <List>
                <CheckboxContainer>
                  <Checkbox
                    value="I do not wish to furnish this information"
                    propertyName={ethnicityProperty(index)}
                    text={templateConfig?.pages?.governmentMonitoringInformation?.checkList?.primaryEthnicity?.notWish || 'I do not wish to furnish this information'}
                    handleChange={handleDidNotDisclose(index)}
                    parseDisplayValue={interceptParseDisplay(index)}
                  />
                  <Checkbox
                    value="notHispanic"
                    propertyName={ethnicityProperty(index)}
                    handleChange={handleEthnicity(index)}
                    text={templateConfig?.pages?.governmentMonitoringInformation?.checkList?.primaryEthnicity?.notHispanic || 'Not Hispanic or Latino'}
                    parseDisplayValue={interceptParseDisplay(index)}
                  />
                  <Checkbox
                    value="hispanic"
                    propertyName={ethnicityProperty(index)}
                    handleChange={handleEthnicities(index)}
                    text={templateConfig?.pages?.governmentMonitoringInformation?.checkList?.primaryEthnicity?.hispanic || 'Hispanic or Latino'}
                    parseDisplayValue={interceptParseDisplay(index)}
                  />
                  <CheckboxContainer>
                    <Checkbox
                      value="hispanicMexican"
                      propertyName={ethnicityProperty(index)}
                      handleChange={handleEthnicities(index)}
                      text={templateConfig?.pages?.governmentMonitoringInformation?.checkList?.primaryEthnicity?.hispanicMexican || 'Mexican'}
                      parseDisplayValue={interceptParseDisplay(index)}
                    />
                    <Checkbox
                      value="hispanicPuertoRican"
                      propertyName={ethnicityProperty(index)}
                      handleChange={handleEthnicities(index)}
                      text={templateConfig?.pages?.governmentMonitoringInformation?.checkList?.primaryEthnicity?.hispanicPuertoRican || 'Puerto Rican'}
                      parseDisplayValue={interceptParseDisplay(index)}
                    />
                    <Checkbox
                      value="hispanicCuban"
                      propertyName={ethnicityProperty(index)}
                      handleChange={handleEthnicities(index)}
                      text={templateConfig?.pages?.governmentMonitoringInformation?.checkList?.primaryEthnicity?.hispanicCuban || 'Cuban'}
                      parseDisplayValue={interceptParseDisplay(index)}
                    />
                    <Checkbox
                      propertyName={ethnicityProperty(index)}
                      value="hispanicOther"
                      text={templateConfig?.pages?.governmentMonitoringInformation?.checkList?.primaryEthnicity?.hispanicOther || 'Other Hispanic or Latino'}
                      handleChange={handleChangeOther('hispanicOther', 'hispanicOther', index)}
                      parseDisplayValue={parseOtherCheckbox('hispanicOther', index)}
                    />
                    <CheckboxHide $isVisible={other[index]?.hispanicOther}>
                      <TextInput
                        placeholder="Other Hispanic or Latino Description"
                        size="30rem"
                        propertyName={ethnicityProperty(index)}
                        handleChangeText={handleChangeOtherText('hispanicOther', index)}
                        parseDisplayValue={parseDisplayOtherText('hispanicOther', index)}
                      />
                    </CheckboxHide>
                  </CheckboxContainer>
                </CheckboxContainer>
              </List>
            </FormGroup>
            <FormGroup $isHidden={templateConfig?.pages?.governmentMonitoringInformation?.checkList?.raceGroup?.isHidden ?? false}>
              <GroupTitleContainer>
                <TextBlock text={index === 0 ? `Primary ${applicantLabel} Race` : `${coApplicantLabel} Race`} direction="flex-start" color="default" type={TypeOfText.H2} />
              </GroupTitleContainer>
              <List>
                <RacerContainer>
                  <CheckRacerGroup>
                    <Checkbox
                      propertyName={raceProperty(index)}
                      value="I do not wish to furnish this information"
                      handleChange={handleDidNotDisclose(index)}
                      text={templateConfig?.pages?.governmentMonitoringInformation?.checkList?.raceGroup?.notProvided || 'I do not wish to furnish this information'}
                      parseDisplayValue={interceptParseDisplay(index)}
                    />
                    <Checkbox propertyName={raceProperty(index)} handleChange={handleRace(index)} text="Asian" value="asian" parseDisplayValue={interceptParseDisplay(index)} />
                    <CheckboxContainer>
                      <Checkbox
                        propertyName={raceProperty(index)}
                        value="asianIndian"
                        text={templateConfig?.pages?.governmentMonitoringInformation?.checkList?.raceGroup?.asianIndian || 'Asian Indian'}
                        handleChange={handleRace(index)}
                        parseDisplayValue={interceptParseDisplay(index)}
                      />
                      <Checkbox
                        propertyName={raceProperty(index)}
                        value="asianChinese"
                        handleChange={handleRace(index)}
                        text={templateConfig?.pages?.governmentMonitoringInformation?.checkList?.raceGroup?.asianChinese || 'asianChinese'}
                        parseDisplayValue={interceptParseDisplay(index)}
                      />
                      <Checkbox
                        propertyName={raceProperty(index)}
                        value="asianFilipino"
                        handleChange={handleRace(index)}
                        text={templateConfig?.pages?.governmentMonitoringInformation?.checkList?.raceGroup?.asianFilipino || 'Filipino'}
                        parseDisplayValue={interceptParseDisplay(index)}
                      />
                      <Checkbox
                        propertyName={raceProperty(index)}
                        value="asianJapanese"
                        handleChange={handleRace(index)}
                        text={templateConfig?.pages?.governmentMonitoringInformation?.checkList?.raceGroup?.asianJapanese || 'Japanese'}
                        parseDisplayValue={interceptParseDisplay(index)}
                      />
                      <Checkbox
                        propertyName={raceProperty(index)}
                        value="asianKorean"
                        handleChange={handleRace(index)}
                        text={templateConfig?.pages?.governmentMonitoringInformation?.checkList?.raceGroup?.asianKorean || 'Korean'}
                        parseDisplayValue={interceptParseDisplay(index)}
                      />
                      <Checkbox
                        propertyName={raceProperty(index)}
                        value="asianVietnamese"
                        handleChange={handleRace(index)}
                        text={templateConfig?.pages?.governmentMonitoringInformation?.checkList?.raceGroup?.asianVietnamese || 'Vietnamese'}
                        parseDisplayValue={interceptParseDisplay(index)}
                      />
                      <Checkbox
                        propertyName={raceProperty(index)}
                        value="asianOther"
                        text={templateConfig?.pages?.governmentMonitoringInformation?.checkList?.raceGroup?.asianOther || 'Other Asian'}
                        handleChange={handleChangeOther('asianOther', 'asianOther', index)}
                        parseDisplayValue={parseOtherCheckbox('asianOther', index)}
                      />
                      <CheckboxHide $isVisible={other[index]?.asianOther}>
                        <TextInput
                          placeholder={templateConfig?.pages?.governmentMonitoringInformation?.checkList?.raceGroup?.otherAsianPlaceholder || 'Other Asian Description'}
                          propertyName={raceProperty(index)}
                          handleChangeText={handleChangeOtherText('asianOther', index)}
                          parseDisplayValue={parseDisplayOtherText('asianOther', index)}
                        />
                      </CheckboxHide>
                    </CheckboxContainer>
                  </CheckRacerGroup>
                  <CheckRacerGroup>
                    <Checkbox
                      propertyName={raceProperty(index)}
                      value="pacificIslander"
                      handleChange={handleRace(index)}
                      text={templateConfig?.pages?.governmentMonitoringInformation?.checkList?.raceGroup?.pacificIslander || 'Native Hawaiian/Other Pacific Islander'}
                      parseDisplayValue={interceptParseDisplay(index)}
                    />
                    <CheckboxContainer>
                      <Checkbox
                        propertyName={raceProperty(index)}
                        value="pacificIslanderHawaiian"
                        handleChange={handleRace(index)}
                        text={templateConfig?.pages?.governmentMonitoringInformation?.checkList?.raceGroup?.pacificIslanderHawaiian || 'Native Hawaiian'}
                        parseDisplayValue={interceptParseDisplay(index)}
                      />
                      <Checkbox
                        propertyName={raceProperty(index)}
                        value="pacificIslanderGuamanian"
                        handleChange={handleRace(index)}
                        text={templateConfig?.pages?.governmentMonitoringInformation?.checkList?.raceGroup?.pacificIslanderGuamanian || 'Guamanian or Chamorro'}
                        parseDisplayValue={interceptParseDisplay(index)}
                      />
                      <Checkbox
                        propertyName={raceProperty(index)}
                        value="pacificIslanderSamoan"
                        handleChange={handleRace(index)}
                        text={templateConfig?.pages?.governmentMonitoringInformation?.checkList?.raceGroup?.pacificIslanderSamoan || 'Samoan'}
                        parseDisplayValue={interceptParseDisplay(index)}
                      />
                      <Checkbox
                        propertyName={raceProperty(index)}
                        value="pacificIslanderOther"
                        text={templateConfig?.pages?.governmentMonitoringInformation?.checkList?.raceGroup?.pacificIslanderOther || 'Other Pacific Islander'}
                        parseDisplayValue={parseOtherCheckbox('pacificIslanderOther', index)}
                        handleChange={handleChangeOther('pacificIslanderOther', 'pacificIslanderOther', index)}
                      />
                      <CheckboxHide $isVisible={other[index]?.pacificIslanderOther}>
                        <TextInput
                          placeholder={templateConfig?.pages?.governmentMonitoringInformation?.checkList?.raceGroup?.pacificIslanderOther || 'Other Pacific Islander Description'}
                          propertyName={raceProperty(index)}
                          handleChangeText={handleChangeOtherText('pacificIslanderOther', index)}
                          parseDisplayValue={parseDisplayOtherText('pacificIslanderOther', index)}
                        />
                      </CheckboxHide>
                    </CheckboxContainer>
                  </CheckRacerGroup>
                  <CheckRacerGroup>
                    <Checkbox
                      propertyName={raceProperty(index)}
                      value="black"
                      handleChange={handleRace(index)}
                      text={templateConfig?.pages?.governmentMonitoringInformation?.checkList?.raceGroup?.black || 'Black or African American'}
                      parseDisplayValue={interceptParseDisplay(index)}
                    />
                    <Checkbox propertyName={raceProperty(index)} value="white" handleChange={handleRace(index)} text="White" parseDisplayValue={interceptParseDisplay(index)} />
                    <Checkbox
                      propertyName={raceProperty(index)}
                      value="americanIndianOrAlaskaNative"
                      text={templateConfig?.pages?.governmentMonitoringInformation?.checkList?.raceGroup?.americanIndianOrAlaskaNative || 'American Indian or Alaska Native'}
                      parseDisplayValue={parseOtherCheckbox('americanIndianOrAlaskaNative', index)}
                      handleChange={handleChangeOther('americanIndianOrAlaskaNative', 'americanIndianOrAlaskaNative', index)}
                    />
                    <CheckboxHide $isVisible={other[index]?.americanIndianOrAlaskaNative}>
                      <TextInput
                        placeholder={templateConfig?.pages?.governmentMonitoringInformation?.checkList?.raceGroup?.otherAmericanOrAlaskan || 'Enter name of enrolled principal'}
                        propertyName={raceProperty(index)}
                        handleChangeText={handleChangeOtherText('americanIndianOrAlaskaNative', index)}
                        parseDisplayValue={parseDisplayOtherText('americanIndianOrAlaskaNative', index)}
                      />
                    </CheckboxHide>
                  </CheckRacerGroup>
                </RacerContainer>
              </List>
            </FormGroup>
            <FormGroup $isHidden={templateConfig?.pages?.governmentMonitoringInformation?.checkList?.sexProperty?.isHidden ?? false}>
              <GroupTitleContainer>
                <TextBlock text={index === 0 ? `Primary ${applicantLabel} Sex` : `${coApplicantLabel} Sex`} direction={'flex-start'} color="default" type={TypeOfText.H2} />
              </GroupTitleContainer>

              <List>
                <CheckboxContainer>
                  <Checkbox
                    value="I do not wish to furnish this information"
                    propertyName={sexProperty(index)}
                    text={templateConfig?.pages?.governmentMonitoringInformation?.checkList?.sexProperty?.notProvided || 'I do not wish to furnish this information'}
                    handleChange={handleDidNotDisclose(index)}
                    parseDisplayValue={interceptParseDisplay(index)}
                  />
                  <CheckboxContainerSex>
                    <Checkbox
                      value="Male"
                      propertyName={sexProperty(index)}
                      handleChange={handleGender(index)}
                      text={templateConfig?.pages?.governmentMonitoringInformation?.checkList?.sexProperty?.male || 'Male'}
                      parseDisplayValue={interceptParseDisplay(index)}
                    />
                    <Checkbox
                      value="Female"
                      propertyName={sexProperty(index)}
                      handleChange={handleGender(index)}
                      text={templateConfig?.pages?.governmentMonitoringInformation?.checkList?.sexProperty?.female || 'Female'}
                      parseDisplayValue={interceptParseDisplay(index)}
                    />
                  </CheckboxContainerSex>
                </CheckboxContainer>
              </List>
            </FormGroup>
          </BorrowerContainer>
        ))}
      </BorrowerContainer>
    </Wrapper>
  );
};

export default HMDAFormGroup;
