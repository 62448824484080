import styled, { css } from 'styled-components';
import InfoIcons from '../../../assets/images/info-icons.svg?react';
import ArrowLeftIcons from '../../../assets/images/arrow-left.svg?react';
import ArrowRightIcons from '../../../assets/images/arrow-right.svg?react';

export interface ICardProps {
  $isActive?: boolean;
  $isOriginationFee?: boolean;
}

interface IconProps {
  size?: string;
  color?: string;
}

export const CarouselContainer = styled.div`
  display: flex;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  gap: 1rem;
  padding-bottom: 1rem;
  -webkit-overflow-scrolling: auto;
  width: 97%;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: 96%;
    gap: 0.625rem;
  }
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const CarouselWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const NavButton = styled.button<{ direction: string }>`
  position: absolute;
  top: 45%;
  ${(props) => (props.direction === 'left' ? 'left: -8%;' : 'right: -6%;')}
  transform: translateY(-50%);
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  z-index: 1;
  transition: background-color 0.3s;
  background: transparent;
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    ${(props) => (props.direction === 'left' ? 'left: -10%;' : 'right: -6%;')}
    top: 48%;
  }
`;

const FlexColumn = css`
  display: flex;
  flex-direction: column;
`;

export const WrapperContentCard = styled.div`
  margin: 0 0.625rem;
  font-family: Poppins;
  width: 47%;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints?.md}) {
    width: 100%;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    margin: 0 0.313rem;
  }
`;

export const Card = styled.div<ICardProps>`
  ${FlexColumn}
  background-color: ${(props) => props.theme.colors.bgWhite};
  width: 15.625rem;
  height: 21.875rem;
  border: 0.063rem solid ${({ theme }) => theme.colors.bgMediumGrey};
  border-radius: 0.632rem;
  box-shadow:
    0px 0px 0.057rem -0.057rem rgba(15, 27, 47, 0.12),
    0px 0.057rem 0.115rem -0.057rem rgba(15, 27, 47, 0.16);
  scroll-snap-align: start;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: 9.375rem;
    height: max-content;
  }
`;

export const HeaderCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: auto;
  height: 2.01rem;
  background-color: ${(props) => props.theme.colors.bgSecondary};
  color: ${(props) => props.theme.colors.bgWhite};
  padding: 0.632rem 0.919rem;
  border-radius: 0.632rem 0.632rem 0 0;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    height: 1.25rem;
    padding: 0.5rem;
  }

  :nth-child(1) {
    font-weight: 400;
    font-size: 0.75rem;
    width: 80%;
    @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      font-size: 0.654rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  :nth-child(2) {
    font-size: 0.75rem;
    font-weight: 300;
    line-height: 1.436rem;
    @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      font-size: 0.35rem;
      line-height: 0.861rem;
    }
  }
`;

export const SectionCard = styled.div<ICardProps>`
  ${FlexColumn}
  align-items: center;
  width: 6.0625rem;
  height: 6.0625rem;
  flex-shrink: 0;
  padding: 0.4rem 0 0 0;
  border-radius: 0.574rem;
  background: ${(props) => props.theme.colors.bgSection};
  box-shadow: 0px 0.1rem 0rem 0 rgba(0, 0, 0, 0.07);
  text-align: center;
  position: relative;
  svg {
    position: absolute;
    right: 0.3rem;
    top: 0.313rem;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: 3.75rem;
    height: 4rem;
    padding-left: 0.313rem;
    padding-right: 0.313rem;
  }
  & > :nth-child(1) {
    width: 4.125rem;
    height: ${({ $isOriginationFee }) => ($isOriginationFee ? '2.5rem' : '1.608rem')};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.563rem;
    background: ${(props) => props.theme.colors.bgValueProgram};
    box-shadow: 0px 0.057rem 0.574rem rgba(0, 0, 0, 0.25);
    @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      height: ${({ $isOriginationFee }) => ($isOriginationFee ? '1.5rem' : '1rem')};
      width: 1.9375rem;
    }

    p {
      color: ${(props) => props.theme.colors.bgSection};
      text-align: center;
      font-family: Poppins;
      font-size: ${({ $isOriginationFee }) => ($isOriginationFee ? '0.75rem' : '0.861rem')};
      font-style: normal;
      font-weight: 500;
      line-height: 1.436rem;
      @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
        font-size: ${({ $isOriginationFee }) => ($isOriginationFee ? '0.4rem' : '0.5rem')};
        line-height: ${({ $isOriginationFee }) => ($isOriginationFee ? '0.8rem' : '1.436rem')};
      }
    }
  }
  & > :nth-child(2) {
    ${FlexColumn}
    font-size: 0.625rem;
    font-weight: 400;
    color: ${(props) => props.theme.colors.textDarkGrey};
    height: 1.608rem;
    margin-top: 0.5rem;

    @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      height: 1rem;
      font-size: 0.4375rem;
    }
  }

  & > :nth-child(3) {
    font-size: 0.75rem;
    font-weight: 500;
    color: ${(props) => props.theme.colors.textDarkGrey};
    padding: 0 0.5rem;
    position: relative;
    margin-bottom: 0.313rem;
    line-height: 1rem;
    @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      font-size: 0.4375rem;
      line-height: 0.603rem;
      padding: 0;
      margin-bottom: 0.1rem;
    }
  }
`;
export const Counter = styled.span`
  font-size: 0.5rem;
  position: absolute;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    font-size: 0.25rem;
  }
`;
export const BodyCard = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 1.625rem;
  column-gap: 0.688rem;
  padding: 1.25rem 1.25rem 0 1.25rem;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    row-gap: 1rem;
    column-gap: 0.4375rem;
    padding-top: 0.75rem;
  }
`;

export const WrapperBody = styled.div`
  display: flex;
  justify-content: center;
`;

export const WrapperButton = styled.div`
  nav {
    font-size: 0.75rem;
    height: 2.3125rem;

    @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
      height: 1.125rem;
    }

    button {
      font-size: 0.804rem;
      font-weight: 600;
      font-style: normal;
      line-height: 1.149rem;
      font-family: ${(props) => props.theme.fonts.main};
      min-width: 2.375rem;
      height: 1.875rem;
      margin-top: 1.4375rem;
      padding: 0 0.5rem;
      border-radius: 0.1rem;
      @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
        font-size: 0.5rem;
        height: 1.125rem;
        width: 3.25rem;
        margin-top: 1rem;
      }
    }
  }
`;

export const FooterCard = styled.div`
  height: 0.574rem;
  border-radius: 0 0 0.632rem 0.632rem;
  background: ${(props) => props.theme.colors.bgFooterProgramCard};
`;

export const InfoIcon = styled(InfoIcons)<IconProps>`
  width: ${({ size }) => size || '0.625rem'};
  height: ${({ size }) => size || '0.625rem'};
  cursor: pointer;
  path {
    fill: ${({ color, theme }) => (color ? color : theme.colors.bgFooterProgramCard)};
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints?.sm}) {
    display: none;
  }
`;

export const ArrowLeftIcon = styled(ArrowLeftIcons)<IconProps>`
  width: ${({ size }) => size || '0.5rem'};
  height: ${({ size }) => size || '0.875rem'};
  fill: ${({ color }) => color || 'currentColor'};
`;
export const ArrowRightIcon = styled(ArrowRightIcons)<IconProps>`
  width: ${({ size }) => size || '0.5rem'};
  height: ${({ size }) => size || '0.875rem'};
  fill: ${({ color }) => color || 'currentColor'};
`;
