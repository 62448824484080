import styled, { css } from 'styled-components';
import NCUA from '../../assets/images/NCUA-icon.svg?react';

export interface FooterStylesProps {
  $variant?: 'primary' | 'secondary';
  $isVmoV2?: boolean;
  $isHidden?: boolean;
}

const HoverIcon = css`
  position: absolute;
  transform: translateX(-50%);
  z-index: 1;
  visibility: hidden;
  font-family: var(--font-Poppins);
  padding-top: 0.5625rem;
  padding-bottom: 0.625rem;
  padding-left: 1.0625rem;
  padding-right: 1.0625rem;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  color: #000;
  line-height: 1.125rem;
  text-align: center;
  border-radius: 0.5625rem;
  background: #d9d9d9;
  background-color: #fff;
  flex-shrink: 1;
  max-width: 30rem;
  width: max-content;
  overflow-wrap: break-word;
  border: 0.03125rem solid #d9d9d9;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    left: auto;
    right: 0.5rem;
    font-size: 0.5rem;
    max-width: 20rem;
    transform: translateX(0%);
  }
  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 92%;
    transform: translateX(-50%);
    border-width: 0.5rem;
    border-style: solid;
    border-color: #d9d9d9 transparent transparent transparent;
  }
`;

export const FooterWrapper = styled.div<FooterStylesProps>`
  width: 100%;
  position: static;
  background: ${({ $variant, theme }) => ($variant === 'primary' ? theme.colors.bgWhite : theme.colors.bgGrey)};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    flex-direction: column;
  }
`;

export const FooterDisclosures = styled.div<FooterStylesProps>`
  display: ${({ $isHidden }) => ($isHidden ? 'none' : 'flex')};
  flex-direction: column;
  padding: 1rem 1rem 0 1rem;
  text-align: center;
  width: ${({ $isVmoV2 }) => ($isVmoV2 ? '85%' : '64%')};
  margin-left: ${({ $isVmoV2 }) => ($isVmoV2 ? '0rem' : '14rem')};
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    gap: ${({ $isVmoV2 }) => ($isVmoV2 ? '0' : '0.5rem')};
    padding: 0 1rem;
    margin-left: 0;
    padding-top: 1rem;
    width: ${({ $isVmoV2 }) => ($isVmoV2 ? '85%' : '100%')};
  }
`;

export const DisclosuresContent = styled.div<FooterStylesProps>`
  display: ${({ $isHidden }) => ($isHidden ? 'none' : 'flex')};
  font-family: Poppins;
  flex-direction: column;
  font-style: normal;
  font-weight: 400;
  font-size: ${({ $isVmoV2 }) => ($isVmoV2 ? '0.688rem' : '1.063rem')};
  line-height: 1.1rem;
  margin-bottom: 0.5rem;
  flex-shrink: 0;
  &:first-child {
    font-family: Poppins;
    font-size: ${({ $isVmoV2 }) => ($isVmoV2 ? '0.688rem' : '0.813rem')};
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  color: ${({ $variant, theme }) => ($variant === 'primary' ? theme.colors.textSecondary : theme.colors.textPrimary)};
  a {
    text-decoration: ${({ $isVmoV2 }) => ($isVmoV2 ? 'underline' : 'none')};
    color: ${({ $isVmoV2, theme }) => ($isVmoV2 ? theme.colors.textSecondary : theme.colors.textPrimaryLd)};
    &:hover {
      font-weight: 600;
      text-decoration: underline;
    }
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    font-size: ${({ $isVmoV2 }) => ($isVmoV2 ? '0.413rem' : '0.813rem')};
    line-height: 0.523rem;
    &:first-child {
      font-size: ${({ $isVmoV2 }) => ($isVmoV2 ? '0.413rem' : '0.813rem')};
      line-height: 0.523rem;
    }
  }
`;

export const FooterLogo = styled.div`
  height: 6rem;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 1.188rem;
  line-height: 1rem;
  white-space: normal;
  padding: 0.688rem;
`;

export const FooterVersion = styled.div<FooterStylesProps>`
  color: ${({ $variant, theme, $isVmoV2 }) =>
    $variant === 'primary' && !$isVmoV2 ? theme.colors.textLightGrey : $isVmoV2 ? theme.colors.textSecondary : theme.colors.textPrimary};
  text-align: center;
  padding: ${({ $isVmoV2 }) => ($isVmoV2 ? '1.5rem 0' : '0 0 1rem 0')};
  font-family: ${({ theme }) => theme.fonts.main};
  font-style: normal;
  font-weight: 400;
  font-size: 0.813rem;
  line-height: normal;
  display: ${({ $isHidden }) => ($isHidden ? 'none' : 'flex')};
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    font-size: ${({ $isVmoV2 }) => ($isVmoV2 ? '0.300rem' : '0.813rem')};
    padding: ${({ $isVmoV2 }) => ($isVmoV2 ? '1rem 0' : '0 0 1rem 0')};
  }
`;

export const NcuaIcon = styled(NCUA)`
  width: 4rem;
  height: 3rem;
  margin-left: 0.5rem;
  cursor: pointer;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: 3.25rem;
  }
`;

export const EqualHousingIcon = styled.div<FooterStylesProps>`
  position: relative;
  display: inline-block;
  font-size: ${({ $isVmoV2 }) => ($isVmoV2 ? '0.413rem' : '0.813rem')};

  img {
    padding: 1rem;
    height: 4rem;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    img {
      height: 4rem;
    }
  }
`;

export const HoverText = styled.span`
  bottom: 4rem;
  left: -33%;
  ${HoverIcon};
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    bottom: 4rem;
    left: -15%;
    &::after {
      left: 40.5%;
    }
  }
  ${EqualHousingIcon}:hover & {
    opacity: 1;
    visibility: visible;
  }
`;

export const NcuaIconContainer = styled.div<FooterStylesProps>`
  position: relative;
  display: inline-block;
  font-size: ${({ $isVmoV2 }) => ($isVmoV2 ? '0.413rem' : '0.813rem')};
  img {
    padding: 1rem;
    height: 4rem;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    img {
      height: 4rem;
    }
  }
`;

export const HoverTextTwo = styled.span`
  bottom: 4rem;
  left: -73%;
  ${HoverIcon};

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    bottom: 4rem;
    left: -90%;
    &::after {
      left: 64%;
    }
  }

  ${NcuaIconContainer}:hover & {
    opacity: 1;
    visibility: visible;
  }
`;
