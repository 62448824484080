import styled, { css } from 'styled-components';

interface SectionBodyProps {
  $fullWidth?: boolean;
  $isBasicInformation?: boolean;
  $isTotalMonths?: boolean;
}

const FlexColumn = css`
  display: flex;
  flex-direction: column;
`;

const FlexRow = css`
  display: flex;
  flex-direction: row;
`;

const WrapperStyles = css`
  width: 100%;
  ${FlexColumn};
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    p {
      text-align: start;
      font-size: 1.313rem;
    }
  }
`;

export const WrapperTitle = styled.div<SectionBodyProps>`
  ${WrapperStyles};
  p {
    font-weight: 500;
  }
`;

export const WrapperApplicant = styled.div<SectionBodyProps>`
  ${WrapperStyles};
  p {
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.011rem;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.xs}) {
    padding-bottom: 1rem;
  }
`;

export const WrapperSubtitle = styled.div`
  ${WrapperStyles};
  gap: 0.938rem;
  p {
    font-size: 0.938rem;
    font-weight: 400;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.xs}) {
    padding-bottom: 1rem;
  }
`;

export const Separator = styled.div<SectionBodyProps>`
  height: ${({ $isTotalMonths }) => ($isTotalMonths ? '0.02rem' : '0.063rem')};
  margin-top: ${({ $isTotalMonths }) => ($isTotalMonths ? '0rem' : '0.2rem')};
  background-color: ${(props) => props.theme.colors.textLightGrey};
  width: 100%;
`;

export const Section = styled.div`
  width: 100%;
  ${FlexColumn};
`;

export const SectionHeader = styled.div`
  margin-left: 0.5rem;
  margin-bottom: 1rem;
  p {
    font-weight: 600;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    p {
      font-size: 1.063rem;
    }
  }
`;

export const SectionBody = styled.div<SectionBodyProps>`
  width: 100%;
  ${FlexRow};
  flex-wrap: wrap;
  gap: 0.9rem;
  flex: 1 1 3rem;
  & > * {
    width: ${(props) => (props.$fullWidth ? '99%' : '49%')};
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    & > * {
      width: 100%;
    }
  }
`;

export const ItemBody = styled.div<SectionBodyProps>`
  ${FlexRow};
  max-width: ${({ $isBasicInformation }) => ($isBasicInformation ? '48%' : '100%')};
  margin-bottom: ${({ $isTotalMonths }) => ($isTotalMonths ? '0rem' : '0.5rem')};
  margin-left: 0.5rem;
  align-items: flex-start;
  justify-content: space-between;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    max-width: 100%;
  }
  > :first-child {
    p {
      font-size: 0.938rem;
      font-weight: 400;
      color: ${({ theme }) => theme.colors.textSecondLightGrey};
      text-align: start;
    }
    @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
      width: max-content;
    }
  }
  > :last-child {
    justify-content: end;
    min-width: 0;
    margin-left: 0.5rem;
    @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
      width: max-content;
      margin-right: 0.5rem;
    }
    h1 {
      text-align: end;
    }
    p {
      font-size: 0.938rem;
    }
  }
`;
