import { MainContainer } from './Main.styles';
import Router from '@/AppRouter';
import { useAppSelector } from '@app/hooks';

export interface MainProps {
  variant: 'primary' | 'secondary';
}

export const MainContent: React.FC<MainProps> = ({ variant }) => {
  let templateConfig = useAppSelector((state) => state.TemplateConfig);

  const isVmoV2 = templateConfig?.features?.vmoRedesign?.isEnabled ?? false;

  return (
    <MainContainer $variant={variant} data-testid="main-content" $isVmoV2={isVmoV2.toString()}>
      <Router />
    </MainContainer>
  );
};
