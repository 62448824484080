import styled, { css } from 'styled-components';

interface WrapperAmountProps {
  $totalDrawColor?: string;
  $isHidden?: boolean;
}
const FontsStyles = css`
  font-size: 0.875rem;
  font-style: normal;
  line-height: 1.125rem;
  font-family: Poppins;
  @media only screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
    font-size: 0.643rem;
    line-height: 0.827rem;
  }
`;

const RowStyles = css`
  align-items: center;
  justify-content: space-between;
  gap: 1.5rem;
`;

const ColStyles = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 20%;
  p {
    text-align: right;
    font-weight: 400;
    ${FontsStyles};
    color: ${(props) => props.theme.colors.textDarkGrey};
    width: 100%;
  }
`;
export const WrapperAmountContainer = styled.div<WrapperAmountProps>`
  display: ${({ $isHidden }) => ($isHidden ? 'none' : 'flex')};
  flex-direction: column;
  border-radius: 1.125rem;
  background: ${(props) => props.theme.colors.bgWhite};
  box-shadow: 0rem 0.063rem 0.625rem 0rem rgba(0, 0, 0, 0.13);
  padding: 1.563rem;
  width: 40%;
  gap: 0.3rem;
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    width: 100%;
  }
  h4 {
    margin: 0;
    ${FontsStyles};
    font-weight: 600;
    margin: 0;
  }
`;

export const Row = styled.div<WrapperAmountProps>`
  ${RowStyles};
  display: ${({ $isHidden }) => ($isHidden ? 'none' : 'flex')};
`;

export const TotalRow = styled.div<WrapperAmountProps>`
  ${RowStyles};
  display: ${({ $isHidden }) => ($isHidden ? 'none' : 'flex')};
  div p {
    color: ${({ $totalDrawColor }) => $totalDrawColor};
  }
  div span {
    color: ${({ $totalDrawColor }) => $totalDrawColor};
  }
`;

export const ColPercent = styled.div`
  ${ColStyles};
  p {
    font-weight: 500;
  }
`;

export const Col = styled.div`
  ${ColStyles};

  span {
    font-weight: 400;
    ${FontsStyles};

    margin: 0;
    color: ${(props) => props.theme.colors.textDarkGrey};
    width: 100%;
  }
`;

export const SeparatorTotal = styled.div<WrapperAmountProps>`
  height: 0.063rem;
  background-color: ${({ $totalDrawColor }) => $totalDrawColor};
  width: 100%;
`;
