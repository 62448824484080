import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container } from './GetStartedAddress.styles';
import { PropertyCard } from '@/components/Layout/PropertyCard/PropertyCard';
import { MapOut } from '@/components/image/MapOut/MapOut';
import { NavigationButton, PageOrigin } from '@components/Button/NavigationButton/NavigationButton';
import { makePatchCall } from '@/services/makePatchCall';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import _ from 'lodash';
import { selectIsLoading } from '@/reducers/currentPage';

import { LoanApplicationState } from '@/reducers/LoanApplication.types';
import { Dispatch } from 'redux';
interface GetStartedAddressProps {
  onAccess: (page: string) => void;
  pageOrigin: PageOrigin;
}

type Action = {
  actionProperties: {
    endpoint: string;
  };
};
const GetStartedAddress: React.FC<GetStartedAddressProps> = ({ onAccess }) => {
  const isLoading = useAppSelector(selectIsLoading);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  let loanApplication = useAppSelector((state) => state.LoanApplication);
  let templateConfig = useAppSelector((state) => state.TemplateConfig);

  const validateCreditScore = async (auxLoanApplication: LoanApplicationState, dispatch: Dispatch, templateConfig: any) => {
    const newAction: Action = {
      actionProperties: {
        endpoint: `/credit-score/%%loanId%%/validate`,
      },
    };

    const payload = {
      creditScore: auxLoanApplication.borrowers[0]?.creditScore || 850,
    };

    await makePatchCall(newAction, auxLoanApplication, payload, dispatch, templateConfig);
  };

  useEffect(() => {
    const validateScore = async () => {
      try {
        if (templateConfig?.isICE && !isLoading) {
          const auxLoanApplication = _.cloneDeep(loanApplication);

          await validateCreditScore(auxLoanApplication, dispatch, templateConfig);
        }
      } catch (error) {
        console.error('Error fetching rates:', error);
      }
    };

    validateScore();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Container>
        <PropertyCard />
        <MapOut />
      </Container>
      <NavigationButton
        variant="primary"
        nextButton={templateConfig?.pages?.LetsGetStartedAddress?.NavigationButton?.nextButton || 'Next'}
        isBackButton={templateConfig?.pages?.LetsGetStartedAddress?.NavigationButton?.backButton?.isHidden || false}
        backButton={templateConfig?.pages?.LetsGetStartedAddress?.NavigationButton?.backButton?.text || 'Back'}
        disabled={false}
        type="next"
        onAccess={onAccess}
        navigation={navigate}
        pageOrigin={PageOrigin.GET_STARTED}
        currentPage={PageOrigin.GET_STARTED_ADDRESS}
        destinationPage={PageOrigin.ESTIMATED_AVAILABLE_EQUITY}
      />
    </>
  );
};

export default GetStartedAddress;
